import Dates from "../../../../helpers/Dates";

interface IFormularioF1Turista {
    state: any
}
export default function FormularioF1Turista({state}: IFormularioF1Turista){
    const stadoCivilOptions = [
        { value: 'solteiro', label: 'Solteiro(a)' },
        { value: 'casado', label: 'Casado(a)' },
        { value: 'uniao estavel', label: 'União Estável' },
        { value: 'separado', label: 'Separado(a)' },
        { value: 'desquitado', label: 'Desquitado(a)' },
        { value: 'viuvo', label: 'Viúvo(a)' },
        { value: 'divorciado', label: 'Divorciado' },
    ];

    /*const motivosViagem = [
        { value: 'estudo_carga_menor', label: 'Estudos (com carga horária inferior a 18 horas semanais)' },
        { value: 'estudo_carga_maior', label: 'Estudos (com carga horária superior a 18 horas semanais)' },
    ];*/

    const motivosViagem = [
        { value: 'turismo', label: 'Turismo' },
        { value: 'negocios', label: 'Negócios' },
        { value: 'estudo_informal', label: 'Estudo Informal (carga horária máxima de 18 horas semanais por até 06 meses)' },
        { value: 'visita_familiar', label: 'Visita Familiar' },
        { value: 'estudo_carga_menor', label: 'Estudos (com carga horária inferior a 18 horas semanais)' },
        { value: 'estudo_carga_maior', label: 'Estudos (com carga horária superior a 18 horas semanais)' },
    ];

    const quemCustearaList = [
        { value: 'eu', label: 'Eu mesmo(a)' },
        { value: 'outra_pessoa', label: 'Outra pessoa' },
        { value: 'empresa', label: 'Empresa / Organização' },
    ];

    const relacaoOptions = [
        { value: 'conjuge', label: 'Cônjuge' },
        { value: 'noivo', label: 'Noivo(a)' },
        { value: 'filho', label: 'Filho(a)' },
        { value: 'irmao', label: 'Irmã(ão)' },
        { value: 'outros', label: 'Outros' },
    ];

    const situacaoOptions = [
        { value: 'cidadao_do_pais', label: 'Cidadão dos Estados Unidos' },
        { value: 'residente', label: 'Residente Permanente Legal nos Estados Unidos' },
        { value: 'nao_imigrante', label: 'Não-imigrante (permissão temporária)' },
        { value: 'imigrante', label: 'Imigrante em situação irregular com a imigração' },
        { value: 'nao_sei', label: 'Não sei' },
        { value: 'outros', label: 'Outros' },
    ];

    const perguntaOptions = [
        { value: 'homens_entre_idade', label: 'Homens e mulheres entre 16 e 45 anos' },
        { value: 'aposentado_menos_cinco_anos', label: 'Homens aposentados a menos de cinco anos' },
        { value: 'homens_requerentes_visto', label: 'Homens requerentes de visto F, M, J, Q' },
        { value: 'mulheres_aponsentadas', label: 'Mulheres aposentadas a menos de cinco anos' },
        { value: 'mulheres_desempregadas', label: 'Mulheres desempregadas ou donas-de-casa' },
        { value: 'mulheres_requerentes_visto', label: 'Mulheres requerentes de visto F, M, J, Q' },
        { value: 'nenhum', label: 'Em nenhum destes casos acima.' },
    ];

    const casosEnvolvidosOptions = [
        { value: 'desrespeito', label: 'Desrespeito a decisões judiciais nos Estados Unidos' },
        { value: 'doencas', label: 'Tuberculose ou doenças que afetam a saúde pública' },
        { value: 'disturbios', label: 'Distúrbio físico ou mental que coloque em perigo a segurança de outros' },
        { value: 'drogas', label: 'Utilização, comércio e produção de drogas' },
        { value: 'prostituicao', label: 'Prostituição' },
        { value: 'lavagem_dinheiro', label: 'Lavagem de dinheiro' },
        { value: 'trafico_pessoas', label: 'Tráfico de pessoas' },
        { value: 'espionagem', label: 'Espionagem e sabotagem' },
        { value: 'terrorismo', label: 'Atividades e organizações terroristas' },
        { value: 'crimes_lesa', label: 'Genocídio, tortura e outros crimes de lesa humanidade' },
        { value: 'nao', label: 'Não' },
    ];

    const locaisOptions = [
        { value: 'casv_brasilia', label: 'CASV Brasília - Edifício Venâncio 2000, 3º Andar - Asa Sul' },
        { value: 'casv_rio_janeiro', label: 'CASV Rio de Janeiro - Lagoa Corporate – Rua Humaitá, 275 - Humaitá' },
        { value: 'casv_recife', label: 'CASV Recife - Comercial Bandeira – Av. Herculano Bandeira, 949 - Pina' },
        { value: 'casv_belo_horizonte', label: 'CASV Belo Horizonte - Ed. Celta – Rua Maranhão, 310 – Loja 1 – Santa Efigênia' },
        { value: 'casv_sao_paulo', label: 'CASV São Paulo - Av. José Maria Whitaker, 370 – Vila Mariana' },
        { value: 'casv_porto_alegre', label: 'CASV Porto Alegre - Avenida Assis Brasil, 1712 em Porto Alegre.' },
        { value: 'menor_quatorze', label: 'Estou isento(a) desta etapa por ter menos de 14 anos.' },
        { value: 'mais_oitenta', label: 'Estou isento(a) desta etapa por ter mais de 80 anos.' },
        { value: 'isento', label: 'Estou isento(a) desta etapa por meu visto B1/B2 anterior ter vencido há no máximo 1 ano.' },
    ];

    const consuladoOptions = [
        { value: 'brasilia', label: 'Embaixada de Brasília - SES Avenida das Nações, Quadra 801, Lote 03 - Asa Sul' },
        { value: 'rio_janeiro', label: 'Consulado do Rio de Janeiro: Avenida Presidente Wilson, 147 – Castelo (entrada na Rua Santa Luzia)' },
        { value: 'recife', label: 'Consulado de Recife: Rua Gonçalves Maia, 163 – Bairro Boa Vista' },
        { value: 'sao_paulo', label: 'Consulado de São Paulo: Rua Henri Dunant, 500 – Chácara Santo Antônio' },
        { value: 'isento', label: 'Estou isento(a) desta etapa por meu visto B1/B2 anterior ter sido emitido a partir de 2004' },
        { value: 'porto_alegre', label: 'Consulado de Porto Alegre - Avenida Assis Brasil, 1889 – Passo D’Areia Porto Alegre – RS 91010-004' },
    ];

    const agendamentoOptions = [
        { value: 'proxima_data', label: 'Agendar para a próxima data disponível' },
        { value: 'proxima_semana', label: 'Agendar para a próxima vaga disponível na próxima semana' },
        { value: 'duas_semanas', label: 'Agendar para próxima vaga disponível daqui a duas semanas' },
        { value: 'tres_semanas', label: 'Agendar para próxima vaga disponível daqui a três semanas' },
        { value: 'proximo_mes', label: 'Agendar para o próxima vaga disponível no próximo mês' },
    ];
    return(
        <div className={'p-4'}>
            <div className={`${ 
                state.conclusao_formulario ? 
                    'p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400'
                    :
                    'p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
                }`}
                 role="alert">
                {
                    state.conclusao_formulario
                        ? 'Formulário finalizado pelo solicitante'
                        : 'Formulário não foi finalizado pelo solicitante'
                }
            </div>
            <div>
                <table className='ps-4 pe-4 table-fixed border-collapse border border-slate-400'>
                    <tbody>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sobrenome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.sobrenome}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Nome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nome}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Nomes Anteriores:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nomes_anteriores}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Sexo:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.sexo ? state.cliente.sexo === 'm' ? 'Masculino' : 'Feminino' : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Estado Civil:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.estado_civil && stadoCivilOptions.filter(filt => filt.value === state.cliente.estado_civil)[0]['label']}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data de Nascimento:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{Dates.convertEnToBr(state.cliente.nascimento)}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade Natal / Estado:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cidade_natal}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >País Natal:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.pais_natal}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Outras Cidadanias:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.outras_cidadanias}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CPF:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cpf}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você tem ou já teve um Social Security Number?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.social_security}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereço(rua/quadra/avenida):</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.endereco}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Bairro:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.bairro}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cidade}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Estado:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.estado}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CEP:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cep}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >País de Residência:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.pais_residencia}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Seu passaporte será encaminhado para o endereço residencial?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.passaporte_encaminhado_residencia ? 'Sim' : 'Não'}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Caso a resposta seja Não, favor informar o endereço alternativo completo com cep.</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.endereco_passaport}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Telefone Primário:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.telefone ? state.cliente.telefone.replace(/[\[\].!'@,><|://\\;&*()_+=|\-/g]/g, "") : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Telefone Secundário:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.telefone_secundario ? state.cliente.telefone_secundario.replace(/[\[\].!'@,><|://\\;&*()_+=|\-/g]/g, "") : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Telefone do Trabalho:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.telefone_trabalho ? state.cliente.telefone_trabalho.replace(/[\[\].!'@,><|://\\;&*()_+=|\-/g]/g, "") : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você já usou outros números de telefone nos últimos cinco anos?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.outros_telefone}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >E-mail:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.usuario.email}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você usou outros endereços de e-mail nos últimos cinco anos?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.outros_emails}</td>
                        </tr>

                        {
                            state.passaporte ?
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Tipo de Passaporte:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte.tipo_passaporte}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Número do Passaporte Válido:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte.numero}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >País que emitiu o passaporte:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte.pais_emissor}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data de Emissão:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{Dates.convertEnToBr(state.passaporte.data_emissao)}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Válido até:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte.validade}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Órgão Emissor:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte.orgao_emissor}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade onde foi emitido:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte.cidade_emissao}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Já teve algum passaporte roubado?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{parseInt(state.passaporte.passaporte_roubado) ? 'Sim' : 'Não'}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade onde foi emitido:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte.cidade_emissao}</td>
                                    </tr>
                                </> : ''
                        }

                        {
                            state.cliente.conjuge ?
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Sobrenome do Cônjuge:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge.sobrenome}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Primeiro Nome do Cônjuge:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge.nome}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data de nascimento do Cônjuge:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{Dates.convertEnToBr(state.cliente.conjuge.nascimento)}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade de nascimento do Cônjuge:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge.cidade}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereço do cônjuge:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge.endereco}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Bairro do Cônjuge:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge.bairro}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade de Residência do Cônjuge:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge.cidade_resideincia}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CEP do Cônjuge:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge.cep}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Telefone do Cônjuge:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge.telefone ? state.cliente.conjuge.telefone.replace(/[\[\].!'@,><|://\\;&*()_+=|\-/g]/g, "") : ''}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Viaja com você?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge.viaja_junto ? 'Sim' : 'Não'}</td>
                                    </tr>
                                </> : ''
                        }

                        {
                            state.dados_dos_pais ?
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Sobrenome do Pai:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais.sobrenome_pai}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Primeiro Nome do Pai:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais.nome_pai}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data de Nascimento do Pai:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais.data_nascimento_pai}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Seu pai está nos Estados Unidos?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais.pai_esta_no_pais}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Sobrenome da Mãe:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais.sobrenome_mae}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Primeiro Nome da Mãe:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais.nome_mae}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data de Nascimento da Mãe:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais.data_nascimento_mae}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Sua mãe está nos Estados Unidos?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais.mae_esta_no_pais}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você possui parentes nos Estados Unidos?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais.parentes_no_pais ? 'Sim' : 'Não'}</td>
                                    </tr>
                                </> : ''
                        }

                        {
                            state.cliente.conjuge ?
                                <tr>
                                    <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Para pessoas separadas, divorciadas
                                        e desquitadas, por favor informar data de início e término da relação
                                    </td>
                                    <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge.informacoes_nao_casados}</td>
                                </tr> : ''
                        }

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Motivo da Viagem:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.motivo_viagem && motivosViagem.filter(filt => filt.value === state.motivo_viagem)[0]['label']}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data de chegada aos EUA:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{Dates.convertEnToBr(state.data_prevista_viagem)}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data da saída dos EUA:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{Dates.convertEnToBr(state.data_prevista_retorno)}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereço completo onde ficará nos Estados Unidos:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.endereco_pais}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade onde ficará nos Estados Unidos:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cidade_pais}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Estado onde ficará nos Estados Unidos:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.estado_pais}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CEP de onde ficará nos Estados Unidos:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cep_pais}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você possui conta em alguma rede social? Se sim, informar a plataforma e nome de usuário.</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.rede_social}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Quem custeará a viagem?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem ? quemCustearaList.filter(filt => filt.value === state.custeio_da_viagem.quem_custeara)[0]['label'] : ""}</td>
                        </tr>
                        {
                            state.custeio_da_viagem ?
                                state.custeio_da_viagem.quem_custeara === 'eu' ? '' :

                                    state.custeio_da_viagem.quem_custeara === 'outra_pessoa' ?
                                        <>
                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Sobrenome de quem custeará a viagem:</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.sobrenome}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Primeiro Nome de quem custeará a viagem:</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.nome}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereço completo de quem irá custear a sua viagem</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.endereco}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Telefone de quem custeará a viagem:</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.telefone ? state.custeio_da_viagem.telefone.replace(/[\[\].!'@,><|://\\;&*()_+=|\-/g]/g, "") : ''}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >E-mail de quem custeará a viagem:</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.email}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Relação com o viajante</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.relacao}</td>
                                            </tr>
                                        </>
                                        :
                                        <>
                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Nome da empresa / organização que custeará a viagem:</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.nome}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Telefone da empresa / organização que custeará a viagem:</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.telefone ? state.custeio_da_viagem.telefone.replace(/[\[\].!'@,><|://\\;&*()_+=|\-/g]/g, "") : ''}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereço completo da empresa / organização que custeará a viagem:</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.endereco}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade da empresa / organização que custeará a viagem:</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.cidade}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Estado da empresa / organização que custeará a viagem:</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.estado}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CEP da empresa / organização que custeará a viagem:</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.cep}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >País da empresa / organização que custeará a viagem:</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.pais}</td>
                                            </tr>

                                            <tr>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você faz parte do quadro de colaboradores desta empresa?</td>
                                                <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem.parte_empresa ? 'Sim' : 'Não'}</td>
                                            </tr>
                                        </>
                                : ''
                        }




                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Nome da escola:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_escola ? state.dados_escola.nome : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Curso que será realizado:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_escola ? state.dados_escola.curso : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereço da escola:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_escola ? state.dados_escola.endereco : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade da escola:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_escola ? state.dados_escola.cidade : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Estado da escola:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_escola ? state.dados_escola.estado : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CEP:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_escola ? state.dados_escola.cep : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você já possui o documento I-20 ou DS-2019?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_escola ? state.dados_escola.documentacao_i10_ds ? 'Sim': 'Não'   : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Número da SEVIS</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_escola ? state.dados_escola.numero_sevis : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Quantas pessoas viajarão com você?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.quantidade_pessoas > 1 ? state.quantidade_pessoas : 0}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Nome completo e relação com cada um deles:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.relacao_entre_viajante}</td>
                        </tr>
                        {
                            state.dados_viagem_anterior ?
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você já teve um visto emitido para os Estados Unidos?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_viagem_anterior.visto_ja_emitido ? 'Sim' : 'Não'}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Se sim, informe a data de emissão e validade, e o local de emissão do visto.</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_viagem_anterior.informacoes_ultimas_viagens}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você já esteve nos Estados Unidos?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_viagem_anterior.ja_esteve_no_pais ? 'Sim' : 'Não'}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Se sim, informe a data de chegada e a duração das últimas cinco visitas.</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_viagem_anterior.informacoes_ultimas_viagens}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você já teve um visto negado para os Estados Unidos?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_viagem_anterior.ja_teve_visto_negado ? 'Sim' : 'Não'}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Se sim, informe a data e a causa provável.</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_viagem_anterior.informacoes_visto_negado}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você possui uma carteira de motorista americana?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_viagem_anterior.carteira_motorista ? 'Sim' : 'Não'}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Em caso positivo, por favor informe o número da licença e estado emissor da carteira.</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_viagem_anterior.informacoes_carteira_motorista}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você já teve uma autorização de
                                            viagem (ESTA) negada ou recusada pelo Departamento de Homeland Security através
                                            do Sistema Eletrônico de Autorização de viagens (ESTA)?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_viagem_anterior.viagem_recusada}</td>
                                    </tr>
                                </> : ''
                        }

                        {
                            state.visto_anterior ?
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data de Emissão do seu último visto:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visto_anterior.data_emissao}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data de Validade do seu último visto:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visto_anterior.data_validade}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Número do visto:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visto_anterior.numero}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade e país onde o seu visto foi emitido:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visto_anterior.cidade_pais}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você está solicitando o visto do mesmo país onde solicitou pela última vez?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visto_anterior.mesmo_tipo_visto ? 'Sim' : 'Não'}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você está pedindo o mesmo tipo de visto?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visto_anterior.mesmo_pais ? 'Sim' : 'Não'}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você já tirou suas digitais no Consulado anteriormente?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visto_anterior.digitais_colhidas ? 'Sim' : 'Não'}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Já teve um visto perdido ou roubado?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visto_anterior.perdido_roubado ? 'Sim' : 'Não'}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Já teve a entrada negada nos Estados Unidos?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visto_anterior.entrada_negada ? 'Sim' : 'Não'}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Se sim, qual motivo da proibição da entrada?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visto_anterior.info_entrada_negada}</td>
                                    </tr>
                                </> : ''
                        }


                        {
                            state.dados_contato_pais ?
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Nome completo da pessoa ou empresa / organização nos Estados Unidos:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais.nome}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereço completo da pessoa ou empresa / organização nos Estados Unidos:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais.endereco}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade da pessoa / empresa:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais.cidade}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Estado da pessoa ou empresa / organização nos Estados Unidos:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais.estado}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CEP da pessoa ou empresa / organização nos Estados Unidos:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais.cep}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Telefone da pessoa ou empresa / organização nos Estados Unidos:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais.telefone}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >E-mail da pessoa ou empresa / organização nos Estados Unidos:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais.email}</td>
                                    </tr>
                                </> : ''
                        }

                        {
                            state.dados_parente_no_pais ?
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Sobrenome do parente nos Estados Unidos:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_parente_no_pais.nome}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Primeiro Nome do parente nos Estados Unidos:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_parente_no_pais.nome}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Relação com o parente nos Estados Unidos:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{relacaoOptions.filter(filt => filt.value === state.dados_parente_no_pais.relacao)[0]['label']}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Caso tenha selecionado "outros", especifique aqui:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_parente_no_pais.outros}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Situação do parente nos Estados Unidos</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{situacaoOptions.filter(filt => filt.value === state.dados_parente_no_pais.situacao)[0]['label']}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Caso tenha selecionado "outros", especifique aqui:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_parente_no_pais.situacao_outros}</td>
                                    </tr>
                                </> : ''
                        }


                        {
                            state.ocupacao ?
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Ocupação Atual:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao.ocupacao}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Nome do empregador ou escola:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao.nome_empresa}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereço completo do empregador ou escola:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao.endereco}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade do empregador ou escola:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao.cidade}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Estado do empregador ou escola:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao.estado}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CEP do empregador ou escola:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao.cep}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Telefone do empregador ou escola:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao.telefone}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >País do empregador ou escola:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao.pais}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data de admissão no trabalho OU início dos estudos.</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{Dates.convertEnToBr(state.ocupacao.data_entrada)}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Salário Mensal (em reais):</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao.salario}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Descreva brevemente suas funções:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao.descricao_funcao}</td>
                                    </tr>
                                </> : ''
                        }


                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você se enquadra em algum destes casos?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.pergunta_adicional_emprego_anterior && perguntaOptions.filter(filt => filt.value === state.pergunta_adicional_emprego_anterior)[0]['label']}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você teve algum emprego anterior?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior ? 'Sim' : 'Não'}</td>
                        </tr>

                        {
                            state.emprego_anterior_r ?
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Nome do empregador anterior:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior_r.nome_empregador}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereço completo do empregador anterior:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior_r.endereco}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade do empregador anterior:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior_r.cidade}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Estado do empregador anterior:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior_r.estado}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CEP do empregador anterior:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior_r.cep}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >País do empregador anterior:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior_r.pais}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Telefone do empregador anterior:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior_r.telefone}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cargo:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior_r.cargo}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Sobrenome do(a) Supervisor(a):</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior_r.sobrenome_supervisor}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Primeiro Nome do(a) Supervisor(a):</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior_r.nome_supervisor}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data de início do trabalho:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{Dates.convertEnToBr(state.emprego_anterior_r.data_inicio)}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data de término do trabalho:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{Dates.convertEnToBr(state.emprego_anterior_r.data_fim)}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Breve descrição das atividades:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.emprego_anterior_r.descricao}</td>
                                    </tr>
                                </> : ''
                        }


                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você realizou algum curso acadêmico ou vocacional após o Ensino Médio?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais ? 'Sim' : 'Não'}</td>
                        </tr>
                        {
                            !state.informacoes_educacionais ?  '' :
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Nome da Instituição:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r.nome_instituicao}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereço completo da Instituição:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r.endereco}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Cidade da Instituição:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r.cidade}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Estado da Instituição:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r.estado}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CEP da Instituição:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r.cep}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >País da Instituição:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r.pais}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Curso realizado:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r.nome_curso}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data do início do curso:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{Dates.convertEnToBr(state.informacoes_educacionais_r.data_inicio)}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Data do término do curso:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{Dates.convertEnToBr(state.informacoes_educacionais_r.data_fim)}</td>
                                    </tr>
                                </>
                        }

                        {
                            state.idiomas_experiencias ?
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Quais Idiomas você fala?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.idiomas_experiencias.idiomas}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Liste os países que você visitou nos últimos cinco anos:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.idiomas_experiencias.paises_visitados}</td>
                                    </tr>
                                </> : ''
                        }

                        {
                            state.pergunta_de_seguranca ?
                                <>
                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Você possui algo a declarar sobre o envolvimento em alguma destas situações?</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{casosEnvolvidosOptions.filter(filt => filt.value === state.pergunta_de_seguranca.caso_envolvido)[0]['label']}</td>
                                    </tr>

                                    <tr>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Caso tenha se envolvido em qualquer uma das questões acima, explique:</td>
                                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.pergunta_de_seguranca.informacao}</td>
                                    </tr>
                                </> : ''
                        }



                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Alguma outra informação relevante que deseje comentar?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.comentario_adicional}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Contato nº1 - Sobrenome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.sobrenome_primeiro : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Primeiro nome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.nome_primeiro : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereco completo:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.endereco_primeiro : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Ciddade:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.cidade_primeiro : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Estado/Província:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.estado_primeiro : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CEP:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.cep_primeiro : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Telefone:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.telefone_primeiro : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Email:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.email_primeiro : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Contato nº2 - Sobrenome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.sobrenome_segundo : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Primeiro nome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.nome_segundo : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Endereco completo:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.endereco_segundo : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Ciddade:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.cidade_segundo : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Estado/Província:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.estado_segundo : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CEP:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.cep_segundo : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Telefone:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.telefone_segundo : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Email:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.contatos_adicionais ? state.contatos_adicionais.email_segundo : ''}</td>
                        </tr>


                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >CASV de sua preferência:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.local_colheita_biometria && locaisOptions.filter(filt => filt.value === state.local_colheita_biometria)[0]['label']}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Consulado de sua preferência:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.consulado && consuladoOptions.filter(filt => filt.value === state.consulado)[0]['label']}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >Datas possíveis para agendamento:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.datas_possiveis_agendamento && agendamentoOptions.filter(filt => filt.value === state.datas_possiveis_agendamento)[0]['label']}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'} >
                                Declaração: Todas as respostas deste formulário devem ser conferidas, pois
                                serão utilizadas no formulário eletrônico da Imigração. Qualquer informação
                                errada repassada através deste formulário é de responsabilidade exclusiva do
                                requerente.
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao ? 'Concordo' : 'Não concordo'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}