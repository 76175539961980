import quantidade from "../../../../../helpers/QuantidadeFormularios";
import HeaderForm from "../../../../../components/headerForm";
import React, {useEffect, useState} from "react";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import InputSelect from "../../../../../components/input/InputSelect";
import processosApi from "../../../../../services/processosApi";
import Alert from "../../../../../helpers/Alert";

export interface IDadosOcupacao{
    processo?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setProcesso?: any;
}

export default function PerguntasAdicionais({processo, setTelaNumero, telanumero, setProcesso}: IDadosOcupacao) {
    const [state, setState] = useState(
        {
            id: processo.id,
            pergunta_adicional_emprego_anterior: 'homens_entre_idade',
            emprego_anterior: 0,
        }
    );
    const [atualizacao, setAtualizacao ] = useState(false);
    const perguntaOptions = [
        { value: 'homens_entre_idade', label: 'Homens e mulheres entre 16 e 45 anos' },
        { value: 'aposentado_menos_cinco_anos', label: 'Homens aposentados a menos de cinco anos' },
        { value: 'homens_requerentes_visto', label: 'Homens requerentes de visto F, M, J, Q' },
        { value: 'mulheres_aponsentadas', label: 'Mulheres aposentadas a menos de cinco anos' },
        { value: 'mulheres_desempregadas', label: 'Mulheres desempregadas ou donas-de-casa' },
        { value: 'mulheres_requerentes_visto', label: 'Mulheres requerentes de visto F, M, J, Q' },
        { value: 'nenhum', label: 'Em nenhum destes casos acima.' },
    ];
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const getDataProcesso = async () => {
        const {data, isError} = await processosApi.one(processo.id);
        if (!isError) {
            if (Object.values(data.data).length) {
                setProcesso(data.data);
                setState({
                    id: data.data.id,
                    pergunta_adicional_emprego_anterior: data.data.pergunta_adicional_emprego_anterior ? data.data.pergunta_adicional_emprego_anterior : 'homens_entre_idade',
                    emprego_anterior: data.data.emprego_anterior,
                })
                setAtualizacao(!!data.data.pergunta_adicional_emprego_anterior);
            }
        }
    }
    useEffect(() => {
        getDataProcesso()
    }, [])
    async function onSubmit() {
        const {data, isError} = await processosApi.update(state.id, state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (atualizacao){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, percet_conclusao: percent, emprego_anterior:state.emprego_anterior})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(state.emprego_anterior ? telanumero+1 : telanumero+2);
    }

    return(
        <>
            <HeaderForm
                titulo={'Perguntas adicionais de empregos anteriores\n'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />

            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    {/*<div>
                        As perguntas adicionais de empregos anteriores, estudo e viagens são obrigatórias apenas nos
                        seguintes casos:
                        <p>- Homens e mulheres entre 16 e 45 anos</p>
                        <p>- Homens aposentados a menos de cinco anos - Homens requerentes de visto F, M, J, Q</p>
                        <p>- Mulheres aposentadas a menos de cinco anos</p>
                        <p>- Mulheres desempregadas ou donas-de-casa</p>
                        <p>- Mulheres requerentes de visto F, M, J, Q</p>
                        <p>- Você se enquadra em algum destes casos acima?</p>
                    </div>
                    <div className={'grid grid-cols-1 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <InputSelect
                                selected={perguntaOptions.filter((opt: any) => opt.value === state.pergunta_adicional_emprego_anterior)}
                                options={perguntaOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'pergunta_adicional_emprego_anterior')}
                            />
                        </div>
                    </div>*/}
                    <div className={'grid grid-cols-1 gap-4 mt-5'}>
                        <div className={'col-span-1 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Você teve algum emprego anterior?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'emprego_anterior')}
                                            value={1}
                                            id="checked-emprego_anterior-s"
                                            type="radio"
                                            checked={state.emprego_anterior === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-emprego_anterior-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.emprego_anterior === 0 }
                                            onChange={e => handleChange(0,'emprego_anterior')}
                                            id="checked-emprego_anterior-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-emprego_anterior-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}