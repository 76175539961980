import React, {useEffect, useState} from "react";
import conjugeApi from "../../../../../services/conjugeApi";
import Alert from "../../../../../helpers/Alert";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import processosApi from "../../../../../services/processosApi";
import HeaderForm from "../../../../../components/headerForm";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";

export interface IDadosConjugeCa{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setConjujeAnterior?: any;
}

export default function DadosConjugeCa({processo, setProcesso, setTelaNumero, telanumero, setConjujeAnterior}: IDadosConjugeCa) {
    const [state, setState] = useState({
        id: '',
        cliente_id: processo.cliente_id,
        data_uniao: '',
        nome: '',
        sobrenome: '',
        nascimento: '',
        nacionalidade: '',
        endereco: '',
        ocupacao: '',
        viaja_junto: 0,
        conjuge_anterior: 0,
    });
    useEffect(() => {
        //console.log(processo.cliente.estado_civil);
        getData()
    }, []);

    const getData = async () => {
        const {data, isError} = await conjugeApi.one(processo.cliente_id);
        if (!isError){
            if (Object.values(data.data).length){
                setState({
                    id: data.data.id,
                    cliente_id: data.data.cliente_id,
                    data_uniao: data.data.data_uniao,
                    nome: data.data.nome,
                    sobrenome: data.data.sobrenome,
                    nascimento: data.data.nascimento,
                    nacionalidade: data.data.nacionalidade,
                    endereco: data.data.endereco,
                    ocupacao: data.data.ocupacao,
                    viaja_junto: data.data.viaja_junto,
                    conjuge_anterior: data.data.conjuge_anterior === null ? 0 : data.data.conjuge_anterior,
                });
            }
        }
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await conjugeApi.update(state.id, state) : await conjugeApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        setConjujeAnterior(state.conjuge_anterior)
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(state.conjuge_anterior ? telanumero + 1 : telanumero + 2);
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados do cônjuge'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Data do casamento ou união estavel '}
                            type={'date'}
                            placeholder={'Data do casamento ou união estavel'}
                            large={true}
                            name={'data_uniao'}
                            setValue={e => handleChange(e,'data_uniao')}
                            value={state.data_uniao}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Nome '}
                            placeholder={'Nome'}
                            large={true}
                            name={'nome'}
                            setValue={e => handleChange(e,'nome')}
                            value={state.nome}
                            required={true}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Sobrenome '}
                            placeholder={'Sobrenome'}
                            large={true}
                            name={'sobrenome'}
                            setValue={e => handleChange(e,'sobrenome')}
                            value={state.sobrenome}
                            required={true}
                        />

                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Data de nascimento '}
                            type={'date'}
                            placeholder={'Data de nascimento'}
                            large={true}
                            name={'nascimento'}
                            setValue={e => handleChange(e,'nascimento')}
                            value={state.nascimento}
                            required={true}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'País de nascimento do cônjuge '}
                            placeholder={'País de nascimento do cônjuge'}
                            large={true}
                            name={'nacionalidade'}
                            setValue={e => handleChange(e,'nacionalidade')}
                            value={state.nacionalidade}
                            required={processo.cliente.estado_civil != 'viuvo'}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Endereço atual do cônjuge '}
                            placeholder={'Endereço atual do cônjuge'}
                            large={true}
                            name={'endereco'}
                            setValue={e => handleChange(e,'endereco')}
                            value={state.endereco}
                            required={processo.cliente.estado_civil != 'viuvo'}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Ocupação atual do cônjuge '}
                            placeholder={'Ocupação atual do cônjuge'}
                            large={true}
                            name={'ocupacao'}
                            setValue={e => handleChange(e,'ocupacao')}
                            value={state.ocupacao}
                            legenda={'Incluir cargo, nome completo e endereço do empregador.'}
                            required={processo.cliente.estado_civil != 'viuvo'}
                        />
                        <div className={'col-span-1 flex flex-col'}>
                            <label
                                className="text-[16px] font-medium text-gray-900 dark:text-gray-300"
                            >
                                Viaja com você?
                            </label>
                            <div className={'flex mt-[1.5rem] gap-4'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'viaja_junto')}
                                        value={1}
                                        id="checked-viaja_junto-1"
                                        type="radio"
                                        checked={state.viaja_junto === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viaja_junto-1"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.viaja_junto === 0 }
                                        onChange={e => handleChange(0,'viaja_junto')}
                                        id="checked-viaja_junto"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viaja_junto"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-1 flex flex-col'}>
                            <label
                                className="text-[16px] font-medium text-gray-900 dark:text-gray-300"
                            >
                                Já foi casado ou teve união estavel anterior?
                            </label>
                            <div className={'flex mt-[1.5rem] gap-4'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'conjuge_anterior')}
                                        value={1}
                                        id="checked-conjuge_anterior-1"
                                        type="radio"
                                        checked={state.conjuge_anterior === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-conjuge_anterior-1"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.conjuge_anterior === 0 }
                                        onChange={e => handleChange(0,'conjuge_anterior')}
                                        id="checked-conjuge_anterior"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-conjuge_anterior"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}