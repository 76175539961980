import React, { useEffect } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Routes } from './router';

const queryClient = new QueryClient();

// Função de sanitização
const sanitizeInput = (value: string) => value.normalize("NFD").replace(/[\u0300-\u036f]/g, '');

export default function App() {
  useEffect(() => {
    const handleGlobalInput = (event: Event) => {
      // Verifica se o alvo é um campo de texto ou textarea
      if (event.target instanceof HTMLInputElement || event.target instanceof HTMLTextAreaElement) {
        const originalValue = event.target.value;
        const sanitizedValue = sanitizeInput(originalValue);

        // Atualiza o valor apenas se for diferente
        if (originalValue !== sanitizedValue) {
          event.target.value = sanitizedValue;
        }
      }
    };

    // Adiciona o evento global
    document.addEventListener('input', handleGlobalInput);

    // Limpa o evento ao desmontar o componente
    return () => {
      document.removeEventListener('input', handleGlobalInput);
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Routes />
    </QueryClientProvider>
  );
}
