import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import informacoesEducacionaisApi from "../../../../../services/informacoesEducacionaisApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import informacoesProfissionaisApi from "../../../../../services/informacoesProfissionaisApi";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";

export interface IInformacoesProfissionaisCa{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setInformacoesProfissionais?: any;
}

export default function InformacoesProfissionaisCa({processo, setProcesso, setTelaNumero, telanumero, setInformacoesProfissionais}: IInformacoesProfissionaisCa) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        ocupacao: '',
        endereco: '',
        data_admissao: '',
        nome: '',
        atividades_anteriores: 'Sim',
    });
    const atividadeProfissionalOptions = [
        { value: 'Sim', label: 'Sim' },
        { value: 'Nao', label: 'Não' },
        { value: 'Apenas estudei', label: 'Apenas estudei' },
        { value: 'Sou aposentado(a)', label: 'Sou aposentado(a)' },
    ]
    const getData = async () => {
        const {data, isError} = await informacoesProfissionaisApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                const response = data.data;
                setState(response);
            }
        }

    }

    useEffect(() => {
        getData()
    }, []);
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await informacoesProfissionaisApi.update(state.id, state) : await informacoesProfissionaisApi.create(state);
        if (isError){

            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        setInformacoesProfissionais(state)
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(state.atividades_anteriores === 'Nao'? telanumero + 3 : telanumero + 1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={'Informações Profissionais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />

            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'text-md text-red-600'}>
                        Os dados são referentes ao emprego atual!
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Qual a sua ocupação ou profissão atual'}
                            required={true}
                            name={'ocupacao'}
                            setValue={e => handleChange(e,'ocupacao')}
                            value={state.ocupacao}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Endereço completo do empregador ou escola'}
                            required={true}
                            name={'endereco'}
                            setValue={e => handleChange(e,'endereco')}
                            value={state.endereco}
                            legenda={'Informar pelo menos cidade/estado.'}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Data de admissão no trabalho ou início dos estudos'}
                            required={true}
                            type={'date'}
                            name={'data_admissao'}
                            setValue={e => handleChange(e,'data_admissao')}
                            value={state.data_admissao}
                        />
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome do empregador ou escola'}
                            required={true}
                            name={'nome'}
                            setValue={e => handleChange(e,'nome')}
                            value={state.nome}
                        />
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Você exerceu alguma atividade profissional ou estudo nos últimos 10 anos?"}
                                selected={atividadeProfissionalOptions.filter((opt: any) => opt.value === state.atividades_anteriores)}
                                options={atividadeProfissionalOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'atividades_anteriores')}
                            />
                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}