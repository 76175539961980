import React from "react";
import {ReactComponent  as SetaSubindo} from '../../assets/icone/seta-subindo.svg';
import {ReactComponent  as SetaBaixo} from '../../assets/icone/seta-baixo.svg';
import {LineChart} from "@mui/x-charts";

interface IDashboard {
    cliente: any
}

export default function dashboard({cliente}: IDashboard){

    const axis = cliente.quantidade_cliente > cliente.quantidade_cliente_mes_anterior ?
         [cliente.quantidade_cliente_mes_anterior, cliente.quantidade_cliente] : [cliente.quantidade_cliente, cliente.quantidade_cliente_mes_anterior]

    return(
        <div className={'flex border rounded-md w-[469px] h-[137px] border-2 p-3  '}>
            <div className={'flex'}>
                <div>
                    <div className={'text-gray-700 font-semibold text-[25px]'}>
                        Clientes
                    </div>
                    <div className={'flex mt-[30px] items-center'}>
                        <div className={'text-gray-700 font-semibold text-[25px]'}>{cliente.quantidade_cliente}</div>
                        {
                            Math.sign(cliente.percent_mes_anterior) === -1 ?
                                <div className={'flex ml-1 text-[#c12929]'}>
                                    <SetaBaixo/> {cliente.percent_mes_anterior} %
                                </div>
                                :
                                <div className={'flex ml-1 text-[#1d9d68]'}>
                                    <SetaSubindo/> {cliente.percent_mes_anterior} %
                                </div>
                        }

                    </div>
                </div>
                <div className={'ml-5'}>
                    <LineChart
                        xAxis={[{ data: axis }]}
                        series={[
                            {
                                data: [cliente.quantidade_cliente_mes_anterior,cliente.quantidade_cliente],
                                area: true,
                            },
                        ]}
                        width={300}
                        height={200}
                        margin={{ top: 5, bottom: 98, left: 40, right: 7 }}
                        colors={['#0f4eb3', '#082a60']}
                    />
                </div>
            </div>

        </div>
    )
}