import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import passaporteApi from "../../../../../services/passaporteApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import Input from "../../../../../components/input/Input";

export interface IPassaporteAusVisitante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function PassaporteAusVisitante({processo, setProcesso, setTelaNumero, telanumero}: IPassaporteAusVisitante ) {
    const [state, setState] = useState({
        processo_id: processo.id,
        id: '',
        numero: '',
        pais_emissor: '',
        nacionalidade: '',
        cidadao_pais_emissor: 1,
        status_imigratorio:'',
        data_emissao: '',
        data_validade: '',
        cidade_emissao: '',
        outra_cidadania: 0,
        pais_outra_cidadania: '',
        passaporte_antigo: 0,
        dados_passaporte_antigo: '',
    });
    const getDadosPassaporte = async () => {
        const {data, isError} = await passaporteApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }

    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    useEffect(() => {
        getDadosPassaporte()
    },[])
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await passaporteApi.update(state.id, state) : await passaporteApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        let numero = telanumero + 1;
        setTelaNumero(numero);
    }
    return(
        <>
            <HeaderForm
                titulo={'Passaporte'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form   onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Número do Passaporte'}
                            placeholder={'Número do Passaporte'}
                            large={true}
                            required={true}
                            name={'numero'}
                            setValue={e => handleChange(e,'numero')}
                            value={state.numero}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'País emissor'}
                            placeholder={'País emissor'}
                            large={true}
                            name={'pais_emissor'}
                            setValue={e => handleChange(e,'pais_emissor')}
                            value={state.pais_emissor}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Nacionalidade'}
                            placeholder={'Nacionalidade'}
                            large={true}
                            name={'nacionalidade'}
                            setValue={e => handleChange(e,'nacionalidade')}
                            value={state.nacionalidade}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Local de emissão / autoridade emissora'}
                            placeholder={'Local de emissão / autoridade emissora'}
                            large={true}
                            required={true}
                            name={'cidade_emissao'}
                            setValue={e => handleChange(e,'cidade_emissao')}
                            value={state.cidade_emissao}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Data de emissão'}
                            placeholder={'Data de emissão'}
                            large={true}
                            type={'date'}
                            name={'data_emissao'}
                            setValue={e => handleChange(e,'data_emissao')}
                            value={state.data_emissao}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Data de expiração'}
                            placeholder={'Data de expiração'}
                            large={true}
                            type={'date'}
                            name={'data_validade'}
                            setValue={e => handleChange(e,'data_validade')}
                            value={state.data_validade}
                            required
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium  "
                                >
                                    Você é cidadão do país emissor do seu passaporte?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'cidadao_pais_emissor')}
                                            value={1}
                                            id="checked-cidadao_pais_emissor-s"
                                            type="radio"
                                            checked={state.cidadao_pais_emissor === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-cidadao_pais_emissor-s"
                                            className="ms-2 text-[16px] font-medium  "
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.cidadao_pais_emissor === 0 }
                                            onChange={e => handleChange(0,'cidadao_pais_emissor')}
                                            id="checked-cidadao_pais_emissor-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-cidadao_pais_emissor-n"
                                            className="ms-2 text-[16px] font-medium  "
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Input
                            required={!state.cidadao_pais_emissor}
                            className={'md:col-span-2'}
                            label={'Em caso negativo, qual o seu status imigratório?'}
                            name={'status_imigratorio'}
                            setValue={e => handleChange(e,'status_imigratorio')}
                            value={state.status_imigratorio}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium  "
                                >
                                    Você possui outra cidadania?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'outra_cidadania')}
                                            value={1}
                                            id="checked-outra_cidadania-s"
                                            type="radio"
                                            checked={state.outra_cidadania === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-outra_cidadania-s"
                                            className="ms-2 text-[16px] font-medium  "
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.outra_cidadania === 0 }
                                            onChange={e => handleChange(0,'outra_cidadania')}
                                            id="checked-outra_cidadania-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-outra_cidadania-n"
                                            className="ms-2 text-[16px] font-medium  "
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Input
                            required={!!state.outra_cidadania}
                            className={'md:col-span-2'}
                            label={'Em caso afirmativo, por favor informar o país, e se possui passaporte do mesmo'}
                            name={'pais_outra_cidadania'}
                            setValue={e => handleChange(e,'pais_outra_cidadania')}
                            value={state.pais_outra_cidadania}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium  "
                                >
                                    Você tem passaportes antigos, ou de outra nacionalidade que estejam com você?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'passaporte_antigo')}
                                            value={1}
                                            id="checked-passaporte_antigo-s"
                                            type="radio"
                                            checked={state.passaporte_antigo === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-passaporte_antigo-s"
                                            className="ms-2 text-[16px] font-medium  "
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.passaporte_antigo === 0 }
                                            onChange={e => handleChange(0,'passaporte_antigo')}
                                            id="checked-passaporte_antigo-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-passaporte_antigo-n"
                                            className="ms-2 text-[16px] font-medium  "
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Input
                            required={!!state.passaporte_antigo}
                            className={'md:col-span-2'}
                            label={'Em caso afirmativo, por favor informar o país, e se possui passaporte do mesmo'}
                            name={'dados_passaporte_antigo'}
                            setValue={e => handleChange(e,'dados_passaporte_antigo')}
                            value={state.dados_passaporte_antigo}
                        />
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}