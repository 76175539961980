import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import declaracoesFinaisApi from "../../../../../services/declaracoesFinaisApi";
import Alert from "../../../../../helpers/Alert";
import InputSelect from "../../../../../components/input/InputSelect";

export interface IDeclaracaoFinalAusEstudante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DeclaracaoFinalAusEstudante({processo, setProcesso, setTelaNumero, telanumero}: IDeclaracaoFinalAusEstudante) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        fundos_suficientes: 0,
        visto_nao_trabalho: 0,
        visto_nao_estudo: 0,
        intencao_genuina: 0,
        condicao_8503: 0,
        respeitar_condicao_8503: 0,
        informar_escritorio_pais: 0,
        taxa_paga: 'fornecer_dados',
        declaracao_boa_fe: 0,
        afirmacao_informacoes: 0,
        condicao_8534: 0,
        respeitar_condicao_8534: 0,
    });
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const getDados = async () => {
        const {data, isError} = await declaracoesFinaisApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                console.log(data);
                setState(data.data);
            }
        }
    }
    useEffect(()=>{getDados()}, [])
    const onSubmit = async () => {
        const confirm = await Alert.confirm('Deseja realmente concluir esse processo?', 'Concluir processo');
        if (!confirm) return;
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await declaracoesFinaisApi.update(state.id, state) : await declaracoesFinaisApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        Alert.close();
        window.scrollTo(0, 0);
        setProcesso({...processo, percet_conclusao:100 })
        setTelaNumero(telanumero + 1);
    }
    const pagamentoOptions = [
        { value: 'fornecer_dados', label: 'Meu cartão de crédito internacional (desejo fornecer os dados para pagamento)' },
        { value: 'ligacao_fornecer_dados', label: 'Meu cartão de crédito internacional (me liguem no momento do pagamento que repasso as informações para pagamento)' },
    ]
    return(
        <>
            <HeaderForm
                titulo={'Declarações finais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Compreendo que o visto que estou solicitando me autoriza trabalhar ou praticar atividades de negócios na Austrália, por 20 horas semanais durante o período letivo e 40 horas semanais durante os recessos escolares
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_nao_trabalho')}
                                        value={1}
                                        id="checked-visto_nao_trabalho-s"
                                        type="radio"
                                        checked={state.visto_nao_trabalho === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_nao_trabalho-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_nao_trabalho === 0 }
                                        onChange={e => handleChange(0,'visto_nao_trabalho')}
                                        id="checked-visto_nao_trabalho-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_nao_trabalho-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                A minha intenção de visitar a Austrália é genuína e eu agirei de acordo com as condições e período de estadia do visto
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'intencao_genuina')}
                                        value={1}
                                        id="checked-intencao_genuina-s"
                                        type="radio"
                                        checked={state.intencao_genuina === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-intencao_genuina-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.intencao_genuina === 0 }
                                        onChange={e => handleChange(0,'intencao_genuina')}
                                        id="checked-intencao_genuina-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-intencao_genuina-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Eu reconheço que a condição 8534 pode ser imposta sobre o meu visto de estudante, não me permitido estender o prazo de estadia no país:
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'condicao_8534')}
                                        value={1}
                                        id="checked-condicao_8534-s"
                                        type="radio"
                                        checked={state.condicao_8534 === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-condicao_8534-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.condicao_8534 === 0 }
                                        onChange={e => handleChange(0,'condicao_8534')}
                                        id="checked-condicao_8534-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-condicao_8534-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Se o meu visto de estudante vier com a condição 8534 - que não permite estender a minha permanência autorizada", concordo em respeitar a condição
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'respeitar_condicao_8534')}
                                        value={1}
                                        id="checked-respeitar_condicao_8534-s"
                                        type="radio"
                                        checked={state.respeitar_condicao_8534 === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-respeitar_condicao_8534-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.respeitar_condicao_8534 === 0 }
                                        onChange={e => handleChange(0,'respeitar_condicao_8534')}
                                        id="checked-respeitar_condicao_8534-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-respeitar_condicao_8534-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Eu afirmo ter fundos suficientes para cobrir todos os custos associados com a visita para a Austrália
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'fundos_suficientes')}
                                        value={1}
                                        id="checked-fundos_suficientes-s"
                                        type="radio"
                                        checked={state.fundos_suficientes === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-fundos_suficientes-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.fundos_suficientes === 0 }
                                        onChange={e => handleChange(0,'fundos_suficientes')}
                                        id="checked-fundos_suficientes-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-fundos_suficientes-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                        <label
                            className="text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Eu declaro de boa fé todos os detalhes relevantes que me foram solicitados neste formulário
                        </label>
                        <div className={'flex  gap-4 mt-[1rem]'}>
                            <div className={'flex'}>
                                <input
                                    onChange={e => handleChange(1,'declaracao_boa_fe')}
                                    value={1}
                                    id="checked-declaracao_boa_fe-s"
                                    type="radio"
                                    checked={state.declaracao_boa_fe === 1 }
                                    className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="checked-declaracao_boa_fe-s"
                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Sim
                                </label>
                            </div>
                            <div className={'flex'}>
                                <input
                                    checked={state.declaracao_boa_fe === 0 }
                                    onChange={e => handleChange(0,'declaracao_boa_fe')}
                                    id="checked-declaracao_boa_fe-n"
                                    type="radio"
                                    value={0}
                                    className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="checked-declaracao_boa_fe-n"
                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Não
                                </label>
                            </div>
                        </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Se concedido o Visto, é de meu dever informar o escritório do governo australiano, caso haja alguma alteração na minha viagem para a Austrália
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'informar_escritorio_pais')}
                                        value={1}
                                        id="checked-informar_escritorio_pais-s"
                                        type="radio"
                                        checked={state.informar_escritorio_pais === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-informar_escritorio_pais-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.informar_escritorio_pais === 0 }
                                        onChange={e => handleChange(0,'informar_escritorio_pais')}
                                        id="checked-informar_escritorio_pais-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-informar_escritorio_pais-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Como sua taxa consular será paga"}
                                selected={pagamentoOptions.filter((opt: any) => opt.value === state.taxa_paga)}
                                options={pagamentoOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'taxa_paga')}
                            />
                        </div>
                    </div>




























                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Compreendo que o visto que estou solicitando não me permite estudar por um período maior de 3 meses na Austrália
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_nao_estudo')}
                                        value={1}
                                        id="checked-visto_nao_estudo-s"
                                        type="radio"
                                        checked={state.visto_nao_estudo === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_nao_estudo-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_nao_estudo === 0 }
                                        onChange={e => handleChange(0,'visto_nao_estudo')}
                                        id="checked-visto_nao_estudo-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_nao_estudo-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Eu afirmo ter lido e entendido as informações fornecidas a mim nesta aplicação
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'afirmacao_informacoes')}
                                        value={1}
                                        id="checked-afirmacao_informacoes-s"
                                        type="radio"
                                        checked={state.afirmacao_informacoes === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-afirmacao_informacoes-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.afirmacao_informacoes === 0 }
                                        onChange={e => handleChange(0,'afirmacao_informacoes')}
                                        id="checked-afirmacao_informacoes-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-afirmacao_informacoes-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}