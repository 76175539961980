import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import detalhamentoEmpregoAnteriorCaApi from "../../../../../services/detalhamentoEmpregoAnteriorCaApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import saudeHistoricoImigracionalApi from "../../../../../services/saudeHistoricoImigracionalApi";
import Input from "../../../../../components/input/Input";

export interface ISaudeHistoricoImigracional{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
    informacoesProfissionais?: any;
}

export default function SaudeHistoricoImigracional({processo, setProcesso, setTelaNumero, telanumero, informacoesProfissionais}: ISaudeHistoricoImigracional) {
    const [state, setState] = useState(
        {
            id: '',
            processo_id: processo.id,
            tuberculose: 0,
            disturbios: 0,
            visto_negado: 0,
            entrada_recusada: 0,
            visto_ja_solicitado: 0,
            visto_ja_solicitado_obs: '',

            visto_negado_outro_pais: 0,
            dados_visto_negado: '',
            visto_eua_aprovado: 0,
            eua_visitado: 0,
            paises_visitados: '',
        }
    );

    const getData = async () => {
        const {data, isError} = await saudeHistoricoImigracionalApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                const response = data.data;
                setState(response);
            }
        }

    }

    useEffect(() => {
        getData()
    }, []);
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await saudeHistoricoImigracionalApi.update(state.id, state) : await saudeHistoricoImigracionalApi.create(state);
        if (isError){

            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }

        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={'Pergunta de saúde e histórico imigracional'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Você tem algum distúrbio físico ou mental que demandaria serviços sociais ou de saúde durante sua estadia?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'tuberculose')}
                                        value={1}
                                        id="checked-tuberculose-s"
                                        type="radio"
                                        checked={state.tuberculose === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-tuberculose-s"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.tuberculose === 0 }
                                        onChange={e => handleChange(0,'tuberculose')}
                                        id="checked-tuberculose-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-tuberculose-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Você tem algum distúrbio físico ou mental que demandaria serviços sociais ou de saúde durante sua estadia?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'disturbios')}
                                        value={1}
                                        id="checked-disturbios-s"
                                        type="radio"
                                        checked={state.disturbios === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-disturbios-s"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.disturbios === 0 }
                                        onChange={e => handleChange(0,'disturbios')}
                                        id="checked-disturbios-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-disturbios-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Já teve um visto negado para o Canadá ou outro país?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_negado')}
                                        value={1}
                                        id="checked-visto_negado-s"
                                        type="radio"
                                        checked={state.visto_negado === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_negado-s"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_negado === 0 }
                                        onChange={e => handleChange(0,'visto_negado')}
                                        id="checked-visto_negado-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_negado-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Já teve a entrada recusada, ou foi obrigado(a) a deixar o Canadá ou qualquer outro país?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'entrada_recusada')}
                                        value={1}
                                        id="checked-entrada_recusada-s"
                                        type="radio"
                                        checked={state.entrada_recusada === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-entrada_recusada-s"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.entrada_recusada === 0 }
                                        onChange={e => handleChange(0,'entrada_recusada')}
                                        id="checked-entrada_recusada-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-entrada_recusada-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Já solicitou algum visto para o Canadá?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_ja_solicitado')}
                                        value={1}
                                        id="checked-visto_ja_solicitado-s"
                                        type="radio"
                                        checked={state.visto_ja_solicitado === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_ja_solicitado-s"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_ja_solicitado === 0 }
                                        onChange={e => handleChange(0,'visto_ja_solicitado')}
                                        id="checked-visto_ja_solicitado-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_ja_solicitado-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Caso tenha servido ao exército, polícia ou unidade de defesa, detalhe a função
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'visto_ja_solicitado_obs'}
                                onChange={e => handleChange(e.target.value,'visto_ja_solicitado_obs')}
                                value={state.visto_ja_solicitado_obs ? state.visto_ja_solicitado_obs : ""}
                            />
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Já teve algum visto negado para algum outro país?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_negado_outro_pais')}
                                        value={1}
                                        id="checked-visto_negado_outro_pais-s"
                                        type="radio"
                                        checked={state.visto_negado_outro_pais === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_negado_outro_pais-s"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_negado_outro_pais === 0 }
                                        onChange={e => handleChange(0,'visto_negado_outro_pais')}
                                        id="checked-visto_negado_outro_pais-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_negado_outro_pais-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Em caso afirmativo, por favor informe o nome do país, tipo de visto e data da negativa'}
                            required={!!state.visto_negado_outro_pais}
                            name={'dados_visto_negado'}
                            setValue={e => handleChange(e,'dados_visto_negado')}
                            value={state.dados_visto_negado}
                        />
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Você tem ou já teve visto americano aprovado nos últimos 10 anos?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_eua_aprovado')}
                                        value={1}
                                        id="checked-visto_eua_aprovado-s"
                                        type="radio"
                                        checked={state.visto_eua_aprovado === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_eua_aprovado-s"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_eua_aprovado === 0 }
                                        onChange={e => handleChange(0,'visto_eua_aprovado')}
                                        id="checked-visto_eua_aprovado-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_eua_aprovado-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Você já visitou os EUA nos últimos 10 anos?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'eua_visitado')}
                                        value={1}
                                        id="checked-eua_visitado-s"
                                        type="radio"
                                        checked={state.eua_visitado === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-eua_visitado-s"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.eua_visitado === 0 }
                                        onChange={e => handleChange(0,'eua_visitado')}
                                        id="checked-eua_visitado-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-eua_visitado-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Liste os países visitados nos últimos 05 anos.'}
                            name={'paises_visitados'}
                            setValue={e => handleChange(e,'paises_visitados')}
                            value={state.paises_visitados}
                            legenda={'Por favor informar data de entrada e saída do país, cidade visitada e o motivo da viagem.'}
                        />
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(informacoesProfissionais.atividades_anteriores === "Nao" ? telanumero - 3 : telanumero - 1 )}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}