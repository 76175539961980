import Dates from "../../../../helpers/Dates";

interface IFormularioAusEstudante {
    state: any
}
export default function FormularioAusEstudante({state}: IFormularioAusEstudante){
    const situacaoImigratoriaOptions = [
        { value: 'cidadao', label: 'Cidadão' },
        { value: 'visitante', label: 'Visitante' },
        { value: 'residente_permanente', label: 'Residente Permanente' },
        { value: 'estudante', label: 'Estudante Oficial - com visto de estudante' },
        { value: 'visto_trabalho', label: 'Trabalhador - com visto de trabalho' },
        { value: 'outros', label: 'Outros' },
        { value: 'ilegal', label: 'Ilegal' },
    ];

    const stadoCivilOptions = [
        { value: 'solteiro', label: 'Solteiro(a)' },
        { value: 'casado', label: 'Casado(a)' },
        { value: 'uniao estavel', label: 'União Estável' },
        { value: 'separado', label: 'Separado(a)' },
        { value: 'desquitado', label: 'Desquitado(a)' },
        { value: 'viuvo', label: 'Viúvo(a)' },
        { value: 'divorciado', label: 'Divorciado' },
    ];

    const optionsSituacaoVisto = [
        { value: 'Aprovado', label: 'Aprovado' },
        { value: 'Negado', label: 'Negado' },
        { value: 'Invalidado', label: 'Invalidado' },
        { value: 'Retirado', label: 'Retirado' },
        { value: 'Outros', label: 'Outros' },
    ];

    const optionsTipoVisto = [
        { value: 'Visitor', label: 'Visitor' },
        { value: 'Student', label: 'Student' },
        { value: 'Work', label: 'Work' },
        { value: 'Transit', label: 'Transit' },
        { value: 'Outros', label: 'Outros' },
    ];

    const optionsAplicado = [
        { value: 'requerente_principal', label: 'Requerente principal, solicitando um visto de estudo para a Austrália' },
        { value: 'acompanhante', label: 'Um acompanhante, que irá viajar com um estudante para a Austrália.' },
    ];

    const optionsInformcaoCertificado = [
        { value: 'ielts', label: 'IELTS' },
        { value: 'toefl_pbt', label: 'TOEFL PBT' },
        { value: 'toefl_ibt', label: 'TOEFL IBT' },
        { value: 'academico', label: 'Inglês Acadêmico' },
        { value: 'cambridge', label: 'Cambridge FCE ou CAE' },
        { value: 'ocupacional', label: 'este de inglês ocupacional' },
    ];

    const optionsLocalEstudo = [
        { value: 'australia', label: 'Austrália' },
        { value: 'canada', label: 'Canadá' },
        { value: 'nova_zelandia', label: 'Nova Zelândia' },
        { value: 'africa', label: 'África do Sul' },
        { value: 'irlanda', label: 'Irlanda' },
        { value: 'reino_unido', label: 'Reino Unido' },
        { value: 'eua', label: 'Estados Unidos' },
    ];

    const assistenciaOptions = [
        { value: 'solteiro', label: 'OSHC (Overseas Student Health Cover)' },
        { value: 'casado', label: 'Outros (Nesse caso, aconselhamos que o solicitante verifique se o plano cobre o que o governo australiano exige)' },
    ];

    const pagamentoOptions = [
        { value: 'fornecer_dados', label: 'Meu cartão de crédito internacional (desejo fornecer os dados para pagamento)' },
        { value: 'ligacao_fornecer_dados', label: 'Meu cartão de crédito internacional (me liguem no momento do pagamento que repasso as informações para pagamento)' },
    ]

    return(
        <div className={'p-4'}>
            <div className={`${
                state.conclusao_formulario ?
                    'p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400'
                    :
                    'p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
            }`}
                 role="alert">
                {
                    state.conclusao_formulario
                        ? 'Formulário finalizado pelo solicitante'
                        : 'Formulário não foi finalizado pelo solicitante'
                }
            </div>
            <div>
                <table className='ps-4 mt-4 pe-4'>
                    <tbody>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Em que país você está neste momento da sua aplicação de visto?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.pais_atual_visto ? state.pais_atual_visto : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual o seu status imigratório?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.situacao_imigratoria && situacaoImigratoriaOptions.filter(sti => sti.value === state.situacao_imigratoria)[0]['label']}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você está solicitando este visto devido o
                                encerramento das atividades do seu prestador de educação australiano?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visto_solicitado ? 'Sim' : 'Não'}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você recebe ou receberá financiamento
                                parcial ou total do Governo Australiano(Commonwealth)?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.financiamento_governo ? 'Sim' : 'Não'}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sobrenome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.sobrenome}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nome}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já possuiu outro nome?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.possui_nomes_anteriores ? 'Sim' : 'Não'}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso positivo, qual?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nomes_anteriores ? state.cliente.nomes_anteriores : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sexo:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.sexo ? state.cliente.sexo === 'm' ? 'Masculino' : 'Feminino' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de Nascimento (Dia/Mês/Ano):</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nascimento ? Dates.convertEnToBr(state.cliente.nascimento) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já possui outra data de nascimento?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.possui_outra_nascimento ? 'Sim' : 'Não'}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso afirmativo, qual?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.outra_nascimento ? state.cliente.outra_nascimento : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade e estado de Nascimento:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cidade_natal ? state.cliente.cidade_natal : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País de Nascimento:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.pais_natal ? state.cliente.pais_natal : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Estado Civil:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.estado_civil && stadoCivilOptions.filter(sti => sti.value === state.cliente.estado_civil)[0]['label']}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso esteja em uma relação estável. Qual a data de inicio da relação?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nome_completo_conjuge ? state.cliente.nome_completo_conjuge : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nacionalidade:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente ? (state.cliente.nacionalidade ? state.cliente.nacionalidade : '') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você possui outra nacionalidade?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.possui_outra_nacionalidade ? 'Sim' : 'Não'}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.outras_cidadanias ? state.cliente.outras_cidadanias : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>CPF:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cpf ? state.cliente.cpf : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Língua de preferência, em caso de entrevista:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.lingua_preferencia_entrevista ? state.lingua_preferencia_entrevista : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? state.passaporte.numero : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País da Emissão:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? (state.passaporte.pais_emissor ? state.passaporte.pais_emissor : '') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de emissão do passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? Dates.convertEnToBr(state.passaporte.data_emissao) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de validade do passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? Dates.convertEnToBr(state.passaporte.data_validade) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Orgão emissor:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? (state.passaporte.orgao_emissor ? state.passaporte.orgao_emissor : '') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>RG:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.rg ? state.cliente.rg : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Orgão emissor:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.orgao_emissor ? state.cliente.orgao_emissor : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.endereco ? state.cliente.endereco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade e estado em que reside.</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cidade ? state.cliente.cidade : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>CEP.</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cep ? state.cliente.cep : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País que reside, atualmente:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.pais_residencia ? state.cliente.pais_residencia : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone Primário:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.telefone ? state.cliente.telefone : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone Secundário:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.telefone_secundario ? state.cliente.telefone_secundario : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone trabalho:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.telefone_trabalho ? state.cliente.telefone_trabalho : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>E-mail:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.usuario ? state.cliente.usuario.email : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo onde ficará na Austrália: (caso saiba)</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.endereco_pais ? state.endereco_pais : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você concorda que o departamento consular entre em
                                contato com você por telefone ou e-mail?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.concordo_consulado_contato ? 'Sim' : 'Não'}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você tem filhos?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.filhos ? 'Sim' : 'Não'}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Em caso afirmativo, por favor informe o nome completo e
                                data de nascimento de cada um deles. E se viajarão com
                                você.</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Detalhe a seguir os seus parentes que ficam no Brasil.</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.familiares_brasil ? state.familiares_brasil : ''}</td>
                        </tr>


                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Sobrenome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.sobrenome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Nome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.nome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Grau de Parentesco:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.grau_parentesco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Nacionalidade:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.nacionalidade : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Data de Nascimento (Dia/Mês/Ano):</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? Dates.convertEnToBr(state.r_familiares_brasil.data_nascimento) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Viaja com você?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.concordo_consulado_contato ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Número do Passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.numero_passaporte : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - País de emissão:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.pais_passaporte : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Data de emissão do passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? Dates.convertEnToBr(state.r_familiares_brasil.data_emissao) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Data de validade do passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? Dates.convertEnToBr(state.r_familiares_brasil.data_validade) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Orgão emissor:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.orgao_emissor : ''}</td>
                        </tr>






                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Sobrenome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.segundo_sobrenome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Nome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.segundo_nome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Grau de Parentesco:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.segundo_grau_parentesco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Nacionalidade:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.segundo_nacionalidade : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Data de Nascimento (Dia/Mês/Ano):</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? Dates.convertEnToBr(state.r_familiares_brasil.segundo_data_nascimento) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Viaja com você?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.concordo_consulado_contato ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Número do Passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.segundo_numero_passaporte : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - País de emissão:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.segundo_pais_passaporte : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Data de emissão do passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? Dates.convertEnToBr(state.r_familiares_brasil.segundo_data_emissao) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Data de validade do passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? Dates.convertEnToBr(state.r_familiares_brasil.segundo_data_validade) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Orgão emissor:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.segundo_orgao_emissor : ''}</td>
                        </tr>


                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Sobrenome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.terceiro_sobrenome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Nome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.terceiro_nome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Grau de Parentesco:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.terceiro_grau_parentesco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Nacionalidade:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.terceiro_nacionalidade : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Data de Nascimento (Dia/Mês/Ano):</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? Dates.convertEnToBr(state.r_familiares_brasil.terceiro_data_nascimento) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Viaja com você?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.concordo_consulado_contato ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Número do Passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.terceiro_numero_passaporte : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - País de emissão:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.terceiro_pais_passaporte : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Data de emissão do passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? Dates.convertEnToBr(state.r_familiares_brasil.terceiro_data_emissao) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Data de validade do passaporte:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? Dates.convertEnToBr(state.r_familiares_brasil.terceiro_data_validade) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Orgão emissor:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.terceiro_orgao_emissor : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha outro membro familiar, que você acredita ser
                                importante informar, favor usar o espaço abaixo:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.outros_membros : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você ficará hospedado na casa de algum parente ou amigo
                                durante a sua viagem?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.hospedado ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você tem algum parente na Austrália?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.r_familiares_brasil ? state.r_familiares_brasil.parente_pais ? 'Sim' : 'Não' : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já aplicou para algum tipo de visto australiano?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.aplicou_tipo_visto ? 'Sim' : 'Não' : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual foi a situação final da solicitação?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores && optionsSituacaoVisto.filter(sti => sti.value === state.dados_vistos_anteriores.situacao_final)[0]['label']}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual o tipo de visto solicitado?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores && optionsTipoVisto.filter(sti => sti.value === state.dados_vistos_anteriores.tipo_visto_solicitado)[0]['label']}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você possui um visto australiano válido?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.visto_valido ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você está aguardando a resposta de algum processo de visto australiano atualmente?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.aguardando_resposta_processo ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique os dados abaixo:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.informacao_resposta_processo : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você, ou outra pessoa incluída neste processo, já esteve na Austrália por um período maior que o concedido no visto, ou então desrespeitou alguma regra consular durante sua estadia? - Teve a entrada negada, ou o visto negado para a Austrália?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.estadia_maior ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.especifique_estadia_maior : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual o tipo de visto solicitado?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores && state.dados_vistos_anteriores.aplicado_como &&  optionsAplicado.filter(sti => sti.value === state.dados_vistos_anteriores.aplicado_como)[0]['label']}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já teve algum visto aprovado para outro país?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.visto_aprovado_outro ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Por favor, informe o nome do país e o tipo de visto</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.informacao_visto_aprovado_outro : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já teve algum visto negado para qualquer país?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.visto_negado_outro ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Por favor, informe o nome do país e o tipo de visto</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.informacao_visto_negado_outro : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você possui algum visto aprovado neste momento?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.visto_aprovado ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Por favor, informe o nome do país e o tipo de visto</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_vistos_anteriores ? state.dados_vistos_anteriores.informacao_visto_aprovado : ''}</td>
                        </tr>


                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Que tipo de intercâmbio irá realizar?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.tipo_intercambio : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data da sua viagem à Austrália?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.data_prevista_viagem ? Dates.convertEnToBr(state.data_prevista_viagem) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Duração da sua viagem?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.duracao_viagem : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você levará algum dependente familiar na sua viagem e no
                                seu visto?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.dependente_familiar ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Por favor, informe o nome do país e o tipo de visto</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.informacoes_dependente_familiar : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Favor informar o seu histórico escolar atual, tal como
                                faculdade ou curso de especialização: (Fora da Austrália)</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.historico_escolar : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Grau de formação</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.grau_formacao : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Nome da instituição</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.nome_instituicao : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Curso / Área de formação</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.nome_curso : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Endereço completo, com o CEP</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.endereco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Data de início do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.data_inicio : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Data de termino do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.data_fim : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Grau de formação</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.segundo_grau_formacao : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Nome da instituição</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.segundo_nome_instituicao : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Endereço completo, com o CEP</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.segundo_endereco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Data de início do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.segundo_data_inicio : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Data de termino do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.segundo_data_fim : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Grau de formação</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.terceiro_grau_formacao : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Nome da instituição</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.terceiro_nome_instituicao : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Endereço completo, com o CEP</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.terceiro_endereco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Data de início do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.terceiro_data_inicio : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Data de termino do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.terceiro_data_fim : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já estudou na Austrália?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.estudou_pais ? 'Sim' : 'Não' : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Caso tenha respondido "Sim", favor informar qual o curso realizado</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.primeiro_curso_realizado : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Nome da instituição</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.primeiro_curso_realizado : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Data de início do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? Dates.convertEnToBr(state.informacoes_educacionais_r.primeiro_data_inicio_curso) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Data de termino do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? Dates.convertEnToBr(state.informacoes_educacionais_r.primeiro_data_fim_curso) : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Qual o curso realizado?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.segundo_curso_realizado : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Nome da instituição</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.segundo_curso_realizado : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Data de início do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? Dates.convertEnToBr(state.informacoes_educacionais_r.segundo_data_inicio_curso) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Data de termino do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? Dates.convertEnToBr(state.informacoes_educacionais_r.segundo_data_fim_curso) : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual curso você pretende fazer enquanto estiver na Austrália?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.curso_pretende : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome da instituição</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.curso_pretende_intituicao : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Início do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? Dates.convertEnToBr(state.informacoes_educacionais_r.curso_pretende_inicio) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Termino do curso</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? Dates.convertEnToBr(state.informacoes_educacionais_r.curso_pretende_fim) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já recebeu o eCOE</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.recebeu_ecoe : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Informe os motivos centrais de ter escolhido a Austrália como destino para a viagem (em inglês para que não seja necessária tradução e com, no máximo 2000 caracteres)</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.motivos_centrais : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome de um contato emergencial no Brasil</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.contato_brasil_nome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual o parentesco/relação com você?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.contato_brasil_parentesco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo do contato emergencial.</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.contato_brasil_endereco : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone do contato emergencial</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.contato_brasil_telefone : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você possui algum certificado de proficiência em inglês, que tenha sido concedido nos últimos 2 anos?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.nivel_ingles ? state.nivel_ingles.certificado ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, qual?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.nivel_ingles ? (state.nivel_ingles.informacao_certificado ? optionsInformcaoCertificado.filter(sti => sti.value === state.nivel_ingles.informacao_certificado)[0]['label'] : '') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual foi a data da aplicação do teste?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.nivel_ingles ? Dates.convertEnToBr(state.nivel_ingles.data_teste) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Localização da aplicação do teste:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.nivel_ingles ? state.nivel_ingles.local_aplicacao : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>O número de certificação do teste:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.nivel_ingles ? state.nivel_ingles.numero_certificado : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>A pontuação final:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.nivel_ingles ? state.nivel_ingles.pontuacao : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já estudou inglês em algum país, que não o seu de
                                residência, nos últimos 5 anos?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.nivel_ingles ? state.nivel_ingles.estudou_outro_pais ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Se sim, onde?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.nivel_ingles ? (state.nivel_ingles.local_estudo ? optionsLocalEstudo.filter(sti => sti.value === state.nivel_ingles.local_estudo)[0]['label'] : '') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual curso realizado nesse país?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.nivel_ingles ? state.nivel_ingles.curso_realizado : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Possui um certificado?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.nivel_ingles ? state.nivel_ingles.possui_certificado ? 'Sim' : 'Não' : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você está trabalhando no momento?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.tabalhando ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual curso realizado nesse país?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.ocupacao : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual a data de admissão no trabalho ou início dos seus
                                estudos na escola/faculdade?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? Dates.convertEnToBr(state.ocupacao.data_entrada) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome do empregador ou escola?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.nome_empresa : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual o ramo de atuação do seu empregador?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.ramo_empregador : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo, com o CEP:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.endereco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.telefone : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Informe o nome e sobrenome de um supervisor ou contato
                                no seu trabalho atual</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.nome_supervisor : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual o valor do seu salário/remuneração?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.salario : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Possui comprovantes?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.possui_comprovantes ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Possui algum histórico trabalhista anterior?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.possui_historico_trabalhista ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de admissão no trabalho ou início dos estudos?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? Dates.convertEnToBr(state.ocupacao.data_admissao_trabalho_anterior) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual a sua função, cargo ou ocupação anterior?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.cargo_trabalho_anterior : ''}</td>
                        </tr>


                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Nome do empregador ou escola?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.segundo_nome_empresa : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Qual o ramo de atuação do seu empregador?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.segundo_ramo_empregador : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Endereço completo, com o CEP:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.segundo_endereco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Telefone:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.segundo_telefone : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Qual o valor da sua remuneração</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.segundo_remuneracao : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Possui comprovantes?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.segundo_possui_comprovantes ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Qual o período trabalhado</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.segundo_periodo : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já foi convidado à algum trabalho quando retornar para o seu país de origem?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.convidado_atralhar ? 'Sim' : 'Não' : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, para qual cargo? Em caso
                                negativo, em qual área você pretende atuar no futuro?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? state.ocupacao.informacoes_convidado_atralhar : ''}</td>
                        </tr>



                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Caso você tenha algum contato na Austrália, (parente,
                                conhecido, amigo) favor informar o nome da pessoa:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.nome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Grau de Parentesco:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.grau_parentesco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Data de Nascimento</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? Dates.convertEnToBr(state.dados_contato_pais.nascimento) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Endereço completo, com o CEP:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.endereco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Status Migratório dessa pessoa</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.status_imigratorio : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Data de chegada na Austrália</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? Dates.convertEnToBr(state.dados_contato_pais.data_chegada) : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Caso você tenha algum contato na Austrália, (parente,
                                conhecido, amigo) favor informar o nome da pessoa:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.segundo_nome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Grau de Parentesco:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.segundo_grau_parentesco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Data de Nascimento</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? Dates.convertEnToBr(state.dados_contato_pais.segundo_nascimento) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Endereço completo, com o CEP:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.segundo_endereco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Status Migratório dessa pessoa</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.segundo_status_imigratorio : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Data de chegada na Austrália</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? Dates.convertEnToBr(state.dados_contato_pais.segundo_data_chegada) : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Favor informar o contato de um parente seu que reside no
                                Brasil e que não esteja acompanhando você na viagem:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.info_contato_brasil : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Grau de Parentesco:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.info_contato_grau_parentesco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo, com o CEP:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.info_contato_endereco : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone Primário:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_contato_pais ? state.dados_contato_pais.info_contato_telefone : ''}</td>
                        </tr>


                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Quem irá custear a sua viagem e o processo de visto?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_financeiros ? state.dados_financeiros.quem_custeara : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você afirma ter renda suficiente para arcar com a viagem e
                                o curso para a Austrália?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_financeiros ? state.dados_financeiros.renda_suficiente ? 'Sim' : 'Não' : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual o total de renda que você tem disponível para a
                                viagem?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_financeiros ? state.dados_financeiros.valor_total : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Parte dessa renda é emprestada?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_financeiros ? (state.dados_financeiros.parte_emprestada ? 'Sim' : 'Não') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, quanto?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_financeiros ? state.dados_financeiros.informacao_parte_emprestada : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Parte desse dinheiro foi emprestado condicionalmente?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_financeiros ? (state.dados_financeiros.parte_emprestada_condicionalmente ? 'Sim' : 'Não') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, quanto?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_financeiros ? state.dados_financeiros.informacao_parte_emprestada_condicionalmente : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Como você pretende ter acesso a esse dinheiro, enquanto
                                estiver na Austrália?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_financeiros ? state.dados_financeiros.como_ter_dinheiro : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>A sua viagem será patrocinada por alguma companhia ou
                                empresa?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_financeiros ? state.dados_financeiros.patrocinado : ''}</td>
                        </tr>



                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já possui uma assistência médica quVocê tem alguma informação, ou documento,e cubra toda a
                                sua estadia enquanto estiver na Austrália?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.assistencia_medica ? (state.assistencia_medica.possui_assistencia ? 'Sim' : 'Não') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Favor selecionar a melhor opção de qual assistência
                                médica você possui:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.assistencia_medica ? (state.assistencia_medica.local_estudo ? assistenciaOptions.filter(sti => sti.value === state.assistencia_medica.assistencia)[0]['label'] : '') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha marcado outros, especifique aqui qual o
                                plano:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_financeiros ? state.dados_financeiros.outros : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você tem alguma informação, ou documento, que acredita ser importante mencionar no processo?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.comentario_adicional ? state.comentario_adicional : ''}</td>
                        </tr>


                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nos últimos 10 anos, você ou outra pessoa incluída nesse
                                processo, já visitou ou morou em outro país?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.eua_visitado ? 'Sim' : 'Não') : ''}</td>
                        </tr>


                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, favor informar o nome
                                completo da pessoa:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.primeiro_nome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País onde visitou / morou:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.paises_visitados : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual o motivo da viagem?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.primeiro_motivo_viagem : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de entrada no país:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? Dates.convertEnToBr(state.saude_historico_imigracional.primeira_data_entrada) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de saída do país:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? Dates.convertEnToBr(state.saude_historico_imigracional.primeira_data_saida) : ''}</td>
                        </tr>



                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Nome completo:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.segundo_nome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - País onde visitou / morou:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.segundo_motivo_viagem : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Data de entrada no país:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? Dates.convertEnToBr(state.saude_historico_imigracional.segunda_data_entrada) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Data de saída do país:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? Dates.convertEnToBr(state.saude_historico_imigracional.segunda_data_saida) : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Nome completo:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.terceiro_nome : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - País onde visitou / morou:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.terceiro_motivo_viagem : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Data de entrada no país:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? Dates.convertEnToBr(state.saude_historico_imigracional.terceira_data_entrada) : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Data de saída do país:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? Dates.convertEnToBr(state.saude_historico_imigracional.terceira_data_saida) : ''}</td>
                        </tr>


                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você, ou outra pessoa incluída neste processo, tem a
                                intenção de utilizar um hospital ou uma unidade de saúde
                                (incluindo casas de repouso), enquanto estiver na
                                Austrália?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.usar_hospital ? 'Sim' : 'Não') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.informacao_usar_hospital : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você, ou outra pessoa incluída neste processo, tem a
                                intenção de trabalhar ou estudar na área de saúde enquanto
                                estiver na Austrália?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.trabalhar_estudar_saude ? 'Sim' : 'Não') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você, ou outra pessoa incluída neste processo, tem a
                                intenção de trabalhar ou estudar, enquanto estiver na
                                Asutrália?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.trabalhar_estudar ? 'Sim' : 'Não') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.informacao_trabalhar_estudar : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você, ou outra pessoa incluída neste
                                processo, sofre ou já sofreu de tuberculose?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.tuberculose ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Esteve em contato direto com algum familiar,
                                com tuberculose?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.parente_tuberculose ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Já tirou um raio-x onde constou uma
                                anormalidade?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.raio_x_anormalidade ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.informacao_raio_x_anormalidade : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Durante a sua visita à Austrália, você ou
                                qualquer outra pessoa incluída neste
                                processo, precisará de custos médicos, ou
                                exigir tratamento ou acompanhamento
                                médico para:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.custos_medicos ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cancer:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.cancer ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Doença cardíaca:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.doenca_cardiaca ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Hepatite B ou C:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.hepatite ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Doença hepática:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.doenca_hepatica ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>HIV, incluindo AIDS:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.hiv ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Doença renal, incluindo diálise:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.doenca_renal ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Doença mental:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.disturbios ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Gravidez:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.gravidez ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Doença respiratória que exige internação ou terapia de oxigênio:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.problemas_respiratorios ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Outros:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.outros ? state.saude_historico_imigracional.outros : '')   : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você, ou qualquer outra pessoa incluída neste
                                processo, requer assistência com mobilidade
                                ou alguma assistência especial devido a uma
                                condição médica?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.pcd ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.informacao_pcd : ''}</td>
                        </tr>





                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Foi condenado(a) por um crime ou delito em qualquer país
                                (incluindo qualquer caso que agora tenha sido removido
                                dos registros oficiais)
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.condenado_por_crime ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_condenado_por_crime    : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Foi acusado(a) de qualquer crime que está aguardando uma
                                ação legal?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.acusado_por_crime ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_acusado_por_crime    : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Foi acusado(a) de qualquer delito criminal ou outro em
                                razão de doença mental, loucura ou debilidade mental?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.acusado_por_delito ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_acusado_por_delito    : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Foi expulso(a) ou deportado(a) de qualquer país?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.deportado ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_deportado    : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Deixou algum país para evitar ser expulso(a) ou
                                deportado(a)?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.deixou_pais ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_deixou_pais    : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Foi expulso(a) ou teve um pedido para sair de qualquer
                                país?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.expulso_pais ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_expulso_pais    : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Cometeu, ou se envolveu na prática de crimes de guerra
                                contra a humanidade ou direitos humanos?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.crimes_guerra ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_crimes_guerra    : ''}</td>
                        </tr>



                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Esteve envolvidos em quaisquer atividades que possam representar um risco para a segurança nacional da Austrália?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.atividade_risco_pais ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_atividade_risco_pais    : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Tem quaisquer dívidas com o Governo australiano ou qualquer autoridade pública na Austrália?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.dividas_pais ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_dividas_pais    : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Se envolveu em qualquer atividade, ou foi acusado(a) de
                                qualquer delito, relacionado com o movimento ilegal de
                                pessoas para qualquer país?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.movimento_ilegal ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_movimento_ilegal    : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Serviu uma das forças militares patrocinados pelo estado
                                ou milícia privada, submetido(a) a qualquer treinamento
                                militar ou paramilitar, ou treinamento no uso de armas ou
                                explosivos, excepto no curso de serviço militar obrigatório?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.serviu_forcas_militares ? 'Sim' : 'Não')  : ''}</td>
                        </tr>





                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>O aplicante principal é menor de idade?</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.aplicante_menor ? 'Sim' : 'Não')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sobrenome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.sobrenome ? state.dados_responsaveis.sobrenome : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.nome ? state.dados_responsaveis.nome : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.endereco ? state.dados_responsaveis.endereco : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cep:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.cep ? state.dados_responsaveis.cep : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.telefone ? state.dados_responsaveis.telefone : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Celular:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.celular ? state.dados_responsaveis.celular : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Grau de Parentesco:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.grau_parentesco ? state.dados_responsaveis.grau_parentesco : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Número do Passaporte ou RG:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.passaporte_rg ? state.dados_responsaveis.passaporte_rg : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Sobrenome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_sobrenome ? state.dados_responsaveis.segundo_sobrenome : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Nome:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_nome ? state.dados_responsaveis.segundo_nome : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Endereço completo:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_endereco ? state.dados_responsaveis.segundo_endereco : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Cep:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_cep ? state.dados_responsaveis.segundo_cep : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Telefone:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_telefone ? state.dados_responsaveis.segundo_telefone : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Celular:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_celular ? state.dados_responsaveis.segundo_celular : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Grau de Parentesco:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_grau_parentesco ? state.dados_responsaveis.segundo_grau_parentesco : '')  : ''}</td>
                        </tr>

                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Número do Passaporte ou RG:</td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_passaporte_rg ? state.dados_responsaveis.segundo_passaporte_rg : '')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Você pai ou guardião legal, irá viajar com o seu filho para a
                                Austrália?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.guardiao_viaja ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Você pai ou guardião legal, autorizou o seu filho menor
                                viajante, à morar com um parente pessoal (maior de 21
                                anos) na Austrália?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.guardiao_autoriza ? 'Sim' : 'Não')  : ''}</td>
                        </tr>



                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Compreendo que o visto que estou solicitando me autoriza
                                trabalhar ou praticar atividades de negócios na Austrália,
                                por 20 horas semanais durante o período letivo e 40 horas
                                semanais durante os recessos escolares:
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.visto_trabalho ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                A minha intenção de visitar a Austrália é genuína e eu agirei
                                de acordo com as condições e período de estadia do visto:
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.intencao_genuina ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Eu reconheço que a condição 8534 pode ser imposta sobre
                                o meu visto de estudante, não me permitido estender o
                                prazo de estadia no país:
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.condicao_8534 ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Se o meu visto de estudante vier com a condição 8534 - que
                                não permite estender a minha permanência autorizada&quot;,
                                concordo em respeitar a condição:
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.respeitar_condicao_8534 ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Eu afirmo ter fundos suficientes para cobrir todos os
                                custos associados com a visita para a Austrália:
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.fundos_suficientes ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Eu declaro de boa fé todos os detalhes relevantes que me
                                foram solicitados neste formulário:
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.declaracao_boa_fe ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Se concedido o Visto, é de meu dever informar o escritório
                                do governo australiano, caso haja alguma alteração na
                                minha viagem para a Austrália.
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.informar_escritorio_pais ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Como sua taxa consular será paga?
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.taxa_paga ? pagamentoOptions.filter(sti => sti.value === state.declaracao_final.taxa_paga)[0]['label'] : '') : ''}</td>
                        </tr>
                        <tr>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>
                                Eu afirmo ter lido e entendido as informações fornecidas a
                                mim nesta aplicação:
                            </td>
                            <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.afirmacao_informacoes ? 'Sim' : 'Não')  : ''}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}