import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import dadosDosPaisApi from "../../../../../services/dadosDosPaisApi";
import Alert from "../../../../../helpers/Alert";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";
import processosApi from "../../../../../services/processosApi";

export interface IDadosDosPais{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setStateDadosDosPais?: any;
}

export default function DadosDosPais({processo, setProcesso, setTelaNumero, telanumero, setStateDadosDosPais}: IDadosDosPais) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome_pai: '',
        sobrenome_pai: '',
        data_nascimento_pai: '',
        pai_esta_no_pais: '',
        nome_mae: '',
        sobrenome_mae: '',
        data_nascimento_mae: '',
        mae_esta_no_pais: '',
        parentes_no_pais: 0,
    })

    async function onSubmit() {
        const {data, isError} = state.id ? await dadosDosPaisApi.update(state.id, state) :  await dadosDosPaisApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setStateDadosDosPais(state);

        if (state.parentes_no_pais){
            setTelaNumero(telanumero+1);
        }else{
            setTelaNumero(telanumero+2);
        }

    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    useEffect(() => {
        getDadoDosPais();
    }, []);
    const getDadoDosPais = async () =>{
        const {data, isError} = await dadosDosPaisApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados dos pais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            required={true}
                            className={'md:col-span-1'}
                            label={'Nome do pai'}
                            name={'nome_pai'}
                            setValue={e => handleChange(e,'nome_pai')}
                            value={state.nome_pai}
                        />
                        <Input
                            required={true}
                            className={'md:col-span-1'}
                            label={'Sobrenome do pai'}
                            name={'sobrenome_pai'}
                            setValue={e => handleChange(e,'sobrenome_pai')}
                            value={state.sobrenome_pai}
                        />
                        <Input
                            required={true}
                            type={'date'}
                            className={'md:col-span-1'}
                            label={'Data de nascimento pai'}
                            name={'data_nascimento_pai'}
                            setValue={e => handleChange(e,'data_nascimento_pai')}
                            value={state.data_nascimento_pai}
                        />
                    </div>
                    <div className={'grid grid-cols-1 gap-4 mt-5'}>
                        <Input
                            required={true}
                            className={'md:col-span-1'}
                            label={'Seu pai está nos Estados Unidos?'}
                            name={'pai_esta_no_pais'}
                            setValue={e => handleChange(e,'pai_esta_no_pais')}
                            value={state.pai_esta_no_pais}
                        />
                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            required={true}
                            className={'md:col-span-1'}
                            label={'Nome da mãe'}
                            name={'nome_mae'}
                            setValue={e => handleChange(e,'nome_mae')}
                            value={state.nome_mae}
                        />
                        <Input
                            required={true}
                            className={'md:col-span-1'}
                            label={'Sobrenome da mãe'}
                            name={'sobrenome_mae'}
                            setValue={e => handleChange(e,'sobrenome_mae')}
                            value={state.sobrenome_mae}
                        />
                        <Input
                            required={true}
                            type={'date'}
                            className={'md:col-span-1'}
                            label={'Data de nascimento mãe'}
                            name={'data_nascimento_mae'}
                            setValue={e => handleChange(e,'data_nascimento_mae')}
                            value={state.data_nascimento_mae}
                        />
                    </div>
                    <div className={'grid grid-cols-1 gap-4 mt-5'}>
                        <Input
                            required={true}
                            className={'md:col-span-1'}
                            label={'Sua mãe está nos Estados Unidos?'}
                            name={'mae_esta_no_pais'}
                            setValue={e => handleChange(e,'mae_esta_no_pais')}
                            value={state.mae_esta_no_pais}
                        />
                    </div>
                    <div className={'col-span-1 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Você possui parentes nos Estados Unidos?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={() => handleChange(1,'parentes_no_pais')}
                                        value={1}
                                        id="checked-parentes_no_pais-s"
                                        type="radio"
                                        checked={state.parentes_no_pais === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-parentes_no_pais-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.parentes_no_pais === 0 }
                                        onChange={e => handleChange(0,'parentes_no_pais')}
                                        id="checked-parentes_no_pais-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-parentes_no_pais-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}