import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";
import ViaCepService from "../../../../../services/viaCepApi";
import Alert from "../../../../../helpers/Alert";
import informacoesEducacionaisApi from "../../../../../services/informacoesEducacionaisApi";
import processosApi from "../../../../../services/processosApi";

export interface IInformacoesEducacionais{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}
export default function InformaçõesEducacionais({processo, setProcesso, setTelaNumero, telanumero}: IInformacoesEducacionais) {

    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome_instituicao: '',
        bairro: '',
        endereco: '',
        cidade: '',
        estado: '',
        cep: '',
        pais: '',
        nome_curso: '',
        data_inicio: '',
        data_fim: '',
        informacoes_educacionais: 0,
    });
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const cepSrv = new ViaCepService();
    const handleChangeCep = (value: any) => {
        cepSrv.consultaCep(state.cep).then((cepResponse: any) =>{
            if (!cepResponse.data.erro){
                setState({
                    ...state,
                    endereco: removeDiacritics(cepResponse.data.logradouro),
                    bairro: removeDiacritics(cepResponse.data.bairro),
                    cidade: removeDiacritics(cepResponse.data.localidade),
                    estado: removeDiacritics(cepResponse.data.uf),
                    pais: 'Brasil',
                });
            }
        });
    }

    function removeDiacritics(value: string): string {
        return value.normalize("NFD").replace(/[\u0300-\u036f]/g, ''); // Remove diacríticos como acentos e cedilha
    }

    useEffect(() => {getInformacoesEdu()}, []);
    const getInformacoesEdu = async () => {
        const {data, isError} = await informacoesEducacionaisApi.one(processo.id);
        if (!isError){

            if (Object.values(data.data).length){
                const response = data.data;
                setState({
                    id: response.id,
                    processo_id: response.processo_id,
                    nome_instituicao: response.nome_instituicao,
                    endereco: response.endereco,
                    cidade: response.cidade,
                    estado: response.estado,
                    bairro: response.bairro,
                    cep: response.cep,
                    pais: response.pais,
                    nome_curso: response.nome_curso,
                    data_inicio: response.data_inicio,
                    data_fim: response.data_fim,
                    informacoes_educacionais: response.processo.informacoes_educacionais,
                });
            }
        }
    }
    async function onSubmit() {
        const {data, isError} = state.id ? await informacoesEducacionaisApi.update(state.id, state) : await informacoesEducacionaisApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Informações Educacionais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />

            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você realizou algum curso acadêmico ou vocacional após o Ensino Médio?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={() => handleChange(1,'informacoes_educacionais')}
                                        value={1}
                                        id="checked-informacoes_educacionais-s"
                                        type="radio"
                                        checked={state.informacoes_educacionais === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-informacoes_educacionais-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.informacoes_educacionais === 0 }
                                        onChange={e => handleChange(0,'informacoes_educacionais')}
                                        id="checked-informacoes_educacionais-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-informacoes_educacionais-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>

                        </div>
                    </div>
                    {
                        state.informacoes_educacionais ?
                            <>
                                <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                                    <Input
                                        required={true}
                                        className={'col-span-1'}
                                        label={'Nome da instituição'}
                                        name={'nome_instituicao'}
                                        setValue={e => handleChange(e, 'nome_instituicao')}
                                        value={state.nome_instituicao}
                                    />
                                    <Input
                                        required={true}
                                        className={'col-span-1'}
                                        label={'Curso realizado'}
                                        name={'nome_curso'}
                                        setValue={e => handleChange(e, 'nome_curso')}
                                        value={state.nome_curso}
                                    />
                                    <Input
                                        required={true}
                                        className={'col-span-1'}
                                        label={'Data de início'}
                                        type={'date'}
                                        name={'data_inicio'}
                                        setValue={e => handleChange(e, 'data_inicio')}
                                        value={state.data_inicio}
                                    />
                                    <Input
                                        required={true}
                                        className={'col-span-1'}
                                        label={'Data de término'}
                                        type={'date'}
                                        name={'data_fim'}
                                        setValue={e => handleChange(e, 'data_fim')}
                                        value={state.data_fim}
                                    />
                                </div>
                                <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                                    <Input
                                        className={'md:col-span-1'}
                                        label={'CEP'}
                                        name={'cep'}

                                        setValue={e => handleChange(e, 'cep')}
                                        onBlur={(e: any) => handleChangeCep(e)}
                                        value={state.cep}
                                    />
                                    <Input                                        
                                        className={'col-span-2'}
                                        label={'Endereço'}
                                        name={'endereco'}
                                        setValue={e => handleChange(e, 'endereco')}
                                        value={state.endereco}
                                    />
                                    <Input                                        
                                        className={'col-span-1'}
                                        label={'Bairro'}
                                        name={'bairro'}
                                        setValue={e => handleChange(e, 'bairro')}
                                        value={state.bairro}
                                    />
                                </div>
                                <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                                    <Input
                                        required={true}
                                        className={'col-span-1'}
                                        label={'Cidade'}
                                        name={'cidade'}
                                        setValue={e => handleChange(e, 'cidade')}
                                        value={state.cidade}
                                    />
                                    <Input
                                        required={true}
                                        className={'col-span-1'}
                                        label={'Estado'}
                                        name={'estado'}
                                        setValue={e => handleChange(e, 'estado')}
                                        value={state.estado}
                                    />
                                    <Input
                                        required={true}
                                        className={'col-span-1'}
                                        label={'País'}
                                        name={'pais'}
                                        setValue={e => handleChange(e, 'pais')}
                                        value={state.pais}
                                    />
                                </div>
                            </>
                            :
                            ''
                    }
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(processo.emprego_anterior ? telanumero-1 : telanumero-2 )}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}