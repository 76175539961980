import React, {useEffect, useState} from "react";
import perguntaSegurancaApi from "../../../../../services/perguntaSegurancaApi";
import Alert from "../../../../../helpers/Alert";
import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import InputSelect from "../../../../../components/input/InputSelect";
import processosApi from "../../../../../services/processosApi";

export interface IPerguntaSeguranca{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}
export default function PerguntaSeguranca({processo, setProcesso, setTelaNumero, telanumero}: IPerguntaSeguranca) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        caso_envolvido: 'desrespeito',
        informacao: ''
    });

    useEffect(() => {getIdiomaExperiencias()},[])
    const getIdiomaExperiencias = async () => {
        const {data, isError} = await perguntaSegurancaApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    async function onSubmit() {
        const {data, isError} = state.id ? await perguntaSegurancaApi.update(state.id, state) : await perguntaSegurancaApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const casosEnvolvidosOptions = [
        { value: 'desrespeito', label: 'Desrespeito a decisões judiciais nos Estados Unidos' },
        { value: 'doencas', label: 'Tuberculose ou doenças que afetam a saúde pública' },
        { value: 'disturbios', label: 'Distúrbio físico ou mental que coloque em perigo a segurança de outros' },
        { value: 'drogas', label: 'Utilização, comércio e produção de drogas' },
        { value: 'prostituicao', label: 'Prostituição' },
        { value: 'lavagem_dinheiro', label: 'Lavagem de dinheiro' },
        { value: 'trafico_pessoas', label: 'Tráfico de pessoas' },
        { value: 'espionagem', label: 'Espionagem e sabotagem' },
        { value: 'terrorismo', label: 'Atividades e organizações terroristas' },
        { value: 'crimes_lesa', label: 'Genocídio, tortura e outros crimes de lesa humanidade' },
        { value: 'nao', label: 'Não' },
    ]
    return(
        <>
            <HeaderForm
                titulo={'Pergunta de segurança'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />

            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-2'}>
                            <InputSelect
                                label={"Você possui algo a declarar sobre o envolvimento em alguma destas situações?"}
                                selected={casosEnvolvidosOptions.filter((opt: any) => opt.value === state.caso_envolvido)}
                                options={casosEnvolvidosOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'caso_envolvido')}
                            />
                            <div className={'text-sm text-gray-400'}>Selecione as situações que queira comentar com a Imigração.</div>

                        </div>
                    </div>
                    <div className={'grid  md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-2 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Caso tenha se envolvido em qualquer uma das questões acima, explique
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'informacao'}
                                onChange={e => handleChange(e.target.value,'informacao')}
                                value={state.informacao ? state.informacao : ""}
                            />
                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}