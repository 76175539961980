import React, { useState, useEffect } from 'react';
import useForm from "../../../hooks/useForm";
import {LoginData} from "../../../types";
import authApi from "../../../services/authApi";
import Storage from '../../../helpers/Storage';
import Input from "../../../components/input/Input";
import Form from "../../../components/form/Form";
import LogoImg from '../../../assets/img/logo_login.png';
import Bolhas from '../../../assets/img/bolhas.png';
export default function RedefinirSenha() {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { form, isFormInvalid, handleChange } = useForm<LoginData>({
        validator: (data) => !data.password || !data.email
    });

    async function handleSubmit() {
        setErrorMessage('');
        setIsLoading(true);

        const { data, isError } = await authApi.login(form);

        if (isError) {

            setIsLoading(false);
            setErrorMessage(data.message);
            return;
        }

        Storage.setApiToken(data.data.token);
        Storage.setUserData({ usuario: data.data.usuario });
        window.location.href = '/dashboard';
    }

//md:w-520 lg:ml-6 lg:w-5/12
    return(
        <section className="h-screen">
            <div >
                <img src={Bolhas} className={'absolute w-full z-[1]'}/>
            </div>
            <div className={'w-full h-[336px] bg-[#082a60] absolute'}>

            </div>
            <div className=" h-full px-6 py-24">
                <div className="g-6 flex h-full w-full flex-wrap items-center justify-center">
                    <div className="flex flex-col items-center w-[500px]  z-10 bg-white p-[40px] rounded-sm shadow-lg">
                        <div className={'w-[260px] h-[200px]'}>
                            <img src={LogoImg}/>
                        </div>
                        <div className={'font-bold text-[20px]'}>
                            Recuperação de senha
                        </div>
                        <Form className={'w-full'}  onSubmit={handleSubmit}>
                            <div className={'grid grid-cols-1 gap-4'}>
                                <Input
                                    className="col-span-1"
                                    large
                                    label={'Senha'}
                                    placeholder={'Senha'}
                                    inputType="password"
                                    value={form?.password}
                                    setValue={(v) => handleChange(v, 'password')}
                                />
                                <Input
                                    className="col-span-1"
                                    large
                                    placeholder={'Confirmar senha'}
                                    label={'Confirmar senha'}
                                    inputType="password"
                                    value={form?.password}
                                    setValue={(v) => handleChange(v, 'password')}
                                />
                            </div>
                            <div className="mt-6">
                                <button
                                    className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#082a60] hover:bg-[#09306b] focus:outline-none focus:bg-[#09306b]">
                                    Alterar senha
                                </button>
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
        </section>
    );
}