import React, {useEffect, useState} from "react";
import dadosDosPaisApi from "../../../../../services/dadosDosPaisApi";
import Alert from "../../../../../helpers/Alert";
import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import dadosParentesPaisApi from "../../../../../services/dadosParentesPaisApi";
import processosApi from "../../../../../services/processosApi";

export interface IDadosParentesNoPais{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosParentesNoPais({processo, setProcesso, setTelaNumero, telanumero}: IDadosParentesNoPais) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome: '',
        sobrenome: '',
        relacao: 'conjuge',
        outros: '',
        situacao: 'cidadao_do_pais',
        situacao_outros: '',
    });


    const relacaoOptions = [
        { value: 'conjuge', label: 'Cônjuge' },
        { value: 'noivo', label: 'Noivo(a)' },
        { value: 'filho', label: 'Filho(a)' },
        { value: 'irmao', label: 'Irmã(ão)' },
        { value: 'outros', label: 'Outros' },
    ];

    const situacaoOptions = [
        { value: 'cidadao_do_pais', label: 'Cidadão dos Estados Unidos' },
        { value: 'residente', label: 'Residente Permanente Legal nos Estados Unidos' },
        { value: 'nao_imigrante', label: 'Não-imigrante (permissão temporária)' },
        { value: 'imigrante', label: 'Imigrante em situação irregular com a imigração' },
        { value: 'nao_sei', label: 'Não sei' },
        { value: 'outros', label: 'Outros' },
    ];

    useEffect(() => {
        getDadosParentes();
    }, []);
    const getDadosParentes = async () => {
        const {data, isError} = await dadosParentesPaisApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    async function onSubmit() {
        const {data, isError} = state.id ? await dadosParentesPaisApi.update(state.id, state) : await dadosParentesPaisApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Parentes nos Estados Unidos'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Nome'}
                            name={'nome'}
                            setValue={e => handleChange(e, 'nome')}
                            value={state.nome}
                        />
                        <Input
                            required={true}
                            className={'col-span-1'}
                            label={'Sobrenome'}
                            name={'sobrenome'}
                            setValue={e => handleChange(e, 'sobrenome')}
                            value={state.sobrenome}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Relação com o parente"}
                                selected={relacaoOptions.filter((opt: any) => opt.value === state.relacao)}
                                options={relacaoOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'relacao')}
                            />
                        </div>
                        <Input
                            className={'col-span-2'}
                            label={'Caso tenha selecionado "outros", especifique aqui'}
                            name={'outros'}
                            setValue={e => handleChange(e, 'outros')}
                            value={state.outros}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Situação do parente"}
                                selected={situacaoOptions.filter((opt: any) => opt.value === state.situacao)}
                                options={situacaoOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'situacao')}
                            />
                        </div>
                        <Input
                            className={'col-span-2'}
                            label={'Caso tenha selecionado "outros", especifique aqui'}
                            name={'situacao_outros'}
                            setValue={e => handleChange(e, 'situacao_outros')}
                            value={state.situacao_outros}
                        />
                    </div>
                </div>

                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}