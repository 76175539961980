import Form from "../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Input from "../../../components/input/Input";
import Button from "../../../components/button/Button";
import {ReactComponent as AddIcon} from "../../../assets/icone/add.svg";
import Alert from "../../../helpers/Alert";
import {useNavigate} from "react-router-dom";
import InputSelect from "../../../components/input/InputSelect";
import usuariosApi from "../../../services/usuariosApi";
import ViaCepService from "../../../services/viaCepApi";
import Dates from "../../../helpers/Dates";
import envioSenhaApi from "../../../services/envioSenhaApi";
import useAppContext from "../../../hooks/useAppContext";
import clientesApi from "../../../services/clientesApi";
export interface IDadosPessoaisCliente{
    onSubmit: any;
    idCliente: any;
    stateClient: any;
    setStateClient: any;
}
export default function DadosPessoaisCliente({onSubmit, idCliente, stateClient, setStateClient}: IDadosPessoaisCliente){
    const navigate = useNavigate();
    const appContext = useAppContext();

    const [state, setState]: any = useState(Object.keys(stateClient).length ? stateClient : {
        id: '',
        usuario_id: '',
        usuario_responsavel_id: '',
        nome: '',
        sobrenome: '',
        nascimento: '',
        nacionalidade: '',
        telefone: '',
        telefone_secundario: '',
        email: '',
        password: '',
        password_confirme: '',
        cep: '',
        endereco: '',
        bairro: '',
        cidade: '',
        estado: '',
        membros: [],
        cliente_responsavel: [],
        data_aceite_termos: '',

    });

    const cepSrv = new ViaCepService();
    const [usuariosOptions, setUsuariosOptions]: any = useState([])
    const handleChange = (value: any, input: string) => {
        setStateClient({...stateClient, [input]: value});
        setState({...state, [input]: value});
    };
    const handleChangeCep = (value: any) => {
        const data = cepSrv.consultaCep(state.cep).then((cepResponse: any) =>{
            if (!cepResponse.data.erro){
                setState({
                    ...state,
                    endereco: removeDiacritics(cepResponse.data.logradouro),
                    bairro: removeDiacritics(cepResponse.data.bairro),
                    cidade: removeDiacritics(cepResponse.data.localidade),
                    estado: removeDiacritics(cepResponse.data.uf),
                });
            }
        });
    }

    function removeDiacritics(value: string): string {
        return value.normalize("NFD").replace(/[\u0300-\u036f]/g, ''); // Remove diacríticos como acentos e cedilha
    }

    const handleChangeMembros = (value: any, input: string, index?: any) => {
        const newMembros = [...state['membros']];
        newMembros[index][input] = value;
        setState({...state, ['membros']: newMembros});
    };
    const addMembros = () => {
        const newState: any = {...state};
        const novoMembro = {
            nome: '',
            nascimento: '',
            nacionalidade: '',
            telefone: '',
            telefone_secundario: '',
            email: '',
            password: '',
            password_confirme: '',
        }
        if (newState.membros.length == 10){
            Alert.error("O número máximo de mebros já adiconado!");
            return;
        }
        newState.membros.push(novoMembro);
        setState(newState)
    }

    useEffect(() => {
        getUsuarios();
    }, [])
    const getUsuarios = async () => {
        const data = await usuariosApi.listUsersAdmins();
        if (!data.isError){
            const options = data.data.data.map((user: any) => {
                return { value: user.id, label: user.name }
            });
            setUsuariosOptions(options);
        }
    }

    const submit = () => {
        if (state.password !== state.password_confirme ){
            Alert.error("Senhas são diferentes!");
            return;
        }
        onSubmit(state)

    }

    const validaSenhaMembro = (index: number) => {
        const newMembros = [...state['membros']];
        if (newMembros[index].password !== newMembros[index].password_confirme ){
            Alert.error(`AS SENHA DO CLIENTE: ${newMembros[index].nome} ESTÃO DIFERENTES!`);
            return;
        }
    }

    async function enviarSenha() {
        Alert.await(`Enviando e-mail...`);

        const dt = {
            id: state.usuario_id
        }
        const data = await envioSenhaApi.reenviarSenha(dt);
        if (!data.isError) {
            Alert.success(data.data.message);
        }
        //Alert.close();
    }
    //console.log(state);

    async function deleteData() {
        const confirm = await Alert.confirm('Tem certeza que deseja excluir?', 'Excluir Registro?');
        if (!confirm) return;
        Alert.await('Excluindo cliente...');
        await clientesApi.destroy(state.id);
        Alert.close();
        Alert.success('Cliente excluido');

        setTimeout(function(){
            navigate('/clientes');
        }, 4000);

    }
    return(
        <>
            <Form className={'p-5'} onSubmit={() => submit()}>
                <div className={'grid md:grid-cols-3'}>
                    <div className={'col-span-1'}>
                        <InputSelect
                            label={"Usuário responsável"}
                            selected={usuariosOptions.filter((opt: any) => opt.value === state.usuario_responsavel_id)}
                            options={usuariosOptions}
                            onChangeSelect={(e: any) => handleChange(e.value, 'usuario_responsavel_id')}
                        />
                    </div>
                    <div className={'col-span-1'}>
                    </div>

                    {
                        state.id ?
                        state.data_aceite_termos ?
                            <div className={'col-span-1 flex flex-col items-center justify-center'}>
                                <div>Data do aceite dos termos</div>
                                <div>{Dates.convertEnToBr(state.data_aceite_termos, null, true)}</div>
                            </div>
                            :
                            <div className={'col-span-1 flex flex-col items-center justify-center'}>
                                Termos não foram aceitos
                            </div>
                            :
                            ''
                    }


                </div>
                {
                    state.cliente_responsavel.length ?
                        <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                            <Input
                                className={'col-span-1'}
                                label={'Cliente responsável'}
                                name={'nome'}
                                value={state.cliente_responsavel[0].nome}
                                disabled
                            />
                            <Input
                                className={'col-span-1'}
                                label={'CPF Cliente responsável'}
                                inputType={'onlyNumbers'}
                                mask={'CPF'}
                                name={'nascimento'}
                                value={state.cliente_responsavel[0].cpf}
                                disabled
                            />
                        </div>
                        :
                        ''
                }

                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                    <Input
                        className={'col-span-1'}
                        label={'Nome'}
                        name={'nome'}
                        setValue={e => handleChange(e, 'nome')}
                        value={state.nome}
                        required
                    />
                    <Input
                        className={'col-span-1'}
                        label={'Data de nascimento'}
                        type={'date'}
                        name={'nascimento'}
                        setValue={e => handleChange(e,'nascimento')}
                        value={state.nascimento}
                        required
                    />
                    <Input
                        className={'col-span-1'}
                        label={'Nacionalidade'}
                        name={'nacionalidade'}
                        setValue={e => handleChange(e,'nacionalidade')}
                        value={state.nacionalidade}
                        required
                    />
                </div>
                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                    <Input
                        className={'col-span-1'}
                        label={'Telefone'}
                        name={'telefone'}
                        mask={'cellphone'}
                        setValue={e => handleChange(e,'telefone')}
                        value={state.telefone}
                        required
                    />
                    <Input
                        className={'col-span-1'}
                        label={'Telefone secundário'}
                        name={'telefone_secundario'}
                        mask={'cellphone'}
                        setValue={e => handleChange(e,'telefone_secundario')}
                        value={state.telefone_secundario}
                    />
                    <Input
                        className={'col-span-1'}
                        label={'E-mail'}
                        name={'email'}
                        setValue={e => handleChange(e,'email')}
                        value={state.email}
                        inputType="email"
                        required
                    />
                </div>
                {
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        {
                            idCliente ? '' :
                            <>
                                <Input
                                    className={'col-span-1'}
                                    label={'Senha'}
                                    name={'senha'}
                                    inputType="password"
                                    setValue={e => handleChange(e,'password')}
                                    value={state.password}
                                    required
                                />
                                <Input
                                    className={'col-span-1'}
                                    label={'Confirme a senha'}
                                    name={'senha_confirme'}
                                    inputType="password"
                                    setValue={e => handleChange(e,'password_confirme')}
                                    value={state.password_confirme}
                                    required
                                />
                            </>
                        }

                        {
                            idCliente ?
                                <div className={'col-span-1'}>
                                    <Button onClick={enviarSenha} className={'w-[164px] h-[48px] border border-[#082a60] text-[#082a60] font-semibold mt-[1rem]'}>Enviar senha</Button>
                                </div>
                                :
                                ''
                        }

                    </div>
                }

                <div className={'grid grid-cols-1 mt-[40px] '}>
                    <div className={'font-bold text-[25px]'}>Endereço</div>
                </div>
                <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                    <Input
                        className={'md:col-span-1'}
                        label={'CEP'}
                        name={'cep'}

                        setValue={e => handleChange(e, 'cep')}
                        onBlur={(e: any) => handleChangeCep(e)}
                        value={state.cep}
                    />
                    <Input
                        className={'md:col-span-3'}
                        label={'Endereço'}
                        name={'endereco'}
                        setValue={e => handleChange(e,'endereco')}
                        value={state.endereco}
                    />
                </div>
                <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                    <Input
                        className={'col-span-1'}
                        label={'Bairro'}
                        name={'bairro'}
                        setValue={e => handleChange(e,'bairro')}
                        value={state.bairro}
                    />
                    <Input
                        className={'md:col-span-2'}
                        label={'Cidade'}
                        name={'cidade'}
                        setValue={e => handleChange(e,'cidade')}
                        value={state.cidade}
                        required
                    />
                    <Input
                        className={'md:col-span-1'}
                        label={'Estado'}
                        name={'estado'}
                        setValue={e => handleChange(e,'estado')}
                        value={state.estado}
                        required
                    />
                </div>

                {
                    state.membros.length ?
                        state.membros.map((membro: any, index: number) => {
                            return(
                                <div className={'my-5 '}>
                                    <div className={'grid grid-cols-1 mt-[40px] '}>
                                        <div className={'font-bold text-[25px]'}>Membro {index+1}</div>
                                    </div>
                                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                        <Input
                                            className={'col-span-1'}
                                            label={'Nome'}
                                            name={'nome'}
                                            required={true}
                                            setValue={e => handleChangeMembros(e, 'nome', index)}
                                            value={state.membros[index]['nome']}
                                            disabled={membro.id}
                                        />
                                        <Input
                                            className={'col-span-1'}
                                            label={'Data de nascimento'}
                                            type={'date'}
                                            name={'nascimento'}
                                            setValue={e => handleChangeMembros(e, 'nascimento', index)}
                                            value={state.membros[index]['nascimento']}
                                            disabled={membro.id}
                                        />
                                        <Input
                                            className={'col-span-1'}
                                            label={'Nacionalidade'}
                                            name={'nacionalidade'}
                                            setValue={e => handleChangeMembros(e, 'nacionalidade', index)}
                                            value={state.membros[index]['nacionalidade']}
                                            disabled={membro.id}
                                            required
                                        />
                                        <Input
                                            className={'col-span-1'}
                                            label={'Telefone'}
                                            name={'telefone'}
                                            mask={'cellphone'}
                                            setValue={e => handleChangeMembros(e, 'telefone', index)}
                                            value={state.membros[index]['telefone']}
                                            disabled={membro.id}
                                            required
                                        />
                                        <Input
                                            className={'col-span-1'}
                                            label={'Telefone secundario'}
                                            name={'telefone_secundario'}
                                            mask={'cellphone'}
                                            setValue={e => handleChangeMembros(e, 'telefone_secundario', index)}
                                            value={state.membros[index]['telefone_secundario']}
                                            disabled={membro.id}
                                        />
                                        <Input
                                            className={'col-span-1'}
                                            label={'E-mail'}
                                            name={'email'}
                                            required={true}
                                            setValue={e => handleChangeMembros(e, 'email', index)}
                                            value={state.membros[index]['email']}
                                            disabled={membro.id}
                                        />


                                        {
                                            !membro.id ?
                                                <>
                                                    <Input
                                                        className={'col-span-1'}
                                                        label={'Senha'}
                                                        inputType={'password'}
                                                        required={true}
                                                        name={'password'}
                                                        setValue={e => handleChangeMembros(e, 'password', index)}
                                                        value={state.membros[index]['password']}
                                                    />
                                                    <Input
                                                        className={'col-span-1'}
                                                        label={'Confirme Senha'}
                                                        required={true}
                                                        name={'password_confirme'}
                                                        inputType={'password'}
                                                        setValue={e => handleChangeMembros(e, 'password_confirme', index)}
                                                        value={state.membros[index]['password_confirme']}
                                                        onBlur={() => validaSenhaMembro(index)}
                                                    />
                                                </>
                                                :
                                                ''
                                        }


                                    </div>
                                </div>
                            )
                        })

                        :
                        ''
                }
                <div className={'mt-5'}>
                    <button type={'button'} className={'flex text-[#082a60]'} onClick={addMembros}>
                        <AddIcon className={"stroke-white"}/> Adicionar Membros
                    </button>
                </div>
                <div className={'gap-2 flex justify-end max-[800px]:mb-[3rem]'}>
                    {
                        appContext.usuario.tipo_id === 1 ?
                            <Button
                                onClick={() => deleteData()}
                                className={'w-[164px] h-[48px] bg-white text-[#ff0000] border border-[#ff0000] font-semibold mt-[1rem]'}
                            >
                                Excluir
                            </Button>
                            :
                            ''
                    }


                    <Button
                        onClick={() => navigate('/clientes')}
                        className={'w-[164px] h-[48px] bg-white text-[#082a60] border border-[#082a60] font-semibold mt-[1rem]'}
                    >
                        Cancelar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold mt-[1rem]'}
                    >
                        Salvar
                    </Button>
                </div>

            </Form>
        </>
    )
}