import Dates from "../../../../helpers/Dates";

interface IFormularioCAEstudante {
    state: any
}
export default function FormularioCAEstudante({state}: IFormularioCAEstudante){
    const stadoCivilOptions = [
        { value: 'solteiro', label: 'Solteiro(a)' },
        { value: 'casado', label: 'Casado(a)' },
        { value: 'uniao estavel', label: 'União Estável' },
        { value: 'separado', label: 'Separado(a)' },
        { value: 'desquitado', label: 'Desquitado(a)' },
        { value: 'viuvo', label: 'Viúvo(a)' },
        { value: 'divorciado', label: 'Divorciado' },
    ];

    const localDevolucaoOptions = [
        { value: 'pessoalmente', label: 'Irei retirar pessoalmente no VAC - Visa Application Center (sem custos adicionais)' },
        { value: 'procuracao', label: 'Emitirei uma procuração autorizando a retirada do meu passaporte (custo do reconhecimento de firma) e depois decidirei a melhor forma para tê-lo em minhas mãos' },
        { value: 'endereco', label: 'Meu passaporte será enviado diretamente para o meu endereço (valores sob consulta - variam de acordo com a localidade)' },
    ];

    const motivoOptions = [
        { value: 'estudos', label: 'Estudos acima de seis meses' },
        { value: 'highscool', label: 'High School' },
        { value: 'intercambio', label: 'Intercâmbio (Estudo e Trabalho)' },
    ];

    const casosEnvolvidosOptions = [
        { value: 'crimes', label: 'Já foi preso(a) ou acusado(a) por qualquer crime em qualquer país?' },
        { value: 'exercito', label: 'Já fez parte do exército, milícia, polícia ou unidade de defesa?' },
        { value: 'governo', label: 'Já trabalhou no governo em alguma área relacionada à segurança?' },
        { value: 'posicao_politica', label: 'Já ocupou posição de autoridade no governo, judiciário ou partido político?' },
        { value: 'crimes_guerra', label: 'Em tempos de paz ou guerra, esteve envolvido em crimes que lesa a humanidade?' },
        { value: 'nao', label: 'Não se aplica.' },
    ];
    return(
        <div className={'p-4'}>
            <div className={`${
                state.conclusao_formulario ?
                    'p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400'
                    :
                    'p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
            }`}
                 role="alert">
                {
                    state.conclusao_formulario
                        ? 'Formulário finalizado pelo solicitante'
                        : 'Formulário não foi finalizado pelo solicitante'
                }
            </div>
            <div>
                <table className='ps-4 pe-4 table-fixed border-collapse border border-slate-400'>
                    <tbody>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sobrenome:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.sobrenome}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nome}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nomes anteriores:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nomes_anteriores ? state.cliente.nomes_anteriores : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>CPF:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cpf}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sexo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.sexo ? state.cliente.sexo === 'm' ? 'Masculino' : 'Feminino' : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de Nascimento (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{Dates.convertEnToBr(state.cliente.nascimento)}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade e estado de Nascimento:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cidade_natal}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País de Nascimento:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.pais_natal}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você é cidadão brasileiro?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.brasileiro ? 'Sim' : 'Não'}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Possui outras cidadanias?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.outras_cidadanias}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você esteve por mais de seis meses longe do seu país de residência nos últimos cinco anos?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.conjuge.obs_fora_pais ? state.cliente.conjuge.obs_fora_pais : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual o seu estado civil?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.estado_civil && stadoCivilOptions.filter(filt => filt.value === state.cliente.estado_civil)[0]['label']}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sobrenome do cônjuge anterior:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.conjuge.conjuge_anterior ? state.cliente.conjuge_anterior.sobrenome : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Primeiro Nome do cônjuge anterior:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.conjuge.conjuge_anterior ? state.cliente.conjuge_anterior.nome : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de Nascimento</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.conjuge.conjuge_anterior ? Dates.convertEnToBr(state.cliente.conjuge_anterior.nascimento) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Tipo de Relacionamento:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.conjuge.conjuge_anterior ? state.cliente.conjuge_anterior.tipo_uniao === 'uniao_estavel' ? 'União estavel' : 'Casamento' : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data do início do casamento ou união estável (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.conjuge.conjuge_anterior ? Dates.convertEnToBr(state.cliente.conjuge_anterior.data_inicio) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data do término do casamento ou união estável (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.conjuge.conjuge_anterior ? Dates.convertEnToBr(state.cliente.conjuge_anterior.data_fim) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual a data em que você se casou ou iniciou sua união estável?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.estado_civil !== 'solteiro'  ? Dates.convertEnToBr(state.cliente.conjuge.data_uniao) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sobrenome do cônjuge atual, divorciado ou falecido:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.estado_civil !== 'solteiro'  ? state.cliente.conjuge.sobrenome : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Primeiro Nome do cônjuge atual, divorciado ou falecido:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.estado_civil !== 'solteiro'  ? state.cliente.conjuge.nome : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de nascimento do cônjuge:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.estado_civil !== 'solteiro'  ? Dates.convertEnToBr(state.cliente.conjuge.data_uniao) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País de nascimento do cônjuge:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.estado_civil !== 'solteiro'  ? state.cliente.conjuge.nacionalidade : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço atual do cônjuge:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.estado_civil !== 'solteiro'  ? state.cliente.conjuge.endereco : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Ocupação atual do cônjuge:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.estado_civil !== 'solteiro'  ? state.cliente.conjuge.ocupacao : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Viaja com você para o Canadá?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.estado_civil !== 'solteiro'  ? (state.cliente.conjuge.viaja_junto ? 'Sim' : 'Não') : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já foi casado(a) ou possuiu uma outra união estável anteriormente?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.conjuge && state.cliente.estado_civil !== 'solteiro' ? (state.cliente.conjuge.conjuge_anterior ? 'Sim' : 'Não') : '' }</td>
                    </tr>


                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Clique no item, caso ele se aplique ao seu caso</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.pergunta_de_seguranca ? casosEnvolvidosOptions.filter(ce => ce.value === state.pergunta_de_seguranca.caso_envolvido)[0]['label'] : ''   }</td>
                    </tr>


                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Número do Passaporte:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? state.passaporte.numero : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País referente ao passaporte:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? state.passaporte.pais_emissor : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de emissão do passaporte:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? Dates.convertEnToBr(state.passaporte.data_emissao) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de expiração do passaporte:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? Dates.convertEnToBr(state.passaporte.data_validade) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Como você quer que seja feita a devolução do seu passaporte?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ?
                            state.passaporte.local_devolucao && localDevolucaoOptions.filter(tp => tp.value === state.passaporte.local_devolucao)[0]['label']
                            : ''
                        }</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.endereco}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Bairro:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.bairro}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>CEP:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cep}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade e estado:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cidade}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.pais_natal}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone Primário:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.telefone}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Tipo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.tipo_telefone}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone Secundário:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.telefone_secundario}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Tipo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.tipo_telefone_secundario}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>E-mail:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.usuario.email}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Motivo da Viagem:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.motivo_viagem && motivoOptions.filter(mt => mt.value === state.motivo_viagem)[0]['label']}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome da Instituição de Ensino no Canadá</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? state.permissao_estudos.nome_instituicao : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Província:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? state.permissao_estudos.provincia : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? state.permissao_estudos.cidade : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço da Instituição de Ensino:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? state.permissao_estudos.endereco : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Campo de estudo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? state.permissao_estudos.campo_estudo : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data da entrada no Canadá (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? Dates.convertEnToBr(state.permissao_estudos.data_entrada) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data da saída do Canadá (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? Dates.convertEnToBr(state.permissao_estudos.data_saida) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Código DLI (Designated Learning Institution DLI)</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? state.permissao_estudos.codigo_dli : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Student ID:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? state.permissao_estudos.student_id : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Valor total do curso:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? state.permissao_estudos.valor_curso : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Valor da acomodação:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? state.permissao_estudos.valor_acomodacao : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Fundos disponíveis para a sua visita (em dólares canadenses):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? state.permissao_estudos.valor_fundo_disponivel : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Quem custeará sua viagem?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.permissao_estudos ? state.permissao_estudos.quem_custeara : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você realizou algum estudo após o Ensino Médio (incluindo universidades e estudos técnicos):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais ? 'Sim' : 'Não'}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de início do curso (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? Dates.convertEnToBr(state.informacoes_educacionais_r.data_inicio) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data do término do curso (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? Dates.convertEnToBr(state.informacoes_educacionais_r.data_fim) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Área de Estudos:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.nome_curso : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome da Instituição de Ensino:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.nome_instituicao : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade da Instituição de Ensino:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.cidade : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País da Instituição de Ensino:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_educacionais_r ? state.informacoes_educacionais_r.pais : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual a sua ocupação ou profissão atual?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacao_profissional ? state.informacao_profissional.ocupacao : '' }</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo do empregador ou escola</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacao_profissional ? state.informacao_profissional.endereco : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de admissão no trabalho ou início dos estudos?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacao_profissional ? Dates.convertEnToBr(state.informacao_profissional.data_admissao) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome do empregador ou escola?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacao_profissional ? state.informacao_profissional.nome : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você exerceu alguma atividade profissional ou estudo nos últimos 10 anos?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacao_profissional ? state.informacao_profissional.atividades_anteriores : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1 - Ocupação ou profissão:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.profissao1 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome do empregador ou Instituição de Ensino:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.nome_contratante1 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.cidade1 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.pais1 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de início da ocupação / Estudo (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? Dates.convertEnToBr(state.detalhe_empregaticios_estudo_aposentadoria.data_inicio1) : ''}</td>

                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data do término da ocupação / Estudo (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? Dates.convertEnToBr(state.detalhe_empregaticios_estudo_aposentadoria.data_fim1) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Ocupação anterior:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.profissao2 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome do empregador:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.nome_contratante2 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.cidade2 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.pais2 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de início da ocupação (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? Dates.convertEnToBr(state.detalhe_empregaticios_estudo_aposentadoria.data_inicio2) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data do término da ocupação (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? Dates.convertEnToBr(state.detalhe_empregaticios_estudo_aposentadoria.data_fim2) : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3 - Ocupação anterior:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.profissao3 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome do empregador:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.nome_contratante3 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.cidade3 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? state.detalhe_empregaticios_estudo_aposentadoria.pais3 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de início da ocupação (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? Dates.convertEnToBr(state.detalhe_empregaticios_estudo_aposentadoria.data_inicio3) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data do término da ocupação (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhe_empregaticios_estudo_aposentadoria ? Dates.convertEnToBr(state.detalhe_empregaticios_estudo_aposentadoria.data_fim3) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nos últimos dois anos, você ou algum membro da família tiveram tuberculose ou contato próximo com alguém com tuberculose?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.tuberculose ? 'Sim' : 'Não') : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você tem algum distúrbio físico ou mental que demandaria serviços sociais ou de saúde (que não sejam simples medicação) durante sua estadia no Canadá?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.disturbios ? 'Sim' : 'Não') : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Já teve um visto negado para o Canadá?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.visto_negado ? 'Sim' : 'Não') : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Já teve a entrada recusada, ou foi obrigado(a) a deixar o Canadá ou qualquer outro país?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.entrada_recusada ? 'Sim' : 'Não') : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Já solicitou algum visto para o Canadá?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.visto_ja_solicitado ? 'Sim' : 'Não') : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido "Sim" a qualquer uma das perguntas acima, especifique:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? state.saude_historico_imigracional.visto_ja_solicitado_obs  : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1- Salário mensal dos seus empregos nos últimos 10 anos:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhamento_empregos_anteriores ? state.detalhamento_empregos_anteriores.salarios : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2- a) Caso tenha servido ao exército, milícia, polícia ou unidade de defesa, detalhe sua função:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhamento_empregos_anteriores ? state.detalhamento_empregos_anteriores.detalhe_exercito : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2- b) Caso tenha recebido algum treinamento especial, detalhe:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhamento_empregos_anteriores ? state.detalhamento_empregos_anteriores.detalhe_treinamento_especial : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2- c) Caso tenha participado em alguma forma de combate, detalhe:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhamento_empregos_anteriores ? state.detalhamento_empregos_anteriores.detalhe_combate : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2- d) Por quê deixou de servir?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhamento_empregos_anteriores ? state.detalhamento_empregos_anteriores.porque_deixou_servico : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3- Caso já tenha participado ou testemunhado maus tratos a prisioneiros ou civis, pilhagem ou violação de edifícios religiosos, detalhe:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhamento_empregos_anteriores ? state.detalhamento_empregos_anteriores.detalhe_maus_tratos : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>4- Caso já tenha sido membro de partido político ou outro grupo ou organização, detalhe:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhamento_empregos_anteriores ? state.detalhamento_empregos_anteriores.partido_politico : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>5- Caso já tenha ocupado um cargo no governo, judiciário ou empresa pública, detalhe:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhamento_empregos_anteriores ? state.detalhamento_empregos_anteriores.cargo_governo : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>6- Caso você ou algum membro de sua família que esteja acompanhando tenha cometido, sido preso ou acusado por qualquer crime, detalhe:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.detalhamento_empregos_anteriores ? state.detalhamento_empregos_anteriores.cometido_crimes : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome completo de sua mãe:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? state.dados_dos_pais.nome_mae : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de nascimento de sua mãe (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? Dates.convertEnToBr(state.dados_dos_pais.data_nascimento_mae) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País de nascimento de sua mãe:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? state.dados_dos_pais.pais_nascimento_mae : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Estado Civil de sua mãe:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? state.dados_dos_pais.estado_civil_mae : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço atual de sua mãe:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? state.dados_dos_pais.endereco_mae : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Ocupação atual da sua mãe:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? state.dados_dos_pais.ocupacao_mae : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sua mãe vai acompanhar você ao Canadá?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? (state.dados_dos_pais.viaja_junto_mae ? 'Sim' : 'Não') : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome completo de seu pai:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? state.dados_dos_pais.nome_pai : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de nascimento de seu pai (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? Dates.convertEnToBr(state.dados_dos_pais.data_nascimento_pai) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País de nascimento de seu pai:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? state.dados_dos_pais.pais_nascimento_pai : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Estado Civil de seu pai:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? state.dados_dos_pais.estado_civil_pai : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço atual de seu pai:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? state.dados_dos_pais.endereco_pai : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Ocupação atual de seu pai:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? state.dados_dos_pais.ocupacao_pai : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Seu pai vai acompanhar você ao Canadá?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? (state.dados_dos_pais.viaja_junto_pai ? 'Sim' : 'Não') : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você possui filhos?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_dos_pais ? (state.dados_dos_pais.viajante_possui_filhos ? 'Sim' : 'Não') : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Quantos filhos você possui?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.quantidade_filhos : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1- Nome completo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.nome_filho_1 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1- Data de nascimento (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? Dates.convertEnToBr(state.informacoes_filhos.nascimento_filho_1) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1- País de nascimento:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.pais_filho_1 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1- Estado Civil:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.estado_civil_filho_1 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1- Endereço atual:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.endereco_filho_1 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1- Ocupação atual:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.ocupacao_filho_1 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>1- Vai acompanhar você ao Canadá?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? (state.informacoes_filhos.viaja_junto_filho_1 ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2- Nome completo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.nome_filho_2 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2- Data de nascimento (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? Dates.convertEnToBr(state.informacoes_filhos.nascimento_filho_2) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2- País de nascimento:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.pais_filho_2 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2- Estado Civil:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.estado_civil_filho_2 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2- Endereço atual:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.endereco_filho_2 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2- Ocupação atual:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.ocupacao_filho_2 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2- Vai acompanhar você ao Canadá?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? (state.informacoes_filhos.viaja_junto_filho_2 ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3- Nome completo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.nome_filho_3 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3- Data de nascimento (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? Dates.convertEnToBr(state.informacoes_filhos.nascimento_filho_3) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3- País de nascimento:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.pais_filho_3 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3- Estado Civil:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.estado_civil_filho_3 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3- Endereço atual:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.endereco_filho_3 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3- Ocupação atual:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.ocupacao_filho_3 : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>3- Vai acompanhar você ao Canadá?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? (state.informacoes_filhos.viaja_junto_filho_3 ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Dados dos outros filhos:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos.dados_outros_filhos : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Breve descrição das atividades enquanto estiver no Canadá:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_adicionais_viagem ? state.informacoes_adicionais_viagem.descricao_atividades_pais : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Tem algum conhecido no Canadá (amigos, família, colegas de trabalho, etc)?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_adicionais_viagem ? state.informacoes_adicionais_viagem.conhecido_no_pais : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Está viajando com alguém para o Canadá (amigos, família, colegas de trabalho, etc)?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_adicionais_viagem ? state.informacoes_adicionais_viagem.alguem_vai_junto : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Alguma informação relevante que queira mencionar?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_adicionais_viagem ? state.informacoes_adicionais_viagem.informacoes_relevantes : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual a sua língua nativa?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_adicionais_viagem ? state.informacoes_adicionais_viagem.lingua_nativa : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Se não for inglês ou francês, qual língua você usa com mais frequência?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_adicionais_viagem ? state.informacoes_adicionais_viagem.outras_linguas : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você está apto a conversar em:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_adicionais_viagem ? state.informacoes_adicionais_viagem.apto_conversar : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já realizou algum teste de proficiência em Inglês ou Francês?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_adicionais_viagem ? (state.informacoes_adicionais_viagem.teste_proeviciencia ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    </tbody>
                </table>
            </div>
        </div>
    )
}