import {LoginData, QueryParams} from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll, RequestError, RequestResponse} from './api';

const endpoint = 'cronograma-processo';


export default {
    all: async (params?: QueryParams) => getAll<any>({ endpoint, params }),
    listAll: async () => listAll<any>({ endpoint }),
    one: async (id: string) => getOne<any>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<any>) => create<any>({ endpoint, formData }),
    update: async (id: string, formData: Partial<any>) => update<any>({ endpoint, formData, id }),
    async getCronogramaData(date?: string, cliente?: string, situacaoId?: string | number, vendedor?: string){
        try {
            const url = `${endpoint}?data=${date}${cliente ? '&cliente='+cliente : ''}${situacaoId ? '&situacao_id='+situacaoId : ''}${vendedor ? '&vendedor_id='+vendedor : ''}`;
            const { data } = await api.get(url)
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
    async getCronogramaCalendario(date?: string, consult?: string){
        try {
            const url = `${endpoint}/calendario?${date ? '&data='+date : ''}${consult ? '&'+consult : ''}`;
            const { data } = await api.get(url)
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
    async cronogramasReplicar(params: any){
        try {
            const url = `${endpoint}/replicar`;
            const { data } = await api.post(url, params)
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
    async cronogramasProcesso(processId: any){
        try {
            const url = `${endpoint}/processo/${processId}`;
            const { data } = await api.get(url)
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
};
