import { QueryParams } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = 'processo';

export default {
    all: async (params?: QueryParams) => getAll<any>({ endpoint, params }),
    listAll: async () => listAll<any>({ endpoint }),
    one: async (id: string) => getOne<any>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<any>) => create<any>({ endpoint, formData }),
    update: async (id: string, formData: Partial<any>) => update<any>({ endpoint, formData, id }),
    membros: async (id: number) => {
        try {
            const { data } = await api.get(endpoint+'/membros?id_processo='+id);
            return { data, isError: false };
        }catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
    cancelar: async (id: number) => {
        try {
            const { data } = await api.post(endpoint+'/cancelar/'+id);
            return { data, isError: false };
        }catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
    getFormularioData: async (id: number|string, url: string) => {
        try {
            const { data } = await api.get(endpoint+url+id);
            return { data, isError: false };
        }catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
    getClientProcessos: async (params: any) => {
        const page    = params?.page    ? 'page='    + params?.page    : 'page=1';
        const perPage = params?.perPage ? '&perPage=' + params?.perPage : '';
        const search  = params?.search  ? '&'  + params?.search  : '';
        const relations  = params?.relations  ? '&relations='  + params?.relations  : '';
        const withes    = params?.with  ? '&with='  + params?.with  : '';
        const where  = params?.where  ? `&where=${params?.where}`  : '';
        const orderBy  = params?.orderBy  ? `&orderBy=${params?.orderBy}`  : '&orderBy=id,desc';

        let finalEndPoint = `${endpoint}/cliente?${page}${perPage}${search}${relations}${where}${withes}${orderBy}`;

        Object.entries(params || {}).forEach(([key, value]) => {
            if (key !== 'page' && key !== 'perPage' && key !== 'search' && key !== 'relations' && key !== 'where' && key !== 'with' && key !== 'orderBy'){
                finalEndPoint += `&${key}=${value}`;
            }
        })

        try {
            const { data } = await api.get(finalEndPoint);
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    }
};
