import {FaCheck} from "react-icons/fa6";
import React from "react";
import Dates from "../../helpers/Dates";

export interface IStepper{
    idStepperSelected: number;
    nome: string;
    arrayLengt: any;
    index: number;
    situacao?: string;
    onClick?: any;
    data?: any;
}




export default function Stepper({idStepperSelected, nome, arrayLengt, index, situacao, onClick, data}: IStepper) {

    function content() {
        return(
            <>
                <div className="flex items-center text-white relative cursor-pointer" onClick={() => onClick(data)} key={index}>
                    <div className="flex justify-center rounded-full bg-[#c22929] transition duration-500 ease-in-out h-12 w-12 items-center border-2 border-[#c22929]">

                    </div>
                    <div
                        className={`md:absolute md:top-0 md:-ml-10 md:text-center md:mt-16 md:w-32 xs:ml-1 text-xs text-gray-800 ${idStepperSelected === data.id ? 'font-bold' : 'font-medium'}`}
                    >
                        {nome}
                    </div>
                </div>
                {
                    arrayLengt == index + 1 ?
                        '' :
                        <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-[#fff]"></div>

                }
            </>
        )
    }

    if (data.data_finalizacao){
        if (
            parseInt(Dates.difDay(new Date().toISOString().slice(0, 10), data.data_finalizacao).toString()) < 0
            && data.status  !== 'concluido')
        {

            return(
                <>
                    <div className="flex items-center text-white relative cursor-pointer" onClick={() => onClick(data)} key={index}>
                        <div className="flex justify-center rounded-full bg-[#c22929] transition duration-500 ease-in-out h-12 w-12 items-center border-2 border-[#c22929]">

                        </div>
                        <div
                            className={`md:absolute md:top-0 md:-ml-10 md:text-center md:mt-16 md:w-32 xs:ml-1 text-xs text-gray-800 ${idStepperSelected === data.id ? 'font-bold' : 'font-medium'}`}
                        >
                            {nome}
                        </div>
                    </div>
                    {
                        arrayLengt == index + 1 ?
                            '' :
                            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-[#fff]"></div>

                    }
                </>

            )
        }
        if (parseInt(Dates.difDay(new Date().toISOString().slice(0, 10), data.data_finalizacao).toString()) && data.status  !== 'concluido'){
            return(
                <>
                    <div className="flex items-center text-white relative cursor-pointer" onClick={() => onClick(data)} key={index}>
                        <div className="flex justify-center rounded-full bg-[#e7e463] transition duration-500 ease-in-out h-12 w-12 items-center border-2 border-[#e7e463]">

                        </div>
                        <div
                            className={`md:absolute md:top-0 md:-ml-10 md:text-center md:mt-16 md:w-32 xs:ml-1 text-xs text-gray-800 ${idStepperSelected === data.id ? 'font-bold' : 'font-medium'}`}
                        >
                            {nome}
                        </div>
                    </div>
                    {
                        arrayLengt == index + 1 ?
                            '' :
                            <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-[#fff]"></div>

                    }
                </>

            )
        }
    }



    if (data.status === 'cancelado'){
        return(
            <>
                <div className="flex items-center text-white relative " onClick={() => onClick(data)} key={index}>
                    <div className="flex justify-center rounded-full bg-[#c22929] transition duration-500 ease-in-out h-12 w-12 items-center border-2 border-[#c22929]">
                        <FaCheck size={20} />
                    </div>
                    <div
                        className={`md:absolute md:top-0 md:-ml-10 md:text-center md:mt-16 md:w-32 text-xs text-gray-800 ${idStepperSelected === data.id ? 'font-bold' : 'font-medium'}`}
                    >
                        {nome}
                    </div>
                </div>
                {
                    arrayLengt == index + 1 ?
                        '' :
                        <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-[#fff]"></div>

                }
            </>

        )
    }

    if (data.status === 'concluido'){
        return(
            <>
                <div className="flex items-center text-[#082a60] relative cursor-pointer" onClick={() => onClick(data)} key={index}>
                    <div className="flex justify-center rounded-full bg-[#30c55a] transition duration-500 ease-in-out h-12 w-12 items-center border-2 border-[#30c55a]">
                        <FaCheck size={20} />
                    </div>
                    <div
                        className={`md:absolute md:top-0 md:-ml-10 md:text-center xs:ml-1 md:mt-16 md:w-32 text-xs text-gray-800 ${idStepperSelected === data.id ? 'font-bold' : 'font-medium'}`}
                    >
                        {nome}
                    </div>
                </div>
                {
                    arrayLengt == index + 1 ?
                        '' :
                        <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-[#fff]"></div>

                }
            </>
        )
    }

    if (data.status === 'andamento'){
        return(
            <>
                <div className="flex items-center text-white relative cursor-pointer" onClick={() => onClick(data)} key={index}>
                    <div className="flex justify-center rounded-full transition duration-500 ease-in-out text-[20px] h-12 w-12 text-gray-25 items-center border-2 bg-[#061f46] border-[#061f46]">
                        {/*{index + 1}*/}
                    </div>
                    <div
                        className={`md:absolute md:top-0 md:-ml-10 md:text-center md:mt-16 md:w-32 xs:ml-1 text-xs text-gray-800 ${idStepperSelected === data.id ? 'font-bold' : 'font-medium'}`}
                    >
                        {nome}
                    </div>
                </div>
                {
                    arrayLengt == index + 1 ?
                        '' :
                        <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-[#fff]"></div>

                }
            </>

        )
    }



    return(
        <>
            <div className="flex items-center text-white relative cursor-pointer" onClick={() => onClick(data)} key={index}>
                <div className="flex justify-center rounded-full transition duration-500 ease-in-out text-[20px] h-12 w-12 text-gray-25 items-center border-2 bg-gray-400 border-gray-400">
                    {/*{index + 1}*/}
                </div>
                <div
                    className={`md:absolute md:top-0 md:-ml-10 md:text-center md:mt-16 md:w-32 xs:ml-1 text-xs text-gray-800 ${idStepperSelected === data.id ? 'font-bold' : 'font-medium'}`}
                >
                    {nome}
                </div>
            </div>
            {
                arrayLengt == index + 1 ?
                    '' :
                    <div className="flex-auto border-t-2 transition duration-500 ease-in-out border-[#fff]"></div>

            }
        </>
    )



}