import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import detalhesEmpregaticiosEstudoAposentadoriaApi
    from "../../../../../services/detalhesEmpregaticiosEstudoAposentadoriaApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import dadosFilhosApi from "../../../../../services/dadosFilhosApi";
import InputSelect from "../../../../../components/input/InputSelect";
import Input from "../../../../../components/input/Input";

export interface IDadosFilhosCa{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosFilhosCa({processo, setProcesso, setTelaNumero, telanumero}: IDadosFilhosCa) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        quantidade_filhos: '1',
        nome_filho_1: '',
        nascimento_filho_1: '',
        pais_filho_1: '',
        estado_civil_filho_1: '',
        endereco_filho_1: '',
        ocupacao_filho_1: '',
        viaja_junto_filho_1: 0,
        nome_filho_2: '',
        nascimento_filho_2: '',
        pais_filho_2: '',
        estado_civil_filho_2: '',
        endereco_filho_2: '',
        ocupacao_filho_2: '',
        viaja_junto_filho_2: 0,
        nome_filho_3: '',
        nascimento_filho_3: '',
        pais_filho_3: '',
        estado_civil_filho_3: '',
        endereco_filho_3: '',
        ocupacao_filho_3: '',
        viaja_junto_filho_3: 0,
        dados_outros_filhos: '',
    });
    const filhosOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: 'Acima de 3', label: 'Acima de 3' },
    ];
    const getData = async () => {
        const {data, isError} = await dadosFilhosApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }

    useEffect(() => {
        getData()
    }, []);
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await dadosFilhosApi.update(state.id, state) : await dadosFilhosApi.create(state);
        if (isError){

            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={'Informações dos filhos'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Quantos filhos você possui"}
                                selected={filhosOptions.filter((opt: any) => opt.value === state.quantidade_filhos)}
                                options={filhosOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'quantidade_filhos')}
                            />
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'1 - Nome completo'}
                            required={true}
                            name={'nome_filho_1'}
                            setValue={e => handleChange(e,'nome_filho_1')}
                            value={state.nome_filho_1}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Data de nascimento'}
                            type={'date'}
                            required={true}
                            name={'nascimento_filho_1'}
                            setValue={e => handleChange(e,'nascimento_filho_1')}
                            value={state.nascimento_filho_1}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'País de nascimento'}
                            required={true}
                            name={'pais_filho_1'}
                            setValue={e => handleChange(e,'pais_filho_1')}
                            value={state.pais_filho_1}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Estado civil'}
                            required={true}
                            name={'estado_civil_filho_1'}
                            setValue={e => handleChange(e,'estado_civil_filho_1')}
                            value={state.estado_civil_filho_1}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Endereço atual'}
                            required={true}
                            name={'endereco_filho_1'}
                            setValue={e => handleChange(e,'endereco_filho_1')}
                            value={state.endereco_filho_1}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Ocupação atual'}
                            required={true}
                            name={'ocupacao_filho_1'}
                            setValue={e => handleChange(e,'ocupacao_filho_1')}
                            value={state.ocupacao_filho_1}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1 flex flex-col'}>
                            <label
                                className="text-[16px] font-medium text-gray-900 dark:text-gray-300"
                            >
                                Viaja com você?
                            </label>
                            <div className={'flex mt-[1.5rem] gap-4'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'viaja_junto_filho_1')}
                                        value={1}
                                        id="checked-viaja_junto_filho_1-1"
                                        type="radio"
                                        checked={state.viaja_junto_filho_1 === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viaja_junto_filho_1-1"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.viaja_junto_filho_1 === 0 }
                                        onChange={e => handleChange(0,'viaja_junto_filho_1')}
                                        id="checked-viaja_junto_filho_1"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viaja_junto_filho_1"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-[2.5rem]'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'2 - Nome completo'}
                            name={'nome_filho_2'}
                            setValue={e => handleChange(e,'nome_filho_2')}
                            value={state.nome_filho_2}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Data de nascimento'}
                            type={'date'}
                            name={'nascimento_filho_2'}
                            setValue={e => handleChange(e,'nascimento_filho_2')}
                            value={state.nascimento_filho_2}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'País de nascimento'}
                            name={'pais_filho_2'}
                            setValue={e => handleChange(e,'pais_filho_2')}
                            value={state.pais_filho_2}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Estado civil'}
                            name={'estado_civil_filho_2'}
                            setValue={e => handleChange(e,'estado_civil_filho_2')}
                            value={state.estado_civil_filho_2}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Endereço atual'}
                            name={'endereco_filho_2'}
                            setValue={e => handleChange(e,'endereco_filho_2')}
                            value={state.endereco_filho_2}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Ocupação atual'}
                            name={'ocupacao_filho_2'}
                            setValue={e => handleChange(e,'ocupacao_filho_2')}
                            value={state.ocupacao_filho_2}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1 flex flex-col'}>
                            <label
                                className="text-[16px] font-medium text-gray-900 dark:text-gray-300"
                            >
                                Viaja com você?
                            </label>
                            <div className={'flex mt-[1.5rem] gap-4'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'viaja_junto_filho_2')}
                                        value={1}
                                        id="checked-viaja_junto_filho_2-1"
                                        type="radio"
                                        checked={state.viaja_junto_filho_2 === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viaja_junto_filho_2-1"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.viaja_junto_filho_2 === 0 }
                                        onChange={e => handleChange(0,'viaja_junto_filho_2')}
                                        id="checked-viaja_junto_filho_2"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viaja_junto_filho_2"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-[2.5rem]'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'3 - Nome completo'}
                            name={'nome_filho_3'}
                            setValue={e => handleChange(e,'nome_filho_3')}
                            value={state.nome_filho_3}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Data de nascimento'}
                            type={'date'}
                            name={'nascimento_filho_3'}
                            setValue={e => handleChange(e,'nascimento_filho_3')}
                            value={state.nascimento_filho_3}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'País de nascimento'}
                            name={'pais_filho_3'}
                            setValue={e => handleChange(e,'pais_filho_3')}
                            value={state.pais_filho_3}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Estado civil'}
                            name={'estado_civil_filho_3'}
                            setValue={e => handleChange(e,'estado_civil_filho_3')}
                            value={state.estado_civil_filho_3}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Endereço atual'}
                            name={'endereco_filho_3'}
                            setValue={e => handleChange(e,'endereco_filho_3')}
                            value={state.endereco_filho_3}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Ocupação atual'}
                            name={'ocupacao_filho_3'}
                            setValue={e => handleChange(e,'ocupacao_filho_3')}
                            value={state.ocupacao_filho_3}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1 flex flex-col'}>
                            <label
                                className="text-[16px] font-medium text-gray-900 dark:text-gray-300"
                            >
                                Viaja com você?
                            </label>
                            <div className={'flex mt-[1.5rem] gap-4'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'viaja_junto_filho_3')}
                                        value={1}
                                        id="checked-viaja_junto_filho_3-1"
                                        type="radio"
                                        checked={state.viaja_junto_filho_3 === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viaja_junto_filho_3-1"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.viaja_junto_filho_3 === 0 }
                                        onChange={e => handleChange(0,'viaja_junto_filho_3')}
                                        id="checked-viaja_junto_filho_3"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viaja_junto_filho_3"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-2 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Dados dos outros filhos
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'dados_outros_filhos'}
                                onChange={e => handleChange(e.target.value,'dados_outros_filhos')}
                                value={state.dados_outros_filhos ? state.dados_outros_filhos : ""}
                            />
                            <div className={'text-sm text-gray-400'}>
                                Nome Completo, data de nascimento, país de nascimento, estado civil, endereço atual, ocupação atual e informar caso viaje com você para o Canadá.
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero - 1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}