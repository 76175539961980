import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import dadosResponsavelApi from "../../../../../services/dadosResponsavelApi";
import saudeHistoricoImigracionalApi from "../../../../../services/saudeHistoricoImigracionalApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";

export interface IDeclaracaoSaudeAus{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DeclaracaoSaudeAus({processo, setProcesso, setTelaNumero, telanumero}: IDeclaracaoSaudeAus ) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        eua_visitado: 0,
        paises_visitados: '',
        visto_ja_solicitado: 0,
        visto_ja_solicitado_obs: '',
        visto_negado_outro_pais: 0,
        dados_visto_negado: '',
        usar_hospital: 0,
        trabalhar_estudar: 0,
        trabalhar_estudar_saude: 0,
        tuberculose: 0,
        parente_tuberculose: 0,
        raio_x_anormalidade: 0,
        custos_medicos: 0,
        cancer: 0,
        doenca_cardiaca: 0,
        hepatite: 0,
        doenca_hepatica: 0,
        hiv: 0,
        doenca_renal: 0,
        disturbios: 0,
        gravidez: 0,
        problemas_respiratorios: 0,
        outros: '',
        pcd: 0,
    });
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const getDados = async () => {
        const {data, isError} = await saudeHistoricoImigracionalApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    useEffect(()=>{getDados()}, [])
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await saudeHistoricoImigracionalApi.update(state.id, state) : await saudeHistoricoImigracionalApi.create(state);
        if (isError){

            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }

        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Pergunta de saúde e histórico imigracional'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form   onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Eu afirmo ter fundos suficientes para cobrir todos os custos associados com a visita para a Austrália
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'eua_visitado')}
                                        value={1}
                                        id="checked-eua_visitado-s"
                                        type="radio"
                                        checked={state.eua_visitado === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-eua_visitado-s"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.eua_visitado === 0 }
                                        onChange={e => handleChange(0,'eua_visitado')}
                                        id="checked-eua_visitado-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-eua_visitado-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Em caso afirmativo, por favor informar
                                detalhes da viagem e/ou visto, tais como: país
                                visitado, data da viagem, motivo da viagem,
                                duração da viagem, tipo de visto {!!state.eua_visitado ? <span className={'text-[#c22929]'}>*</span> : ''}
                            </label>
                            <textarea
                                required={!!state.eua_visitado}
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'paises_visitados'}
                                onChange={e => handleChange(e.target.value,'paises_visitados')}
                                value={state.paises_visitados ? state.paises_visitados : ""}
                            />
                        </div>
                    </div>


                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium "
                            >
                                Você possui algum visto aprovado e válido?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_ja_solicitado')}
                                        value={1}
                                        id="checked-visto_ja_solicitado-s"
                                        type="radio"
                                        checked={state.visto_ja_solicitado === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_ja_solicitado-s"
                                        className="ms-2 text-[16px] font-medium "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_ja_solicitado === 0 }
                                        onChange={e => handleChange(0,'visto_ja_solicitado')}
                                        id="checked-visto_ja_solicitado-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_ja_solicitado-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Em caso afirmativo, por favor informar detalhes do visto, tais como: país emissor, tipo de visto, data de emissão e validade {!!state.visto_ja_solicitado ? <span className={'text-[#c22929]'}>*</span> : ''}
                            </label>
                            <textarea
                                required={!!state.visto_ja_solicitado}
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'visto_ja_solicitado_obs'}
                                onChange={e => handleChange(e.target.value,'visto_ja_solicitado_obs')}
                                value={state.visto_ja_solicitado_obs ? state.visto_ja_solicitado_obs : ""}
                            />
                        </div>
                    </div>


                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium "
                            >
                                Você já teve algum visto negado para qualquer país?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_negado_outro_pais')}
                                        value={1}
                                        id="checked-visto_negado_outro_pais-s"
                                        type="radio"
                                        checked={state.visto_negado_outro_pais === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_negado_outro_pais-s"
                                        className="ms-2 text-[16px] font-medium "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_negado_outro_pais === 0 }
                                        onChange={e => handleChange(0,'visto_negado_outro_pais')}
                                        id="checked-visto_negado_outro_pais-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_negado_outro_pais-n"
                                        className="ms-2 text-[16px] font-medium "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Em caso afirmativo, por favor informar detalhes da viagem e/ou visto, tais como: país visitado, data da viagem, motivo da viagem, duração da viagem, tipo de visto {!!state.visto_negado_outro_pais ? <span className={'text-[#c22929]'}>*</span> : ''}
                            </label>
                            <textarea
                                required={!!state.visto_negado_outro_pais}
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'dados_visto_negado'}
                                onChange={e => handleChange(e.target.value,'dados_visto_negado')}
                                value={state.dados_visto_negado ? state.dados_visto_negado : ""}
                            />
                        </div>
                    </div>


                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Você, ou outra pessoa incluída neste processo, tem a intenção de utilizar um hospital ou uma unidade de saúde (incluindo casas de repouso), enquanto estiver na Austrália?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'usar_hospital')}
                                        value={1}
                                        id="checked-usar_hospital-s"
                                        type="radio"
                                        checked={state.usar_hospital === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-usar_hospital-s"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.usar_hospital === 0 }
                                        onChange={e => handleChange(0,'usar_hospital')}
                                        id="checked-usar_hospital-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-usar_hospital-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Você, ou outra pessoa incluída neste processo, tem a intenção de trabalhar ou estudar na área de saúde, enquanto estiver na Austrália?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'trabalhar_estudar_saude')}
                                        value={1}
                                        id="checked-trabalhar_estudar_saude-s"
                                        type="radio"
                                        checked={state.trabalhar_estudar_saude === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-trabalhar_estudar_saude-s"
                                        className="ms-2 text-[16px] font-medium "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.trabalhar_estudar_saude === 0 }
                                        onChange={e => handleChange(0,'trabalhar_estudar_saude')}
                                        id="checked-trabalhar_estudar_saude-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-trabalhar_estudar_saude-n"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Você, ou outra pessoa incluída neste processo, sofre ou já sofreu de tuberculose?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'tuberculose')}
                                        value={1}
                                        id="checked-tuberculose-s"
                                        type="radio"
                                        checked={state.tuberculose === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-tuberculose-s"
                                        className="ms-2 text-[16px] font-medium"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.tuberculose === 0 }
                                        onChange={e => handleChange(0,'tuberculose')}
                                        id="checked-tuberculose-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-tuberculose-n"
                                        className="ms-2 text-[16px] font-medium "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium "
                            >
                                Esteve em contato direto com algum familiar, com tuberculose?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'parente_tuberculose')}
                                        value={1}
                                        id="checked-parente_tuberculose-s"
                                        type="radio"
                                        checked={state.parente_tuberculose === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-parente_tuberculose-s"
                                        className="ms-2 text-[16px] font-medium "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.parente_tuberculose === 0 }
                                        onChange={e => handleChange(0,'parente_tuberculose')}
                                        id="checked-parente_tuberculose-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-parente_tuberculose-n"
                                        className="ms-2 text-[16px] font-medium "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium "
                            >
                                Já tirou um raio-x onde constou uma anormalidade?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'raio_x_anormalidade')}
                                        value={1}
                                        id="checked-raio_x_anormalidade-s"
                                        type="radio"
                                        checked={state.raio_x_anormalidade === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-raio_x_anormalidade-s"
                                        className="ms-2 text-[16px] font-medium "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.raio_x_anormalidade === 0 }
                                        onChange={e => handleChange(0,'raio_x_anormalidade')}
                                        id="checked-raio_x_anormalidade-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-raio_x_anormalidade-n"
                                        className="ms-2 text-[16px] font-medium "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Durante a sua visita à Austrália, você ou qualquer outra pessoa incluída neste processo, precisará de custos médicos, ou exigir tratamento ou acompanhamento médico para transtorno de sangue
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'custos_medicos')}
                                        value={1}
                                        id="checked-custos_medicos-s"
                                        type="radio"
                                        checked={state.custos_medicos === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-custos_medicos-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.custos_medicos === 0 }
                                        onChange={e => handleChange(0,'custos_medicos')}
                                        id="checked-custos_medicos-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-custos_medicos-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Cancer
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'cancer')}
                                        value={1}
                                        id="checked-cancer-s"
                                        type="radio"
                                        checked={state.cancer === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-cancer-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.cancer === 0 }
                                        onChange={e => handleChange(0,'cancer')}
                                        id="checked-cancer-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-cancer-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Doença cardíaca
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'doenca_cardiaca')}
                                        value={1}
                                        id="checked-doenca_cardiaca-s"
                                        type="radio"
                                        checked={state.doenca_cardiaca === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-doenca_cardiaca-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.doenca_cardiaca === 0 }
                                        onChange={e => handleChange(0,'doenca_cardiaca')}
                                        id="checked-doenca_cardiaca-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-doenca_cardiaca-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Hepatite B ou Ca
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'hepatite')}
                                        value={1}
                                        id="checked-hepatite-s"
                                        type="radio"
                                        checked={state.hepatite === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-hepatite-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.hepatite === 0 }
                                        onChange={e => handleChange(0,'hepatite')}
                                        id="checked-hepatite-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-hepatite-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Doença hepática
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'doenca_hepatica')}
                                        value={1}
                                        id="checked-doenca_hepatica-s"
                                        type="radio"
                                        checked={state.doenca_hepatica === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-doenca_hepatica-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.doenca_hepatica === 0 }
                                        onChange={e => handleChange(0,'doenca_hepatica')}
                                        id="checked-doenca_hepatica-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-doenca_hepatica-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                HIV, incluindo AIDS
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'hiv')}
                                        value={1}
                                        id="checked-hiv-s"
                                        type="radio"
                                        checked={state.hiv === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-hiv-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.hiv === 0 }
                                        onChange={e => handleChange(0,'hiv')}
                                        id="checked-hiv-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-hiv-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Doença renal, incluindo diálise
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'doenca_renal')}
                                        value={1}
                                        id="checked-doenca_renal-s"
                                        type="radio"
                                        checked={state.doenca_renal === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-doenca_renal-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.doenca_renal === 0 }
                                        onChange={e => handleChange(0,'doenca_renal')}
                                        id="checked-doenca_renal-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-doenca_renal-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Doença mental
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'disturbios')}
                                        value={1}
                                        id="checked-disturbios-s"
                                        type="radio"
                                        checked={state.disturbios === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-disturbios-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.disturbios === 0 }
                                        onChange={e => handleChange(0,'disturbios')}
                                        id="checked-disturbios-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-disturbios-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Gravidez
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'gravidez')}
                                        value={1}
                                        id="checked-gravidez-s"
                                        type="radio"
                                        checked={state.gravidez === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-gravidez-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.gravidez === 0 }
                                        onChange={e => handleChange(0,'gravidez')}
                                        id="checked-gravidez-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-gravidez-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Doença respiratória que exige internação ou terapia de oxigênio
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'problemas_respiratorios')}
                                        value={1}
                                        id="checked-problemas_respiratorios-s"
                                        type="radio"
                                        checked={state.problemas_respiratorios === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-problemas_respiratorios-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.problemas_respiratorios === 0 }
                                        onChange={e => handleChange(0,'problemas_respiratorios')}
                                        id="checked-problemas_respiratorios-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-problemas_respiratorios-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Outros
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={2}
                                cols={50}
                                name={'dados_visto_negado'}
                                onChange={e => handleChange(e.target.value,'dados_visto_negado')}
                                value={state.dados_visto_negado ? state.dados_visto_negado : ""}
                            />
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Você, ou qualquer outra pessoa incluída neste processo, requer assistência com mobilidade ou alguma assistência especial devido a uma condição médica?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'pcd')}
                                        value={1}
                                        id="checked-pcd-s"
                                        type="radio"
                                        checked={state.pcd === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-pcd-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.pcd === 0 }
                                        onChange={e => handleChange(0,'pcd')}
                                        id="checked-pcd-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-pcd-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>


                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}