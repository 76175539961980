import { QueryParams } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = '/idiomas-experiencias';

export default {
    all: async (params?: QueryParams) => getAll<any>({ endpoint, params }),
    listAll: async () => listAll<any>({ endpoint }),
    one: async (id: string) => getOne<any>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<any>) => create<any>({ endpoint, formData }),
    update: async (id: string, formData: Partial<any>) => update<any>({ endpoint, formData, id }),
};
