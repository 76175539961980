import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import ContatoApi from "../../../../../services/contatoApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import Input from "../../../../../components/input/Input";

export interface ISegundoContatoPais{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function SegundoContatoPais({processo, setProcesso, setTelaNumero, telanumero}: ISegundoContatoPais) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome: '',
        grau_parentesco: '',
        nascimento: '',
        email: '',
        endereco: '',
        cidade: '',
        estado: '',
        cep: '',
        telefone: '',
        status_imigratorio: '',
        data_chegada: '',
        segundo_nome: '',
        segundo_grau_parentesco: '',
        segundo_nascimento: '',
        segundo_endereco: '',
        segundo_status_imigratorio: '',
        segundo_data_chegada: '',
        info_contato_brasil: '',
        info_contato_grau_parentesco: '',
        info_contato_endereco: '',
        info_contato_telefone: '',
    });
    useEffect(() => {
        getContato();
    }, []);

    const getContato = async () => {
        const {data, isError} = await ContatoApi.one(processo.id);
        if (!isError){
            const response = data.data;
            if (Object.values(response).length){
                setState(response);
            }
        }
    }

    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };

    async function onSubmit() {
        const {data, isError} = state.id ? await ContatoApi.update(state.id, state) : await ContatoApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Contato Australia'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-6 gap-4 mt-5'}>
                        <Input
                            className={'col-span-4'}
                            label={'Caso você tenha algum contato na Austrália, (parente, conhecido, amigo) favor informar o nome da pessoa'}
                            placeholder={'Caso você tenha algum contato na Austrália, (parente, conhecido, amigo) favor informar o nome da pessoa'}
                            large={true}
                            name={'nome'}
                            setValue={e => handleChange(e,'nome')}
                            value={state.nome}
                        />
                        <Input
                            className={'col-span-1 '}
                            label={'Grau de Parentesco'}
                            placeholder={'Grau de Parentesco'}
                            large={true}
                            name={'grau_parentesco'}
                            setValue={e => handleChange(e,'grau_parentesco')}
                            value={state.grau_parentesco}
                        />
                        <Input
                            className={'col-span-1 '}
                            label={'Data de Nascimento'}
                            placeholder={'Data de Nascimento'}
                            type={'date'}
                            large={true}
                            name={'nascimento'}
                            setValue={e => handleChange(e,'nascimento')}
                            value={state.nascimento}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1 '}
                            label={'Endereço completo, com o CEP'}
                            placeholder={'Endereço completo, com o CEP'}
                            large={true}
                            name={'endereco'}
                            setValue={e => handleChange(e,'endereco')}
                            value={state.endereco}
                        />
                        <Input
                            className={'col-span-1 '}
                            label={'Status Migratório dessa pessoa'}
                            placeholder={'Status Migratório dessa pessoa'}
                            large={true}
                            name={'status_imigratorio'}
                            setValue={e => handleChange(e,'status_imigratorio')}
                            value={state.status_imigratorio}
                        />
                        <Input
                            className={'col-span-1 '}
                            label={'Data de chegada na Austrália'}
                            placeholder={'Data de chegada na Austrália'}
                            large={true}
                            type={'date'}
                            name={'data_chegada'}
                            setValue={e => handleChange(e,'data_chegada')}
                            value={state.data_chegada}
                        />
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'text-[20px]'}>2º Contato</div>
                    </div>
                    <div className={'grid md:grid-cols-6 gap-4 mt-5'}>
                        <Input
                            className={'col-span-4'}
                            label={'Caso você tenha algum contato na Austrália, (parente, conhecido, amigo) favor informar o nome da pessoa'}
                            placeholder={'Caso você tenha algum contato na Austrália, (parente, conhecido, amigo) favor informar o nome da pessoa'}
                            large={true}
                            name={'segundo_nome'}
                            setValue={e => handleChange(e,'segundo_nome')}
                            value={state.segundo_nome}
                        />
                        <Input
                            className={'col-span-1 '}
                            label={'Grau de Parentesco'}
                            placeholder={'Grau de Parentesco'}
                            large={true}
                            name={'segundo_grau_parentesco'}
                            setValue={e => handleChange(e,'segundo_grau_parentesco')}
                            value={state.segundo_grau_parentesco}
                        />
                        <Input
                            className={'col-span-1 '}
                            label={'Data de Nascimento'}
                            placeholder={'Data de Nascimento'}
                            type={'date'}
                            large={true}
                            name={'segundo_nascimento'}
                            setValue={e => handleChange(e,'segundo_nascimento')}
                            value={state.segundo_nascimento}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1 '}
                            label={'Endereço completo, com o CEP'}
                            placeholder={'Endereço completo, com o CEP'}
                            large={true}
                            name={'segundo_endereco'}
                            setValue={e => handleChange(e,'segundo_endereco')}
                            value={state.segundo_endereco}
                        />
                        <Input
                            className={'col-span-1 '}
                            label={'Status Migratório dessa pessoa'}
                            placeholder={'Status Migratório dessa pessoa'}
                            large={true}
                            name={'segundo_status_imigratorio'}
                            setValue={e => handleChange(e,'segundo_status_imigratorio')}
                            value={state.segundo_status_imigratorio}
                        />
                        <Input
                            className={'col-span-1 '}
                            label={'Data de chegada na Austrália'}
                            placeholder={'Data de chegada na Austrália'}
                            large={true}
                            type={'date'}
                            name={'segundo_data_chegada'}
                            setValue={e => handleChange(e,'segundo_data_chegada')}
                            value={state.segundo_data_chegada}
                        />
                    </div>


                    <div className={'grid md:grid-cols-6 gap-4 mt-5'}>
                        <Input
                            className={'col-span-4 '}
                            label={'Favor informar o contato de um parente seu que reside no Brasil e que não esteja acompanhando você na viagem'}
                            placeholder={'Favor informar o contato de um parente seu que reside no Brasil e que não esteja acompanhando você na viagem'}
                            large={true}
                            name={'info_contato_brasil'}
                            setValue={e => handleChange(e,'info_contato_brasil')}
                            value={state.info_contato_brasil}
                        />
                        <Input
                            className={'col-span-1 mt-[1.5rem]'}
                            label={'Grau de Parentesco'}
                            placeholder={'Grau de Parentesco'}
                            large={true}
                            name={'info_contato_grau_parentesco'}
                            setValue={e => handleChange(e,'info_contato_grau_parentesco')}
                            value={state.info_contato_grau_parentesco}
                        />
                        <Input
                            className={'col-span-1 mt-[1.5rem]'}
                            label={'Telefone Primário'}
                            placeholder={'Telefone Primário'}
                            large={true}
                            mask={'cellphone'}
                            name={'info_contato_telefone'}
                            setValue={e => handleChange(e,'info_contato_telefone')}
                            value={state.info_contato_telefone}
                        />
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Endereço completo, com o CEP'}
                            placeholder={'Endereço completo, com o CEP'}
                            large={true}
                            name={'info_contato_endereco'}
                            setValue={e => handleChange(e,'info_contato_endereco')}
                            value={state.info_contato_endereco}
                        />
                    </div>

                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}