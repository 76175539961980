import React from 'react';
import { AppRoute } from "./index";
import Login from "../pages/Auth/Login";
import ClienteFinal from "../pages/ClienteFinal";
import ViewProcessoCliente from "../pages/ClienteFinal/ViewProcessoCliente";

const appRoutesClientes: AppRoute[] =  [
    {
        path: '/',
        requiresAuth: false,
        element: <Login />
    },
    {
        path: '/entrar',
        requiresAuth: false,
        element: <Login />
    },
    {
        path: '/processos',
        requiresAuth: true,
        element: <ClienteFinal/>
    },
    {
        path: '/processos/:id',
        requiresAuth: true,
        element: <ViewProcessoCliente/>
    },
    {
        path: '/*',
        requiresAuth: false,
        element: <h1>404</h1>
    }
];

export default appRoutesClientes;