import React from 'react';
import { Navigate, Routes as ReactRouterDomRoutes, Route, RouteProps, useNavigate } from 'react-router-dom';
import appRoutes from './rotas';
import Storage from '../helpers/Storage';
import appRoutesClientes from "./rotasClientes";
export interface AppRoute {
    path: string;
    requiresAuth: boolean;
    element: JSX.Element;
}

export function PrivateRoute({ children }: RouteProps) {
    const logged   = !!Storage.getApiToken();
    const userData = !!Storage.getUserData();

    return logged && userData ? (
        <>
            {children}
        </>
    ) : (
        <Navigate to="/entrar" />
    );
}

export function Routes() {
    // @ts-ignore
    const tipoId = !!Storage.getUserData() ? Storage.getUserData().usuario.tipo_id : '';

    if (tipoId != 4) {
        document.body.style.overflow = "hidden";
    }

    return (
        <ReactRouterDomRoutes>

            {
                tipoId === 4 ?
                    appRoutesClientes.map(({ path, requiresAuth, element }) =>
                        requiresAuth ? (
                            <Route
                                key={path}
                                path={path}
                                element={<PrivateRoute>{element}</PrivateRoute>}
                            />
                        ) : (
                            <Route
                                key={path}
                                path={path}
                                element={element}
                            />
                        ))
                        :
                        appRoutes.map(({ path, requiresAuth, element }) =>
                            requiresAuth ? (
                                <Route
                                    key={path}
                                    path={path}
                                    element={<PrivateRoute>{element}</PrivateRoute>}
                                />
                            ) : (
                                <Route
                                    key={path}
                                    path={path}
                                    element={element}
                                />
                            ))
            }
        </ReactRouterDomRoutes>
    );
}