import {IInputSelect} from "../../../types";
import Select, {components} from "react-select";
import React from "react";

export default function InputSelect({
    selected,
    className,
    label,
    classNameContainer,
    loading,
    options,
    onChangeSelect,
    placeholder,
    isMulti,
    isOptionDisabled,
    isDisabled,
    key,
    legenda,
    labelPlace,
    required,
}:IInputSelect){
    const inputSelectStyle = () => ({
        padding: '6px'
    });

    return(
        <div>
            <label>
                {label} {required ? <span className={'text-[#c22929]'}>*</span> : ''}
            </label>
            <Select
                isDisabled={isDisabled}
                required={required}
                menuPlacement={'auto'}
                isMulti={isMulti}
                value={selected}
                options={options}
                onChange={onChangeSelect}
                placeholder={placeholder ? placeholder : 'Selecione'}
                classNames={{
                    control: (state) => ('hover:ring-gray-300 hover:focus:ring-1 text-ellipsis'),

                }}
                components={{
                    Control: ({ children, ...rest }) => (
                        <components.Control {...rest}>
                            {labelPlace ? <div className={'font-bold ml-2'}>{labelPlace}:</div> : label ? '' : <div className={'font-bold ml-2'}>Status:</div>} {children}
                        </components.Control>
                    )}}
                styles={
                    {
                        input: (styles) => ({ ...styles, ...inputSelectStyle() }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? 'border-gray-300' : 'border-gray-300',
                            outline: state.isFocused ? '1px solid #d0d5dd' : 'none',
                            border: '2px solid #d0d5dd',
                            borderRadius: '0px',
                            boxShadow: state.isFocused ? '1px #d0d5dd' : '1px #d0d5dd',
                            overflow: 'auto',
                        }),
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }
                }
            />
            {
                legenda ?
                    <div className={'text-sm text-[#6c757d]'}>
                        {legenda}
                    </div>
                    :
                    ""
            }

        </div>

    )
}