import React from "react";
import {ReactComponent  as SetaSubindo} from '../../assets/icone/seta-subindo.svg';
import {ReactComponent  as SetaBaixo} from '../../assets/icone/seta-baixo.svg';
import { BarChart } from '@mui/x-charts/BarChart';
interface IFaturamentoDashboard {
    faturamento: any
}
export default function FaturamentoDashboard({faturamento}:IFaturamentoDashboard){
    const seriesData = [{ data: [faturamento.faturamento_mes_anterior], label: 'Mês anterior' }, { data: [faturamento.faturamento_atual], label: 'Esse mês' }]
    return(
        <div className={'flex border rounded-md  items-center w-[469px] h-[137px] border-2 px-3 mt-[32px] '}>
            <div className={'flex flex-col h-full justify-between py-4 flex-70%'}>
                <div className={'text-[25px] text-gray-700 font-semibold'}>Faturamento</div>
                <div className={'flex'}>
                    <div className={'flex flex-col'}>
                        <div className={'text-[#0f4eb3] text-[20px] font-bold'}>R$ {faturamento.faturamento_mes_anterior}</div>
                        {/*<div className={'flex text-[16px] text-[#c12929]'}><SetaBaixo/>12% faturado</div>*/}

                    </div>
                    <div className={'flex flex-col ml-5'}>
                        <div className={'text-[20px] text-[#082a60] font-bold'}>R$ {faturamento.faturamento_atual}</div>
                        {
                            Math.sign(faturamento.percent) === -1 ?
                                <div className={'flex text-[#c12929]'}>
                                    <SetaBaixo/> {faturamento.percent}% faturado
                                </div>
                                :
                                <div className={'flex text-[#1d9d68]'}>
                                    <SetaSubindo/> {faturamento.percent}% faturado
                                </div>
                        }
                        {/*<div className={'flex text-[16px] text-[#1d9d68]'}><SetaSubindo/>12% faturado</div>*/}

                    </div>
                </div>
            </div>
            <div className={'flex'}>
                <BarChart
                    xAxis={[{ scaleType: 'band', data: [''] }]}
                    //series={[{ data: [35000], label: 'Mês anterior' }, { data: [72500], label: 'Esse mês' }]}
                    series={seriesData}
                    width={150}
                    height={190}
                    margin={{ top: 25, bottom: 40, left: 60, right: 5 }}
                    colors={['#0f4eb3', '#082a60']}
                    slotProps={{ legend: { hidden: true } }}
                    leftAxis={null}
                />
            </div>
        </div>
    )
}