import React, {useEffect, useState} from "react";
import Alert from "../../../../../helpers/Alert";
import clientesApi from "../../../../../services/clientesApi";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import processosApi from "../../../../../services/processosApi";
import HeaderForm from "../../../../../components/headerForm";
import Form from "../../../../../components/form/Form";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import Button from "../../../../../components/button/Button";
import {Spinner} from "@react-pdf-viewer/core";

export interface IDadosPessoaisAusVisitante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosPessoaisAusVisitante({processo, setProcesso, setTelaNumero, telanumero}: IDadosPessoaisAusVisitante) {
    const [loading, setLoading] = useState(true);
    const [dadosPessoais, setDadosPessoais] = useState({
        id: processo.cliente.id,
        nome: processo.cliente.nome,
        sobrenome: processo.cliente.sobrenome,
        nomes_anteriores: processo.cliente.nomes_anteriores,
        sexo: processo.cliente.sexo ? processo.cliente.sexo : 'm',
        cpf: processo.cliente.cpf,
        rg: processo.cliente.rg,
        nascimento: processo.cliente.nascimento,
        estado_civil: processo.cliente.estado_civil,
        cidade_natal: processo.cliente.cidade_natal,
        pais_natal: processo.cliente.pais_natal,
        nome_completo_conjuge: processo.cliente.nome_completo_conjuge,
        estado_natal: processo.cliente.estado_natal,
    });
    const stadoCivilOptions = [
        { value: 'solteiro', label: 'Solteiro(a)' },
        { value: 'casado', label: 'Casado(a)' },
        { value: 'uniao estavel', label: 'União Estável' },
        { value: 'separado', label: 'Separado(a)' },
        { value: 'desquitado', label: 'Desquitado(a)' },
        { value: 'viuvo', label: 'Viúvo(a)' },
        { value: 'divorciado', label: 'Divorciado' },
    ];

    const getData = async () => {
        const  {data, isError} = await clientesApi.one(processo.cliente.id);
        setDadosPessoais({
            id: data.data.id,
            nome: data.data.nome,
            sobrenome: data.data.sobrenome,
            nomes_anteriores: data.data.nomes_anteriores,
            sexo: data.data.sexo ? data.data.sexo : 'm',
            cpf: data.data.cpf,
            rg: data.data.rg,
            nascimento: data.data.nascimento,
            estado_civil: data.data.estado_civil,
            cidade_natal: data.data.cidade_natal,
            pais_natal: data.data.pais_natal,
            nome_completo_conjuge: data.data.nome_completo_conjuge,
            estado_natal: data.data.estado_natal,
        })
        setLoading(false)
    }

    useEffect(() => { getData() }, [])


    async function onSubmit() {
        Alert.await("Salvando dados");
        const data = await clientesApi.update(dadosPessoais.id, dadosPessoais);
        if (data.isError){
            if (data.data.message){
                return Alert.error(data.data.message? data.data.message : data.data);
            }
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        let per = processo.percet_conclusao;
        if (processo.percet_conclusao === 0){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            processosApi.update(processo.id, {percet_conclusao: percent})
            per = percent;
        }
        const cliente = data.data.data;
        cliente.estado_civil = dadosPessoais.estado_civil;
        setProcesso({...processo, 'cliente':cliente, percet_conclusao:per })
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
        //setTelaNumero(8);
    }
    const handleChange = (value: any, input: string) => {
        setDadosPessoais({...dadosPessoais, [input]: value});
    };
    if (loading) return (<div className={'text-[16px] flex justify-center'}><Spinner  /></div>);
    return(
        <>
            <HeaderForm
                titulo={'Dados Pessoais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form   onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome '}
                            placeholder={'Nome'}
                            large={true}
                            required={true}
                            name={'nome'}
                            setValue={e => handleChange(e,'nome')}
                            value={dadosPessoais.nome}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Sobrenome '}
                            placeholder={'Sobrenome'}
                            large={true}
                            required={true}
                            name={'sobrenome'}
                            setValue={e => handleChange(e,'sobrenome')}
                            value={dadosPessoais.sobrenome}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Nomes anteriores'}
                            placeholder={'Nomes anteriores'}
                            large={true}
                            name={'nomes_anteriores'}
                            setValue={e => handleChange(e,'nomes_anteriores')}
                            value={dadosPessoais.nomes_anteriores}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Sexo *
                            </label>
                            <div className={'flex mt-[1.5rem] gap-4'}>
                                <div className={'flex'}>
                                    <input

                                        onChange={e => handleChange(e.target.value,'sexo')}
                                        value="m"
                                        id="checked-sexo-m"
                                        type="radio"
                                        checked={dadosPessoais.sexo === "m" || dadosPessoais.sexo === ""}
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-sexo-m"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Masculino
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={dadosPessoais.sexo === "f"}
                                        onChange={e => handleChange(e.target.value,'sexo')}
                                        id="checked-sexo-f"
                                        type="radio"
                                        value="f"
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-sexo-f"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Feminino
                                    </label>
                                </div>


                            </div>

                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'CPF '}
                            placeholder={'CPF'}
                            inputType={'onlyNumbers'}
                            large={true}
                            name={'cpf'}
                            setValue={e => handleChange(e,'cpf')}
                            value={dadosPessoais.cpf}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'RG '}
                            placeholder={'RG'}
                            large={true}
                            name={'rg'}
                            setValue={e => handleChange(e,'rg')}
                            value={dadosPessoais.rg}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Data de nascimento '}
                            type={'date'}
                            placeholder={'Data de nascimento'}
                            large={true}
                            name={'nascimento'}
                            setValue={e => handleChange(e,'nascimento')}
                            value={dadosPessoais.nascimento}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Cidade nascimento'}
                            placeholder={'Cidade nascimento'}
                            large={true}
                            name={'cidade_natal'}
                            setValue={e => handleChange(e,'cidade_natal')}
                            value={dadosPessoais.cidade_natal}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Estado nascimento'}
                            placeholder={'Estado nascimento'}
                            large={true}
                            name={'estado_natal'}
                            setValue={e => handleChange(e,'estado_natal')}
                            value={dadosPessoais.estado_natal}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'País de nascimento'}
                            placeholder={'País de nascimento'}
                            large={true}
                            name={'pais_natal'}
                            setValue={e => handleChange(e,'pais_natal')}
                            value={dadosPessoais.pais_natal}
                        />
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Estado civil"}
                                selected={stadoCivilOptions.filter((opt: any) => opt.value === dadosPessoais.estado_civil)}
                                options={stadoCivilOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'estado_civil')}
                            />
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Nome completo e data de nascimento do cônjuge'}
                            placeholder={'Nome completo e data de nascimento do cônjuge'}
                            large={true}
                            name={'nome_completo_conjuge'}
                            setValue={e => handleChange(e,'nome_completo_conjuge')}
                            value={dadosPessoais.nome_completo_conjuge}
                        />
                    </div>
                </div>

                <div className={'border-2'}></div>
                <div className={'flex justify-end p-5'}>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>

                </div>
            </Form>
        </>
    )
}