import { useEffect, useState } from "react";
import { PaginatedRequestResponse, RequestError, RequestResponse } from "../../../services/api";
import PageContainer, { IPageContainer } from "../../container/PageContainer";
import Table from "../../table/Table";
import { PaginatedData2, QueryParams } from "../../../types";
import Alert from "../../../helpers/Alert";
import Button from "../../button/Button";
import {ReactComponent as AddIcon} from "../../../assets/icone/add.svg";
import Modal from "../../modal/Modal";
import useModal from "../../../hooks/useModal";
import Form from "../../form/Form";
import useForm, { UseFormData } from "../../../hooks/useForm";
import { z } from "zod";
import Str from "../../../helpers/Str";
import {entradaApi} from "../../../services";

const ERR = {
  Required: 'Campo obrigatório'
}

export type IListPageLayoutProps<T = any> = {
  title: IPageContainer['pageTitle'];
  filters?: {
    initial?: { [key: string]: any };
    form?:  (params: {} & UseFormData<any>) => React.ReactNode;
  };
  actions?: {
    all: (params?: QueryParams) => Promise<RequestError | PaginatedRequestResponse<T>>;
    one?: (id: string) => Promise<RequestError<any> | RequestResponse<T>>;
    destroy?:(id: string | number) => Promise<RequestError | RequestResponse>;
    create?: (formData: Partial<T>) => Promise<RequestError | RequestResponse>;
    update?: (id: string | number, formData: any/*Partial<T>*/) => Promise<RequestError | RequestResponse>;
  }
  table?: {
    columns: ({
      title: string;
      field: keyof T | `${Extract<keyof T, string>}.${string}`;
      type?: 'date' | 'currency'
    } | {
      title: string;
      render: (rowData: T) => React.ReactNode;
    })[];
    loadOnInit?: boolean;
    onPageChange?: (newPageIndex: number) => void;
  }
  register?: {
    allowedActions?: ('create' | 'update' | 'destroy')[];
    saveBtnText?: (params: {} & UseFormData<T>) => React.ReactNode;
    mode: 'modal';
    size?: 'sm' | 'md' | 'lg' | 'lm' | 'max-content';
    title?: string;
    form: (params: {} & UseFormData<T>) => React.ReactNode;
    onOpen?: (params: {} & UseFormData<T>) => void;
    footer?: (p: { loader: any, form? : Partial<T>, closeModal: () => void  }) => React.ReactNode;
    footerClassName?: string;
    rules?: {
      [key in keyof T]?: 'required';
    }
  }
  functionPago?: any;
}

export function ListPageLayout<T = any>({
  title,
  table,
  actions,
  register,
  filters,
  functionPago,
}: IListPageLayoutProps<T>) {
  const registerModal = useModal();
  const [data, setData] = useState<PaginatedData2<T>>();
  const modalForm = useForm({});
  const filterForm = useForm({
    initialData:  {
      ...initialFilters(),
      ...(filters?.initial ?? {})
    },
  });

  function initialFilters() {
    const searchParams = new URLSearchParams( window.location.search ) as any;
    const d = searchParams.has('filter') ? JSON.parse(searchParams.get('filter')) : {};
    return d;
  }

  async function load() {
    if (! table || ! actions) return;

    const response = await actions.all(filterForm.form);
    if (response.isError) return Alert.error(
      response.data.message || response.data.error || 'Falha ao carregar itens.' 
    );
    const searchParams = new URLSearchParams( window.location.search ) as any;
    //console.log(searchParams, filterForm.form);
    searchParams.set('filter', JSON.stringify(filterForm.form));
    window.history.replaceState({}, '', `${window.location.pathname}?${searchParams.toString()}`);
    setData((response.data as any).data);
  }

  useEffect(() => {
    load();
  }, [filterForm.form]);

  const setPagamento = async (data: any) => {
    Alert.await('Sandando dados...');
    await actions?.update?.(modalForm.form.id, {pago: true});
    load();
    Alert.close();
    registerModal.close();
  }

  return (
    <PageContainer pageTitle={title}>
      <header className={'flex w-full justify-between mb-6'}>
        <div>
          {!!filters?.form && filters.form(filterForm)}
        </div>
        <div>
          {(!register?.allowedActions || (register?.allowedActions.includes('create'))) && !!register && <Button 
            className={'flex bg-[#082a60] text-white w-[146px] h-[48px]'}
            onClick={() => {
              if (register?.mode === 'modal') {
                modalForm.setForm({});
                registerModal.open();
              }
            }}
          >
            <AddIcon className="fill-white mr-1 " /> Cadastrar
          </Button>}
        </div>
      </header>

      {!!table && <Table 
        data={data ?? {}}
        tableData={data?.data ?? []}
        page={filterForm.form.page}
        perPage={filterForm.form.limit}
        onClick={(row: T) => {
          if (register?.mode === 'modal') {
            modalForm.setForm(row);
            registerModal.open();
            register.onOpen?.({ ...modalForm, form: row });
          }
        }}
        onPageChange={(page: any) => {
          filterForm.setForm({ ...filterForm.form, page });
        }}
        setPerPage={(limit:any) => {
          filterForm.setForm({ ...filterForm.form, limit, page: 1 });
        }}
        columns={table.columns.map((column) => ({
          ...column,
          label: column.title,
          index: (column as any).field
        }))}
      />}

      {register?.mode === 'modal' && <Modal 
        {...registerModal.modalProps}
        title={register.title}
        size={register.size ?? 'lg'}
      >
        <Form onSubmit={async () => {
          modalForm.setErrors({});
          if (!!register?.rules) {
            let r: any = {};
            Object.keys(register.rules).forEach((key) => {
              if ((register.rules as any)[key] === 'required') {
                r[key] = z.string().min(1);
              }
            })

            const result = z.object(r).safeParse(modalForm.form);
            if (!result.success) {
              const issues = (result as any).error.issues;
              let err: any = {};
              issues.forEach((issue: any) => {
                err[issue.path[0]] = (ERR as any)[issue.message] ?? issue.message;
              })
              modalForm.setErrors(err);
              return;
            }
          }

          Alert.await('Sandando dados...');
          if (modalForm.form.id) {
            await actions?.update?.(modalForm.form.id, modalForm.form);
          } else {
            await actions?.create?.(modalForm.form);
          }
          load();
          Alert.close();
          registerModal.close();
        }}>
          {register.form(modalForm)}

          <footer className={'mt-[2rem] gap-2 flex justify-between'}>
            {(!register?.allowedActions || (register?.allowedActions.includes('destroy'))) && modalForm.form.id ? <button
                type="button"
                onClick={async () => {
                  const confirm = await Alert.confirm('Tem certeza que deseja excluir?', 'Excluir Registro?');
                  if (!confirm) return;
                  const response = await actions?.destroy?.(modalForm.form.id);
                  if (response?.isError) return Alert.error(response.data.message || 'Falha ao excluir o registro.');
                  Alert.success('Registro excluído com sucesso!');
                  registerModal.close();
                  load();
                }}
                className={'text-red-600 border-transparent font-semibold p-1 pb-0'}
            >
                Excluir
            </button> : <div></div>}
            
            <div className={Str.tw('gap-2 flex', register?.footerClassName)}>
              {!!register.footer && register.footer({ loader: load, form: modalForm.form, closeModal: registerModal.close })}
              {(!register?.allowedActions || (register?.allowedActions.includes('create') || register?.allowedActions.includes('update'))) && <Button
                  type={'submit'}
                  className={'bg-[#082a60] text-white font-semibold'}
              >
                {register.saveBtnText ? register.saveBtnText(modalForm) : 'Salvar'}
              </Button>}

              {
                functionPago && !modalForm.form.pago ?
                    <Button
                        onClick={() => setPagamento(modalForm.form)}
                        className={'bg-[#29D26F] text-white font-semibold'}
                    >
                      Lançar Pagamento
                    </Button>
                    :
                    ''
              }
            </div>
        </footer>
        </Form>
      </Modal>}
    </PageContainer>
  )
}
