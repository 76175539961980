import React, {useEffect} from "react";
import {GoDotFill} from "react-icons/go";
import Dates from "../../helpers/Dates";
interface INotificacoesDashboard {
    data: any;
}
export default function NotificacoesDashboard({data}: INotificacoesDashboard){
    return(
        <div className={'flex-col border rounded-md w-[500px]  h-[95vh] border-2  md:ml-[32px] overflow-auto'}>
            <div className={'flex justify-between w-full items-center h-[60px] rounded-t-lg p-5 mt-3'}>
                <div className={'text-[20px] font-semibold'}>Notificações</div>
                <div className={'font-bold '}>
                    Mês <span className={'text-[12px]'}>({data.length})</span>
                </div>
            </div>
            {
                data.map((atividade: any, index: number) => {

                    return(
                        <div className={'px-5 mt-5'} key={index}>
                            <div className={'flex flex-col w-full '}>
                                <div className={''}>
                                    <span className={'font-bold '}>Cliente:</span> {atividade['processo']['cliente']['nome']+' '+atividade['processo']['cliente']['sobrenome']}
                                </div>
                                <div className={''}>
                                    <span className={'font-bold '}>Dias para finalizar:</span> {!atividade['data_finalizacao'] ? (atividade['data_prazo_finalizacao'] ? Dates.difDay(new Date().toISOString().slice(0, 10), atividade['data_prazo_finalizacao']).toString() : '') : 0}
                                </div>
                                <div className={''}>
                                    <span className={'font-bold '}>Situação:</span> {atividade['situacao_processo'] ? atividade['situacao_processo']['nome'] : ''}
                                </div>
                                <div className={''}>
                                    <span className={'font-bold '}>País:</span> {atividade['processo']['pais'] ? atividade['processo']['pais']['nome'] : '' }
                                </div>
                                <div className={''}>
                                    <span className={'font-bold '}>Prazo:</span> {atividade['data_prazo_finalizacao'] ? Dates.convertEnToBr(atividade['data_prazo_finalizacao']) : '' }
                                </div>
                                <div className={''}>
                                    <span className={'font-bold '}>Tipo de visto:</span> {atividade['processo']['tipo_visto'] ? atividade['processo']['tipo_visto']['nome'] : ''}
                                </div>
                                <div className={'mb-5'}>
                                    <span className={'font-bold '}>Concluído:</span> <span className={`${atividade['data_finalizacao'] ? 'text-[#061f46]' : 'text-red-600'}`}>{atividade['data_finalizacao'] ? 'Sim' : 'Não'}</span>
                                </div>
                            </div>
                            <div className={''}>
                                <div className={'border-b-2'}></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}