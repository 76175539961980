import React, { useState, useEffect } from 'react';
import useForm from "../../../hooks/useForm";
import {LoginData} from "../../../types";
import authApi from "../../../services/authApi";
import Storage from '../../../helpers/Storage';
import Input from "../../../components/input/Input";
import Form from "../../../components/form/Form";
import LogoImg from '../../../assets/img/logo_login.png';
import Bolhas from '../../../assets/img/bolhas.png';
import Alert from "../../../helpers/Alert";
import menusAtendente from "../../../router/menusAtendentes";
export default function Login() {
    const [errorMessage, setErrorMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const { form, isFormInvalid, handleChange } = useForm<LoginData>({
        validator: (data) => !data.password || !data.email
    });

    async function handleSubmit() {
        setErrorMessage('');
        setIsLoading(true);

        const { data, isError } = await authApi.login(form);

        if (isError) {
            setIsLoading(false);
            Alert.error(data.error);
            setErrorMessage(data.error);
            return;
        }

        Storage.setApiToken(data.data.token);
        Storage.setUserData({ usuario: data.data.usuario });

        window.location.href = data.data.usuario.tipo_id !== 1 ? '/processos' : '/dashboard';
    }

//md:w-520 lg:ml-6 lg:w-5/12
    return(
        <section className="h-screen">
            <div >
                <img src={Bolhas} className={'absolute w-full z-[1]'}/>
            </div>
            <div className={'w-full h-[336px] bg-[#082a60] absolute'}>

            </div>
            <div className=" h-full px-6 py-24">
                <div className="g-6 flex h-full w-full flex-wrap items-center justify-center">
                    <div className="flex flex-col items-center w-[500px]  z-10 bg-white p-[40px] rounded-sm shadow-lg">
                        <div className={'w-[300px] h-[226px]'}>
                            <img src={LogoImg}/>
                        </div>
                        <Form className={'w-full'}  onSubmit={handleSubmit}>
                            <div className={'grid grid-cols-1 gap-4'}>
                                <Input
                                    large
                                    label={'E-mail'}
                                    inputType="email"
                                    className="col-span-1"
                                    value={form?.email}
                                    setValue={(v) => handleChange(v, 'email')}
                                />
                                <Input
                                    className="col-span-1"
                                    large
                                    label={'Senha'}
                                    inputType="password"
                                    value={form?.password}
                                    setValue={(v) => handleChange(v, 'password')}
                                />
                            </div>
                            {/*<div className="my-5">
                                <Input
                                    large
                                    label={'E-mail'}
                                    inputType="email"
                                    className="my-5"
                                    value={form?.email}
                                    setValue={(v) => handleChange(v, 'email')}
                                />
                            </div>*/}





                            <div className="mt-6">
                                <button
                                    className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#082a60] hover:bg-[#09306b] focus:outline-none focus:bg-[#09306b]">
                                    Entrar
                                </button>
                            </div>
                        </Form>
                        <div className={'mt-5'}>
                            Esqueceu sua senha? <a className={'text-[#0b3983]'} href={'/redefinir-senha'}>Redefinir senha</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}