import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = '/email-send';

export default {

    reenviarSenha: async (dataSenha: any) => {
        try {
            const { data } = await api.post('/email-send/reenviar-senha', dataSenha);
            return { data, isError: false };
        }catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    }
};
