import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import dadosFinanceirosApi from "../../../../../services/dadosFinanceirosApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import assistenciaMedicaApi from "../../../../../services/assistenciaMedicaApi";
import InputSelect from "../../../../../components/input/InputSelect";

export interface IAssistenciaMedicaAusEstudante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function AssistenciaMedicaAusEstudante({processo, setProcesso, setTelaNumero, telanumero}: IAssistenciaMedicaAusEstudante) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        possui_assistencia: 0,
        assistencia: '',
        outros: '',
    });
    const assistenciaOptions = [
        { value: 'solteiro', label: 'OSHC (Overseas Student Health Cover)' },
        { value: 'casado', label: 'Outros (Nesse caso, aconselhamos que o solicitante verifique se o plano cobre o que o governo australiano exige)' },
    ];
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const getDados = async () => {
        const {data, isError} = await assistenciaMedicaApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }

    }
    useEffect(() => {
        getDados()
    },[])
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await assistenciaMedicaApi.update(state.id, state) : await assistenciaMedicaApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }

    return(
        <>
            <HeaderForm
                titulo={'Assistencia médica'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-2'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Você já possui uma assistência médica que cubra toda a sua estadia enquanto estiver na Austrália?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'possui_assistencia')}
                                        value={1}
                                        id="checked-possui_assistencia-s"
                                        type="radio"
                                        checked={state.possui_assistencia === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_assistencia-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.possui_assistencia === 0 }
                                        onChange={e => handleChange(0,'possui_assistencia')}
                                        id="checked-possui_assistencia-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_assistencia-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                            <div className={'text-sm text-gray-400'}>
                                (caso você ainda não tenha, vale lembrar da importância de possuir um, não só para sua segurança mas também para o aceite de seu visto. Já que ele é obrigatório para a Austrália).
                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Favor selecionar a melhor opção de qual assistência médica você possui"}
                                selected={assistenciaOptions.filter((opt: any) => opt.value === state.assistencia)}
                                options={assistenciaOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'assistencia')}
                            />
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Caso tenha marcado "outros", especifique aqui qual o plano
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={2}
                                cols={50}
                                name={'outros'}
                                onChange={e => handleChange(e.target.value,'outros')}
                                value={state.outros ? state.outros : ""}
                            />
                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}