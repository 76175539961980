import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import custeioApi from "../../../../../services/custeioApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import Input from "../../../../../components/input/Input";

export interface IComprovanteRendaAus{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function ComprovanteRendaAus({processo, setProcesso, setTelaNumero, telanumero}: IComprovanteRendaAus ) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        se_mantera: 0,
        nome: '',
        relacao: '',
        endereco: '',
        telefone: '',
        tipo_apoio: '',
    })

    const getDataCusteio = async () => {
        const {data, isError} = await custeioApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    useEffect(() => {
        getDataCusteio()
    }, [])
    async function onSubmit() {
        const {data, isError} = state.id ? await custeioApi.update(state.id, state) : await custeioApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={'Comprovação de renda'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form   onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col md:col-span-1'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Você vai se manter na Austrália, por conta própria?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'se_mantera')}
                                        value={1}
                                        id="checked-se_mantera-s"
                                        type="radio"
                                        checked={state.se_mantera === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-se_mantera-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.se_mantera === 0 }
                                        onChange={e => handleChange(0,'se_mantera')}
                                        id="checked-se_mantera-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-se_mantera-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                            <div className={'text-sm text-gray-400'}>
                                (Nota: Caso positivo, você poderá ser convidado a apresentar provas de sua renda. Exemplos: extrato bancário pessoal, contra-cheques, contas auditadas ou registros fiscais).
                            </div>
                        </div>
                    </div>

                    {
                        state.se_mantera ?
                            <>
                                <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                                    <Input
                                        required={true}
                                        large={true}
                                        className={'col-span-1'}
                                        label={'Nome completo'}
                                        name={'nome'}
                                        setValue={e => handleChange(e, 'nome')}
                                        value={state.nome}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'Qual o parentesco/relação com você?'}
                                        name={'relacao'}
                                        setValue={e => handleChange(e, 'relacao')}
                                        value={state.relacao}
                                    />
                                </div>
                                <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                                    <Input
                                        large={true}
                                        className={'col-span-1'}
                                        label={'Endereco completo'}
                                        name={'endereco'}
                                        setValue={e => handleChange(e, 'endereco')}
                                        value={state.endereco}
                                    />
                                </div>
                                <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                    <Input
                                        large={true}
                                        mask={'cellphone'}
                                        className={'col-span-1'}
                                        label={'Telefone'}
                                        name={'telefone'}
                                        setValue={e => handleChange(e, 'telefone')}
                                        value={state.telefone}
                                    />
                                    <Input
                                        large={true}
                                        className={'col-span-2'}
                                        label={'Tipo de apoio'}
                                        name={'tipo_apoio'}
                                        setValue={e => handleChange(e, 'tipo_apoio')}
                                        value={state.tipo_apoio}
                                    />
                                </div>
                            </>
                            :
                            ''
                    }



                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}