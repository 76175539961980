import { IconType } from 'react-icons/lib/cjs';
import { FiHome } from "react-icons/fi";
import { FaUsers } from "react-icons/fa6";
import { CgNotes } from "react-icons/cg";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineAttachMoney } from "react-icons/md";
import { FaRegChartBar } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import { FaUserTie } from "react-icons/fa6";
export interface Menus {
    title: string;
    path?: string;
    icon?: IconType;
    subMenus?: Menus[];
}

//import HomeSvg from 'assets/icone/home.svg';

export default [
    {
        title: 'Dashboards',
        path: '/dashboard',
        icon: FiHome,
    },
    {
        title: 'Clientes',
        path: '/clientes',
        icon: FaUsers,
    },
    {
        title: 'Processos',
        path: '/processos',
        icon: CgNotes,
    },
    {
        title: 'Cronograma',
        path: '/cronogramas',
        icon: FaRegCalendarAlt,
    },
    {
        title: 'Financeiro',
        icon: MdOutlineAttachMoney,
        subMenus:[
            {
                title: 'Entradas',
                path: '/financeiro/entradas',
            },
            {
                title: 'Saídas',
                path: '/financeiro/saidas',
            },
        ]
    },
    {
        title: 'Relatórios',
        path: '/relatorios',
        icon: FaRegChartBar
    },
    {
        title: 'Usuários',
        path: '/usuarios',
        icon: CiUser,
    },
    {
        title: 'Parceiros',
        path: '/parceiros',
        icon: FaUserTie,
    },
] as Menus[];