import PageContainer from "../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import InputIcon from "../../components/input/InputIcon";
import InputSelect from "../../components/input/InputSelect";
import {ReactComponent  as ArrowLeftIcon} from '../../assets/icone/arrow-left.svg';
import {ReactComponent  as ArrowRightIcon} from '../../assets/icone/arrow-right.svg';
import ClienteDashboard from "../../components/dashboard/clienteDashboard";
import FaturamentoDashboard from "../../components/dashboard/faturamenteDash";
import ProcessosDashboard from "../../components/dashboard/processosDashboard";
import PaisesDashboard from "../../components/dashboard/paisesDashboard";
import NotificacoesDashboard from "../../components/dashboard/notificacoesDashboard";
import cronogramaApi from "../../services/cronogramaApi";
import usuariosApi from "../../services/usuariosApi";
import dashboardApi from "../../services/dashboardApi";
export default function Dashboard() {
    const hoje = new Date();
    const [loading, setLoading] = useState(true);
    const [cronogramas, setCronogramas]: any = useState([]);
    const [vendedores, setVendedores] = useState([]);
    const [vendedorSelected, setVendedorSelected] = useState('');
    const [dataString, setDataString] = useState(new Date().toISOString().slice(0, 7));
    //const [dateIndex, setDateIndex]: any = useState(hoje.getFullYear()+'-'+(hoje.getMonth()+1));
    const [dashboard, setDashboard]: any = useState([]);

    const [dataConsulta, setDataConsulta] = useState(new Date());

    const arrayMeses: any = {
        1: 'Janeiro',
        2: 'Fevereiro',
        3: 'Março',
        4: 'Abril',
        5: 'Maio',
        6: 'Junho',
        7: 'Julho',
        8: 'Agosto',
        9: 'Setembro',
        10: 'Outobro',
        11: 'Novembro',
        12: 'Dezembro',
    }

    const getDataDash = async (dateConsulta?: string, vendedor?: string) => {
        setLoading(true);
        const splitMes = dateConsulta ? dateConsulta.split('-') : dataString.split('-');

        setDataString(arrayMeses[parseInt(splitMes[1])]+'-'+splitMes[0]);

        const mes = hoje.getFullYear()+'-'+((hoje.getMonth()+1 < 9 ? '0' : '') + (hoje.getMonth()+1));

        if (!dateConsulta){
            const vendedoresData = await usuariosApi.listUsersAdmins();
            if (!vendedoresData.isError){
                const optionsVendedores = vendedoresData.data.data.map((vendedor: any) => {
                    return {value: vendedor.id, label: vendedor.name}
                });
                optionsVendedores.unshift({value: '', label: 'Todos'})
                setVendedores(optionsVendedores);
            }
        }
        const [cronogramaData, dasboardData] = await  Promise.all([
            cronogramaApi.getCronogramaData(dateConsulta ? dateConsulta : mes, '', '', vendedor? vendedor : ''),
            dashboardApi.getData(dateConsulta ? dateConsulta : mes, vendedor)
        ]);
        if (!cronogramaData.isError){
            setCronogramas(cronogramaData.data);
        }
        if (!dasboardData.isError){
            setDashboard(dasboardData.data.data);
        }

        setLoading(false);
    }
    useEffect(() => {getDataDash()}, [])

    async function prev(){
        dataConsulta.setDate(1);
        dataConsulta.setMonth(dataConsulta.getMonth() - 1);
        setDataConsulta(dataConsulta);
        getDataDash(dataConsulta.toISOString().slice(0, 7))

    }

    async function next(){
        dataConsulta.setDate(1);
        dataConsulta.setMonth(dataConsulta.getMonth() + 1);
        setDataConsulta(dataConsulta);
        getDataDash(dataConsulta.toISOString().slice(0, 7))
    }

    const selectVendedor = (value: any) => {
        setVendedorSelected(value.value);
        getDataDash(dataConsulta.toISOString().slice(0, 7), value.value);
    }

    /*if (loading){
        return <span className="flex items-center justify-center h-7">
                  <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                </span>

    }*/

    return(
        <PageContainer pageTitle={'Visão Geral'}>
            {
                loading ?
                    <span className="flex items-center justify-center h-7">
                      <svg className="animate-spin -ml-1 mr-3 h-[14px] w-[14px] text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                    </span>
                    :
                    <>
                        <div className={'md:flex md:justify-between'}>
                            <div className={'w-[326px]'}>
                                <InputSelect
                                    /*label={"Status"}*/
                                    selected={vendedores.filter((opt: any) => opt.value === vendedorSelected)}
                                    options={vendedores}
                                    onChangeSelect={(e: any) => selectVendedor(e)}
                                    placeholder={"Vendedor"}
                                    labelPlace={'Vendedor'}
                                />
                            </div>
                            <div>
                                <div className={'flex justify-between items-center content-center border rounded-md border-gray-300 p-2 w-[329px] text-[#082a60]'}>
                                    <div className={'cursor-pointer'} onClick={e => prev()}>
                                        <ArrowLeftIcon/>
                                    </div>
                                    <div className={'text-[20px] font-[#082a60] font-bold'}>
                                        {dataString}
                                    </div>
                                    <div className={'cursor-pointer'} onClick={e => next()}>
                                        <ArrowRightIcon/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'hidden lg:inline-flex flex mt-5'}>
                            <div className={'flex flex-col flex-70%'}>
                                <div className={'flex'}>
                                    <div className={'flex flex-col mb-5 mt-1 '}>
                                        <ClienteDashboard cliente={dashboard.cliente}/>
                                        <FaturamentoDashboard faturamento={dashboard.faturamento}/>
                                    </div>
                                    <PaisesDashboard paises={dashboard.paises}/>
                                </div>
                                <ProcessosDashboard processos={dashboard.status_processos}/>
                            </div>
                            <NotificacoesDashboard data={cronogramas.data}/>
                        </div>
                        <div className={'md:hidden flex mt-5'}>
                            <div className={'flex flex-col mb-5 mt-1 content-center'}>
                                <ClienteDashboard cliente={dashboard.cliente}/>
                                <FaturamentoDashboard faturamento={dashboard.faturamento}/>
                                <PaisesDashboard paises={dashboard.paises}/>
                                <ProcessosDashboard processos={dashboard.status_processos}/>
                                <NotificacoesDashboard data={cronogramas.data}/>
                            </div>
                        </div>
                    </>
            }

        </PageContainer>
    )
}