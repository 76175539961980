import React, {useEffect, useState} from "react";
import Alert from "../../../../../helpers/Alert";
import passaporteApi from "../../../../../services/passaporteApi";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import processosApi from "../../../../../services/processosApi";
import HeaderForm from "../../../../../components/headerForm";
import Form from "../../../../../components/form/Form";
import Input from "../../../../../components/input/Input";
import Button from "../../../../../components/button/Button";

export interface IPassaporteAusEstudante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function PassaporteAusEstudante({processo, setProcesso, setTelaNumero, telanumero}: IPassaporteAusEstudante) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        numero: '',
        orgao_emissor: '',
        pais_emissor: '',
        data_emissao: '',
        data_validade: '',
    });
    const getDadosPassaporte = async () => {
        const {data, isError} = await passaporteApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }

    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    useEffect(() => {
        getDadosPassaporte()
    },[])
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await passaporteApi.update(state.id, state) : await passaporteApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        let numero = telanumero + 1;
        setTelaNumero(numero);
    }
    return(
        <>
            <HeaderForm
                titulo={'Passaporte'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form   onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Número do Passaporte'}
                            placeholder={'Número do Passaporte'}
                            large={true}
                            required={true}
                            name={'numero'}
                            setValue={e => handleChange(e,'numero')}
                            value={state.numero}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Orgão emissor'}
                            placeholder={'Orgão emissor'}
                            large={true}
                            name={'orgao_emissor'}
                            setValue={e => handleChange(e,'orgao_emissor')}
                            value={state.orgao_emissor}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'País emissor'}
                            placeholder={'País emissor'}
                            large={true}
                            name={'pais_emissor'}
                            setValue={e => handleChange(e,'pais_emissor')}
                            value={state.pais_emissor}
                            required
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Data de emissão'}
                            placeholder={'Data de emissão'}
                            large={true}
                            type={'date'}
                            name={'data_emissao'}
                            setValue={e => handleChange(e,'data_emissao')}
                            value={state.data_emissao}
                            required
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Data de expiração'}
                            placeholder={'Data de expiração'}
                            large={true}
                            type={'date'}
                            name={'data_validade'}
                            setValue={e => handleChange(e,'data_validade')}
                            value={state.data_validade}
                            required
                        />
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}