import DadosPessoaisEua from "./DadosPessoais";
import {useEffect, useState} from "react";
import DadosConjugeEua from "./DadosConjuge";
import PassaporteEua from "./Passaporte";
import DadosViagemEua from "./DadosViagem";
import DadosEscolaEua from "./DadosEscola";
import DadosContatoBrasil from "./DadosContatoBrasil";
import CusteioViagem from "./CusteioViagem";
import DadosAcompantesEua from "./DadosAcompanhantes";
import DadosAcompantes from "./DadosAcompanhantes";
import DadosViagensAnteriores from "./DadosViagensAnteriores";
import ContatoEUA from "./ContatoEUA";
import DadosDosPais from "./DadosDosPais";
import VistoAnterior from "./VistoAnterior";
import DadosParentesNoPais from "./DadosParentesNoPais";
import DadosOcupacao from "./DadosOcupacao";
import PerguntasAdicionais from "./PerguntasAdicionais";
import EmpregosAnteriores from "./EmpregosAnteriores";
import InformaçõesEducacionais from "./InformacoesEducacionais";
import IdiomaExperienciaInternacional from "./IdiomaExperienciaInternacional";
import PerguntaSeguranca from "./PerguntaSeguranca";
import ComentariosAdicionais from "./ComentariosAdicionais";
import DadosBiometria from "./DadosBiometria";
import Consulado from "./Consulado";
import Agendamento from "./Agendamento";
import Declaracao from "./Declaracao";
import Conclusao from "../Conclusao";

export interface IFormularioEua{
    processo?: any;
    onClickForm?: any;
}
export default function FormularioEua({processo, onClickForm}: IFormularioEua){
    const [process, setProcess] = useState(processo);
    const [telanumero, setTelaNumero] = useState(1);
    const [component, setComponent] = useState(<DadosPessoaisEua processo={process} setTelaNumero={setTelaNumero}  telanumero={telanumero}/>);
    const [dadosViagemAnterior, setDadosViagemAnterior]: any = useState([]);
    const [stateDadosDosPais, setStateDadosDosPais]: any = useState([]);

    const controlleTela = (numero: number) => {

        switch (numero) {
            case 1:
                setComponent(
                        <DadosPessoaisEua
                            processo={process}
                            setProcesso={setProcess}
                            setTelaNumero={setTelaNumero}
                            telanumero={telanumero}
                        />
                    );
                break;
            case 2:
                setComponent(
                    <DadosConjugeEua
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 3:
                setComponent(
                    <PassaporteEua
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 4:
                setComponent(
                    <DadosViagemEua
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 5:
                setComponent(
                    <DadosEscolaEua
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 6:
                setComponent(
                    <DadosContatoBrasil
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 7:
                setComponent(
                    <CusteioViagem
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 8:
                setComponent(
                    <DadosAcompantes
                        processo={process}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 9:
                setComponent(
                    <DadosViagensAnteriores
                        setProcesso={setProcess}
                        setDadosViagemAnterior={setDadosViagemAnterior}
                        processo={process}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 10:
                setComponent(
                    <VistoAnterior
                        setProcesso={setProcess}
                        processo={process}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}

                    />
                );
                break;
            case 11:
                setComponent(
                    <ContatoEUA
                        dadosViagemAnterior={dadosViagemAnterior}
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 12:
                setComponent(
                    <DadosDosPais
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        setStateDadosDosPais={setStateDadosDosPais}
                    />
                );
                break;
            case 13:
                setComponent(
                    <DadosParentesNoPais
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 14:
                setComponent(
                    <DadosOcupacao
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        dadosDosPais={stateDadosDosPais}
                    />
                );
                break;
            case 15:
                setComponent(
                    <PerguntasAdicionais
                        processo={process}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        setProcesso={setProcess}
                    />
                );
                break;
            case 16:
                setComponent(
                    <EmpregosAnteriores
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 17:
                setComponent(
                    <InformaçõesEducacionais
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 18:
                setComponent(
                    <IdiomaExperienciaInternacional
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 19:
                setComponent(
                    <PerguntaSeguranca
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 20:
                setComponent(
                    <ComentariosAdicionais
                        processo={process}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        setProcesso={setProcess}
                    />
                );
                break;
            case 21:
                setComponent(
                    <DadosBiometria
                        processo={process}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        setProcesso={setProcess}
                    />
                );
                break;
            case 22:
                setComponent(
                    <Consulado
                        processo={process}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        setProcesso={setProcess}
                    />
                );
                break;
            case 23:
                setComponent(
                    <Agendamento
                        processo={process}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        setProcesso={setProcess}
                    />
                );
                break;
            case 24:
                setComponent(
                    <Declaracao
                        processo={process}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        setProcesso={setProcess}
                    />
                );
                break;
            case 25:
                setComponent(
                    <Conclusao/>
                );
                break;
        }
    }

    useEffect(() => {
        controlleTela(telanumero);
    }, [telanumero])


    return (component)
}