import React, {useEffect, useState} from "react";
import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import permissaoEstudosApi from "../../../../../services/permissaoEstudosApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import informacoesEducacionaisApi from "../../../../../services/informacoesEducacionaisApi";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";

export interface IDadosEducacionaisCa{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosEducacionaisCa({processo, setProcesso, setTelaNumero, telanumero}: IDadosEducacionaisCa) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome_instituicao: '',
        cidade: '',
        pais: '',
        nome_curso: '',
        data_inicio: '',
        data_fim: '',
        informacoes_educacionais: 0,
    });
    const getData = async () => {
        const {data, isError} = await informacoesEducacionaisApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                const response = data.data;
                setState(
                    {
                        id: response.id,
                        processo_id: response.processo_id,
                        nome_instituicao: response.nome_instituicao,
                        cidade: response.cidade,
                        pais: response.pais,
                        nome_curso: response.nome_curso,
                        data_inicio: response.data_inicio,
                        data_fim: response.data_fim,
                        informacoes_educacionais: response.processo.informacoes_educacionais,
                    }
                );
            }
        }

    }

    useEffect(() => {
        getData()
    }, []);
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await informacoesEducacionaisApi.update(state.id, state) : await informacoesEducacionaisApi.create(state);
        if (isError){

            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={processo.tipo_visto_id === 3 ? "Permissão de estudos" : "Visto de residente temporário"}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'col-span-1 flex flex-col'}>
                        <label
                            className="text-[16px] font-medium text-gray-900 dark:text-gray-300"
                        >
                            Você realizou algum estudo após o Ensino Médio (incluindo universidades e estudos técnicos)
                        </label>
                        <div className={'flex mt-[1.5rem] gap-4'}>
                            <div className={'flex'}>
                                <input
                                    onChange={e => handleChange(1,'informacoes_educacionais')}
                                    value={1}
                                    id="checked-informacoes_educacionais-1"
                                    type="radio"
                                    checked={state.informacoes_educacionais === 1 }
                                    className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="checked-informacoes_educacionais-1"
                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Sim
                                </label>
                            </div>
                            <div className={'flex'}>
                                <input
                                    checked={state.informacoes_educacionais === 0 }
                                    onChange={e => handleChange(0,'informacoes_educacionais')}
                                    id="checked-informacoes_educacionais"
                                    type="radio"
                                    value={0}
                                    className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="checked-informacoes_educacionais"
                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Não
                                </label>
                            </div>
                        </div>
                        {
                            state.informacoes_educacionais ?
                                <>
                                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                        <Input
                                            className={'md:col-span-1'}
                                            required={true}
                                            large={true}
                                            type={'date'}
                                            label={'Data de início'}
                                            name={'data_inicio'}
                                            setValue={e => handleChange(e,'data_inicio')}
                                            value={state.data_inicio}
                                        />
                                        <Input
                                            className={'md:col-span-1'}
                                            required={true}
                                            large={true}
                                            type={'date'}
                                            label={'Data de término'}
                                            name={'data_fim'}
                                            setValue={e => handleChange(e,'data_fim')}
                                            value={state.data_fim}
                                        />
                                        <Input
                                            className={'md:col-span-1'}
                                            required={true}
                                            large={true}
                                            label={'Área de Estudos'}
                                            name={'nome_curso'}
                                            setValue={e => handleChange(e,'nome_curso')}
                                            value={state.nome_curso}
                                        />
                                    </div>
                                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                        <Input
                                            className={'md:col-span-1'}
                                            required={true}
                                            large={true}
                                            label={'Nome da instituição'}
                                            name={'nome_instituicao'}
                                            setValue={e => handleChange(e,'nome_instituicao')}
                                            value={state.nome_instituicao}
                                        />
                                        <Input
                                            className={'md:col-span-1'}
                                            required={true}
                                            large={true}
                                            label={'Cidade'}
                                            name={'cidade'}
                                            setValue={e => handleChange(e,'cidade')}
                                            value={state.cidade}
                                        />
                                        <Input
                                            className={'md:col-span-1'}
                                            required={true}
                                            large={true}
                                            label={'País da Instituição'}
                                            name={'pais'}
                                            setValue={e => handleChange(e,'pais')}
                                            value={state.pais}
                                        />
                                    </div>
                                </>
                                :
                                ''
                        }
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}