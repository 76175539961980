import React, {useEffect, useState} from "react";
import processosApi from "../../../../../services/processosApi";
import Alert from "../../../../../helpers/Alert";
import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import InputSelect from "../../../../../components/input/InputSelect";

export interface IDadosViagemCa{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosViagemCa({processo, setProcesso, setTelaNumero, telanumero}: IDadosViagemCa) {
    const [state, setState] = useState({
        motivo_viagem: ''
    });
    const [loading, setLoading] = useState(true);
    const [edit, setEdit] = useState(false);
    const [motivoOptions, setMotivoOptions] = useState([
        { value: 'estudos', label: 'Estudos acima de seis meses' },
        { value: 'highscool', label: 'High School' },
        { value: 'intercambio', label: 'Intercâmbio (Estudo e Trabalho)' },
    ])
    useEffect(()=> {
        getDataProcesso();

    }, [])

    const getDataProcesso = async () => {
        const {data, isError} = await processosApi.one(processo.id);
        if (isError) {
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function (key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message ? data.data.message : data.data);
        }

        const response = data.data;
        if (Object.values(response).length){
            if (response.tipo_visto_id === 4){
                setMotivoOptions([
                    { value: 'Turismo', label: 'Turismo' },
                    { value: 'Negocios', label: 'Negócios' },
                    { value: 'estudos', label: 'Estudo por menos de seis meses' },
                ])

                setState({motivo_viagem: response.motivo_viagem ? response.motivo_viagem : 'Turismo'})
            }else{
                setState({motivo_viagem: response.motivo_viagem ? response.motivo_viagem : 'estudos'})
            }
            setEdit(!response.motivo_viagem);
        }
        setLoading(false)
    }
    async function onSubmit() {
        Alert.await("Salvando dados");
        const data = await processosApi.update(processo.id, state);
        if (data.isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (edit){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    if (loading) return <>Carregando</>

    return(
        <>
            <HeaderForm
                titulo={'Dados sobre a viagem'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Motivo da viagem"}
                                selected={motivoOptions.filter((opt: any) => opt.value === state.motivo_viagem)}
                                options={motivoOptions}
                                onChangeSelect={(e: any) => setState({motivo_viagem: e.value})}
                            />
                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}