import React, {useEffect, useState} from "react";
import informacoesEducacionaisApi from "../../../../../services/informacoesEducacionaisApi";
import idiomaExperienciaApi from "../../../../../services/idiomaExperienciaApi";
import Alert from "../../../../../helpers/Alert";
import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import processosApi from "../../../../../services/processosApi";

export interface IIdiomaExperienciaInternacional{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}
export default function IdiomaExperienciaInternacional({processo, setProcesso, setTelaNumero, telanumero}: IIdiomaExperienciaInternacional) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        idiomas: '',
        paises_visitados: ''
    });
    useEffect(() => {getIdiomaExperiencias()},[])
    const getIdiomaExperiencias = async () => {
        const {data, isError} = await idiomaExperienciaApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    async function onSubmit() {
        const {data, isError} = state.id ? await idiomaExperienciaApi.update(state.id, state) : await idiomaExperienciaApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={'Idiomas e experiência internacional'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />

            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div>
                        <div className={'mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Quais Idiomas você fala? <span className={'text-[#c22929]'}>*</span>
                            </label>
                            <textarea
                                required={true}
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'idiomas'}
                                onChange={e => handleChange(e.target.value,'idiomas')}
                                value={state.idiomas ? state.idiomas : ""}
                            />
                            <div className={'text-sm text-gray-400'}>Informe também seu idioma nativo. Desta forma, esta pergunta deve ter pelo menos um idioma.</div>
                        </div>
                    </div>
                    <div>
                        <div className={'mt-3 flex flex-col'}>
                            <label
                                className=" font-medium"
                            >
                                Liste os países que você visitou nos últimos cinco anos
                            </label>
                            <textarea
                                /*required={true}*/
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'paises_visitados'}
                                onChange={e => handleChange(e.target.value,'paises_visitados')}
                                value={state.paises_visitados ? state.paises_visitados : ""}
                            />
                            <div className={'text-sm text-gray-400'}>Caso não tenha visitado nenhum país, deixe em branco.</div>
                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}