import React, {useEffect, useState} from "react";
import conjugeApi from "../../../../../services/conjugeApi";
import Alert from "../../../../../helpers/Alert";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import processosApi from "../../../../../services/processosApi";
import conjugeAnteriorApi from "../../../../../services/conjugeAnteriorApi";
import HeaderForm from "../../../../../components/headerForm";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";

export interface IConjugeAnteriorCa{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function ConjugeAnteriorCa({processo, setProcesso, setTelaNumero, telanumero}: IConjugeAnteriorCa) {
    const [state, setState] = useState({
        id: '',
        cliente_id: processo.cliente_id,
        nome: '',
        sobrenome: '',
        nascimento: '',
        tipo_uniao: 'uniao_estavel',
        data_inicio: '',
        data_fim: '',
    });

    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    useEffect(() => {
        getData()
    }, []);

    const getData = async () => {
        const {data, isError} = await conjugeAnteriorApi.one(processo.cliente_id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await conjugeAnteriorApi.update(state.id, state) : await conjugeAnteriorApi.create(state);

        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }

        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados do cônjuge anterior'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome '}
                            placeholder={'Nome'}
                            large={true}
                            name={'nome'}
                            setValue={e => handleChange(e,'nome')}
                            value={state.nome}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Sobrenome '}
                            placeholder={'Sobrenome'}
                            large={true}
                            name={'sobrenome'}
                            setValue={e => handleChange(e,'sobrenome')}
                            value={state.sobrenome}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Data de nascimento '}
                            type={'date'}
                            placeholder={'Data de nascimento'}
                            large={true}
                            name={'nascimento'}
                            setValue={e => handleChange(e,'nascimento')}
                            value={state.nascimento}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1 flex flex-col'}>
                            <label
                                className="text-[16px] font-medium text-gray-900 dark:text-gray-300"
                            >
                                Tipo de Relacionamento
                            </label>
                            <div className={'flex mt-[1.5rem] gap-4'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange('uniao_estavel','tipo_uniao')}
                                        value={'uniao_estavel'}
                                        id="checked-tipo_uniao-1"
                                        type="radio"
                                        checked={state.tipo_uniao === 'uniao_estavel' }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-tipo_uniao-1"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        União estável
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.tipo_uniao === 'casamento' }
                                        onChange={e => handleChange('casamento','tipo_uniao')}
                                        id="checked-tipo_uniao"
                                        type="radio"
                                        value={'casamento'}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-tipo_uniao"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Casamento
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Data do início do relacionamento '}
                            type={'date'}
                            placeholder={'Data do início do relacionamento'}
                            large={true}
                            name={'data_inicio'}
                            setValue={e => handleChange(e,'data_inicio')}
                            value={state.data_inicio}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Data do término do relacionamento '}
                            type={'date'}
                            placeholder={'Data do término do relacionamento'}
                            large={true}
                            name={'data_fim'}
                            setValue={e => handleChange(e,'data_fim')}
                            value={state.data_fim}
                        />
                    </div>
                </div>

                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}