import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import familiaresBrasilApi from "../../../../../services/familiaresBrasilApi";
import dadosFinanceirosApi from "../../../../../services/dadosFinanceirosApi";
import Alert from "../../../../../helpers/Alert";
import informacoesEducacionaisApi from "../../../../../services/informacoesEducacionaisApi";
import processosApi from "../../../../../services/processosApi";
import Input from "../../../../../components/input/Input";

export interface IInformacoesFinanceirasAus{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
    informacoesFamiliaresBrasil?: any;
}

export default function InformacoesFinanceirasAus({processo, setProcesso, setTelaNumero, telanumero, informacoesFamiliaresBrasil}: IInformacoesFinanceirasAus) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        quem_custeara: '',
        renda_suficiente: 0,
        valor_total: '',
        parte_emprestada: 0,
        informacao_parte_emprestada: '',
        parte_emprestada_condicionalmente: 0,
        informacao_parte_emprestada_condicionalmente: '',
        como_ter_dinheiro: '',
        patrocinado: '',
    })
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const getDados = async () => {
        const {data, isError} = await dadosFinanceirosApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }

    }
    useEffect(() => {
        getDados()
    },[])
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await dadosFinanceirosApi.update(state.id, state) : await dadosFinanceirosApi.create(state);
        if (isError){

            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Informações financeiras'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Quem irá custear a viagem e o processo de visto?'}
                            placeholder={'Quem irá custear a viagem e o processo de visto?'}
                            large={true}
                            required={true}
                            name={'quem_custeara'}
                            setValue={e => handleChange(e,'quem_custeara')}
                            value={state.quem_custeara}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>

                        <div className={'flex flex-col col-span-2'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Você afirma ter renda suficiente para arcar com a viagem e o curso para a Austrália?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'renda_suficiente')}
                                            value={1}
                                            id="checked-renda_suficiente-s"
                                            type="radio"
                                            checked={state.renda_suficiente === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-renda_suficiente-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.renda_suficiente === 0 }
                                            onChange={e => handleChange(0,'renda_suficiente')}
                                            id="checked-renda_suficiente-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-renda_suficiente-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>

                        <Input
                            className={'col-span-2'}
                            label={'Qual o total de renda que você tem disponível para a viagem?'}
                            placeholder={'Qual o total de renda que você tem disponível para a viagem?'}
                            large={true}
                            name={'valor_total'}
                            setValue={e => handleChange(e,'valor_total')}
                            value={state.valor_total}
                        />
                    </div>

                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>

                        <div className={'flex flex-col col-span-2'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Parte dessa renda é emprestada?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'parte_emprestada')}
                                        value={1}
                                        id="checked-parte_emprestada-s"
                                        type="radio"
                                        checked={state.parte_emprestada === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-parte_emprestada-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.parte_emprestada === 0 }
                                        onChange={e => handleChange(0,'parte_emprestada')}
                                        id="checked-parte_emprestada-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-parte_emprestada-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>

                        <Input
                            className={'col-span-2'}
                            label={'Caso tenha respondido "Sim", quanto?'}
                            placeholder={'Caso tenha respondido "Sim", quanto?'}
                            large={true}
                            name={'informacao_parte_emprestada'}
                            setValue={e => handleChange(e,'informacao_parte_emprestada')}
                            value={state.informacao_parte_emprestada}
                        />
                    </div>

                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>

                        <div className={'flex flex-col md:col-span-2'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Parte desse dinheiro foi emprestado condicionalmente?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'parte_emprestada_condicionalmente')}
                                        value={1}
                                        id="checked-parte_emprestada_condicionalmente-s"
                                        type="radio"
                                        checked={state.parte_emprestada_condicionalmente === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-parte_emprestada_condicionalmente-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.parte_emprestada_condicionalmente === 0 }
                                        onChange={e => handleChange(0,'parte_emprestada_condicionalmente')}
                                        id="checked-parte_emprestada_condicionalmente-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-parte_emprestada_condicionalmente-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>

                        <Input
                            className={'md:col-span-2'}
                            label={'Caso tenha respondido "Sim", quanto?'}
                            placeholder={'Caso tenha respondido "Sim", quanto?'}
                            large={true}
                            name={'informacao_parte_emprestada_condicionalmente'}
                            setValue={e => handleChange(e,'informacao_parte_emprestada_condicionalmente')}
                            value={state.informacao_parte_emprestada_condicionalmente}
                        />
                    </div>

                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-2'}
                            label={'Como você pretende ter acesso a esse dinheiro, enquanto estiver na Austrália?'}
                            placeholder={'Como você pretende ter acesso a esse dinheiro, enquanto estiver na Austrália?'}
                            large={true}
                            name={'como_ter_dinheiro'}
                            setValue={e => handleChange(e,'como_ter_dinheiro')}
                            value={state.como_ter_dinheiro}
                        />
                        <Input
                            className={'md:col-span-2'}
                            label={'A sua viagem será patrocinada por alguma companhia ou empresa?'}
                            placeholder={'A sua viagem será patrocinada por alguma companhia ou empresa?'}
                            large={true}
                            name={'patrocinado'}
                            setValue={e => handleChange(e,'patrocinado')}
                            value={state.patrocinado}
                        />
                    </div>


                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(informacoesFamiliaresBrasil.hospedado ? telanumero-2 : telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}