import passaporteApi from "../../../../../services/passaporteApi";
import React, {useEffect, useState} from "react";
import nivelInglesApi from "../../../../../services/nivelInglesApi";
import Alert from "../../../../../helpers/Alert";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import processosApi from "../../../../../services/processosApi";
import HeaderForm from "../../../../../components/headerForm";
import Form from "../../../../../components/form/Form";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import Button from "../../../../../components/button/Button";

export interface INivelIngles{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function NivelIngles({processo, setProcesso, setTelaNumero, telanumero}: INivelIngles) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        certificado: 0,
        informacao_certificado: 'ielts',
        data_teste: '',
        local_aplicacao: '',
        numero_certificado: '',
        pontuacao: '',
        estudou_outro_pais: 0,
        local_estudo: 'australia',
        curso_realizado: '',
        possui_certificado: 0,
    });
    const optionsInformcaoCertificado = [
        { value: 'ielts', label: 'IELTS' },
        { value: 'toefl_pbt', label: 'TOEFL PBT' },
        { value: 'toefl_ibt', label: 'TOEFL IBT' },
        { value: 'academico', label: 'Inglês Acadêmico' },
        { value: 'cambridge', label: 'Cambridge FCE ou CAE' },
        { value: 'ocupacional', label: 'este de inglês ocupacional' },
    ];
    const optionsLocalEstudo = [
        { value: 'australia', label: 'Austrália' },
        { value: 'canada', label: 'Canadá' },
        { value: 'nova_zelandia', label: 'Nova Zelândia' },
        { value: 'africa', label: 'África do Sul' },
        { value: 'irlanda', label: 'Irlanda' },
        { value: 'reino_unido', label: 'Reino Unido' },
        { value: 'eua', label: 'Estados Unidos' },
    ];
    const getDados = async () => {
        const {data, isError} = await nivelInglesApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }

    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    useEffect(() => {
        getDados()
    },[])
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await nivelInglesApi.update(state.id, state) : await nivelInglesApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        let numero = telanumero + 1;
        setTelaNumero(numero);
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados do seu nível de inglês'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form   onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-2'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Você possui algum certificado de proficiência em inglês, que tenha sido concedido nos últimos 2 anos?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'certificado')}
                                        value={1}
                                        id="checked-certificado-s"
                                        type="radio"
                                        checked={state.certificado === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-certificado-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.certificado === 0 }
                                        onChange={e => handleChange(0,'certificado')}
                                        id="checked-certificado-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-certificado-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Caso tenha respondido Sim, qual?"}
                                selected={optionsInformcaoCertificado.filter((opt: any) => opt.value === state.informacao_certificado)}
                                options={optionsInformcaoCertificado}
                                onChangeSelect={(e: any) => handleChange(e.value, 'informacao_certificado')}
                            />
                        </div>
                    </div>


                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            type={'date'}
                            label={'Qual foi a data da aplicação do teste?'}
                            name={'data_teste'}
                            setValue={e => handleChange(e,'data_teste')}
                            value={state.data_teste}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Localização da aplicação do teste'}
                            name={'local_aplicacao'}
                            setValue={e => handleChange(e,'local_aplicacao')}
                            value={state.local_aplicacao}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'O número de certificação do teste'}
                            name={'numero_certificado'}
                            setValue={e => handleChange(e,'numero_certificado')}
                            value={state.numero_certificado}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            mask={'cellphone'}
                            label={'A pontuação final'}
                            name={'pontuacao'}
                            setValue={e => handleChange(e,'pontuacao')}
                            value={state.pontuacao}
                        />
                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-2'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Você já morou ou estudou inglês em algum país, que não o seu de residência, nos últimos 5 anos?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'estudou_outro_pais')}
                                        value={1}
                                        id="checked-estudou_outro_pais-s"
                                        type="radio"
                                        checked={state.estudou_outro_pais === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-estudou_outro_pais-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.estudou_outro_pais === 0 }
                                        onChange={e => handleChange(0,'estudou_outro_pais')}
                                        id="checked-estudou_outro_pais-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-estudou_outro_pais-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Se sim, onde?"}
                                selected={optionsLocalEstudo.filter((opt: any) => opt.value === state.local_estudo)}
                                options={optionsLocalEstudo}
                                onChangeSelect={(e: any) => handleChange(e.value, 'local_estudo')}
                            />
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Qual curso realizado nesse país?'}
                            name={'curso_realizado'}
                            setValue={e => handleChange(e,'curso_realizado')}
                            value={state.curso_realizado}
                        />
                        <div className={'flex flex-col col-span-2'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Possui um certificado?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'possui_certificado')}
                                        value={1}
                                        id="checked-possui_certificado-s"
                                        type="radio"
                                        checked={state.possui_certificado === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_certificado-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.possui_certificado === 0 }
                                        onChange={e => handleChange(0,'possui_certificado')}
                                        id="checked-possui_certificado-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_certificado-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}