import { useRef } from "react";
import { AiOutlineDownload } from "react-icons/ai";
import { FiTrash2 } from "react-icons/fi";

export type FileInputProps = {
  label?: string;
  className?: string;
  value?: {
    added?: {
      id?: number;
      name: string;
      file: string;
    }[];
    deleted?: {
      id?: number;
      name: string;
      file: string;
    }[];
    files?: {
      id?: number;
      name: string;
      file: string;
    }[]
  }
  setValue(data: {
    added?: {
      id?: number;
      name: string;
      file: string;
    }[];
    deleted?: {
      id?: number;
      name: string;
      file: string;
    }[];
    files?: {
      id?: number;
      name: string;
      file: string;
    }[]
  }): void;
}

export function  FileInput({
  label,
  className,
  setValue,
  value
}: FileInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  function getBase64(file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
 }
 
  return (
    <div className={className}>
      <span className="block  font-medium mb-2">
        {label}
      </span>

      <ul>
        {value?.added?.map((file, i) => (
          <li key={i} className="border  rounded p-2 mb-2 flex items-center justify-between">
            <span>
              {file.name}
            </span>

            <div>
              <button
                className="rounded bg-blue-700  text-white p-1 ml-2"
                type="button"
                onClick={async () => {
                  const link = document.createElement('a');
                  link.href = file.file as any;
                  link.download = file.name;
                  link.click();
                }}
              >
                <AiOutlineDownload />
              </button>

              <button
                className="rounded bg-red-500 text-white p-1 ml-1"
                type="button"
                onClick={() => setValue({
                  ...value,
                  added: value.added!.filter((_, index) => index !== i)
                })}
              >
                <FiTrash2 />
              </button>
            </div>
          </li>
        ))}

        {value?.files?.map((file, i) => (
          <li key={i} className="border  rounded p-2 mb-2 flex items-center justify-between">
            <span>
              {file.name}
            </span>

            <div>
              <button
                className="rounded bg-blue-700  text-white p-1 ml-2"
                type="button"
                onClick={async () => {
                  const link = document.createElement('a');
                  link.href = file.file as any;
                  link.click();
                }}
              >
                <AiOutlineDownload />
              </button>

              <button
                className="rounded bg-red-500 text-white p-1 ml-1"
                type="button"
                onClick={() => setValue({
                  ...value,
                  files: value.files!.filter((_, index) => index !== i),
                  deleted: [...(value.deleted ?? []), file]
                })}
              >
                <FiTrash2 />
              </button>
            </div>
          </li>
        ))}
      </ul>


      <div className="border rounded">
        <button  
          className="bg-[#082a60] text-white font-semibold rounded-tl rounded-bl px-4 py-3"
          type="button"
          onClick={() => inputRef.current?.click()}
        >
          Escolhe arquivo
        </button>

        <span className="pl-3 text-slate-500">
          {!!value?.added?.length || !!value?.files?.length ? `${(value?.added?.length  ?? 0)+ (value?.files?.length ?? 0)} arquivos selecionados` : 'Nenhum arquivo escolhido'}
        </span>

        <input
          multiple
          hidden
          ref={inputRef}
          type="file"
          onChange={async (e) => {
            let d: any = [];
            Array.from(e.target.files!)?.forEach(async (file, i) => {
              const base64 = await getBase64(file);
              d.push({
                name: file.name,
                file: base64
              });

              console.log(d);

              i === (Array.from(e.target.files!).length - 1) && setValue({
                ...(value ?? {}),
                added: [
                  ...(value?.added ?? []),
                  ...d,
                ]
              });
            })
          }}
        />
      </div>
    </div>
  );
}