import { FileInput, ListPageLayout, SearchInput, Select, Textarea } from "../../../components";
import Dates from "../../../helpers/Dates";
import Str from "../../../helpers/Str";
import { entradaApi } from "../../../services";
import Storage from "../../../helpers/Storage";
import InputCurrency from "../../../components/inputCurrency";
import React from "react";

export default function Entradas() {
    const user = Storage.getUserData();
    const tipoId = user ? user.usuario.tipo_id : null;

  return (
    <ListPageLayout 
      title="Entradas"
      actions={entradaApi}
      filters={{
        initial: {
          with: 'cliente',
        },
        form: ({ register }) => <div className="flex items-center gap-3">
          <SearchInput
              placeholder="Pesquisar"
              {...register('cliente_nome')}
          />

          <Select
            multi
            {...register('status')}
            placeholder="Status"
            options={[
              { label: 'Em aberto', value: 'Em Aberto' },
              { label: 'Pago', value: 'Pago' },
            ]}
          />
        </div>
      }}

      table={{
        columns: [
          { title: 'Cliente', render: (rowData) => `${rowData.cliente?.nome} ${rowData.cliente?.sobrenome}` },
          { title: 'Valor', field: 'valor', type: 'currency' },
          { title: 'Data Pagamento', field: 'data', type: 'date' },
          { title: 'Status', render: (rowData) => {
            if (rowData.data) return <span className="bg-green-100 text-green-600 px-3 rounded-full">Pago</span>
            return <span className="bg-red-100 text-red-600 px-3 rounded-full">Em aberto</span>
          }},
        ]
      }}
      functionPago={true}
      register={{
        mode: 'modal',
        title: 'Lançar Entrada',
        saveBtnText: ({ form }) => 'Editar',
        allowedActions: ['update'],
        onOpen: ({ form , setForm }) => {
          setForm({
            ...form,
            /*pago: false,*/
            files: {
              added: [],
              deleted: [],
              files: form.comprovantes?.map(comp => ({
                id: comp.id,
                name: comp.nome_original!,
                file: comp.img!
              }) ?? [])
            },
          });
        },
        form: ({ register, form }) => (
          <div className="grid gap-4 grid-cols-12">
            <div className="col-span-full md:col-span-8 grid">
              <span>Cliente</span>
              <span className="font-semibold">{`${form.cliente?.nome} ${form.cliente?.sobrenome}`}</span>
            </div>

            <div className="col-span-full md:col-span-4 grid">
              <span>ID Processo</span>
              <span className="font-semibold">{form.processo_id}</span>
            </div>
              {
                  (tipoId === 1) ?
                      <InputCurrency
                          label={'Valor'}
                          className={'col-span-full md:col-span-4 grid'}
                          /*value={form?.valor}*/
                          {...register('valor')}
                          decimalSeparator=","
                          decimalScale={2}
                      />
                      :
                      <div className="col-span-full md:col-span-8 grid">
                          <span>Valor</span>
                          <span className="font-semibold">{Str.addCurrencyMask(form?.valor)}</span>
                      </div>
              }

            <div className="col-span-full md:col-span-4 grid">
              <span>Data Pagamento</span>
              <span className="font-semibold">{Dates.convertEnToBr(form?.data)}</span>
            </div>

            <Textarea label="Observação" {...register('observacao')} className="col-span-full" />

            <FileInput 
              label="Comprovantes" 
              className="col-span-full" 
              {...register('files')}
            />
          </div>
        ),
      }}
    />
  );
}
