const Dates = {
    convertEnToBr(dataString: string, delimitador?: string | null, horas?: boolean){
        if (dataString){
            if (horas){
                const newDt = dataString.split(' ');
                return  newDt[0].split('-').reverse().join('/') +' '+ this.adjustHours(newDt[1]);
            }
            if (delimitador){
                const newDt = dataString.split(delimitador);
                return newDt[0].split('-').reverse().join('/')

            }
            return  dataString.split('-').reverse().join('/')
        }
    },
    adjustHours(hours: string){
        if (hours){
            let hArray = hours.split(':');
            return hArray[0]+":"+hArray[1];
        }
    },
    difDay(dateInitial: any, datefinal: any){
        // @ts-ignore
        const diffInMs   = new Date(datefinal) - new Date(dateInitial)
        return diffInMs / (1000 * 60 * 60 * 24);
    },
    addDaysToDate(date: string, days: number){
        if (date && days){
            let res = new Date(date);
            res.setDate(res.getDate() + days);
            return res.toISOString().slice(0, 10);

        }
        return date

    }
};
export default Dates;