import PageContainer from "../../components/container/PageContainer";
import {useNavigate} from "react-router-dom";
import React, {useEffect, useState} from "react";
import {QueryParams} from "../../types";
import Alert from "../../helpers/Alert";
import InputIcon from "../../components/input/InputIcon";
import Button from "../../components/button/Button";
import {ReactComponent as AddIcon} from "../../assets/icone/add.svg";
import clientesApi from "../../services/clientesApi";
import usuariosApi from "../../services/usuariosApi";
import Table from "../../components/table/Table";
import Modal from '../../components/modal/Modal';
import useModal from '../../hooks/useModal';
import Form from "../../components/form/Form";
import Input from "../../components/input/Input";
import InputSelect from "../../components/input/InputSelect";

export default function Usuarios() {
    const navigate = useNavigate();
    const inicialState = {
        id: '',
        name: '',
        email: '',
        tipo_id: 1,
        password: '',
        ativo: 1,
    }
    const [state, setState] = useState(inicialState);
    const [loading, setLoading] = useState(true);
    const [dataPaginate, setDataPaginate] = useState({
        current_page: 1,
        last_page: 2,
        total_data: 0,
        per_page: 0,
    });
    const [data, setData] = useState({});
    const [atualPageData, setAtualPageData] = useState(1);
    const [porPagina, setPorPagina] = useState(10);
    const [consulta, setConsulta] = useState('');
    const { modalProps, open, close } = useModal();
    const columns = [
        { label: "NOME", index: "name", type: 'string'},
        { label: "E-MAIL", index: "email", type: 'string'},
        { label: "TIPO", index: "tipo.nome", type: 'string' },
        { label: "PERFIL", index: "ativo", type: 'perfil' },
    ];
    const [textTitle, setTextTitle] = useState('Adicionar usuário');


    async function getData(atualPage: number, porPagina?: number, busca?: string){
        setLoading(true);

        const params: QueryParams = {
            page: atualPage,
            perPage: porPagina ? porPagina : 10,
            search: busca ? 'usuario='+busca : ''
        }

        const [usuariosData] = await Promise.all([usuariosApi.all(params)]);

        if (!usuariosData.isError){
            const usuario = usuariosData.data.data;
            const paginate = {
                'current_page': usuario.current_page,
                'last_page': usuario.last_page,
                'total_data': usuario.total,
                'per_page': usuario.per_page,
            };
            setDataPaginate(paginate);
            setData(usuario.data);
        }
        setLoading(false);
        Alert.close();
    }

    useEffect(() => {
        Alert.await(`Carregando dados...`);
        getData(dataPaginate.current_page);
    }, []);

    const onPageChange = (e: number) => {
        getData(e, porPagina);
    }

    const onChangePerPage = (e: number) => {
        setPorPagina(e);
        getData(atualPageData, e);
    }

    const onClickTable = (e: any) => {
        setState(e);
        setTextTitle('Editar usuário')
        open()
    }

    const consult = (e: any) => {
        setConsulta(e)

        if (e.length > 3){
            getData(1, porPagina, e);
        }
        if (e.length === 0){
            getData(1)
        }

    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    let tipoUsuarioOptions = [
        { value: 1, label: 'Admin' },
        { value: 2, label: 'Atendente' },
        /*{ value: 3, label: 'Parceiro' },*/
        /*{ value: 4, label: 'Cliente' }*/
    ]

    const onSubmit = async () => {
        Alert.await("Salvando dados");
        /*if (state.id){
            // @ts-ignore
            delete state.password;
        }*/
        if (!state.password){
            // @ts-ignore
            delete state.password;
        }
        const {data, isError} = state.id ? await usuariosApi.update(state.id, state) : await usuariosApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        Alert.close();
        Alert.success("Salvo com sucesso");
        close()
        setState(inicialState);
        getData(dataPaginate.current_page);
        setTextTitle('Adicionar usuário')
    }
    const openModal = () => {
        setState(inicialState);
        open()
        setTextTitle('Adicionar usuário')
    }
    const closeModal = () => {
        setState(inicialState);
        close()
        setTimeout(() => {
            setTextTitle('Adicionar usuário')
        }, 1000);

    }
    return(
        <>
            <PageContainer pageTitle={'Usuarios'}>
                <div className={'flex w-full justify-between'}>
                    <div className={'grid grid-cols-6 gap-4 w-[50%]'}>
                        <InputIcon
                            classNameLabel={'col-span-3'}
                            name={'nome'}
                            setValue={e => consult(e.target.value)}
                            value={consulta}
                            placeholder={'Pesquisar'}
                        />
                    </div>
                    <div>
                        <Button className={'flex bg-[#082a60] text-white w-[146px] h-[48px]'} onClick={() => openModal()}>
                            <AddIcon className="fill-white mr-1 " /> Cadastrar
                        </Button>
                    </div>
                </div>
                {
                    loading ? '' :
                        <div className={'w-full'}>
                            <Table
                                columns={columns}
                                tableData={data}
                                data={dataPaginate}
                                onPageChange={onPageChange}
                                setPerPage={onChangePerPage}
                                onClick={onClickTable}
                                perPage={porPagina}
                            />
                        </div>
                }

            </PageContainer>
            <Modal size="lg" title={textTitle} {...modalProps}>
                <Form onSubmit={e => onSubmit()}>
                    <div className={'grid md:grid-cols-1 gap-4'}>
                        <InputSelect
                            label={"Tipo"}
                            selected={tipoUsuarioOptions.filter((opt: any) => opt.value === state.tipo_id)}
                            options={tipoUsuarioOptions}
                            onChangeSelect={(e: any) => handleChange(e.value, 'tipo_id')}
                        />
                        <Input
                            large={true}
                            required={true}
                            label="Nome"
                            value={state.name}
                            setValue={e => handleChange(e, 'name')}
                        />
                        <Input
                            large={true}
                            required={true}
                            label="E-mail"
                            value={state.email}
                            setValue={e => handleChange(e, 'email')}
                        />

                        <Input
                            large={true}
                            required={!state.id}
                            label="Senha"
                            inputType='password'
                            value={state.password}
                            setValue={e => handleChange(e, 'password')}
                        />
                        <div >
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Ativo
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'ativo')}
                                            value={1}
                                            id="checked-ativo-s"
                                            type="radio"
                                            checked={state.ativo === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-ativo-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.ativo === 0 }
                                            onChange={e => handleChange(0,'ativo')}
                                            id="checked-ativo-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-ativo-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className={'p-3 flex justify-end mt-5'}>
                        <Button onClick={closeModal} className={'border border-[#061f46] text-[#061f46] '}>
                            Cancelar
                        </Button>
                        <Button type='submit' className={'ml-5 text-white bg-[#061f46]'} >
                            Salvar
                        </Button>
                    </div>

                </Form>
            </Modal>
        </>


    )
}