import React, {useEffect, useState} from "react";
import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import Alert from "../../../../../helpers/Alert";
import Input from "../../../../../components/input/Input";
import passaporteApi from "../../../../../services/passaporteApi";
import dadosEscolaApi from "../../../../../services/dadosEscolaApi";
import processosApi from "../../../../../services/processosApi";


export interface IDadosEscolaEua{
    processo?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setProcesso?: any;
}

export default function DadosEscolaEua({processo, setProcesso, setTelaNumero, telanumero}: IDadosEscolaEua) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome: '',
        curso: '',
        endereco: '',
        cidade: '',
        estado: '',
        cep: '',
        documentacao_i10_ds: 0,
        numero_sevis: '',
    });

    useEffect(() => {
        getDadosEscola();

    }, []);


    const getDadosEscola = async () => {
        const {data, isError} = await dadosEscolaApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }

    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await dadosEscolaApi.update(state.id, state) : await dadosEscolaApi.create(state);
        if (isError){
            if (data && data.data && typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data?.data?.message ? data.data.message : data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={'Dados da instuição de ensino nos EUA'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome da escola'}
                            placeholder={'Nome da escola'}
                            large={true}
                            name={'nome'}
                            setValue={e => handleChange(e,'nome')}
                            value={state.nome}                            
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Curso que será realizado'}
                            placeholder={'Curso que será realizado'}
                            large={true}
                            name={'curso'}
                            setValue={e => handleChange(e,'curso')}
                            value={state.curso}
                            
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Endereço da escola'}
                            placeholder={'Endereço da escola'}
                            large={true}
                            name={'endereco'}
                            setValue={e => handleChange(e,'endereco')}
                            value={state.endereco}
                            
                        />
                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Cidade da escola'}
                            placeholder={'Cidade da escola'}
                            large={true}
                            name={'cidade'}
                            setValue={e => handleChange(e,'cidade')}
                            value={state.cidade}                            
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Estado da escola'}
                            placeholder={'Estado da escola'}
                            large={true}
                            name={'estado'}
                            setValue={e => handleChange(e,'estado')}
                            value={state.estado}                            
                        />
                        <Input
                            className={'col-span-1'}
                            label={'CEP da escola'}
                            placeholder={'CEP da escola'}
                            large={true}
                            name={'cep'}
                            setValue={e => handleChange(e,'cep')}
                            value={state.cep}                            
                        />
                    </div>
                    <div className={'flex flex-col mt-5'}>
                        <label
                            className="text-sm font-medium text-gray-900 dark:text-gray-300"
                        >
                            Você já possui o documento I-20 ou DS-2019?
                        </label>
                        <div className={'flex  gap-4 mt-[1rem]'}>
                            <div className={'flex'}>
                                <input
                                    onChange={e => handleChange(1,'documentacao_i10_ds')}
                                    value={1}
                                    id="checked-documentacao_i10_ds-s"
                                    type="radio"
                                    checked={state.documentacao_i10_ds === 1 }
                                    className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="checked-documentacao_i10_ds-s"
                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Sim
                                </label>
                            </div>
                            <div className={'flex'}>
                                <input
                                    checked={state.documentacao_i10_ds === 0 }
                                    onChange={e => handleChange(0,'documentacao_i10_ds')}
                                    id="checked-documentacao_i10_ds-n"
                                    type="radio"
                                    value={0}
                                    className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                />
                                <label
                                    htmlFor="checked-documentacao_i10_ds-n"
                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Não
                                </label>
                            </div>
                        </div>

                    </div>
                    {
                        state.documentacao_i10_ds ?
                            <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                                <Input
                                    className={'col-span-1'}
                                    label={'Número da SEVIS'}
                                    placeholder={'Número da SEVIS'}
                                    large={true}
                                    name={'numero_sevis'}
                                    setValue={e => handleChange(e,'numero_sevis')}
                                    value={state.numero_sevis}
                                    required
                                />
                            </div>
                            :
                            ''
                    }
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>

        </>
    )
}