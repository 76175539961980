import {useEffect, useState} from "react";
import DadosPessoaisCa from "../FormulariosCa/DadosPessoais";
import DadosPessoaisAusVisitante from "./DadosPessoaisAus";
import PassaporteAusVisitante from "./PassaporteAusVisitante";
import InformacoesContato from "./InformacoesContato";
import DadosViagemAusVisitante from "./DadosViagemAusVisitante";
import OcupacaoAusVisitante from "./OcupacaoAusVisitante";
import ComprovanteRendaAus from "./ComprovanteRendaAus";
import AutorizacaoMenores from "./AutorizacaoMenores";
import DeclaracaoSaudeAus from "./DeclaracaoSaudeAus";
import DeclaracaoCaraterPessoalAus from "./DeclaracaoCaraterPessoalAus";
import DeclaracaoFinalAus from "./DeclaracaoFinalAus";
import Conclusao from "../Conclusao";

export interface IFormularioAusV{
    processo?: any;
}
export default function FormularioAusV({processo}: IFormularioAusV){
    const [process, setProcess] = useState(processo);
    const [telanumero, setTelaNumero] = useState(1);
    const [component, setComponent] = useState(<DadosPessoaisAusVisitante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
    useEffect(() => {
        controlleTela(telanumero);
    }, [telanumero])

    const controlleTela = (numero: number) =>{
        switch (numero) {
            case 1:
                setComponent(<DadosPessoaisAusVisitante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 2:
                setComponent(<PassaporteAusVisitante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 3:
                setComponent(<InformacoesContato processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 4:
                setComponent(<DadosViagemAusVisitante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 5:
                setComponent(<OcupacaoAusVisitante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 6:
                setComponent(<ComprovanteRendaAus processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 7:
                setComponent(<AutorizacaoMenores processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 8:
                setComponent(<DeclaracaoSaudeAus processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 9:
                setComponent(<DeclaracaoCaraterPessoalAus processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 10:
                setComponent(<DeclaracaoFinalAus processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 11:
                setComponent(<Conclusao/>);
                break;
        }
    }
    return (component)
}