import { useState } from 'react';

export interface UseFormProps<T> {
  validator?: (formData: T) => boolean;
  initialData?: Partial<T>;
}

export type UseFormData<T> = ReturnType<typeof useForm<T>>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useForm<T = any>(params?: UseFormProps<T>) {
  const [form, setForm] = useState<T>((params?.initialData ?? {}) as T);
  const [errors, setErrors] = useState({} as {
    [key in keyof T]?: string;
  });
  const isFormInvalid = params?.validator ? params?.validator(form) : false;

  function handleChange(value: string | number | boolean, name: keyof typeof form) {
    setForm({ ...form, [name]: value });
  }

  function register(field: keyof T) {
    return {
      value: form[field] as any,
      setValue: (value: any) => handleChange(value, field),
      error: errors[field] ?? undefined,
    }
  }

  return {
    form,
    setForm,
    errors,
    setErrors,
    handleChange,
    isFormInvalid,
    register
  };
}
