
export default function Conclusao() {

    return(
        <div className={'flex h-[60vh] justify-center'}>
            <div className={'flex flex-col items-center justify-center'}>
                <div className={'text-[40px]'}>Parabéns!</div>
                <div className={'text-[35px]'}>Você concluiu o formulário</div>
                <div className={'text-[25px]'}>Em breve nossa equipe entrará em contato com você</div>
            </div>
        </div>
    )
}