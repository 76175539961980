import PageContainer from "../../components/container/PageContainer";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import CardAtividades from "../../components/cardAtividades";
import cronogramaApi from "../../services/cronogramaApi";
import {createRef, useEffect, useRef, useState} from "react";
import situacaoProcessoApi from "../../services/situacaoProcessoApi";
import processosApi from "../../services/processosApi"; // a plugin!

import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import { AiOutlineLoading } from "react-icons/ai";
import './index.css';
import { GoDotFill } from "react-icons/go";
import interactionPlugin from '@fullcalendar/interaction';
import React from "react";
import Dates from "../../helpers/Dates";
export interface IFullCalendarComponent {
    calendarData: any;
    onClickDay: any;
    functionPrev: any;
    functionNext: any;
    getDataCalendar: any;
}
export default function FullCalendarComponent({
  calendarData,
  onClickDay,
  functionPrev,
  functionNext,
  getDataCalendar
}:IFullCalendarComponent) {
    const hoje = new Date().toISOString().slice(0, 10);

    const calendarRef = useRef<FullCalendar>(null!);
    function renderEventContent(eventInfo: any) {
        let corDotCalendario = ''

        if (eventInfo.event.extendedProps.concluido){
            corDotCalendario = '#28a44b'
        }else{
            corDotCalendario = '#c22929'
        }
        /*const difDias = Dates.difDay(hoje, eventInfo.event.startStr).toString();
        let corDotCalendario = ''
        if (parseInt(difDias) < 0){
            corDotCalendario = '#c22929'
        }
        if (parseInt(difDias) === 0){
            corDotCalendario = '#0f4eb3'
        }
        if (parseInt(difDias) > 0){
            corDotCalendario = '#28a44b'
        }*/
        return (
            <div className={'text-[#061f46] flex items-center'}>
                <GoDotFill size={17} color={corDotCalendario}/> {eventInfo.event.title} {eventInfo.event.title > 1 ? 'Eventos' : 'Evento'}
            </div>
        )
    }

    async function next(e?: any) {
        functionNext();
        let currentDate = calendarRef.current.getApi();
        currentDate.next();
    }

    /*const next = (e?: any) => {
        let currentDate = calendarRef.current.getApi();
        currentDate.next();
        functionNext();

    }*/
    const prev = (e?: any) => {
        functionPrev();
        let currentDate = calendarRef.current.getApi();
        currentDate.prev();
    }

    return(
        <FullCalendar
            titleFormat={{ year: 'numeric', month: 'long' }}
            ref={calendarRef}
            locale={'pt-br'}
            plugins={[ dayGridPlugin, interactionPlugin ]}
            initialView="dayGridMonth"
            dateClick={(info) => onClickDay(info)}
            contentHeight={600}
            eventContent={renderEventContent}
            eventClick={(info) => onClickDay(info)}
            events={calendarData}
            customButtons={{
                prev: {
                    click: function(e) {
                        prev()
                    },
                },
                next: {
                    click: function() {
                        next()
                    },
                },
            }}
            /*events={
                (fetchInfo, successCallback, failureCallback) => getDataCalendar(fetchInfo, successCallback, failureCallback)
            }*/
            buttonText={
                {
                    today:    'Hoje',
                    month:    'Mês',
                    week:     'Semana',
                    day:      'Dia',
                    list:     'Lista'
                }
            }
            headerToolbar={
                {
                    start: 'prev', // will normally be on the left. if RTL, will be on the right
                    center: 'title',
                    end: 'next' // will normally be on the right. if RTL, will be on the left
                }
            }
            dayCellClassNames={'teste'}
            dayCellContent={function(arg){
                return(
                    <div className={'flex w-full items-center justify-between'}>
                        <div className={'days-header font-semibold'}>{arg.dayNumberText}</div>
                        {arg.isToday ? <div className={'text-[16px] font-bold'}>Hoje</div> : ''}
                    </div>
                )
            }}
        />
    )
}