import {useEffect, useState} from "react";
import DadosPessoaisAusEstudante from "./DadosPessoaisAusEstudante";
import PassaporteAusEstudante from "./PassaporteAusEstudante";
import DadosViagemAusEstudante from "./DadosViagemAusEstudante";
import DadosViageEducacionalAusEstudante from "./DadosViageEducacionalAusEstudante";
import NivelIngles from "./NivelIngles";
import DadosVistosAnterioresAusEstudante from "./DadosVistosAnterioresAusEstudante";
import OcupacaoAusEstudante from "./OcupacaoAusEstudante";
import FamiliaresBrasil from "./FamiliaresBrasil";
import SegundoContatoPais from "./SegundoContatoPais";
import InformacoesFinanceirasAus from "./InformacoesFinanceirasAus";
import AssistenciaMedicaAusEstudante from "./AssistenciaMedicaAusEstudante";
import DeclaracaoSaudeAusEstudante from "./DeclaracaoSaudeAusEstudante";
import DeclaracaoCaraterPessoal from "./DeclaracaoCaraterPessoal";
import AutorizacaoMenorAusEstudante from "./AutorizacaoMenorAusEstudante";
import InformacoesAdicionaisAusEstudante from "./InformacoesAdicionaisAusEstudante";
import DeclaracaoFinalAusEstudante from "./DeclaracaoFinalAusEstudante";
import Conclusao from "../Conclusao";

export interface IFormularioAusV{
    processo?: any;
}
export default function FormularioAusEstudante({processo}: IFormularioAusV){
    const [process, setProcess] = useState(processo);
    const [informacoesFamiliaresBrasil, setInformacoesFamiliaresBrasil]: any = useState([]);
    const [telanumero, setTelaNumero] = useState(1);
    const [component, setComponent] = useState(<DadosPessoaisAusEstudante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
    useEffect(() => {
        controlleTela(telanumero);
    }, [telanumero])
    const controlleTela = (numero: number) =>{
        switch (numero) {
            case 1:
                setComponent(<DadosPessoaisAusEstudante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 2:
                setComponent(<PassaporteAusEstudante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 3:
                setComponent(<DadosViagemAusEstudante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 4:
                setComponent(<DadosViageEducacionalAusEstudante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 5:
                setComponent(<NivelIngles processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 6:
                setComponent(<DadosVistosAnterioresAusEstudante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 7:
                setComponent(<OcupacaoAusEstudante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 8:
                setComponent(<FamiliaresBrasil setInformacoesFamiliaresBrasil={setInformacoesFamiliaresBrasil} processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 9:
                setComponent(<SegundoContatoPais processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 10:
                setComponent(<InformacoesFinanceirasAus informacoesFamiliaresBrasil={informacoesFamiliaresBrasil} processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 11:
                setComponent(<AssistenciaMedicaAusEstudante  processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 12:
                setComponent(<DeclaracaoSaudeAusEstudante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 13:
                setComponent(<DeclaracaoCaraterPessoal processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 14:
                setComponent(<AutorizacaoMenorAusEstudante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 15:
                setComponent(<InformacoesAdicionaisAusEstudante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 16:
                setComponent(<DeclaracaoFinalAusEstudante processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 17:
                setComponent(<Conclusao/>);
                break;
        }
    };
    return(component)
}