import CurrencyInput from "react-currency-input-field";
import Str from "../../../helpers/Str";
import React from "react";
import {IInputCurrency} from "../../../types";

export default function InputCurrency({
    label,
    value,
    onChange,
    onBlur,
    className,
    name,
    setValue,
    decimalSeparator,
    thousandSeparator,
    decimalScale,
    required,
    error
}: IInputCurrency){
    return(
        <div className={`${className}`}>
            {
                label  ?
                    <label
                        className="text-gray-800 font-medium"
                    >
                        {label}
                    </label>
                    :
                    ''
            }

            <CurrencyInput
                id="input-example"
                className={Str.tw(
                    'py-2 px-1',
                    'bg-light border border-border rounded-md outline-none px-3  w-full',
                    'focus:bg-background focus:border-confianca transition'
                )}
                intlConfig={{ locale: 'pt-BR', currency: 'BRL' }}
                name={name}
                value={typeof value === 'number' ? value : value?.replace('.', ',')}
                decimalsLimit={2}
                onValueChange={(value, name) => {
                    value = value?.replace(',', '.');
                    setValue ? setValue(value) : onChange && onChange(value, name);
                }}
                onBlur={onBlur}
                decimalSeparator=","
                groupSeparator="."
                required={required}
            />
            {!!error?.length && <span className="text-red-600 text-sm">{error}</span>}
        </div>
    )
}