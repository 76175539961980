import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import processosApi from "../../../../../services/processosApi";
import Alert from "../../../../../helpers/Alert";

export interface IInformacoesAdicionaisAusEstudante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function InformacoesAdicionaisAusEstudante({processo, setProcesso, setTelaNumero, telanumero}: IInformacoesAdicionaisAusEstudante) {
    const [state, setState] = useState({
        processo_id: processo.id,
        comentario_adicional: processo.comentario_adicional
    });
    const [atualizacao, setAtualizacao] = useState(false);
    async function onSubmit() {
        const {data, isError} = await processosApi.update(state.processo_id, state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!atualizacao){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    const getDataProcesso = async () => {
        const {data, isError} = await processosApi.one(processo.id);
        if (!isError) {
            if (Object.values(data.data).length) {
                setProcesso(data.data);
                setState({
                    processo_id: processo.id,
                    comentario_adicional: data.data.comentario_adicional,
                })
                setAtualizacao(!!data.data.comentario_adicional);
            }
        }
    }
    useEffect(() => {
        getDataProcesso()
    }, [])
    return(
        <>
            <HeaderForm
                titulo={'Comentários adicionais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'mt-3 md:col-span-2 flex flex-col'}>
                            <label
                                className="text-sm font-medium"
                            >
                                Alguma outra informação relevante que deseje comentar?
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'comentario_adicional'}
                                onChange={e => setState({...state, ['comentario_adicional']: e.target.value.toUpperCase()})}
                                value={state.comentario_adicional}
                            />
                        </div>
                    </div>
                </div>

                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}