import Navbar from "../../components/container/NavBar";
import React, {useEffect, useState} from "react";
import Alert from "../../helpers/Alert";
import {QueryParams} from "../../types";
import processosApi from "../../services/processosApi";
import Table from "../../components/table/Table";
import {useNavigate} from "react-router-dom";
import Storage from "../../helpers/Storage";
import Modal from '../../components/modal/Modal';
import useModal from '../../hooks/useModal';
import Button from "../../components/button/Button";

// Core viewer
import { Viewer } from '@react-pdf-viewer/core';

// Plugins
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import { Worker } from '@react-pdf-viewer/core';
import usuariosApi from "../../services/usuariosApi";



export default function ClienteFinal() {
    //console.log(pdf);
    const [userData, setUserData]: any = useState(Storage.getUserData());
    const { modalProps, open, close } = useModal();

    const navigate = useNavigate();
    const [data, setData] = useState({});
    const [atualPageData, setAtualPageData] = useState(1);
    const [porPagina, setPorPagina] = useState(10);
    const [loading, setLoading] = useState(true);
    const [dataPaginate, setDataPaginate] = useState({
        current_page: 1,
        last_page: 2,
        total_data: 0,
        per_page: 0,
    });
    const [tableOrder, setTableOrder] = useState({
        field: 'data_prevista_viagem',
        order: 'desc'
    });

    useEffect(() => {
        Alert.await(`Carregando dados...`);
        setLoading(true);
        getData(dataPaginate.current_page);
        if (!userData?.usuario.data_aceite_termos){
            open()
        }
    }, []);

    async function getData(atualPage: number, porPagina?: number, busca?: string, oderData?: {field: string, order: string}){

        const params: QueryParams = {
            page: atualPage,
            perPage: porPagina ? porPagina : 10,
            search: busca,
            /*relations: 'cliente.membrosFamiliares',*/
            orderBy: oderData ? oderData.field+','+oderData.order : tableOrder.field+','+tableOrder.order,
        }
        const [processos] = await  Promise.all([processosApi.getClientProcessos(params)]);

        if (!processos.isError){
            const dataProcessos = processos.data.data;
            const paginate = {
                'current_page': dataProcessos.current_page,
                'last_page': dataProcessos.last_page,
                'total_data': dataProcessos.total,
                'per_page': dataProcessos.per_page,
            };
            setDataPaginate(paginate);
            setData(dataProcessos.data);
        }
        setLoading(false);
        Alert.close();
    }
    const onClickTable = (e: any) => {

        if (userData.usuario.data_aceite_termos){
            navigate(`/processos/${e.id}`);
        }else{
            open()
        }
        //navigate(`/processos/${e.id}`);
    }
    const columns = [
        { label: "NOME", index: "cliente.nome+cliente.sobrenome", type: 'string', order: true},
        { label: "VINCULO", index: "id_cliente_responsavel", type: 'vinculo', order: false},
        { label: "País", index: "pais.nome", type: 'string', order: false},
        { label: "Data VIAGEM", index: "data_prevista_viagem", type: 'date', order: true },
        { label: "TIPO VISTO", index: "tipo_visto.nome", type: 'string', order: false },
        { label: "Preencher formulário", index: "button", type: 'button', onCkick: onClickTable, order: false },
    ];

    const onPageChange = (e: number) => {
        getData(e, porPagina);
    }

    const onChangePerPage = (e: number) => {
        getData(atualPageData, e);
    }

    const  onSaveAceite  = async () => {

        const {data, isError} = await usuariosApi.aceitoTermos();
        const newData: any = userData;
        // @ts-ignore
        newData.usuario.data_aceite_termos = data.data;
        setUserData({ usuario: newData?.usuario })
        Storage.setUserData({ usuario: newData?.usuario });
        close()
    }

    const oderTable = (field: string, order: string) => {

        let fieldOrder = 'data_prevista_viagem';

        switch (field) {
            case 'cliente.nome+cliente.sobrenome':
                fieldOrder = 'cliente.name'
        }


        const orderData = {
            field: fieldOrder,
            order: order === 'asc' ? 'desc' : 'asc'
        };

        setTableOrder(orderData)
        getData(atualPageData, porPagina, '', orderData);
    }


    return(
        <div>
            <Navbar admin={false}/>
            <div className={'md:px-[120px] md:py-[1.8rem] xs:overflow-x-auto'}>
                <div className={'text-[34px] text-gray-900 '}>
                    Seus Processos
                </div>
                {
                    loading ? '' :
                        <div className={'w-full mt-5'}>
                            <Table
                                tableOrder={tableOrder}
                                setTableOrder={oderTable}
                                columns={columns}
                                tableData={data}
                                data={dataPaginate}
                                onPageChange={onPageChange}
                                setPerPage={onChangePerPage}
                                onClick={onClickTable}
                            />
                        </div>
                }
            </div>
            <Modal size="lm" title="TERMOS E CONDIÇÕES DE PRESTAÇÃO DE SERVIÇO" {...modalProps}>
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                    <div className={'p-3 flex '}>
                        <Viewer
                            fileUrl='./termodeaceite.pdf'
                            plugins={[
                                // Register plugins
                                //defaultLayoutPluginInstance,
                            ]}
                        />
                    </div>
                    <div className={'p-3 flex justify-end'}>
                        <Button onClick={close} className={'bg-white text-[#c61111] border border-[#c61111]'}>
                            Cancelar
                        </Button>
                        <Button onClick={onSaveAceite} className={'ml-5 bg-white text-[#082a60] border border-[#082a60]'} >
                            Aceito
                        </Button>
                    </div>
                </Worker>
            </Modal>
        </div>
    )
}