import React, {useEffect, useState} from "react";
import ocupacaoApi from "../../../../../services/ocupacaoApi";
import Alert from "../../../../../helpers/Alert";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import processosApi from "../../../../../services/processosApi";
import HeaderForm from "../../../../../components/headerForm";
import Form from "../../../../../components/form/Form";
import Input from "../../../../../components/input/Input";
import Button from "../../../../../components/button/Button";

export interface IOcupacaoAusEstudante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function OcupacaoAusEstudante({processo, setProcesso, setTelaNumero, telanumero}: IOcupacaoAusEstudante) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        tabalhando: 0,
        ocupacao: '',
        data_entrada: '',
        nome_empresa: '',
        ramo_empregador: '',
        endereco: '',
        telefone: '',
        nome_supervisor: '',
        salario: '',
        possui_comprovantes: 0,
        possui_historico_trabalhista: 0,
        data_admissao_trabalho_anterior: '',
        cargo_trabalho_anterior: '',
        segundo_nome_empresa: '',
        segundo_ramo_empregador: '',
        segundo_endereco: '',
        segundo_telefone: '',
        segundo_remuneracao: '',
        segundo_possui_comprovantes: 0,
        segundo_periodo: '',
        convidado_atralhar: 0,
        informacoes_convidado_atralhar: '',

    });
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    useEffect(() => {
        getOcupacao()
    }, [])

    const getOcupacao = async () => {
        const {data, isError} = await ocupacaoApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    async function onSubmit() {
        const {data, isError} = state.id ? await ocupacaoApi.update(state.id, state) : await ocupacaoApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Ocupacão atual'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você está trabalhando no momento?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'tabalhando')}
                                        value={1}
                                        id="checked-tabalhando-s"
                                        type="radio"
                                        checked={state.tabalhando === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-tabalhando-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.tabalhando === 0 }
                                        onChange={e => handleChange(0,'tabalhando')}
                                        id="checked-tabalhando-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-tabalhando-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Qual a sua ocupação atual?'}
                            placeholder={'Qual a sua ocupação atual?'}
                            large={true}
                            required={true}
                            name={'ocupacao'}
                            setValue={e => handleChange(e,'ocupacao')}
                            value={state.ocupacao}
                        />
                        <Input
                            className={'col-span-2'}
                            label={'Qual a data de admissão no trabalho ou início dos seus estudos'}
                            placeholder={'Qual a data de admissão no trabalho ou início dos seus estudos'}
                            large={true}
                            required={true}
                            type={'date'}
                            name={'data_entrada'}
                            setValue={e => handleChange(e,'data_entrada')}
                            value={state.data_entrada}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome do empregador ou escola'}
                            placeholder={'Nome do empregador ou escola'}
                            large={true}
                            required={true}
                            name={'nome_empresa'}
                            setValue={e => handleChange(e,'nome_empresa')}
                            value={state.nome_empresa}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Ramo de atuação do seu empregador'}
                            placeholder={'Ramo de atuação do seu empregador'}
                            large={true}
                            name={'ramo_empregador'}
                            setValue={e => handleChange(e,'ramo_empregador')}
                            value={state.ramo_empregador}
                        />
                        <Input
                            className={'col-span-2'}
                            label={'Endereço completo, com o CEP'}
                            placeholder={'Endereço completo, com o CEP'}
                            large={true}
                            required={true}
                            name={'endereco'}
                            setValue={e => handleChange(e,'endereco')}
                            value={state.endereco}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Telefone'}
                            placeholder={'Telefone'}
                            large={true}
                            mask={'cellphone'}
                            required={true}
                            name={'telefone'}
                            setValue={e => handleChange(e,'telefone')}
                            value={state.telefone}
                        />
                        <Input
                            className={'col-span-2'}
                            label={'Informe o nome e sobrenome de um supervisor ou contato no seu trabalho atual'}
                            placeholder={'Informe o nome e sobrenome de um supervisor ou contato no seu trabalho atual'}
                            large={true}
                            required={true}
                            name={'nome_supervisor'}
                            setValue={e => handleChange(e,'nome_supervisor')}
                            value={state.nome_supervisor}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Qual o valor da sua remuneração'}
                            placeholder={'Qual o valor da sua remuneração'}
                            large={true}
                            name={'salario'}
                            setValue={e => handleChange(e,'salario')}
                            value={state.salario}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Possui comprovantes?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'possui_comprovantes')}
                                        value={1}
                                        id="checked-possui_comprovantes-s"
                                        type="radio"
                                        checked={state.possui_comprovantes === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_comprovantes-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.possui_comprovantes === 0 }
                                        onChange={e => handleChange(0,'possui_comprovantes')}
                                        id="checked-possui_comprovantes-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_comprovantes-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Possui algum histórico trabalhista anterior
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'possui_historico_trabalhista')}
                                        value={1}
                                        id="checked-possui_historico_trabalhista-s"
                                        type="radio"
                                        checked={state.possui_historico_trabalhista === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_historico_trabalhista-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.possui_historico_trabalhista === 0 }
                                        onChange={e => handleChange(0,'possui_historico_trabalhista')}
                                        id="checked-possui_historico_trabalhista-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-possui_historico_trabalhista-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Data de admissão no trabalho ou início dos estudos?'}
                            placeholder={'Data de admissão no trabalho ou início dos estudos?'}
                            large={true}
                            type={'date'}
                            name={'data_admissao_trabalho_anterior'}
                            setValue={e => handleChange(e,'data_admissao_trabalho_anterior')}
                            value={state.data_admissao_trabalho_anterior}
                        />

                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Qual a sua função, cargo ou ocupação anterior'}
                            placeholder={'Qual a sua função, cargo ou ocupação anterior'}
                            large={true}
                            name={'cargo_trabalho_anterior'}
                            setValue={e => handleChange(e,'cargo_trabalho_anterior')}
                            value={state.cargo_trabalho_anterior}
                        />
                    </div>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'text-[20px]'}>2º Empregador/Escola</div>
                    </div>
                    <div className={'grid md:grid-cols-6 gap-4 mt-5'}>
                        <Input
                            className={'col-span-2'}
                            label={'Nome do empregador ou escola'}
                            placeholder={'Nome do empregador ou escola'}
                            large={true}
                            name={'segundo_nome_empresa'}
                            setValue={e => handleChange(e,'segundo_nome_empresa')}
                            value={state.segundo_nome_empresa}
                        />
                        <Input
                            className={'col-span-2'}
                            label={'Endereço completo, com o CEP'}
                            placeholder={'Endereço completo, com o CEP'}
                            large={true}
                            name={'segundo_endereco'}
                            setValue={e => handleChange(e,'segundo_endereco')}
                            value={state.segundo_endereco}
                        />
                        <Input
                            className={'col-span-2'}
                            label={'Qual o ramo de atuação do seu empregador'}
                            placeholder={'Qual o ramo de atuação do seu empregador'}
                            large={true}
                            name={'segundo_ramo_empregador'}
                            setValue={e => handleChange(e,'segundo_ramo_empregador')}
                            value={state.segundo_ramo_empregador}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Telefone'}
                            placeholder={'Telefone'}
                            large={true}
                            mask={'cellphone'}
                            name={'segundo_telefone'}
                            setValue={e => handleChange(e,'segundo_telefone')}
                            value={state.segundo_telefone}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Qual o valor da sua remuneração'}
                            placeholder={'Qual o valor da sua remuneração'}
                            large={true}
                            name={'segundo_remuneracao'}
                            setValue={e => handleChange(e,'segundo_remuneracao')}
                            value={state.segundo_remuneracao}
                        />
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Possui comprovantes?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'segundo_possui_comprovantes')}
                                        value={1}
                                        id="checked-segundo_possui_comprovantes-s"
                                        type="radio"
                                        checked={state.segundo_possui_comprovantes === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-segundo_possui_comprovantes-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.segundo_possui_comprovantes === 0 }
                                        onChange={e => handleChange(0,'segundo_possui_comprovantes')}
                                        id="checked-segundo_possui_comprovantes-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-segundo_possui_comprovantes-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Qual o período trabalhado'}
                            placeholder={'Qual o período trabalhado'}
                            large={true}
                            name={'segundo_periodo'}
                            setValue={e => handleChange(e,'segundo_periodo')}
                            value={state.segundo_periodo}
                        />
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1 md:mt-[1.5rem]'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você já foi convidado à algum trabalho quando retornar para o seu país de origem?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'convidado_atralhar')}
                                        value={1}
                                        id="checked-convidado_atralhar-s"
                                        type="radio"
                                        checked={state.convidado_atralhar === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-convidado_atralhar-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.convidado_atralhar === 0 }
                                        onChange={e => handleChange(0,'convidado_atralhar')}
                                        id="checked-convidado_atralhar-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-convidado_atralhar-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Caso tenha respondido "Sim", para qual cargo? Em caso negativo, em qual área você pretende atuar no futuro?'}
                            placeholder={'Caso tenha respondido "Sim", para qual cargo? Em caso negativo, em qual área você pretende atuar no futuro?'}
                            large={true}
                            name={'informacoes_convidado_atralhar'}
                            setValue={e => handleChange(e,'informacoes_convidado_atralhar')}
                            value={state.informacoes_convidado_atralhar}
                        />
                    </div>

                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}