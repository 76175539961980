import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import Input from "../../../../../components/input/Input";
import vistoAnteriorApi from "../../../../../services/vistoAnteriorApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";

export interface IVistoAnterior{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function VistoAnterior({processo, setProcesso, setTelaNumero, telanumero}: IVistoAnterior) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        data_emissao: '',
        data_validade: '',
        numero: '',
        cidade_pais: '',
        mesmo_tipo_visto: 0,
        mesmo_pais: 0,
        digitais_colhidas: 0,
        perdido_roubado: 0,
        cancelado_revogado: 0,
        entrada_negada: 0,
        info_entrada_negada: ''
    });
    useEffect(() => {
        getVistoAnterior()
    }, [])
    const getVistoAnterior = async () =>{
        const {data, isError} = await vistoAnteriorApi.one(processo.id);
        if (!isError){
            const response = data.data;
            if (Object.values(response).length){
                setState(response);
            }
        }
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    async function onSubmit() {
        const {data, isError} = state.id ? await vistoAnteriorApi.update(state.id, state) : await vistoAnteriorApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados do visto anterior para os Estados Unidos'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            required={true}
                            type={'date'}
                            className={'md:col-span-1'}
                            label={'Data de Emissão do seu último visto'}
                            name={'data_emissao'}
                            setValue={e => handleChange(e,'data_emissao')}
                            value={state.data_emissao}
                        />
                        <Input
                            required={true}
                            type={'date'}
                            className={'md:col-span-1'}
                            label={'Data de Validade do seu último visto'}
                            name={'data_validade'}
                            setValue={e => handleChange(e,'data_validade')}
                            value={state.data_validade}
                        />

                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            required={true}
                            className={'md:col-span-1'}
                            label={'Número do visto'}
                            name={'numero'}
                            setValue={e => handleChange(e,'numero')}
                            value={state.numero}
                        />
                        <Input
                            className={'md:col-span-1'}
                            label={'Cidade e país onde o seu visto foi emitido'}
                            name={'cidade_pais'}
                            setValue={e => handleChange(e,'cidade_pais')}
                            value={state.cidade_pais}
                        />
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Você está pedindo o mesmo tipo de visto?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'mesmo_tipo_visto')}
                                            value={1}
                                            id="checked-mesmo_tipo_visto-s"
                                            type="radio"
                                            checked={state.mesmo_tipo_visto === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-mesmo_tipo_visto-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.mesmo_tipo_visto === 0 }
                                            onChange={e => handleChange(0,'mesmo_tipo_visto')}
                                            id="checked-mesmo_tipo_visto-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-mesmo_tipo_visto-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Você está solicitando o visto do mesmo país onde solicitou pela última vez?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'mesmo_pais')}
                                            value={1}
                                            id="checked-mesmo_pais-s"
                                            type="radio"
                                            checked={state.mesmo_pais === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-mesmo_pais-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.mesmo_pais === 0 }
                                            onChange={e => handleChange(0,'mesmo_pais')}
                                            id="checked-mesmo_pais-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-mesmo_pais-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Você já tirou suas digitais no Consulado anteriormente?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'digitais_colhidas')}
                                            value={1}
                                            id="checked-digitais_colhidas-s"
                                            type="radio"
                                            checked={state.digitais_colhidas === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-digitais_colhidas-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.digitais_colhidas === 0 }
                                            onChange={e => handleChange(0,'digitais_colhidas')}
                                            id="checked-digitais_colhidas-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-digitais_colhidas-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Já teve um visto perdido ou roubado?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'perdido_roubado')}
                                            value={1}
                                            id="checked-perdido_roubado-s"
                                            type="radio"
                                            checked={state.perdido_roubado === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-perdido_roubado-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.perdido_roubado === 0 }
                                            onChange={e => handleChange(0,'perdido_roubado')}
                                            id="checked-perdido_roubado-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-perdido_roubado-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Já teve algum visto cancelado ou revogado?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'cancelado_revogado')}
                                            value={1}
                                            id="checked-cancelado_revogado-s"
                                            type="radio"
                                            checked={state.cancelado_revogado === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-cancelado_revogado-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.cancelado_revogado === 0 }
                                            onChange={e => handleChange(0,'cancelado_revogado')}
                                            id="checked-cancelado_revogado-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-cancelado_revogado-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Já teve a entrada negada nos Estados Unidos?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'entrada_negada')}
                                            value={1}
                                            id="checked-entrada_negada-s"
                                            type="radio"
                                            checked={state.entrada_negada === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-entrada_negada-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.entrada_negada === 0 }
                                            onChange={e => handleChange(0,'entrada_negada')}
                                            id="checked-entrada_negada-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-entrada_negada-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                                <div>
                                    <div className={'mt-3 flex flex-col'}>
                                        <label
                                            className="text-sm font-medium"
                                        >
                                            Se sim, qual motivo da proibição da entrada?
                                        </label>
                                        <textarea
                                            className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                            rows={5}
                                            cols={50}
                                            name={'info_entrada_negada'}
                                            onChange={e => handleChange(e.target.value,'info_entrada_negada')}
                                            value={state.info_entrada_negada ? state.info_entrada_negada : ""}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}