import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import Alert from "../../../../../helpers/Alert";
import dadosFilhosApi from "../../../../../services/dadosFilhosApi";
import processosApi from "../../../../../services/processosApi";
import informacoesAdicionaisViagemApi from "../../../../../services/informacoesAdicionaisViagemApi";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";

export interface IInformacoesAdicionaisViagemCa{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function InformacoesAdicionaisViagemCa({processo, setProcesso, setTelaNumero, telanumero}: IInformacoesAdicionaisViagemCa) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        descricao_atividades_pais: '',
        conhecido_no_pais: '',
        alguem_vai_junto: '',
        informacoes_relevantes: '',
        lingua_nativa: '',
        outras_linguas: '',
        apto_conversar: 'ingles',
        teste_proeviciencia: 1,
    });

    const aptoOptions = [
        { value: 'ingles', label: 'Inglês' },
        { value: 'frances', label: 'Francês' },
        { value: 'nenhum', label: 'Nenhum' },
        { value: 'ambos', label: 'Ambos os idiomas' },
    ];
    const getData = async () => {
        const {data, isError} = await informacoesAdicionaisViagemApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }
    useEffect(() => {
        getData()
    }, []);
    const onSubmit = async () => {
        const confirm = await Alert.confirm('Deseja realmente concluir esse processo?', 'Concluir processo');
        if (!confirm) return;
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await informacoesAdicionaisViagemApi.update(state.id, state) : await informacoesAdicionaisViagemApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.message? data.message : data);
        }
        setProcesso({...processo, ['percet_conclusao']: 100.00 })
        Alert.success('Formulario concluído com sucesso');
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);

    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={'Informações adicionais sobre a viagem'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Breve descrição das atividades enquanto estiver no Canadá <span className={'text-[#c22929]'}>*</span>
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                required={true}
                                name={'descricao_atividades_pais'}
                                onChange={e => handleChange(e.target.value,'descricao_atividades_pais')}
                                value={state.descricao_atividades_pais ? state.descricao_atividades_pais : ""}
                            />
                            <div className={'text-sm text-gray-400'}>
                                Exemplo: "Pretendo estudar inglês em Vancouver, entrar em contato com a cultura e conhecer as belezas naturais do Canadá".
                            </div>
                        </div>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Tem algum conhecido no Canadá (amigos, família, colegas de trabalho, etc)
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'descricao_atividades_pais'}
                                onChange={e => handleChange(e.target.value,'conhecido_no_pais')}
                                value={state.conhecido_no_pais ? state.conhecido_no_pais : ""}
                            />
                            <div className={'text-sm text-gray-400'}>
                                Caso tenha, informar nome completo, data de nascimento, sexo e relação com você.
                            </div>
                        </div>

                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Está viajando com alguém para o Canadá (amigos, família, colegas de trabalho, etc)?
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'alguem_vai_junto'}
                                onChange={e => handleChange(e.target.value,'alguem_vai_junto')}
                                value={state.alguem_vai_junto ? state.alguem_vai_junto : ""}
                            />
                            <div className={'text-sm text-gray-400'}>
                                Caso tenha, informar nome completo, data de nascimento, sexo e relação com você.
                            </div>
                        </div>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Alguma informação relevante que queira mencionar?
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'informacoes_relevantes'}
                                onChange={e => handleChange(e.target.value,'informacoes_relevantes')}
                                value={state.informacoes_relevantes ? state.informacoes_relevantes : ""}
                            />
                        </div>
                    </div>


                    {
                        processo.tipo_visto_id !== 4 ?
                            <>
                                <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                                    <Input
                                        className={'md:col-span-1'}
                                        large={true}
                                        label={'Qual a sua língua nativa?'}
                                        required={true}
                                        name={'lingua_nativa'}
                                        setValue={e => handleChange(e,'lingua_nativa')}
                                        value={state.lingua_nativa}
                                    />
                                    <Input
                                        className={'md:col-span-2'}
                                        large={true}
                                        label={processo.tipo_visto_id === 3 ? 'Se não for inglês ou francês, qual língua você usa com mais frequência' : 'Qual língua você usa com mais frequência?'}
                                        required={true}
                                        name={'outras_linguas'}
                                        setValue={e => handleChange(e,'outras_linguas')}
                                        value={state.outras_linguas}
                                    />
                                    <div className={'col-span-1'}>
                                        <InputSelect
                                            label={"Você está apto a conversar em"}
                                            selected={aptoOptions.filter((opt: any) => opt.value === state.apto_conversar)}
                                            options={aptoOptions}
                                            onChangeSelect={(e: any) => handleChange(e.value, 'apto_conversar')}
                                        />
                                    </div>
                                </div>
                                <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                                    <div className={'flex flex-col'}>
                                        <label
                                            className="text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Você já realizou teste de proficiência em Inglês ou Francês
                                        </label>
                                        <div className={'flex mt-[1.5rem] gap-4'}>
                                            <div className={'flex'}>
                                                <input
                                                    onChange={e => handleChange(1,'teste_proeviciencia')}
                                                    value={1}
                                                    id="checked-teste_proeviciencia-1"
                                                    type="radio"
                                                    checked={state.teste_proeviciencia === 1 }
                                                    className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label
                                                    htmlFor="checked-teste_proeviciencia-1"
                                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Sim
                                                </label>
                                            </div>
                                            <div className={'flex'}>
                                                <input
                                                    checked={state.teste_proeviciencia === 0 }
                                                    onChange={e => handleChange(0,'teste_proeviciencia')}
                                                    id="checked-teste_proeviciencia"
                                                    type="radio"
                                                    value={0}
                                                    className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                />
                                                <label
                                                    htmlFor="checked-teste_proeviciencia"
                                                    className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                                >
                                                    Não
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            ''
                    }



                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero - 1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}