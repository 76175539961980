import React from "react";
import {PieChart} from "@mui/x-charts";
interface IProcessosDashboard {
    processos: any
}
export default function ProcessosDashboard({processos}: IProcessosDashboard){
    const classCircle: any = {
        0: `border h-4 w-4 rounded-full border-4 mr-1 border-[#e4edfc]`,
        1: 'border h-4 w-4 rounded-full border-4 mr-1 border-[#c0d8ff]',
        2: 'border h-4 w-4 rounded-full border-4 mr-1 border-[#79a8f4]',
        3: 'border h-4 w-4 rounded-full border-4 mr-1 border-[#4083ef]',
        4: 'border h-4 w-4 rounded-full border-4 mr-1 border-[#0f4eb3]',
        5: 'border h-4 w-4 rounded-full border-4 mr-1 border-[#0b3983]',
        6: 'border h-4 w-4 rounded-full border-4 mr-1 border-[#0b3983]',
    }
    const cores: any = {
        0: `#e4edfc`,
        1: '#c0d8ff',
        2: '#79a8f4',
        3: '#4083ef',
        4: '#0f4eb3',
        5: '#0b3983',
        6: '#0b3983',
    }
    const finalizados = processos[5].quantidade;
    let naoFinalizados = 0;
    let total = 0;

    processos.map((processo: any, index: number) => {
        if (index < 5){
            naoFinalizados += processo.quantidade
        }
        total += processo.quantidade;
    });

    console.log(total);

    return(
        <div className={'flex-col border rounded-md w-[700px] h-[362px] border-2 p-5 '}>
            <div className={'font-semibold text-[20px] text-gray-700 '}>Processos</div>
            <div className={'flex mt-5 w-full'}>
                <div className={'flex flex-col w-[40%] '}>
                    <div className={'flex justify-center mr-5 text-gray-600 text-[18px]'}>
                        <div>{naoFinalizados + finalizados} Total</div>
                    </div>
                    <PieChart
                        series={[
                            {
                                data: processos.map((processo: any, index: number) =>{
                                    return {
                                        id: processo.id,
                                        value: processo.quantidade,
                                        label: processo.nome,
                                        color: cores[index]
                                    }
                                }) /*[
                                    { id: 0, value: 10, label: 'series A' },
                                    { id: 1, value: 15, label: 'series B' },
                                    { id: 2, value: 20, label: 'series C' },
                                ]*/,
                            },
                        ]}
                        margin={{left: 20 }}
                        slotProps={{ legend: { hidden: true } }}
                        width={300}
                        height={200}
                        colors={cores}

                    />
                    <div className={'flex justify-between text-gray-600 text-[18px]'}>
                        <div>{naoFinalizados} Abertos</div>
                        <div className={'mr-3'}>{finalizados} finalizados</div>
                    </div>

                </div>
                <div className={'w-full'}>
                    <ul>
                        {
                            processos.map((processo: any, index: number) =>{
                                return(
                                    <li className={'flex justify-between items-center mt-3 text-gray-700'}>
                                        <div className={'flex'}>
                                            <div className={classCircle[index]}/>
                                            {processo.nome}
                                        </div>
                                        <div className={'flex'}>

                                            {processo.percent} %
                                        </div>
                                    </li>
                                )
                            })
                        }

                    </ul>
                </div>
            </div>
        </div>
    )
}