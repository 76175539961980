import PageContainer from "../../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import Tabs from "../../../components/Tabs";
import Button from "../../../components/button/Button";
import DadosPessoaisCliente from "../DadosPessoais";
import DadosViagem from "../DadosViagem";
import clientesApi from "../../../services/clientesApi";
import Alert from "../../../helpers/Alert";
import {useNavigate, useParams} from "react-router-dom";
import processosApi from "../../../services/processosApi";

export default function ClientesFormulario(){
    const navigate = useNavigate();
    const [indexTabs, setIndexTabs] = useState(0)
    const params = useParams();
    const id = params.id;
    const [idCliente, setIdClient]: any = useState(id ? id : '');
    const [stateClient, setStateClient]: any = useState({});

    const [loading, setLoading] = useState(true);

    const [tabData, setTabData] = useState<any>([
        {
            title: 'Dados pessoais',
            component: <DadosPessoaisCliente
                onSubmit={(e: any) => onSubmit(e)}
                idCliente={idCliente}
                stateClient={stateClient}
                setStateClient={setStateClient}
            />
        },
        {
            title: 'Dados da viagem',
            component: <DadosViagem/>
        }
    ]);

    const clienteData = async () => {
        if (id){
            const {data, isError} = await clientesApi.one(id.toString());

            const newData = {
                id: data.data.id,
                usuario_id: data.data.usuario_id,
                usuario_responsavel_id: data.data.usuario_responsavel_id,
                nome: data.data.usuario.name,
                sobrenome: data.data.sobrenome,
                nascimento: data.data.nascimento,
                nacionalidade: data.data.nacionalidade,
                telefone: data.data.telefone,
                telefone_secundario: data.data.telefone_secundario,
                email: data.data.usuario.email,
                cep: data.data.cep,
                endereco: data.data.endereco,
                bairro: data.data.bairro,
                cidade: data.data.cidade,
                estado: data.data.estado,
                data_aceite_termos: data.data.usuario.data_aceite_termos,
                membros: data.data.membros.map((membros:any) => { return {
                    id: membros.id,
                    nome: membros.usuario.name,
                    nascimento: membros.nascimento,
                    nacionalidade: membros.nacionalidade,
                    telefone: membros.telefone,
                    telefone_secundario: membros.telefone_secundario,
                    email: membros.usuario.email,
                } }),
                cliente_responsavel: data.data.responsavel ? data.data.responsavel.map((membros:any) => { return {
                    id: membros.id,
                    nome: membros.nome+' '+membros.sobrenome,
                    cpf: membros.cpf,
                } }) : []
            }



            setStateClient(newData)
            setTabData([
                {
                    title: 'Dados pessoais',
                    component: <DadosPessoaisCliente
                        onSubmit={(e: any) => onSubmit(e)}
                        idCliente={idCliente}
                        stateClient={newData}
                        setStateClient={setStateClient}
                    />
                },
                {
                    title: 'Dados da viagem',
                    component: <DadosViagem
                        membros={newData.membros}
                        idCliente={idCliente}
                        onSubmit={(e: any) => onSubmitDadosViagem(e)}
                    />
                }
            ])

        }
        setLoading(false);

    }

    useEffect(() => {
        //setIndexTabs(indexTabs+1);
        clienteData()
    },[])

    const onSubmit = async (data: any) => {
        //Alert.await(`Salvando cliente`);
        //setLoading(true)
        let dataForm = {...data};
        const arrayTmb = dataForm.nome.split(' ');
        let sobrenome = '';
        arrayTmb.map((dt: any, index:number) => {
            if (index){
                sobrenome += dt+" ";
            }
        });
        if (!sobrenome.length){
            Alert.error('Cliente deve ter nome e sobrenome');
            setLoading(false)
            return
        }

        dataForm.nome = arrayTmb[0];
        dataForm.sobrenome = sobrenome.trim();
        dataForm.membros = dataForm.membros.filter((membro: any) =>  !membro.id);

        const clientData = id ? await clientesApi.update(id, dataForm) : await clientesApi.create(dataForm);

        if (clientData.isError){
            setLoading(false)
            setTabData([
                {
                    title: 'Dados pessoais',
                    component: <DadosPessoaisCliente
                        onSubmit={(e: any) => onSubmit(e)}
                        idCliente={idCliente}
                        stateClient={data}
                        setStateClient={setStateClient}
                    />
                },
                {
                    title: 'Dados da viagem',
                    component: <DadosViagem
                        membros={dataForm.membros}
                        idCliente={idCliente}
                        onSubmit={(e: any) => onSubmitDadosViagem(e)}
                    />
                }
            ])

            if (typeof clientData.data === 'object') {
                if (clientData.data.message){
                    return Alert.error(clientData.data.message? clientData.data.message : clientData.data);
                }
                Object.keys(clientData.data.errors).forEach(function(key, index) {
                    Alert.error(clientData.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(clientData.data.message? clientData.data.message : clientData.data);
        }
        Alert.success(id ? 'cliente editado' : 'cliente salvo');

        setIdClient(clientData.data.data.id);
        data.id = clientData.data.data.id;

        const newData = {
            id: clientData.data.data.id,
            usuario_responsavel_id: clientData.data.data.usuario_responsavel_id,
            nome: clientData.data.data.nome,
            sobrenome: clientData.data.data.sobrenome,
            nascimento: clientData.data.data.nascimento,
            nacionalidade: clientData.data.data.nacionalidade,
            telefone: clientData.data.data.telefone,
            telefone_secundario: clientData.data.data.telefone_secundario,
            email: clientData.data.data.usuario.email,
            cep: clientData.data.data.cep,
            endereco: clientData.data.data.endereco,
            bairro: clientData.data.data.bairro,
            cidade: clientData.data.data.cidade,
            estado: clientData.data.data.estado,
            data_aceite_termos: clientData.data.data.usuario.data_aceite_termos,
            membros: clientData.data.data.membros.map((membros:any) => { return {
                id: membros.id,
                nome: membros.usuario.name,
                nascimento: membros.nascimento,
                nacionalidade: membros.nacionalidade,
                telefone: membros.telefone,
                telefone_secundario: membros.telefone_secundario,
                email: membros.usuario.email,
            } }),
            cliente_responsavel: clientData.data.data.responsavel ? clientData.data.data.responsavel.map((membros:any) => { return {
                id: membros.id,
                nome: membros.nome+' '+membros.sobrenome,
                cpf: membros.cpf,
            } }) : []
        }

        setStateClient(newData);


        setTabData([
            {
                title: 'Dados pessoais',
                component: <DadosPessoaisCliente
                    onSubmit={(e: any) => onSubmit(e)}
                    idCliente={idCliente}
                    stateClient={newData}
                    setStateClient={setStateClient}
                />
            },
            {
                title: 'Dados da viagem',
                component: <DadosViagem
                    membros={newData.membros}
                    idCliente={newData.id}
                    onSubmit={(e: any) => onSubmitDadosViagem(e)}
                />
            }
        ])

        if(id)
        {
            setTimeout(function(){
                navigate('/clientes');
            }, 4000);

        }else{
            setIndexTabs(indexTabs+1);
            setLoading(false)
        }

    }



    const onSubmitDadosViagem = async (data: any) => {
        data.valor_total_servico = parseFloat(data.valor_total_servico);
        data.quantidade_pessoas = data.membros.length;
        Alert.await(`Salvando processo`);
        const newProcesso = await processosApi.create(data);
        if (newProcesso.isError){
            if (typeof newProcesso.data === 'object') {
                Object.keys(newProcesso.data.errors).forEach(function(key, index) {
                    Alert.error(newProcesso.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(newProcesso.data.message? newProcesso.data.message : newProcesso.data);
        }
        Alert.success('Processo criado');
        navigate('/clientes')
    }

    return(
        <PageContainer pageTitle={'Clientes'}>
            {
                loading ?
                <div>Carregando</div>
                :
                <Tabs
                    tabData={tabData}
                    indexTabs={indexTabs}
                    setIndex={setIndexTabs}
                />
            }

        </PageContainer>
    )
}