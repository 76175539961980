import quantidade from "../../../../../helpers/QuantidadeFormularios";
import HeaderForm from "../../../../../components/headerForm";
import React, {useEffect, useState} from "react";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import perguntaSegurancaApi from "../../../../../services/perguntaSegurancaApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import InputSelect from "../../../../../components/input/InputSelect";

export interface IAgendamento{
    processo?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setProcesso?: any;
}
export default function Agendamento({processo, setTelaNumero, telanumero, setProcesso}: IAgendamento) {
    const [state, setState] = useState({
        processo_id: processo.id,
        datas_possiveis_agendamento: processo.datas_possiveis_agendamento ? processo.datas_possiveis_agendamento : 'proxima_data'
    });
    const [atualizacao, setAtualizacao] = useState(false);

    const agendamentoOptions = [
        { value: 'proxima_data', label: 'Agendar para a próxima data disponível' },
        { value: 'proxima_semana', label: 'Agendar para a próxima vaga disponível na próxima semana' },
        { value: 'duas_semanas', label: 'Agendar para próxima vaga disponível daqui a duas semanas' },
        { value: 'tres_semanas', label: 'Agendar para próxima vaga disponível daqui a três semanas' },
        { value: 'proximo_mes', label: 'Agendar para o próxima vaga disponível no próximo mês' },
    ]
    async function onSubmit() {
        const {data, isError} = await processosApi.update(state.processo_id, state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!atualizacao){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    const getDataProcesso = async () => {
        const {data, isError} = await processosApi.one(processo.id);
        if (!isError) {
            if (Object.values(data.data).length) {
                setProcesso(data.data);
                setState({
                    processo_id: processo.id,
                    datas_possiveis_agendamento: data.data.datas_possiveis_agendamento ? data.data.datas_possiveis_agendamento : 'proxima_data',
                })
                setAtualizacao(true)
            }
        }
    }
    useEffect(() => {
        getDataProcesso()
    }, [])
    return(
        <>
            <HeaderForm
                titulo={'Agendamento'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>

                        <div className={'col-span-2'}>
                            <InputSelect
                                label={"Datas possíveis para agendamento"}
                                selected={agendamentoOptions.filter((opt: any) => opt.value === state.datas_possiveis_agendamento)}
                                options={agendamentoOptions}
                                onChangeSelect={(e: any) => setState({...state, ['datas_possiveis_agendamento']: e.value})}
                            />
                            <div className={'text-gray-400 text-sm'}>
                                Escolha a opção de sua preferência sobre o agendamento. Pela regulamentação do
                                Consulado, a ida ao CASV e ao Consulado deverão SEMPRE acontecer em dias diferentes.
                                Os agendamentos serão feitos sempre baseados nas informações fornecidas por você.
                                Caso a vaga desejada não esteja disponível, informaremos a você no momento do
                                agendamento.
                            </div>
                        </div>

                    </div>
                </div>

                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}