import React from 'react';
import { AppRoute } from "./index";
import Login from "../pages/Auth/Login";
import Processos from "../pages/Processos";
import Clientes from "../pages/Clientes";
import ViewProcessos from "../pages/Processos/ViewProcessos";
import Dashboard from "../pages/Dashboard";
import Cronogramas from "../pages/Cronogramas";
import Entradas from "../pages/Financeiro/Entradas";
import Saidas from "../pages/Financeiro/Saidas";
import Usuarios from "../pages/Usuarios";
import Parceiros from "../pages/Parceiros";
import ClientesFormulario from "../pages/Clientes/Form";
import RedefinirSenha from "../pages/Auth/RedefinirSenha";
import Relatorios from "../pages/Relatorios";

const appRoutes: AppRoute[] =  [
    {
        path: '/',
        requiresAuth: false,
        element: <Login />
    },
    {
        path: '/redefinir-senha',
        requiresAuth: false,
        element: <RedefinirSenha />
    },
    {
        path: '/entrar',
        requiresAuth: false,
        element: <Login />
    },
    {
        path: '/processos',
        requiresAuth: true,
        element: <Processos />
    },
    {
        path: '/processos/:id',
        requiresAuth: true,
        element: <ViewProcessos />
    },
    {
        path: '/clientes',
        requiresAuth: true,
        element: <Clientes />
    },
    {
        path: '/clientes/formulario',
        requiresAuth: true,
        element: <ClientesFormulario />
    },
    {
        path: '/clientes/formulario/:id',
        requiresAuth: true,
        element: <ClientesFormulario />
    },
    {
        path: '/dashboard',
        requiresAuth: true,
        element: <Dashboard />
    },
    {
        path: '/cronogramas',
        requiresAuth: true,
        element: <Cronogramas />
    },
    {
        path: '/financeiro/entradas',
        requiresAuth: true,
        element: <Entradas />
    },
    {
        path: '/financeiro/saidas',
        requiresAuth: true,
        element: <Saidas />
    },
    {
        path: '/usuarios',
        requiresAuth: true,
        element: <Usuarios />
    },
    {
        path: '/parceiros',
        requiresAuth: true,
        element: <Parceiros />
    },
    {
        path: '/relatorios',
        requiresAuth: true,
        element: <Relatorios />
    },
    {
        path: '/*',
        requiresAuth: false,
        element: <h1>404</h1>
    }
];

export default appRoutes;