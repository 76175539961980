import dayjs from "dayjs";
import { Input, InputCurrency, ListPageLayout, Textarea, Button, SearchInput, Select } from "../../../components";
import { saidaApi } from "../../../services";
import Alert from "../../../helpers/Alert";

export default function Saidas() {
  return (
    <ListPageLayout 
      title="Saídas" 
      actions={saidaApi}
      filters={{
        form: ({ register }) => <div className="flex items-center gap-3">
          <SearchInput
              placeholder="Pesquisar"
              {...register('recebedor')}
          />

          <Select
            multi
            {...register('status')}
            placeholder="Status"
            options={[
              { label: 'Vencido', value: 'Vencido' },
              { label: 'Em aberto', value: 'Em Aberto' },
              { label: 'Pago', value: 'Pago' },
            ]}
          />
        </div>
      }}

      table={{
        columns: [
          { title: 'Recebedor', field: 'recebedor' },
          { title: 'Valor', field: 'valor', type: 'currency' },
          { title: 'Data Vencimento', field: 'data_vencimento', type: 'date' },
          { title: 'Data Pagamento', field: 'data', type: 'date' },
          { title: 'Status', render: (rowData) => {
            if (rowData.data) return <span className="bg-green-100 text-green-600 px-3 rounded-full">Pago</span>
            if (dayjs().diff(dayjs(rowData.data_vencimento), 'day')) return <span className="bg-red-100 text-red-600 px-3 rounded-full">Vencido</span>
            return <span className="bg-[#e8e9ea] px-3 rounded-full">Em aberto</span>
          }},
        ]
      }}

      register={{
        mode: 'modal',
        title: 'Lançar Saída',
        rules: {
          recebedor: 'required',
          valor: 'required',
        },

        form: ({ register }) => (
          <div className="grid gap-6">
            <Input label="Nome do Recebedor" {...register('recebedor')} />
            <InputCurrency label="Valor" {...register('valor')} />
            <Input label="Data Vencimento" type="date" {...register('data_vencimento')} />
            <Textarea label="Observação" {...register('observacao')} />
          </div>
        ),
        footerClassName: 'flex-row-reverse',
        footer: ({ loader, closeModal, form }) => {
          return !form?.data && (
            <Button className="bg-green-500 text-white font-medium" onClick={async () => {
              if (!form?.id) return;
              const confirm = await Alert.confirm('Deseja dar baixa nesta saída?', 'Dar baixa?');
              if (!confirm) return;
              Alert.await('Registrando baixa...');
              const response = await saidaApi.update(form.id, { 
                data: dayjs().format('YYYY-MM-DD'),
                pago: true
              });
              if (response.isError) return Alert.error(response.data.message);
              Alert.success('Baixa registrada com sucesso!');
              loader();
              closeModal();
            }}>Lançar Pagamento</Button>
          )
        }
      }}
    />
  );
}