import PageContainer from "../../components/container/PageContainer";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import CardAtividades from "../../components/cardAtividades";
import cronogramaApi from "../../services/cronogramaApi";
import React, {useEffect, useState} from "react";
import situacaoProcessoApi from "../../services/situacaoProcessoApi";
import processosApi from "../../services/processosApi"; // a plugin!
//import listPlugin from '@fullcalendar/list';
import ptBrLocale from '@fullcalendar/core/locales/pt-br';
import { AiOutlineLoading } from "react-icons/ai";
import FullCalendarComponent from "../../components/fullCalendar";
import InputIcon from "../../components/input/InputIcon";
import InputSelect from "../../components/input/InputSelect";
import Button from "../../components/button/Button";
import {ReactComponent as AddIcon} from "../../assets/icone/add.svg";

export default function Cronogramas() {
    const [cronogramas, setCronogramas] = useState([]);
    const [loadingAtividades, setLoadingAtividades] = useState(true);
    const [loadingCalendario, setloadingCalendario] = useState(true);
    const hoje = new Date().toISOString().slice(0, 10);
    const [dataConsulta, setDataConsulta] = useState(new Date());
    const [dia, setDia] = useState(hoje.slice(8));
    const [calendarData, setCalendarData]: any = useState([]);
    const [quantidadeRegistroMes, setQuantidadeRegistroMes]: any = useState(0);
    const [dataConsult, setDataConsult] = useState('');
    const [statusCronogramasOptions, setStatusCronogramasOptions]: any = useState([]);
    const [setDayConsult, setSetDayConsult]: any = useState();
    const [statusSelected, setStatusSelected]: any = useState();
    const getDataCronograma = async (dataConsulta?: any, consult?: string) => {

        const [situacaoProcessosData, cronogramasCalendarioData, cronogramasData] = await  Promise.all([
            situacaoProcessoApi.listAll(),
            cronogramaApi.getCronogramaCalendario(dataConsulta ? dataConsulta : hoje, consult ? 'consult='+consult : ''),
            cronogramaApi.getCronogramaData(dataConsulta ? dataConsulta : hoje, consult ? consult : '')
        ]);

        const dadosCalendario: any[] = [];
        let quantidadeRegistro = 0;
        if (cronogramasCalendarioData.data) {
            cronogramasCalendarioData.data.map((cronograma: any, index: number) => {

                if (cronograma.quantidade_finalizados){
                    quantidadeRegistro += cronograma.quantidade_finalizados;
                    dadosCalendario.push({ title: cronograma.quantidade_finalizados, date: cronograma.data_prazo_finalizacao, concluido: true })
                }
                if (cronograma.quantidade_nao){
                    quantidadeRegistro += cronograma.quantidade_nao;
                    dadosCalendario.push({ title: cronograma.quantidade_nao, date: cronograma.data_prazo_finalizacao, concluido: false })
                }
            });
        }

        let optSituacoes: any = [];
        if (situacaoProcessosData.data){
            optSituacoes = situacaoProcessosData.data.data.map((situacao: any) => {return {value: situacao.id,label: situacao.nome}} )
            optSituacoes.unshift({value: '', label: 'Todos'})
        }

        setStatusCronogramasOptions(optSituacoes);


        setQuantidadeRegistroMes(quantidadeRegistro);
        setCalendarData(dadosCalendario);
        setCronogramas(cronogramasData.data.data);
        setloadingCalendario(false);
        setLoadingAtividades(false);
    }

    useEffect(()=>{
        setloadingCalendario(true);
        setLoadingAtividades(true);
        getDataCronograma()
    },[])

    async function onClickDiaCalendario(info: any){
        let date = info.dateStr;
        if (date === undefined){
            date = info.event.startStr
        }

        setDataConsulta(new Date(date))
        setSetDayConsult(date);
        setLoadingAtividades(true);
        const cronogramaData = await cronogramaApi.getCronogramaData(date, dataConsult);
        setDia(date.slice(8));
        if (!cronogramaData.isError){
            setCronogramas(cronogramaData.data.data);
        }
        setLoadingAtividades(false);
    }

    async function getDataCronogramaCalendario(dataConsulta: any, consult?: string){
        setloadingCalendario(true);
        const cronogramasCalendarioData = await cronogramaApi.getCronogramaCalendario(dataConsulta.toISOString().slice(0, 10), `consult=${consult}`);
        const dadosCalendario: any[] = [];
        if (cronogramasCalendarioData.data){
            cronogramasCalendarioData.data.map((cronograma: any, index: number) => {
                dadosCalendario.push({ title: cronograma.quantidade, date: cronograma.data_prazo_finalizacao })
            });
        }

        setCalendarData(dadosCalendario);
        setloadingCalendario(false);
    }

    async function prev(){
        dataConsulta.setDate(1);
        dataConsulta.setMonth(dataConsulta.getMonth() - 1);
        setDataConsulta(dataConsulta);
        const cronogramasCalendarioData = await cronogramaApi.getCronogramaCalendario(dataConsulta.toISOString().slice(0, 10));
        /*const dadosCalendario: any[] = [];
        if (cronogramasCalendarioData.data){
            cronogramasCalendarioData.data.map((cronograma: any, index: number) => {
                dadosCalendario.push({ title: cronograma.quantidade, date: cronograma.data_prazo_finalizacao })
            });
        }*/

        const dadosCalendario: any[] = [];
        let quantidadeRegistro = 0;
        if (cronogramasCalendarioData.data) {
            cronogramasCalendarioData.data.map((cronograma: any, index: number) => {

                if (cronograma.quantidade_finalizados){
                    quantidadeRegistro += cronograma.quantidade_finalizados;
                    dadosCalendario.push({ title: cronograma.quantidade_finalizados, date: cronograma.data_prazo_finalizacao, concluido: true })
                }
                if (cronograma.quantidade_nao){
                    quantidadeRegistro += cronograma.quantidade_nao;
                    dadosCalendario.push({ title: cronograma.quantidade_nao, date: cronograma.data_prazo_finalizacao, concluido: false })
                }
            });
        }

        setCalendarData(dadosCalendario);
    }
    async function next(){
        dataConsulta.setDate(1);
        dataConsulta.setMonth(dataConsulta.getMonth() + 1);
        setDataConsulta(dataConsulta);
        const cronogramasCalendarioData = await cronogramaApi.getCronogramaCalendario(dataConsulta.toISOString().slice(0, 10));
        /*const dadosCalendario: any[] = [];
        if (cronogramasCalendarioData){
            cronogramasCalendarioData.data.map((cronograma: any, index: number) => {
                dadosCalendario.push({ title: cronograma.quantidade, date: cronograma.data_prazo_finalizacao })
            });
        }*/
        const dadosCalendario: any[] = [];
        let quantidadeRegistro = 0;
        if (cronogramasCalendarioData.data) {
            cronogramasCalendarioData.data.map((cronograma: any, index: number) => {

                if (cronograma.quantidade_finalizados){
                    quantidadeRegistro += cronograma.quantidade_finalizados;
                    dadosCalendario.push({ title: cronograma.quantidade_finalizados, date: cronograma.data_prazo_finalizacao, concluido: true })
                }
                if (cronograma.quantidade_nao){
                    quantidadeRegistro += cronograma.quantidade_nao;
                    dadosCalendario.push({ title: cronograma.quantidade_nao, date: cronograma.data_prazo_finalizacao, concluido: false })
                }
            });
        }
        setCalendarData(dadosCalendario);
    }

    async function getCalendarData(fetchInfo: any, successCallback: any) {
        try {
            const cronogramasCalendarioData = await cronogramaApi.getCronogramaCalendario('2024-01-23');
            successCallback(
                cronogramasCalendarioData.data.map((dataCalendar: any) => {
                    return {
                        title: dataCalendar.quantidade, date: dataCalendar.data_prazo_finalizacao
                    }
                })
            );
        } catch (error) {
            console.log(error);
        }
    }


    async function onChange(e: any){
        setDataConsult(e.target.value);
        if (e.target.value.length > 3) {
            await getDataCronograma(dataConsulta.toISOString().slice(0, 10), e.target.value)
        }
        if (e.target.value.length == 0) {
            await getDataCronograma(dataConsulta.toISOString().slice(0, 10), e.target.value)
        }

    }

    async function buscaStatus(e: any){
        setStatusSelected(e.value)
        setLoadingAtividades(true);
        const cronogramaApiData = await cronogramaApi.getCronogramaData(dataConsulta.toISOString().slice(0, 10), '', e.value);

        /*if (setDayConsult){
            setDia(setDayConsult.slice(8));
        }*/
        if (!cronogramaApiData.isError){
            setCronogramas(cronogramaApiData.data.data);
        }

        setLoadingAtividades(false);
        //console.log(cronogramaApiData);
    }
    //https://fullcalendar.io/docs/eventColor
    //https://fullcalendar.io/docs/headerToolbar
    return(
        <PageContainer pageTitle={'Cronogramas'}>
            <div className={'flex w-full justify-between'}>
                <div className={'grid md:grid-cols-8 gap-4 md:w-[50%]'}>
                    <InputIcon
                        classNameLabel={'md:col-span-3'}
                        name={'nome'}
                        setValue={e => onChange(e)}
                        value={dataConsult}
                        placeholder={'Pesquisar'}
                    />
                    <div className={'mb-5 md:col-span-5'}>
                        <InputSelect
                            /*label={"Status"}*/
                            selected={statusCronogramasOptions.filter((opt: any) => opt.value === statusSelected)}
                            options={statusCronogramasOptions}
                            onChangeSelect={(e: any) => buscaStatus(e)}
                            placeholder={"Status"}
                        />
                    </div>
                </div>
            </div>
            <div className={'flex w-full max-[800px]:flex-col h-[calc(95vh_-_100px)]'}>
                <div className={'w-[70%] max-[800px]:w-[100%]'}>
                    {
                        loadingCalendario ?
                            <div className={'flex items-center h-full justify-center'}>
                                <AiOutlineLoading className={'animate-spin'} size={50} />
                            </div>
                            :
                            <FullCalendarComponent
                                functionNext={next}
                                functionPrev={prev}
                                calendarData={calendarData}
                                getDataCalendar={getCalendarData}
                                onClickDay={onClickDiaCalendario}
                            />
                    }
                </div>

                {
                    loadingAtividades ?
                        <div className={'w-[35%] max-[800px]:w-[100%] flex mt-[8rem] justify-center'}>
                            <AiOutlineLoading className={'animate-spin'} size={50} />
                        </div>
                        :
                        <div className={'w-[35%] max-[800px]:w-[100%] flex max-[800px]:justify-center max-[800px]:mt-5  justify-end text-gray-800'}>
                            <CardAtividades dia={dia}  atividades={cronogramas} quantidadeMes={quantidadeRegistroMes}/>
                        </div>
                }



            </div>

        </PageContainer>
    )
}