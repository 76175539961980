import Dates from "../../../../helpers/Dates";

interface IFormularioAusVisitante {
    state: any
}
export default function FormularioAusVisitante({state}: IFormularioAusVisitante){
    const stadoCivilOptions = [
        { value: 'solteiro', label: 'Solteiro(a)' },
        { value: 'casado', label: 'Casado(a)' },
        { value: 'uniao estavel', label: 'União Estável' },
        { value: 'separado', label: 'Separado(a)' },
        { value: 'desquitado', label: 'Desquitado(a)' },
        { value: 'viuvo', label: 'Viúvo(a)' },
        { value: 'divorciado', label: 'Divorciado' },
    ];

    const situacaoImigratoriaOptions = [
        { value: 'cidadao', label: 'Cidadão' },
        { value: 'visitante', label: 'Visitante' },
        { value: 'residente_permanente', label: 'Residente Permanente' },
        { value: 'estudante', label: 'Estudante' },
        { value: 'visto_trabalho', label: 'Visto de trabalho' },
        { value: 'outros', label: 'Outros' },
        { value: 'ilegal', label: 'Ilegal' },
    ];

    const quemViajaOptions = [
        { value: 'sozinho', label: 'Sozinho(a)' },
        { value: 'familia_direta', label: 'Família Direta' },
        { value: 'parente', label: 'Parente' },
        { value: 'grupo_amigos', label: 'Grupo de Amigos' },
        { value: 'outros', label: 'Outros' },
    ];

    const tempoPermanenciaOptions = [
        { value: '3', label: 'Até 3 meses' },
        { value: '6', label: 'Até 6 meses' },
        { value: '12', label: 'Até 12 meses' },
    ]

    const pagamentoOptions = [
        { value: 'fornecer_dados', label: 'Meu cartão de crédito internacional (desejo fornecer os dados para pagamento)' },
        { value: 'ligacao_fornecer_dados', label: 'Meu cartão de crédito internacional (me liguem no momento do pagamento que repasso as informações para pagamento)' },
    ]

    return(
        <div className={'p-4'}>
            <div className={`${
                state.conclusao_formulario ?
                    'p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400'
                    :
                    'p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400'
            }`}
                 role="alert">
                {
                    state.conclusao_formulario
                        ? 'Formulário finalizado pelo solicitante'
                        : 'Formulário não foi finalizado pelo solicitante'
                }
            </div>
            <div>
                <table className='ps-4 pe-4 table-fixed border-collapse border border-slate-400'>
                    <tbody>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sobrenome:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.sobrenome}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nome}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nomes anteriores:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nomes_anteriores ? state.cliente.nomes_anteriores : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sexo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.sexo ? state.cliente.sexo === 'm' ? 'Masculino' : 'Feminino' : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>CPF:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cpf ? state.cliente.cpf : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de Nascimento (Dia/Mês/Ano):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nascimento ? Dates.convertEnToBr(state.cliente.nascimento) : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade e estado de Nascimento:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cidade_natal ? state.cliente.cidade_natal : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País de Nascimento:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.pais_natal ? state.cliente.pais_natal : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual o seu estado civil?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.estado_civil && stadoCivilOptions.filter(filt => filt.value === state.cliente.estado_civil)[0]['label']}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome completo e data de nascimento do cônjuge:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.nome_completo_conjuge ? state.cliente.nome_completo_conjuge : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço residencial completo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.endereco ? state.cliente.endereco : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cidade ? state.cliente.cidade : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cep:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.cep ? state.cliente.cep : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.pais_natal ? state.cliente.pais_natal : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone residencial:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.telefone_secundario ? state.cliente.telefone_secundario : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone celular:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.telefone ? state.cliente.telefone : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone trabalho:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.telefone_trabalho ? state.cliente.telefone_trabalho : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>E-mail:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente.usuario ? state.cliente.usuario.email : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Número do Passaporte:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? state.passaporte.numero : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Foi condenado(a) por um crime ou delito em
                            qualquer país (incluindo qualquer caso que
                            agora tenha sido removido dos registros
                            oficiais):</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.condenado_por_crime ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_condenado_por_crime ? state.declaracaro_carater_pessoal.dados_condenado_por_crime : '' : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Foi acusado(a) de qualquer crime que está
                            aguardando uma ação legal?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.acusado_por_crime ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_acusado_por_crime ? state.declaracaro_carater_pessoal.dados_acusado_por_crime : '' : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Foi acusado(a) de qualquer delito criminal ou
                            outro em razão de doença mental, loucura ou
                            debilidade mental?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.acusado_por_delito ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_acusado_por_delito ? state.declaracaro_carater_pessoal.dados_acusado_por_delito : '' : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Foi expulso(a) ou deportado(a) de qualquer
                            país?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.expulso_pais ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_expulso_pais ? state.declaracaro_carater_pessoal.dados_expulso_pais : '' : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Deixou algum país para evitar ser expulso(a)
                            ou deportado(a)?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.deixou_pais ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_deixou_pais ? state.declaracaro_carater_pessoal.dados_deixou_pais : '' : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Foi expulso(a) ou teve um pedido para sair de
                            qualquer país?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.expulso_pais ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_expulso_pais ? state.declaracaro_carater_pessoal.dados_expulso_pais : '' : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cometeu, ou se envolveu na prática de crimes
                            de guerra contra a humanidade ou direitos
                            humanos?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.crimes_guerra ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_crimes_guerra ? state.declaracaro_carater_pessoal.dados_crimes_guerra : '' : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Serviu nas forças armadas, policiais militares
                            ou paramilitares, voluntárias ou outro não, no
                            território da ex-Yugoslavia* entre 1991-2000?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.serviu_forcas_armadas ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Esteve envolvidos em quaisquer atividades
                            que possam representar um risco para a
                            segurança nacional da Austrália?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.atividade_risco_pais ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_atividade_risco_pais ? state.declaracaro_carater_pessoal.dados_atividade_risco_pais : '' : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Tem quaisquer dívidas com o Governo
                            australiano ou qualquer autoridade pública na
                            Austrália?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.dividas_pais ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_dividas_pais ? state.declaracaro_carater_pessoal.dados_dividas_pais : '' : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Se envolveu em qualquer atividade, ou foi
                            acusado(a) de qualquer delito, relacionado
                            com o movimento ilegal de pessoas para
                            qualquer país?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.movimento_ilegal ? 'Sim' : 'Não') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso tenha respondido Sim, especifique:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? state.declaracaro_carater_pessoal.dados_movimento_ilegal ? state.declaracaro_carater_pessoal.dados_movimento_ilegal : '' : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Serviu uma das forças militares, patrocinados
                            pelo estado ou milícia privada, submetido(a) a
                            qualquer treinamento militar ou paramilitar,
                            ou treinamento no uso de armas ou
                            explosivos, excepto no curso de serviço
                            militar obrigatório?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracaro_carater_pessoal ? (state.declaracaro_carater_pessoal.serviu_forcas_militares ? 'Sim' : 'Não') : ''}</td>
                    </tr>


                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País da Emissão:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? (state.passaporte.pais_emissor ? state.passaporte.pais_emissor : '') : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você é cidadão do país emissor do seu passaporte?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? (state.passaporte.cidadao_pais_emissor ? 'Sim' : 'Não')  : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Em caso negativo, qual o seu status
                            imigratório?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? (state.passaporte.status_imigratorio ? state.passaporte.status_imigratorio : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual a sua nacionalidade:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.cliente ? (state.cliente.nacionalidade ? state.cliente.nacionalidade : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de emissão do passaporte:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? Dates.convertEnToBr(state.passaporte.data_emissao) : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de validade do passaporte:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? Dates.convertEnToBr(state.passaporte.data_validade) : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Local de emissão / autoridade emissora:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? (state.passaporte.cidade_emissao ? state.passaporte.cidade_emissao : 1) : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você possui outra cidadania?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? (state.passaporte.outra_cidadania ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Em caso afirmativo, por favor informar o país,
                            e se possui passaporte do mesmo.</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? (state.passaporte.pais_outra_cidadania ? state.passaporte.pais_outra_cidadania : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você tem passaportes antigos, ou de outra
                            nacionalidade que estejam com você?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? (state.passaporte.passaporte_antigo ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Em caso afirmativo, por favor informar o
                            número, data de emissão e validade do
                            passaporte.</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.passaporte ? (state.passaporte.dados_passaporte_antigo ? state.passaporte.dados_passaporte_antigo : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Ocupação Atual:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? (state.ocupacao.ocupacao ? state.ocupacao.ocupacao : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Ocupação Atual:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? (state.ocupacao.nome_empresa ? state.ocupacao.nome_empresa : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de início do trabalho ou da aposentadoria:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? (state.ocupacao.data_entrada ? Dates.convertEnToBr(state.ocupacao.data_entrada) : '') : ''}</td>
                    </tr>

                    {/*<tr>
                                    <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Quanto tempo você estuda ou trabalha nesta instituição?</td>
                                    <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? (state.ocupacao.tempo_estudo ? state.ocupacao.tempo_estudo : '') : ''}</td>
                                </tr>*/}

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo do empregador ou escola:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? (state.ocupacao.endereco ? state.ocupacao.endereco : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cidade do empregador ou escola:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? (state.ocupacao.cidade ? state.ocupacao.cidade : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Estado do empregador ou escola:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? (state.ocupacao.estado ? state.ocupacao.estado : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>CEP do empregador ou escola:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? (state.ocupacao.cep ? state.ocupacao.cep : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>País do empregador ou escola:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? (state.ocupacao.pais ? state.ocupacao.pais : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone do empregador ou escola:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.ocupacao ? (state.ocupacao.telefone ? state.ocupacao.telefone : '') : ''}</td>
                    </tr>


                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você vai se manter na Austrália, por conta própria?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem ? (state.custeio_da_viagem.se_mantera ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome completo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem ? (state.custeio_da_viagem.nome ? state.custeio_da_viagem.nome : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual o parentesco/relação com você?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem ? (state.custeio_da_viagem.relacao ? state.custeio_da_viagem.relacao : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem ? (state.custeio_da_viagem.endereco ? state.custeio_da_viagem.endereco : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefones de contato</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem ? (state.custeio_da_viagem.telefone ? state.custeio_da_viagem.telefone : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Tipo de apoio:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem ? (state.custeio_da_viagem.tipo_apoio ? state.custeio_da_viagem.tipo_apoio : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Tipo de apoio:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.custeio_da_viagem ? (state.custeio_da_viagem.tipo_apoio ? state.custeio_da_viagem.tipo_apoio : '') : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Vocês está em qual país neste momento de solicitação do visto?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.pais_atual_visto ? state.pais_atual_visto  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Qual a sua situação imigratória?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.situacao_imigratoria && situacaoImigratoriaOptions.filter(filt => filt.value === state.situacao_imigratoria)[0]['label']}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Caso não seja cidadão do país; informe os
                            motivos da sua situação imigratória atual e
                            data de término do seu visto vigente.</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.motivo_situacao_imigratoria ? state.motivo_situacao_imigratoria  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Motivo da viagem:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.motivo_viagem ? state.motivo_viagem  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Quanto tempo você pretende ficar na Austrália?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.quanto_tempo_pais && tempoPermanenciaOptions.filter(filt => filt.value === state.quanto_tempo_pais)[0]['label']}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data da Chegada:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.data_prevista_viagem ?  Dates.convertEnToBr(state.data_prevista_viagem) : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Data de partida:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.data_prevista_viagem ?  Dates.convertEnToBr(state.data_prevista_retorno) : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo na Austrália:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.endereco_pais ? state.endereco_pais  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já visitou ou esteve na Austrália anteriormente?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visitou_pais_anteriormente ? (state.visitou_pais_anteriormente ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo na Austrália:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.numero_visto_pais ? state.numero_visto_pais  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você irá visitar outro país, saindo da Austrália
                            e depois retornará para o país?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.visita_outros_paises ? (state.visita_outros_paises ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Pretende estudar na Austrália por até 12
                            semanas, durante sua estadia no país?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.estudar_no_pais ? (state.estudar_no_pais ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Em caso positivo, informar nome da escola e
                            curso a ser estudado.</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_estudo_no_pais ? state.informacoes_estudo_no_pais  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você tem algum familiar, amigo, ou conhecido
                            na Austrália, que pretende visitar durante sua
                            estadia?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.familiar_no_pais ? (state.familiar_no_pais ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Parentes na Austrália:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_estudo_no_pais ? state.informacoes_estudo_no_pais  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Quanto tempo você pretende ficar na Austrália?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.quem_vai_junto && quemViajaOptions.filter(filt => filt.value === state.quem_vai_junto)[0]['label']}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Informe nome completo, data de nascimento e
                            parentesco dos seus acompanhantes na
                            viagem.</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_familiar ? state.dados_familiar  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você tem filhos?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.filhos ? (state.filhos ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Em caso afirmativo, por favor informe o nome
                            completo e data de nascimento de cada um
                            deles. E se viajarão com você.</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.informacoes_filhos ? state.informacoes_filhos  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Parentes que ficam no Brasil:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.familiares_brasil ? state.familiares_brasil  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você tem algum filho com menos de 18 anos
                            de idade viajando com você para a Austrália,
                            que estão incluídos no seu passaporte e
                            devem ser incluídos nesta aplicação?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.filho_menor_acompanha ? (state.filho_menor_acompanha ? 'Sim' : 'Não')  : ''}</td>
                    </tr>



                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nos últimos 10 anos, você ou outra pessoa
                            incluída nesse processo, já visitou ou morou
                            em outro país?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.eua_visitado ? 'Sim' : 'Não')  : ''}</td>
                    </tr>
                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Em caso afirmativo, por favor informar
                            detalhes da viagem e/ou visto, tais como: país
                            visitado, data da viagem, motivo da viagem,
                            duração da viagem, tipo de visto</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.paises_visitados ? state.saude_historico_imigracional.paises_visitados : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você possui algum visto aprovado e válido?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.visto_ja_solicitado ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Em caso afirmativo, por favor informar
                            detalhes do visto, tais como: país emissor,
                            tipo de visto, data de emissão e validade</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.visto_ja_solicitado_obs ? state.saude_historico_imigracional.visto_ja_solicitado_obs : '')  : ''}</td>
                    </tr>


                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você já teve algum visto negado para
                            qualquer país?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.visto_negado_outro_pais ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Em caso afirmativo, por favor informar
                            detalhes da viagem e/ou visto, tais como: país
                            visitado, data da viagem, motivo da viagem,
                            duração da viagem, tipo de visto</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.dados_visto_negado ? state.saude_historico_imigracional.dados_visto_negado : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você, ou outra pessoa incluída neste
                            processo, tem a intenção de utilizar um
                            hospital ou uma unidade de saúde (incluindo
                            casas de repouso), enquanto estiver na
                            Austrália?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.usar_hospital ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você, ou outra pessoa incluída neste
                            processo, tem a intenção de trabalhar ou
                            estudar na área de saúde, enquanto estiver na
                            Austrália?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.trabalhar_estudar ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você, ou outra pessoa incluída neste
                            processo, sofre ou já sofreu de tuberculose?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.tuberculose ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Esteve em contato direto com algum familiar,
                            com tuberculose?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.parente_tuberculose ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Já tirou um raio-x onde constou uma
                            anormalidade?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.raio_x_anormalidade ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Durante a sua visita à Austrália, você ou
                            qualquer outra pessoa incluída neste
                            processo, precisará de custos médicos, ou
                            exigir tratamento ou acompanhamento
                            médico para:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.custos_medicos ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cancer:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.cancer ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Doença cardíaca:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.doenca_cardiaca ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Hepatite B ou C:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.hepatite ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Doença hepática:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.doenca_hepatica ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>HIV, incluindo AIDS:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.hiv ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Doença renal, incluindo diálise:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.doenca_renal ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Doença mental:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.disturbios ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Gravidez:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.gravidez ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Doença respiratória que exige internação ou terapia de oxigênio:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.problemas_respiratorios ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Outros:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.outros ? state.saude_historico_imigracional.outros : '')   : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Você, ou qualquer outra pessoa incluída neste
                            processo, requer assistência com mobilidade
                            ou alguma assistência especial devido a uma
                            condição médica?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.saude_historico_imigracional ? (state.saude_historico_imigracional.pcd ? 'Sim' : 'Não')  : ''}</td>
                    </tr>



                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>O aplicante principal é menor de idade?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.aplicante_menor ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Sobrenome:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.sobrenome ? state.dados_responsaveis.sobrenome : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Nome:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.nome ? state.dados_responsaveis.nome : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Endereço completo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.endereco ? state.dados_responsaveis.endereco : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Cep:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.cep ? state.dados_responsaveis.cep : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Telefone:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.telefone ? state.dados_responsaveis.telefone : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Celular:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.celular ? state.dados_responsaveis.celular : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Grau de Parentesco:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.grau_parentesco ? state.dados_responsaveis.grau_parentesco : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Número do Passaporte ou RG:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.passaporte_rg ? state.dados_responsaveis.passaporte_rg : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Sobrenome:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_sobrenome ? state.dados_responsaveis.segundo_sobrenome : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Nome:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_nome ? state.dados_responsaveis.segundo_nome : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Endereço completo:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_endereco ? state.dados_responsaveis.segundo_endereco : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Cep:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_cep ? state.dados_responsaveis.segundo_cep : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Telefone:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_telefone ? state.dados_responsaveis.segundo_telefone : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Celular:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_celular ? state.dados_responsaveis.segundo_celular : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Grau de Parentesco:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_grau_parentesco ? state.dados_responsaveis.segundo_grau_parentesco : '')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>2 - Número do Passaporte ou RG:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.dados_responsaveis ? (state.dados_responsaveis.segundo_passaporte_rg ? state.dados_responsaveis.segundo_passaporte_rg : '')  : ''}</td>
                    </tr>



                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Eu afirmo ter fundos suficientes para cobrir
                            todos os custos associados com a visita para
                            a Austrália:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.fundos_suficientes ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Compreendo que o visto que estou
                            solicitando não me autoriza trabalhar ou
                            praticar atividades de negócios na Austrália:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.visto_nao_trabalho ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Compreendo que o visto que estou
                            solicitando não me permite estudar por um
                            período maior de 3 meses na Austrália:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.visto_nao_estudo ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>A minha intenção de visitar a Austrália é
                            genuína e eu agirei de acordo com as
                            condições e período de estadia do visto:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.intencao_genuina ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Eu reconheço que a condição 8503 pode ser
                            imposta sobre o meu visto de turismo, não me
                            permitido exceder o prazo de estadia no país:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.condicao_8503 ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Se o meu visto de turismo vier com a
                            condição 8503 - que não permite uma estadia
                            maior, que o solicitado, concordo em
                            respeitar a condição:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.respeitar_condicao_8503 ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Se concedido o Visto, é de meu dever
                            informar o escritório do governo australiano,
                            caso haja alguma alteração na minha viagem
                            para a Austrália.</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.informar_escritorio_pais ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Como sua taxa consular será paga?</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final && pagamentoOptions.filter(filt => filt.value === state.declaracao_final.taxa_paga)[0]['label']}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Eu declaro de boa fé todos os detalhes
                            relevantes que me foram solicitados neste
                            formulário:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.declaracao_boa_fe ? 'Sim' : 'Não')  : ''}</td>
                    </tr>

                    <tr>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>Eu afirmo ter lido e entendido as informações
                            fornecidas a mim nesta aplicação:</td>
                        <td width={'50%'} className={'fw-bold border border-slate-300 p-2'}>{state.declaracao_final ? (state.declaracao_final.afirmacao_informacoes ? 'Sim' : 'Não')  : ''}</td>
                    </tr>


                    </tbody>
                </table>
            </div>
        </div>
    )
}