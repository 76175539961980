import {useEffect, useState} from "react";
import processosApi from "../../../services/processosApi";
import FormularioF1Turista from "./FormularioF1Turista";
import {Spinner} from "@react-pdf-viewer/core";
import FormularioB1B2 from "./FormularioB1B2";
import FormularioAusVisitante from "./FormularioAusVisitante";
import FormularioAusEstudante from "./FormularioAusEstudante";
import FormularioCAEstudante from "./FormularioCAEstudante";
import FormularioCATRV from "./FormularioCATRV";

interface IClienteFormData {
    processoId: number | string;
    tipoVistoId: number | string;
}
export default function ClienteFormData({processoId, tipoVistoId}:IClienteFormData){
    const [state, setState]: any = useState();
    const [content, setContent]: any = useState(<></>);
    const [loading, setLoading] = useState(true);
    useEffect(() => { getData() },[])

    const getData = async () => {

        if (tipoVistoId === 1 || tipoVistoId === 2) {
            const {data, isError} = await processosApi.getFormularioData(processoId, '/b1b2/');
            if(tipoVistoId === 1){
                setContent(<FormularioB1B2 state={data.data}/>)
            }else{
                setContent(<FormularioF1Turista state={data.data}/>)

            }
        }
        if (tipoVistoId === 3 ||tipoVistoId === 4 ){
            const {data, isError} = await processosApi.getFormularioData(processoId, '/ca/');
            if (tipoVistoId === 3){
                setContent(<FormularioCAEstudante state={data.data}/>)
            }
            if (tipoVistoId === 4){
                setContent(<FormularioCATRV state={data.data}/>)
            }
        }

        if (tipoVistoId === 5){
            const {data, isError} = await processosApi.getFormularioData(processoId, '/aus/');
            setContent(<FormularioAusVisitante state={data.data}/>)
        }
        if (tipoVistoId === 6){
            const {data, isError} = await processosApi.getFormularioData(processoId, '/aus/estudante/');
            setContent(<FormularioAusEstudante state={data.data}/>)
        }



        setLoading(false);
    }
    if (loading) return (<div className={'text-[16px] flex justify-center'}><Spinner  /></div>);
    return(content)
}