/* eslint-disable @typescript-eslint/ban-types */
import React, { useRef } from 'react';
import { TriggerParams } from '../../components/modal/Modal';

export default function useModal() {
  const ref = useRef<any>(null);
  let closeModal: Function | null = null;

  function open(params?: {
    onOpen: Function;
  }) {
    ref.current?.click();
    setTimeout(() => {
      if (params?.onOpen) params.onOpen();
    }, 50);
  }

  function close() {
    if (closeModal) closeModal();
  }

  function trigger(params: TriggerParams) {
    closeModal = params.close;
    return <></>;
  }

  return {
    ref, 
    open,
    close,
    modalProps: {
      innerRef: ref,
      trigger
    }
  };
}
