export interface ILabelText {
    className?: any;
    textLael: string;
    contentLabel: any;
}
export default function LabelText({className, textLael, contentLabel}:ILabelText) {
    return(
        <div className={className}>
            <label className="block text-gray-600 text-sm {/*font-bold mb-2*/}">
                {textLael}
            </label>
            <div className={'text-gray-800 font-bold text-[16px]'}>
                {contentLabel}
            </div>
        </div>
    )
}