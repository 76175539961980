import SideBar from "../SideBar";
import Navbar, { INavBar } from "../NavBar";
import { useEffect } from "react";

export interface IPageContainer {
    children: React.ReactNode;
    pageTitle?: INavBar['pageTitle'];
    pageTitleSecond?: string;
}

export default function PageContainer({children, pageTitle, pageTitleSecond}: IPageContainer) {
    useEffect(() => {
        document.title = `Visary | ${pageTitle}`
    }, [pageTitle]);

    return(
        <div className={'flex'}>
            <SideBar/>
            <div className={'flex flex-col w-full mb-5'}>
                <Navbar admin={true} pageTitle={pageTitle} pageTitleSecond={pageTitleSecond}/>
                <div className={'md:px-5 md:pt-5  md:h-[calc(100vh_-_95px)] overflow-auto'}>
                    {children}
                </div>

            </div>

        </div>
    )
}