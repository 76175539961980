import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import Input from "../../../../../components/input/Input";
import dadosViagensAnterioresApi from "../../../../../services/dadosViagensAnterioresApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";

export interface IDadosViagensAnteriores{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setDadosViagemAnterior?: any;
}

export default function DadosViagensAnteriores({processo, setProcesso, setTelaNumero, telanumero, setDadosViagemAnterior}: IDadosViagensAnteriores) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        visto_ja_emitido: 0,
        data_local_visto_ja_emitido: '',
        ja_esteve_no_pais: 0,
        informacoes_ultimas_viagens: '',
        ja_teve_visto_negado: 0,
        informacoes_visto_negado: '',
        carteira_motorista: 0,
        informacoes_carteira_motorista: '',
        viagem_recusada: '',
    });

    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };

    useEffect(() => {
        getDadosViagem()
    },[]);

    const getDadosViagem = async () => {
        const {data, isError} = await dadosViagensAnterioresApi.one(processo.id);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        const response = data.data;
        if (Object.values(response).length){
            response.carteira_motorista = parseInt(response.carteira_motorista);
            setState(response);
        }
    }

    async function onSubmit() {
        const {data, isError} = state.id ? await dadosViagensAnterioresApi.update(state.id, state) : await dadosViagensAnterioresApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        let numero = state.visto_ja_emitido ? telanumero + 1 : telanumero + 2;
        setDadosViagemAnterior(state);
        setTelaNumero(numero);

    }
    return(
        <>
            <HeaderForm
                titulo={'Dados viagens anteriores'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Você já teve um visto emitido para os Estados Unidos?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'visto_ja_emitido')}
                                            value={1}
                                            id="checked-visto_ja_emitido-s"
                                            type="radio"
                                            checked={state.visto_ja_emitido === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-visto_ja_emitido-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.visto_ja_emitido === 0 }
                                            onChange={e => handleChange(0,'visto_ja_emitido')}
                                            id="checked-visto_ja_emitido-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-visto_ja_emitido-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Input
                            required={!!state.visto_ja_emitido}
                            className={'md:col-span-2'}
                            label={'Se sim, informe a data de emissão e validade, e o local de emissão do visto.'}
                            name={'data_local_visto_ja_emitido'}
                            setValue={e => handleChange(e,'data_local_visto_ja_emitido')}
                            value={state.data_local_visto_ja_emitido}
                        />

                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Você já esteve nos Estados Unidos?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'ja_esteve_no_pais')}
                                            value={1}
                                            id="checked-ja_esteve_no_pais-s"
                                            type="radio"
                                            checked={state.ja_esteve_no_pais === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-ja_esteve_no_pais-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.ja_esteve_no_pais === 0 }
                                            onChange={e => handleChange(0,'ja_esteve_no_pais')}
                                            id="checked-ja_esteve_no_pais-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-ja_esteve_no_pais-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Input
                            required={!!state.ja_esteve_no_pais}
                            className={'md:col-span-2'}
                            label={'Se sim, informe a data de chegada e a duração das últimas cinco visitas.'}
                            name={'informacoes_ultimas_viagens'}
                            setValue={e => handleChange(e,'informacoes_ultimas_viagens')}
                            value={state.informacoes_ultimas_viagens}
                        />

                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Você já teve um visto negado para os Estados Unidos?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'ja_teve_visto_negado')}
                                            value={1}
                                            id="checked-ja_teve_visto_negado-s"
                                            type="radio"
                                            checked={state.ja_teve_visto_negado === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-ja_teve_visto_negado-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.ja_teve_visto_negado === 0 }
                                            onChange={e => handleChange(0,'ja_teve_visto_negado')}
                                            id="checked-ja_teve_visto_negado-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-ja_teve_visto_negado-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Input
                            required={!!state.ja_teve_visto_negado}
                            className={'md:col-span-2'}
                            label={'Se sim, informe a data e a causa provável.'}
                            name={'informacoes_visto_negado'}
                            setValue={e => handleChange(e,'informacoes_visto_negado')}
                            value={state.informacoes_visto_negado}
                        />

                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Você possui uma carteira de motorista americana?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={() => handleChange(1,'carteira_motorista')}
                                            value={1}
                                            id="checked-carteira_motorista-s"
                                            type="radio"
                                            checked={state.carteira_motorista === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-carteira_motorista-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.carteira_motorista === 0 }
                                            onChange={e => handleChange(0,'carteira_motorista')}
                                            id="checked-carteira_motorista-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-carteira_motorista-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <Input
                            required={!!state.carteira_motorista}
                            className={'md:col-span-2'}
                            label={'Se sim, por favor informe o número da licença e estado emissor da carteira.'}
                            name={'informacoes_carteira_motorista'}
                            setValue={e => handleChange(e,'informacoes_carteira_motorista')}
                            value={state.informacoes_carteira_motorista}
                        />

                    </div>
                    <div className={'grid grid-cols-1 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            label={'Você já teve uma autorização de viagem (ESTA) negada ou recusada pelo Departamento de Homeland Security através do Sistema Eletrônico de Autorização de viagens (ESTA)?'}
                            name={'viagem_recusada'}
                            setValue={e => handleChange(e,'viagem_recusada')}
                            value={state.viagem_recusada}
                        />
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}