import CurrencyInput from "react-currency-input-field";
import Str from "../../helpers/Str";
import React from "react";
import {IInputCurrency} from "../../types";

export default function InputCurrency({
    label,
    value,
    onChange,
    onBlur,
    className,
    name,
    setValue,
    decimalSeparator,
    thousandSeparator,
    decimalScale,
    required,
    error,
    legenda,
    moeda
}: IInputCurrency){
    let moedaFinal = { locale: 'pt-BR', currency: 'BRL' };
    switch (moeda) {
        case 'br':
            moedaFinal = { locale: 'pt-BR', currency: 'BRL' };
            break;
        case 'eua':
            moedaFinal = { locale: 'en-US', currency: 'USD' };
            break
        default:
            moedaFinal = { locale: 'pt-BR', currency: 'BRL' };
            break;
    }
    return(
        <div className={`${className}`}>
            {
                label  ?
                    <label
                        className="text-gray-800 font-medium"
                    >
                        {label} {required ? <span className={'text-[#c22929]'}>*</span> : ''}
                    </label>
                    :
                    ''
            }

            <CurrencyInput
                id="input-example"
                className={Str.tw(
                    'py-2 px-1',
                    'bg-light border border-border rounded-md outline-none px-3  w-full',
                    'focus:bg-background focus:border-confianca transition'
                )}
                intlConfig={moedaFinal}
                name={name}
                value={value}
                decimalsLimit={2}
                onValueChange={(value, name) => setValue ? setValue(value) : onChange && onChange(value, name)}
                onBlur={onBlur}
                decimalSeparator=","
                groupSeparator="."
                required={required}
            />
            {!!error?.length && <span className="text-red-600 text-sm">{error}</span>}
            {
                legenda ?
                    <div className={'text-sm text-gray-400'}>
                        {legenda}
                    </div> :''
            }
        </div>
    )
}