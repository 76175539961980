import { Line, Circle } from 'rc-progress';
import DadosPessoaisEua from "../Formularios/FormulariosEua/DadosPessoais";
import {useEffect, useState} from "react";
import QuantidadeFormularios from "../../../helpers/QuantidadeFormularios";
import FormularioEua from "../Formularios/FormulariosEua";
import FormularioCa from "../Formularios/FormulariosCa";
import FormularioAusV from "../Formularios/FormulariosAusVisitante";
import FormularioAusEstudante from "../Formularios/FormularioAusEstudante";
export interface IProcessosFichaCadastral{
    processo?: any;
    onClickForm?: any;
}

export default function ProcessosFichaCadastral({processo, onClickForm}: IProcessosFichaCadastral) {
    const [loading, setLoading] = useState(true);
    const [component, setComponent]: any = useState();
    useEffect(() => {
        switch (processo.tipo_visto_id) {
            case 1:
            case 2:
                setComponent(<FormularioEua processo={processo} onClickForm={onClickForm}/>);
                break;
            case 3:
            case 4:
                setComponent(<FormularioCa processo={processo}/>);
                break;
            case 5:
                setComponent(<FormularioAusV processo={processo}/>);
                break
            case 6:
                setComponent(<FormularioAusEstudante processo={processo}/>);
                break
        }
        setLoading(false);
    }, []);

    if (loading) return <div>Carregando</div>
    return(component)
}