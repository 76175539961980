import {useEffect, useState} from "react";
import DadosPessoaisCa from "./DadosPessoais";
import DadosConjugeCa from "./DadosConjugeCa";
import DadosConjugeEua from "../FormulariosEua/DadosConjuge";
import ConjugeAnteriorCa from "./ConjugeAnterior";
import PassaporteCa from "./PassaporteCa";
import DadosContatoCa from "./DadosContatoCa";
import DadosViagemCa from "./DadosViagemCa";
import PermissaoEstudosCa from "./PermissaoEstudosCa";
import DadosEducacionaisCa from "./DadosEducacionaisCa";
import InformacoesProfissionaisCa from "./InformacoesProfissionaisCa";
import DetalhesEmpregaticiosEstudoAposentadoria from "./DetalhesEmpregaticiosEstudoAposentadoria";
import DetalhamentoEmpregoAnteriorCA from "./DetalhamentoEmpregoAnteriorCA";
import SaudeHistoricoImigracional from "./SaudeHistoricoImigracionalCa";
import PerguntaSegurancaCa from "./PerguntaSegurancaCa";
import DadosFamiliaresCa from "./DadosFamiliaresCa";
import DadosFilhos from "./DadosFilhosCa";
import DadosFilhosCa from "./DadosFilhosCa";
import InformacoesAdicionaisViagemCa from "./InformacoesAdicionaisViagemCa";
import Conclusao from "../Conclusao";


export interface IFormularioEua{
    processo?: any;
}
export default function FormularioCa({processo}: IFormularioEua){
    const [process, setProcess] = useState(processo);
    const [telanumero, setTelaNumero] = useState(1);
    const [informacoesProfissionais, setInformacoesProfissionais]: any = useState([]);
    const [dtateDadosDosPais, setStateDadosDosPais]: any = useState([]);
    const [conjujeAnterior, setConjujeAnterior]: any = useState();
    const [component, setComponent] = useState(<DadosPessoaisCa processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero}  telanumero={telanumero}/>);
    useEffect(() => {
        controlleTela(telanumero);
    }, [telanumero])
    const controlleTela = (numero: number) =>{
        switch (numero) {
            case 1:
                setComponent(<DadosPessoaisCa processo={process} setProcesso={setProcess} setTelaNumero={setTelaNumero} telanumero={telanumero}/>);
                break;
            case 2:
                setComponent(
                    <DadosConjugeCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        setConjujeAnterior={setConjujeAnterior}
                    />
                    );
                break;
            case 3:
                setComponent(
                    <ConjugeAnteriorCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 4:
                setComponent(
                    <PassaporteCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        conjujeAnterior={conjujeAnterior}
                    />
                );
                break;
            case 5:
                setComponent(
                    <DadosContatoCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 6:
                setComponent(
                    <DadosViagemCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 7:
                setComponent(
                    <PermissaoEstudosCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 8:
                setComponent(
                    <DadosEducacionaisCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 9:
                setComponent(
                    <InformacoesProfissionaisCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        setInformacoesProfissionais={setInformacoesProfissionais}
                    />
                );
                break;
            case 10:
                setComponent(
                    <DetalhesEmpregaticiosEstudoAposentadoria
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 11:
                setComponent(
                    <DetalhamentoEmpregoAnteriorCA
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 12:
                setComponent(
                    <SaudeHistoricoImigracional
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        informacoesProfissionais={informacoesProfissionais}
                    />
                );
                break;
            case 13:
                setComponent(
                    <PerguntaSegurancaCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 14:
                setComponent(
                    <DadosFamiliaresCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                        setStateDadosDosPais={setStateDadosDosPais}
                    />
                );
                break;
            case 15:
                setComponent(
                    <DadosFilhosCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 16:
                setComponent(
                    <InformacoesAdicionaisViagemCa
                        processo={process}
                        setProcesso={setProcess}
                        setTelaNumero={setTelaNumero}
                        telanumero={telanumero}
                    />
                );
                break;
            case 17:
                setComponent(<Conclusao/>);
                break;

        }
    }

    return (component)
}