import { IconType } from 'react-icons/lib/cjs';
import { FiHome } from "react-icons/fi";
import { FaUsers } from "react-icons/fa6";
import { CgNotes } from "react-icons/cg";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdOutlineAttachMoney } from "react-icons/md";
import { FaRegChartBar } from "react-icons/fa";
import { CiUser } from "react-icons/ci";
import { FaUserTie } from "react-icons/fa6";
export interface menusAtendenten {
    title: string;
    path?: string;
    icon?: IconType;
    subMenus?: menusAtendenten[];
}


export default [
    {
        title: 'Clientes',
        path: '/clientes',
        icon: FaUsers,
    },
    {
        title: 'Processos',
        path: '/processos',
        icon: CgNotes,
    },
    {
        title: 'Cronograma',
        path: '/cronogramas',
        icon: FaRegCalendarAlt,
    },
    /*{
        title: 'Usuários',
        path: '/usuarios',
        icon: CiUser,
    },*/
] as menusAtendenten[];