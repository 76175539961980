import React, {useEffect, useState} from "react";
import processosApi from "../../../../../services/processosApi";
import Alert from "../../../../../helpers/Alert";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import HeaderForm from "../../../../../components/headerForm";
import Form from "../../../../../components/form/Form";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import Button from "../../../../../components/button/Button";

export interface IDadosViagemAusEstudante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosViagemAusEstudante({processo, setProcesso, setTelaNumero, telanumero}: IDadosViagemAusEstudante) {
    const [state, setState] = useState({
        id: '',
        pais_atual_visto: '',
        situacao_imigratoria: 'cidadao',
        visto_solicitado: 0,
        financiamento_governo: 0,
        lingua_preferencia_entrevista: '',
        endereco_pais: '',
        concordo_consulado_contato: 0
    });

    const [situacaoImigratoriaOptions, setSituacaoImigratoriaOptions] = useState([
        { value: 'cidadao', label: 'Cidadão' },
        { value: 'visitante', label: 'Visitante' },
        { value: 'residente_permanente', label: 'Residente Permanente' },
        { value: 'estudante', label: 'Estudante Oficial - com visto de estudante' },
        { value: 'visto_trabalho', label: 'Trabalhador - com visto de trabalho' },
        { value: 'outros', label: 'Outros' },
        { value: 'ilegal', label: 'Ilegal' },
    ]);
    useEffect(()=> {
        getDataProcesso();

    }, [])

    const getDataProcesso = async () =>{
        const {data, isError} = await processosApi.one(processo.id);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        const response = data.data;
        if (Object.values(response).length) {
            setProcesso(response);
            setState({
                id: response.id,
                pais_atual_visto: response.pais_atual_visto ? response.pais_atual_visto : '',
                situacao_imigratoria: response.situacao_imigratoria ? response.situacao_imigratoria : 'cidadao',
                visto_solicitado: response.visto_solicitado ? response.visto_solicitado : 0,
                financiamento_governo: response.financiamento_governo ? response.financiamento_governo : 0,
                concordo_consulado_contato: response.concordo_consulado_contato ? response.concordo_consulado_contato : 0,
                lingua_preferencia_entrevista: response.lingua_preferencia_entrevista ? response.lingua_preferencia_entrevista : '',
                endereco_pais: response.endereco_pais ? response.endereco_pais : ''
            })
        }
    }

    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    async function onSubmit() {
        Alert.await("Salvando dados");
        const data = await processosApi.update(state.id, state);
        if (data.isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }

        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados da viagem'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid grid-cols-5 gap-4 mt-5'}>
                        <Input
                            className={'col-span-2'}
                            label={'Vocês está em qual país neste momento de solicitação do visto'}
                            placeholder={'Vocês está em qual país neste momento de solicitação do visto'}
                            large={true}
                            name={'pais_atual_visto'}
                            setValue={e => handleChange(e,'pais_atual_visto')}
                            value={state.pais_atual_visto}
                            required
                        />
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Situação imigratoria"}
                                selected={situacaoImigratoriaOptions.filter((opt: any) => opt.value === state.situacao_imigratoria)}
                                options={situacaoImigratoriaOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'situacao_imigratoria')}
                                /*legenda={'Informe a finalidade da sua viagem. Caso não seja nenhum destes motivos, entre em contato com nossa Central de Atendimento, pois provavelmente este visto não se aplica a você.'}*/
                            />
                        </div>
                        <Input
                            className={'col-span-2'}
                            label={'Língua de preferência, em caso de entrevista'}
                            placeholder={'Língua de preferência, em caso de entrevista'}
                            large={true}
                            name={'lingua_preferencia_entrevista'}
                            setValue={e => handleChange(e,'lingua_preferencia_entrevista')}
                            value={state.lingua_preferencia_entrevista}
                        />
                    </div>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você está solicitando este visto devido o encerramento das atividades do seu prestador de educação australiano?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_solicitado')}
                                        value={1}
                                        id="checked-visto_solicitado-s"
                                        type="radio"
                                        checked={state.visto_solicitado === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_solicitado-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_solicitado === 0 }
                                        onChange={e => handleChange(0,'visto_solicitado')}
                                        id="checked-visto_solicitado-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_solicitado-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você recebe ou receberá financiamento parcial ou total do Governo Australiano(Commonwealth)?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'financiamento_governo')}
                                        value={1}
                                        id="checked-financiamento_governo-s"
                                        type="radio"
                                        checked={state.financiamento_governo === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-financiamento_governo-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.financiamento_governo === 0 }
                                        onChange={e => handleChange(0,'financiamento_governo')}
                                        id="checked-financiamento_governo-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-financiamento_governo-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você concorda que o departamento consular entre em contato com você por telefone ou e-mail?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'concordo_consulado_contato')}
                                        value={1}
                                        id="checked-concordo_consulado_contato-s"
                                        type="radio"
                                        checked={state.concordo_consulado_contato === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-concordo_consulado_contato-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.concordo_consulado_contato === 0 }
                                        onChange={e => handleChange(0,'concordo_consulado_contato')}
                                        id="checked-concordo_consulado_contato-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-concordo_consulado_contato-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={'grid grid-cols-5 gap-4 mt-5'}>
                        <Input
                            className={'col-span-2'}
                            label={'Endereço'}
                            placeholder={'Endereço'}
                            large={true}
                            name={'endereco_pais'}
                            setValue={e => handleChange(e,'endereco_pais')}
                            value={state.endereco_pais}
                        />
                    </div>


                </div>

                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>

            </Form>
        </>
        )
}