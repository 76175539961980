import "./styles.css";
import Str from "../../../helpers/Str";
import { IoSearchOutline } from "react-icons/io5";
export interface IIputIcon{
    name: string
    classNameLabel?: string,
    value?: string | number,
    setValue(value: React.ChangeEvent<HTMLInputElement>): void;
    placeholder?: string,
}

const InputIcon = ({
                       classNameLabel,
                       name,
                       value,
                       setValue,
                       placeholder
}: IIputIcon) => {
    return(
        <label className={Str.tw('w-full flex relative mb-[2rem] border-2 border-gray-300 ', classNameLabel)} htmlFor="copy-button">
            <input
                className={'w-full p-[10px] placeholder:text-[#475467] text-gray-800 pr-[40px] text-[16px] focus:outline-none focus:ring-gray-300 focus:ring-2 text-ellipsis'}
                name={name}
                value={value ?? ''}
                type={'text'}
                aria-label="copy-button"
                onChange={(e) => setValue(e)}
                placeholder={placeholder}
            />
            <IoSearchOutline color={'#475467'} className={'absolute right-[12px] top-[10px] w-[24px] h-[24px]'} id="icon" />
            {/*<img
                id="icon"
                src="https://cdn4.iconfinder.com/data/icons/basic-user-interface-elements/700/copy-duplicate-multiply-clone-512.png"
                alt="icon"
            />*/}
        </label>
    )
}
export default InputIcon;