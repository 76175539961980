import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import vistosApi from "../../../../../services/vistosApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import Input from "../../../../../components/input/Input";
import familiaresBrasilApi from "../../../../../services/familiaresBrasilApi";

export interface IFamiliaresBrasil{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setInformacoesFamiliaresBrasil?: any;
}

export default function FamiliaresBrasil({processo, setProcesso, setTelaNumero, telanumero, setInformacoesFamiliaresBrasil}: IFamiliaresBrasil) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome: '',
        sobrenome: '',
        grau_parentesco: '',
        nacionalidade: '',
        data_nascimento: '',
        viaja_junto: 0,
        numero_passaporte: '',
        pais_passaporte: '',
        data_emissao: '',
        data_validade: '',
        orgao_emissor: '',
        segundo_nome: '',
        segundo_sobrenome: '',
        segundo_grau_parentesco: '',
        segundo_nacionalidade: '',
        segundo_data_nascimento: '',
        segundo_viaja_junto: 0,
        segundo_numero_passaporte: '',
        segundo_pais_passaporte: '',
        segundo_data_emissao: '',
        segundo_data_validade: '',
        segundo_orgao_emissor: '',
        terceiro_nome: '',
        terceiro_sobrenome: '',
        terceiro_grau_parentesco: '',
        terceiro_nacionalidade: '',
        terceiro_data_nascimento: '',
        terceiro_viaja_junto: 0,
        terceiro_numero_passaporte: '',
        terceiro_pais_passaporte: '',
        terceiro_data_emissao: '',
        terceiro_data_validade: '',
        terceiro_orgao_emissor: '',
        outros_membros: '',
        hospedado: 0,
        parente_pais: 0,
    });
    const [processoData, setProcessoData]: any = useState({
        filhos: 0,
        informacoes_filhos: '',
        familiares_brasil: '',
        percet_conclusao: processo.percet_conclusao,
    })
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const getDados = async () => {
        const {data, isError} = await familiaresBrasilApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }

    }
    useEffect(() => {
        getDados()
    },[])
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await familiaresBrasilApi.update(state.id, state) : await familiaresBrasilApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }


        if (!state.id){
            processoData.percet_conclusao = (telanumero * 100) / quantidade[processo.tipo_visto_id]
            await processosApi.update(processo.id, processoData)
            setProcesso({...processo, processoData})
        }else{
            await processosApi.update(processo.id, processoData)
            setProcesso({...processo, processoData})
        }
        setInformacoesFamiliaresBrasil(state);


        Alert.close();
        window.scrollTo(0, 0);
        let numero = state.hospedado ?  telanumero + 1 : telanumero + 2 ;
        console.log(state.hospedado);
        setTelaNumero(numero);
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados dos familiares no Brasil'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você tem filhos?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'filhos')}
                                        value={1}
                                        id="checked-filhos-s"
                                        type="radio"
                                        checked={processoData.filhos === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-filhos-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={processoData.filhos === 0 }
                                        onChange={e => handleChange(0,'filhos')}
                                        id="checked-filhos-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-filhos-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Em caso afirmativo, por favor informe o nome completo e data de nascimento de cada um deles. E se viajarão com você'}
                            placeholder={'Em caso afirmativo, por favor informe o nome completo e data de nascimento de cada um deles. E se viajarão com você'}
                            large={true}
                            name={'informacoes_filhos'}
                            setValue={e => handleChange(e,'informacoes_filhos')}
                            value={processoData.informacoes_filhos}

                        />
                        <Input
                            className={'col-span-1 md:mt-[1.5rem]'}
                            label={'Detalhe a seguir os seus parentes que ficam no Brasil.'}
                            placeholder={'Detalhe a seguir os seus parentes que ficam no Brasil.'}
                            large={true}
                            name={'familiares_brasil'}
                            setValue={e => handleChange(e,'familiares_brasil')}
                            value={processoData.familiares_brasil}

                        />

                    </div>


                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'text-[20px]'}>1º Filho</div>
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-2'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome'}
                            placeholder={'Nome'}
                            large={true}
                            name={'nome'}
                            setValue={e => handleChange(e,'nome')}
                            value={state.nome}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Sobrenome'}
                            placeholder={'Sobrenome'}
                            large={true}
                            name={'sobrenome'}
                            setValue={e => handleChange(e,'sobrenome')}
                            value={state.sobrenome}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Grau de Parentesco'}
                            placeholder={'Grau de Parentesco'}
                            large={true}
                            name={'grau_parentesco'}
                            setValue={e => handleChange(e,'grau_parentesco')}
                            value={state.grau_parentesco}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Nacionalidade'}
                            placeholder={'Nacionalidade'}
                            large={true}
                            name={'nacionalidade'}
                            setValue={e => handleChange(e,'nacionalidade')}
                            value={state.nacionalidade}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Data de Nascimento'}
                            placeholder={'Data de Nascimento'}
                            type={'date'}
                            large={true}
                            name={'data_nascimento'}
                            setValue={e => handleChange(e,'data_nascimento')}
                            value={state.data_nascimento}
                        />
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Viaja com você?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'viaja_junto')}
                                        value={1}
                                        id="checked-viaja_junto-s"
                                        type="radio"
                                        checked={state.viaja_junto === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viaja_junto-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.viaja_junto === 0 }
                                        onChange={e => handleChange(0,'viaja_junto')}
                                        id="checked-viaja_junto-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-viaja_junto-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Número do Passaporte'}
                            placeholder={'Número do Passaporte'}
                            large={true}
                            name={'numero_passaporte'}
                            setValue={e => handleChange(e,'numero_passaporte')}
                            value={state.numero_passaporte}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'País de emissão'}
                            placeholder={'País de emissão'}
                            large={true}
                            name={'pais_passaporte'}
                            setValue={e => handleChange(e,'pais_passaporte')}
                            value={state.pais_passaporte}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Data de emissão'}
                            placeholder={'Data de emissão'}
                            large={true}
                            type={'date'}
                            name={'data_emissao'}
                            setValue={e => handleChange(e,'data_emissao')}
                            value={state.data_emissao}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Data de validade'}
                            placeholder={'Data de validade'}
                            large={true}
                            type={'date'}
                            name={'data_validade'}
                            setValue={e => handleChange(e,'data_validade')}
                            value={state.data_validade}
                        />
                        <Input
                            className={'col-span-2'}
                            label={'Orgão emissor'}
                            placeholder={'Orgão emissor'}
                            large={true}
                            name={'orgao_emissor'}
                            setValue={e => handleChange(e,'orgao_emissor')}
                            value={state.orgao_emissor}
                        />
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'text-[20px]'}>2º Filho</div>
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-2'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome'}
                            placeholder={'Nome'}
                            large={true}
                            name={'segundo_nome'}
                            setValue={e => handleChange(e,'segundo_nome')}
                            value={state.segundo_nome}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Sobrenome'}
                            placeholder={'Sobrenome'}
                            large={true}
                            name={'segundo_sobrenome'}
                            setValue={e => handleChange(e,'segundo_sobrenome')}
                            value={state.segundo_sobrenome}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Grau de Parentesco'}
                            placeholder={'Grau de Parentesco'}
                            large={true}
                            name={'segundo_grau_parentesco'}
                            setValue={e => handleChange(e,'segundo_grau_parentesco')}
                            value={state.segundo_grau_parentesco}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Nacionalidade'}
                            placeholder={'Nacionalidade'}
                            large={true}
                            name={'segundo_nacionalidade'}
                            setValue={e => handleChange(e,'segundo_nacionalidade')}
                            value={state.segundo_nacionalidade}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Data de Nascimento'}
                            placeholder={'Data de Nascimento'}
                            type={'date'}
                            large={true}
                            name={'segundo_data_nascimento'}
                            setValue={e => handleChange(e,'segundo_data_nascimento')}
                            value={state.segundo_data_nascimento}
                        />
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Viaja com você?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'segundo_viaja_junto')}
                                        value={1}
                                        id="checked-segundo_viaja_junto-s"
                                        type="radio"
                                        checked={state.segundo_viaja_junto === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-segundo_viaja_junto-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.segundo_viaja_junto === 0 }
                                        onChange={e => handleChange(0,'segundo_viaja_junto')}
                                        id="checked-segundo_viaja_junto-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-segundo_viaja_junto-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Número do Passaporte'}
                            placeholder={'Número do Passaporte'}
                            large={true}
                            name={'segundo_numero_passaporte'}
                            setValue={e => handleChange(e,'segundo_numero_passaporte')}
                            value={state.segundo_numero_passaporte}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'País de emissão'}
                            placeholder={'País de emissão'}
                            large={true}
                            name={'segundo_pais_passaporte'}
                            setValue={e => handleChange(e,'segundo_pais_passaporte')}
                            value={state.segundo_pais_passaporte}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Data de emissão'}
                            placeholder={'Data de emissão'}
                            large={true}
                            type={'date'}
                            name={'segundo_data_emissao'}
                            setValue={e => handleChange(e,'segundo_data_emissao')}
                            value={state.segundo_data_emissao}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Data de validade'}
                            placeholder={'Data de validade'}
                            large={true}
                            type={'date'}
                            name={'segundo_data_validade'}
                            setValue={e => handleChange(e,'segundo_data_validade')}
                            value={state.segundo_data_validade}
                        />
                        <Input
                            className={'col-span-2'}
                            label={'Orgão emissor'}
                            placeholder={'Orgão emissor'}
                            large={true}
                            name={'segundo_orgao_emissor'}
                            setValue={e => handleChange(e,'segundo_orgao_emissor')}
                            value={state.segundo_orgao_emissor}
                        />
                    </div>

                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <div className={'text-[20px]'}>3º Filho</div>
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-2'}>
                        <Input
                            className={'col-span-1'}
                            label={'Nome'}
                            placeholder={'Nome'}
                            large={true}
                            name={'terceiro_nome'}
                            setValue={e => handleChange(e,'terceiro_nome')}
                            value={state.terceiro_nome}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Sobrenome'}
                            placeholder={'Sobrenome'}
                            large={true}
                            name={'terceiro_sobrenome'}
                            setValue={e => handleChange(e,'terceiro_sobrenome')}
                            value={state.terceiro_sobrenome}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Grau de Parentesco'}
                            placeholder={'Grau de Parentesco'}
                            large={true}
                            name={'terceiro_grau_parentesco'}
                            setValue={e => handleChange(e,'terceiro_grau_parentesco')}
                            value={state.terceiro_grau_parentesco}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Nacionalidade'}
                            placeholder={'Nacionalidade'}
                            large={true}
                            name={'terceiro_nacionalidade'}
                            setValue={e => handleChange(e,'terceiro_nacionalidade')}
                            value={state.terceiro_nacionalidade}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Data de Nascimento'}
                            placeholder={'Data de Nascimento'}
                            type={'date'}
                            large={true}
                            name={'terceiro_data_nascimento'}
                            setValue={e => handleChange(e,'terceiro_data_nascimento')}
                            value={state.terceiro_data_nascimento}
                        />
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Viaja com você?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'terceiro_viaja_junto')}
                                        value={1}
                                        id="checked-terceiro_viaja_junto-s"
                                        type="radio"
                                        checked={state.terceiro_viaja_junto === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-terceiro_viaja_junto-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.terceiro_viaja_junto === 0 }
                                        onChange={e => handleChange(0,'terceiro_viaja_junto')}
                                        id="checked-terceiro_viaja_junto-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-terceiro_viaja_junto-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Número do Passaporte'}
                            placeholder={'Número do Passaporte'}
                            large={true}
                            name={'terceiro_numero_passaporte'}
                            setValue={e => handleChange(e,'terceiro_numero_passaporte')}
                            value={state.terceiro_numero_passaporte}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'País de emissão'}
                            placeholder={'País de emissão'}
                            large={true}
                            name={'terceiro_pais_passaporte'}
                            setValue={e => handleChange(e,'terceiro_pais_passaporte')}
                            value={state.terceiro_pais_passaporte}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Data de emissão'}
                            placeholder={'Data de emissão'}
                            large={true}
                            type={'date'}
                            name={'terceiro_data_emissao'}
                            setValue={e => handleChange(e,'terceiro_data_emissao')}
                            value={state.terceiro_data_emissao}
                        />
                        <Input
                            className={'col-span-1'}
                            label={'Data de validade'}
                            placeholder={'Data de validade'}
                            large={true}
                            type={'date'}
                            name={'terceiro_data_validade'}
                            setValue={e => handleChange(e,'terceiro_data_validade')}
                            value={state.terceiro_data_validade}
                        />
                        <Input
                            className={'col-span-2'}
                            label={'Orgão emissor'}
                            placeholder={'Orgão emissor'}
                            large={true}
                            name={'terceiro_orgao_emissor'}
                            setValue={e => handleChange(e,'terceiro_orgao_emissor')}
                            value={state.terceiro_orgao_emissor}
                        />
                    </div>



                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Caso tenha outro membro familiar, que você acredita ser importante informar, favor usar o espaço abaixo'}
                            placeholder={'Caso tenha outro membro familiar, que você acredita ser importante informar, favor usar o espaço abaixo'}
                            large={true}
                            name={'outros_membros'}
                            setValue={e => handleChange(e,'outros_membros')}
                            value={state.outros_membros}
                        />
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Você ficará hospedado na casa de algum parente ou amigo durante a sua viagem?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'hospedado')}
                                        value={1}
                                        id="checked-hospedado-s"
                                        type="radio"
                                        checked={state.hospedado === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-hospedado-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.hospedado === 0 }
                                        onChange={e => handleChange(0,'hospedado')}
                                        id="checked-hospedado-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-hospedado-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium text-gray-900 dark:text-gray-300"
                            >
                                Você tem algum parente na Austrália?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'parente_pais')}
                                        value={1}
                                        id="checked-parente_pais-s"
                                        type="radio"
                                        checked={state.parente_pais === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-parente_pais-s"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.parente_pais === 0 }
                                        onChange={e => handleChange(0,'parente_pais')}
                                        id="checked-parente_pais-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-parente_pais-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}