const quantidade: any = {
    1: 24,
    2: 24,
    3: 16,
    4: 16,
    5: 10,
    6: 16,
};


export default quantidade;