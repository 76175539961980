import {ITableBody} from "../../../types";
import Dates from "../../../helpers/Dates";
import { BsCheckLg } from "react-icons/bs";
import { CgClose } from "react-icons/cg";

export default function TableBody ({
   columns,
   tableData,
   onClick
}: ITableBody) {
    
    return(
        <tbody >
            {
                tableData && tableData.map((record: any, index) => {
                    return(
                        <tr key={index} onClick={() => onClick(record)} className={'md:h-[50px] cursor-pointer hover:bg-[#e8e9ea]'}>
                            {
                                columns && columns.map((column: any) => {
                                    if (!!column.render) {
                                        return (
                                            <td className={''}>
                                                <span className={' flex'}>{column.render(record)}</span>
                                            </td>
                                        )
                                    }


                                    if (column.type === 'perfil') {
                                        return (
                                            <td className={''}>
                                                <span className={' flex'}>{record[column.index] ? 'Ativo' : 'Inativo'}</span>
                                            </td>
                                        )
                                    }

                                    if (column.type === 'percent') {
                                        return (
                                            <td className={''}>
                                                <span className={' flex'}>{ record[column.index] ? record[column.index].toFixed(2) : 0}%</span>
                                            </td>
                                        )
                                    }

                                    if (column.type === 'button') {
                                        return (
                                            <td className={''}>
                                                <div className={'flex justify-center'}>
                                                    <button
                                                        className={`bg-[#082a60] text-white w-[165px] h-[32px] `}
                                                        onClick={() => column.onCkick(record)}
                                                    >
                                                        {column.label}
                                                    </button>
                                                </div>

                                            </td>
                                        )
                                    }

                                    if (column.type === 'membros_familiares') {
                                        let nomesFamiliares = '';
                                        if (record[column.index]['membros_familiares']){
                                            record[column.index]['membros_familiares'].map((membros: any) =>{
                                                nomesFamiliares += membros.nome+" ";
                                            })
                                        }
                                        return (
                                            <td className={''}>
                                                <span className={''}>{nomesFamiliares}</span>
                                            </td>
                                        )
                                    }

                                    if (column.type === 'ultimo_processo'){
                                        if (record[column.index]){
                                            const processo = record[column.index];

                                            if (processo['ultimo_cronograma_concluido']) {
                                                if (processo['ultimo_cronograma_concluido']['situacao_id'] === 6 || processo['ultimo_cronograma_concluido']['situacao_id'] === 7){
                                                    return(<td><span className={''}>{processo['ultimo_cronograma_concluido']['situacao_processo']['nome']}</span></td>)
                                                }
                                            }
                                            if (processo['ultimo_cronograma']){
                                                return(<td><span className={''}>{processo['ultimo_cronograma']['situacao_processo']['nome']}</span></td>)
                                            }

                                            return(<td><span className={''}></span></td>)

                                        }
                                    }

                                    if (column.type === 'ultimo_cronograma'){
                                        if (record['ultimo_cronograma_concluido']){
                                            if (record['ultimo_cronograma_concluido']['situacao_id'] === 6 || record['ultimo_cronograma_concluido']['situacao_id'] === 7){
                                                return(<td><span className={''}>{record['ultimo_cronograma_concluido']['situacao_processo']['nome']}</span></td>)
                                            }
                                        }
                                        if (record[column.index]){
                                            return(<td><span className={''}>{record[column.index]['situacao_processo']['nome']}</span></td>)
                                        }



                                        return(<td> </td>)
                                    }

                                    const concat = column.index.split("+");
                                    if (concat.length > 1){

                                        let fieldsRealtionchipPrimary = concat[0].split(".")
                                        let fieldsRealtionchipSecondary = concat[1].split(".")
                                        if (fieldsRealtionchipPrimary.length > 1 && fieldsRealtionchipSecondary.length > 1){

                                            const firstDt = record[fieldsRealtionchipPrimary[0]][fieldsRealtionchipPrimary['1']];
                                            const lastDt = record[fieldsRealtionchipSecondary[0]][fieldsRealtionchipSecondary['1']]

                                            return (
                                                <td className={''}>
                                                    <span className={' flex'}>{firstDt} {lastDt}</span>
                                                </td>
                                            )
                                        }

                                        return (
                                            <td className={''}>
                                                <span className={' flex'}>{record[concat[0]]} {record[concat[1]]}</span>
                                            </td>
                                        )
                                    }

                                    let relations = column.index.split(".");

                                    if (relations.length > 1){

                                        let our = relations[1].split(":")

                                        const fildOne = relations[0];
                                        const fildTwo = relations[1];
                                        const isArray = Array.isArray(record[fildOne])
                                        if (record[fildOne]){
                                            if (column.type === 'status'){

                                                return (
                                                    <td>
                                                        <span className={' flex'}>
                                                            {record[fildOne][our[0]] ? <BsCheckLg color={'green'}/> : <CgClose color={'red'}/> }
                                                        </span>
                                                    </td>
                                                )
                                            }
                                            if (column.type === 'date') {
                                                return (
                                                    <td>
                                                        <span className={' flex'}>
                                                            {Dates.convertEnToBr(record[fildOne][our[0]])}
                                                        </span>
                                                    </td>
                                                )
                                            }
                                            if (column.type === 'currency') {
                                                return (
                                                    <td className={''}>
                                                        <span className={' flex'}>{Number(record[fildOne][our[0]]).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                                                    </td>
                                                )
                                            }
                                            if (our[1]){
                                                return (
                                                    <td><span className={' flex'}>{record[fildOne][our[0]][our[1]]}</span></td>
                                                )
                                            }

                                            return (
                                                <td><span className={' flex'}>{record[fildOne][our[0]]}</span></td>
                                            )

                                        }
                                    }

                                    if (column.type === 'currency') {
                                        return (
                                            <td className={''}>
                                                <span className={' flex'}>{Number(record[column.index]).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</span>
                                            </td>
                                        )
                                    }

                                    if (column.type === 'date') {
                                        return (
                                            <td className={''}>
                                                <span className={' flex'}>{Dates.convertEnToBr(record[column.index])}</span>
                                            </td>
                                        )
                                    }

                                    if (column.type === 'status_date') {
                                        if (record['ultimo_cronograma_concluido']){
                                            if (record['ultimo_cronograma_concluido']['situacao_id'] === 6) {
                                                return (
                                                    <td >
                                                        <span className={' rounded-xl px-3 py-1 text-[#45a04b] bg-[#7bfc84] flex'}>
                                                            Finalizado
                                                        </span>
                                                    </td>
                                                )
                                            }
                                        }
                                        if (record['ultimo_cronograma']) {
                                            if (record['ultimo_cronograma']['data_prazo_finalizacao']) {
                                                var date = new Date(record['ultimo_cronograma']['data_prazo_finalizacao']);
                                                var novaData = new Date();
                                                if(date < novaData){
                                                    return (
                                                        <td>
                                                            <span className={' rounded-xl px-3 py-1 text-[#D32F2F] bg-[#FFEBEE]'}>Vencido</span>
                                                        </td>
                                                    )
                                                }
                                            }
                                        }
                                        return (
                                            <td >
                                                <span className={' rounded-xl px-3 py-1 text-[#949695] bg-[#edefed]'}>Em andamento</span>
                                            </td>
                                        )
                                    }
                                    if (column.type === 'vinculo') {
                                        return (
                                            <td className={''}>
                                                <span className={' flex'}>{record[column.index] ? 'MEMBRO' : 'PRINCIPAL'}</span>
                                            </td>
                                        )
                                    }

                                    return(
                                        <td className={''}>
                                            <span className={' flex'}>{record[column.index]}</span>
                                        </td>
                                    )
                                })
                            }
                        </tr>
                    )
                })
            }
            {/*<tr className={'h-[50px]'}>
                <td className={'border border-slate-300'}>
                    <span className={''}>Mariana Markowski Guimarães</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>Estados unidos</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>01/12/2023</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>01/12/2023</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>Aguardando entrevista/ Análise consular</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5 rounded-xl px-3 py-1 text-[#D32F2F] bg-[#FFEBEE]'}>Vencido</span>
                </td>
            </tr>
            <tr className={'h-[50px]'}>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>Mariana Markowski Guimarães</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>Estados unidos</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>01/12/2023</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>01/12/2023</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>Aguardando entrevista/ Análise consular</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>Vencido</span>
                </td>
            </tr>
            <tr className={'h-[50px]'}>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>Mariana Markowski Guimarães</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>Estados unidos</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>01/12/2023</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>01/12/2023</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>Aguardando entrevista/ Análise consular</span>
                </td>
                <td className={'border border-slate-300'}>
                    <span className={'ml-5'}>Vencido</span>
                </td>
            </tr>*/}
        </tbody>
    )
}