import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";
import dadosViagensAnterioresApi from "../../../../../services/dadosViagensAnterioresApi";
import ContatoApi from "../../../../../services/contatoApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";

export interface IContatoEUA{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
    dadosViagemAnterior?: any;
}

export default function ContatoEUA({processo, setProcesso, setTelaNumero, telanumero, dadosViagemAnterior}: IContatoEUA) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        nome: '',
        endereco: '',
        cidade: '',
        cep: '',
        telefone: '',
        email: '',
        estado: '',
    });
    const [telaRetorno] = useState(dadosViagemAnterior.visto_ja_emitido ? telanumero -1 : telanumero- 2);

    useEffect(() => {
        getContato();
    }, []);

    const getContato = async () => {
        const {data, isError} = await ContatoApi.one(processo.id);
        if (!isError){
            const response = data.data;
            if (Object.values(response).length){
                setState(response);
            }
        }
    }



    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };

    async function onSubmit() {
        const {data, isError} = state.id ? await ContatoApi.update(state.id, state) : await ContatoApi.create(state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero+1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Contato nos Estados Unidos'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'text-red-600'}>
                        Caso não saiba alguma informação, escreva "Não sei ainda"
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            label={'Nome completo da pessoa ou empresa'}
                            name={'nome'}
                            setValue={e => handleChange(e,'nome')}
                            value={state.nome}
                        />
                        <Input
                            className={'md:col-span-1'}
                            label={'E-mail da pessoa ou empresa'}
                            name={'email'}
                            setValue={e => handleChange(e,'email')}
                            value={state.email}
                        />
                    </div>
                    <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                        <Input
                            className={'col-span-1'}
                            label={'Endereço completo da pessoa ou empresa'}
                            name={'endereco'}
                            setValue={e => handleChange(e,'endereco')}
                            value={state.endereco}
                        />
                    </div>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            label={'Cidade da pessoa / empresa'}
                            name={'cidade'}
                            setValue={e => handleChange(e,'cidade')}
                            value={state.cidade}
                        />
                        <Input
                            className={'md:col-span-1'}
                            label={'Estado da pessoa ou empresa'}
                            name={'estado'}
                            setValue={e => handleChange(e,'estado')}
                            value={state.estado}
                        />
                        <Input
                            className={'md:col-span-1'}
                            label={'CEP da pessoa ou empresa'}
                            name={'cep'}
                            setValue={e => handleChange(e,'cep')}
                            value={state.cep}
                        />
                        <Input
                            className={'md:col-span-1'}
                            label={'Telefone'}
                            mask={'cellphoneUSA'}
                            name={'telefone'}
                            setValue={e => handleChange(e,'telefone')}
                            value={state.telefone}
                        />
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telaRetorno)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}