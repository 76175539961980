import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import informacoesProfissionaisApi from "../../../../../services/informacoesProfissionaisApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import detalhamentoEmpregoAnteriorCaApi from "../../../../../services/detalhamentoEmpregoAnteriorCaApi";
import InputSelect from "../../../../../components/input/InputSelect";

export interface IDetalhamentoEmpregoAnteriorCA{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DetalhamentoEmpregoAnteriorCA({processo, setProcesso, setTelaNumero, telanumero}: IDetalhamentoEmpregoAnteriorCA) {
    const [state, setState] = useState(
        {
            id: '',
            processo_id: processo.id,
            salarios: '',
            detalhe_exercito: '',
            detalhe_treinamento_especial: '',
            detalhe_combate: '',
            porque_deixou_servico: 'Ainda estou servindo',
            detalhe_maus_tratos: '',
            partido_politico: '',
            cargo_governo: '',
            cometido_crimes: '',
        }
    );
    const deixouServirOptions = [
        { value: 'Ainda estou servindo', label: 'Ainda estou servindo' },
        { value: 'Completei tempo de servico', label: 'Completei tempo de servico' },
        { value: 'Problemas médicos', label: 'Problemas médicos' },
        { value: 'Desertei', label: 'Desertei' },
        { value: 'Outro', label: 'Outro' },
    ];
    const getData = async () => {
        const {data, isError} = await detalhamentoEmpregoAnteriorCaApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                const response = data.data;
                setState(response);
            }
        }

    }

    useEffect(() => {
        getData()
    }, []);
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await detalhamentoEmpregoAnteriorCaApi.update(state.id, state) : await detalhamentoEmpregoAnteriorCaApi.create(state);
        if (isError){

            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }

        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={'Detalhamento de empregos anteriores ou atuais'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        {
                            processo.tipo_visto_id === 3 ?
                                <div className={'col-span-2 mt-3 flex flex-col'}>
                                    <label
                                        className="font-medium"
                                    >
                                        Salário mensal dos seus empregos nos últimos 10 anos
                                    </label>
                                    <textarea
                                        className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                        rows={5}
                                        cols={50}
                                        name={'salarios'}
                                        onChange={e => handleChange(e.target.value,'salarios')}
                                        value={state.salarios ? state.salarios : ""}
                                    />
                                    <div className={'text-sm text-gray-400'}>
                                        Exemplo: Emprego: R$XX.XXX,xx / Emprego: R$XX.XXX,xx / Emprego: R$XX.XXX,xx.
                                    </div>
                                </div>
                                :
                                ''
                        }
                        <div className={'col-span-2 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Caso tenha servido ao exército, polícia ou unidade de defesa, detalhe a função
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'detalhe_exercito'}
                                onChange={e => handleChange(e.target.value,'detalhe_exercito')}
                                value={state.detalhe_exercito ? state.detalhe_exercito : ""}
                            />
                            <div className={'text-sm text-gray-400'}>
                                Incluir Unidade de Operação, Base, Local, Patente, funções e data de início e fim.
                            </div>
                        </div>
                        <div className={'col-span-2 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Caso tenha recebido algum treinamento especial, detalhe
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'detalhe_treinamento_especial'}
                                onChange={e => handleChange(e.target.value,'detalhe_treinamento_especial')}
                                value={state.detalhe_treinamento_especial ? state.detalhe_treinamento_especial : ""}
                            />
                        </div>
                        <div className={'col-span-2 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Caso tenha participado em alguma forma de combate, detalhe
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'detalhe_combate'}
                                onChange={e => handleChange(e.target.value,'detalhe_combate')}
                                value={state.detalhe_combate ? state.detalhe_combate : ""}
                            />
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-4 gap-4 mt-5'}>
                        <div className={'col-span-2'}>
                            <InputSelect
                                label={"Você exerceu alguma atividade profissional ou estudo nos últimos 10 anos?"}
                                selected={deixouServirOptions.filter((opt: any) => opt.value === state.porque_deixou_servico)}
                                options={deixouServirOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'porque_deixou_servico')}
                            />
                        </div>
                    </div>

                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Caso já tenha participado ou testemunhado maus tratos a prisioneiros ou civis, pilhagem ou violação de edifícios religiosos, detalhe
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'detalhe_maus_tratos'}
                                onChange={e => handleChange(e.target.value,'detalhe_maus_tratos')}
                                value={state.detalhe_maus_tratos ? state.detalhe_maus_tratos : ""}
                            />
                            <div className={'text-sm text-gray-400'}>
                                Informar data, local e detalhamento.
                            </div>
                        </div>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Caso já tenha sido membro de partido político ou outro grupo ou organização, detalhe:
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'partido_politico'}
                                onChange={e => handleChange(e.target.value,'partido_politico')}
                                value={state.partido_politico ? state.partido_politico : ""}
                            />
                            <div className={'text-sm text-gray-400'}>
                                Data de início e fim, Nome da organização, Tipo de organização, Cidade e país de atuação, cargo ocupado e atividades realizadas.
                            </div>
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Caso já tenha ocupado um cargo no governo, judiciário ou empresa pública, detalhe
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'cargo_governo'}
                                onChange={e => handleChange(e.target.value,'cargo_governo')}
                                value={state.cargo_governo ? state.cargo_governo : ""}
                            />
                            <div className={'text-sm text-gray-400'}>
                                Data de início e fim, País e nível de jurisdição, Departamento, Cidade, local, cargo ocupado e atividades realizadas.
                            </div>
                        </div>
                        <div className={'col-span-1 mt-3 flex flex-col'}>
                            <label
                                className="font-medium"
                            >
                                Caso você ou algum membro de sua família que esteja acompanhando tenha cometido, sido preso ou acusado por qualquer crime, detalhe
                            </label>
                            <textarea
                                className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                rows={5}
                                cols={50}
                                name={'cometido_crimes'}
                                onChange={e => handleChange(e.target.value,'cometido_crimes')}
                                value={state.cometido_crimes ? state.cometido_crimes : ""}
                            />
                        </div>

                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero - 1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}