
import React from "react";
import {GoDotFill} from "react-icons/go";
import Dates from "../../helpers/Dates";

export interface ICardAtividades {
    atividades: any[];
    dia: string;
    quantidadeMes: string | number;
}
export default function CardAtividades({atividades, dia, quantidadeMes}: ICardAtividades) {

    const naoConcluidas = atividades.filter((atividade:any) => atividade.data_finalizacao === null);
    const concluidas = atividades.filter((atividade:any) => atividade.data_finalizacao !== null);

    return(
        <div className={'w-[400px]  border rounded-md  overflow-auto'}>
            <div className={'flex justify-between items-center h-[60px] bg-[#061f46] rounded-t-lg p-5'}>
                <div className={'text-[20px] text-[#fcfcfd] font-semibold'}>Atividades</div>
                <div className={'text-[14px] text-[#fcfcfd] '}>{quantidadeMes} resultados no mês</div>
            </div>
            <div className={'p-5 flex justify-between'}>
                <div className={'text-[20px]  font-semibold '}>
                    Dia {dia}<span className={'text-[16px]'}>({atividades.length})</span>
                </div>
                <div className={'text-[#061f46] flex items-center text-[14px]'}>
                    <GoDotFill size={17} color={'#28a44b'}/> Concluídos ({concluidas.length})
                    <GoDotFill size={17} color={'#c22929'}/> Não concluídos ({naoConcluidas.length})
                </div>
            </div>
            <div className={'px-5'}>
                <div className={'border-b-2'}></div>
            </div>
            {
                atividades.map((atividade: any, index: number) => {

                    return(
                        <div className={'px-5 mt-5'} key={index}>
                            <div className={'flex flex-col w-full '}>
                                <div className={''}>
                                    <span className={'font-bold '}>Cliente:</span> {atividade['processo']['cliente']['nome']+' '+atividade['processo']['cliente']['sobrenome']}
                                </div>
                                <div className={''}>
                                    <span className={'font-bold '}>Dias para finalizar:</span> {!atividade['data_finalizacao'] ? (atividade['data_prazo_finalizacao'] ? Dates.difDay(new Date().toISOString().slice(0, 10), atividade['data_prazo_finalizacao']).toString() : '') : 0}
                                </div>
                                <div className={''}>
                                    <span className={'font-bold '}>Situação:</span> {atividade['situacao_processo'] ? atividade['situacao_processo']['nome'] : ''}
                                </div>
                                <div className={''}>
                                    <span className={'font-bold '}>País:</span> {atividade['processo']['pais'] ? atividade['processo']['pais']['nome'] : '' }
                                </div>
                                <div className={''}>
                                    <span className={'font-bold '}>Prazo:</span> {atividade['data_prazo_finalizacao'] ? Dates.convertEnToBr(atividade['data_prazo_finalizacao']) : '' }
                                </div>
                                <div className={''}>
                                    <span className={'font-bold '}>Tipo de visto:</span> {atividade['processo']['tipo_visto'] ? atividade['processo']['tipo_visto']['nome'] : ''}
                                </div>
                                <div className={'mb-5'}>
                                    <span className={'font-bold '}>Concluído:</span> <span className={`${atividade['data_finalizacao'] ? 'text-[#061f46]' : 'text-red-600'}`}>{atividade['data_finalizacao'] ? 'Sim' : 'Não'}</span>
                                </div>
                            </div>
                            <div className={''}>
                                <div className={'border-b-2'}></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}