import PageContainer from "../../components/container/PageContainer";
import Input from "../../components/input/Input";
import React, {useEffect, useState} from "react";
import InputIcon from "../../components/input/InputIcon";
import Select, { components } from "react-select";
import InputSelect from "../../components/input/InputSelect";
import Button from "../../components/button/Button";
import { ReactComponent as AddIcon } from '../../assets/icone/add.svg'
import Table from "../../components/table/Table";
import Alert from "../../helpers/Alert";
import processosApi from "../../services/processosApi";
import {QueryParams} from "../../types";
import {useNavigate} from "react-router-dom";
import Storage from "../../helpers/Storage";

export default function Processos() {
    const navigate = useNavigate();
    const userData: any = Storage.getUserData();
    const [dataSearch, setDataSearch] = useState({
        nome: '',
        tipo: '',
    });

    const [dataPaginate, setDataPaginate] = useState({
        current_page: 1,
        last_page: 2,
        total_data: 0,
        per_page: 0,
    });

    const [searchPago, setSearchPago] = useState('');

    const [data, setData] = useState({});
    const [atualPageData, setAtualPageData] = useState(1);
    const [porPagina, setPorPagina] = useState(10);
    const [loading, setLoading] = useState(true);
    const [loadingConsulta, setLoadingConsulta] = useState(true);

    const onChange = (e: any) => {
        const search = `${e.target.value ? 'cliente='+e.target.value : ''}${e.target.value ? '&' : ''}${dataSearch.tipo ? 'status='+dataSearch.tipo : ''}`

        if (e.target.value.length > 4) {
            getData(1, porPagina, search);
        }
        if (e.target.value.length  < 1) {
            getData(1, porPagina, search);
        }
        setDataSearch({ ...dataSearch ,[e.target.name]: e.target.value })
    }
    const options = [
        { value: 'todos', label: 'Todos' },
        { value: 'em_andamento', label: 'Em andamento' },
        { value: 'vencido', label: 'Vencido' },
        { value: 'finalizado', label: 'Finalizado' },
    ];
    const columns = [
        { label: "Clientes", index: "cliente.nome+cliente.sobrenome", type: 'string'},
        { label: "País", index: "pais.nome", type: 'string'},
        { label: "Data ida", index: "data_prevista_viagem", type: 'date' },
        { label: "Data Volta", index: "data_prevista_retorno", type: 'date' },
        { label: "Etapa do processo", index: "ultimo_cronograma", type: 'ultimo_cronograma' },
        { label: "Pago", index: "entrada.pago", type: 'status' },
        { label: "Status", index: "entrada", type: 'status_date' },
    ];

    const optionsPagos = [
        { value: '', label: 'Todos' },
        { value: true, label: 'Pago' },
        { value: false, label: 'Não pago' },
    ];

    async function getData(atualPage: number, porPagina?: number, busca?: string){
        setLoadingConsulta(true);
        const params: QueryParams = {
            page: atualPage,
            perPage: porPagina ? porPagina : 10,
            search: busca,
            orderBy:'id,desc'
        }
        const [processos] = await  Promise.all([processosApi.all(params)]);

        if (!processos.isError){
            const dataProcessos = processos.data.data as any;
            const paginate = {
                'current_page': dataProcessos.current_page,
                'last_page': dataProcessos.last_page,
                'total_data': dataProcessos.total,
                'per_page': dataProcessos.per_page,
            };
            setDataPaginate(paginate);
            setData(dataProcessos.data);
        }
        setLoading(false);
        setLoadingConsulta(false);
        Alert.close();
    }

    useEffect(() => {
        Alert.await(`Carregando dados...`);
        getData(dataPaginate.current_page);
    }, []);

    const onPageChange = (e: number) => {
        const search = `${dataSearch.nome ? 'cliente='+dataSearch.nome : ''}${dataSearch.nome ? '&' : ''}${dataSearch.tipo ? 'status='+dataSearch.tipo : ''}`
        getData(e, porPagina, search);
        setAtualPageData(e);
    }

    const onChangePerPage = (e: any) => {
        const search = `${dataSearch.nome ? 'cliente='+dataSearch.nome : ''}${dataSearch.nome ? '&' : ''}${dataSearch.tipo ? 'status='+dataSearch.tipo : ''}`
        setPorPagina(e);
        getData(atualPageData, e, search);
    }

    const onSelect = (dt: any) => {
        const search = `${dataSearch.nome ? 'cliente='+dataSearch.nome : ''}${dataSearch.nome ? '&' : ''}${dt.value ? 'status='+dt.value : ''}`
        if(dt.value === 'todos') {
            getData(1, porPagina, search);
        }
        if (dt.value !== 'todos') {
            getData(1, porPagina, search);
        }
        setDataSearch({ ...dataSearch ,'tipo': dt.value })
    }

    const onSelectPagos = (dt: any) => {
        let search = `${dataSearch.nome ? 'cliente='+dataSearch.nome : ''}${dataSearch.nome ? '&' : ''}${dataSearch.tipo ? 'status='+dataSearch.tipo : ''}`
        if (dt.value !== ''){
            if(!search.length){
                search += `pagos=`+dt.value
            }else{
                search += `&pagos=`+dt.value
            }
        }else{
            search = `${dataSearch.nome ? 'cliente='+dataSearch.nome : ''}${dataSearch.nome ? '&' : ''}${dataSearch.tipo ? 'status='+dataSearch.tipo : ''}`
        }

        getData(1, porPagina, search);
        setSearchPago(dt);
    }

    const onClickTable = (e: any) => {
        navigate(`/processos/${e.id}`);
    }

    return(
        <PageContainer pageTitle={'Processos'}>
            <div className={'flex flex-col'}>
                {/*Campos de busca*/}
                <div className={'md:flex w-full md:justify-between xs:p-1'}>
                    <div className={'grid md:grid-cols-12 gap-4 md:w-[70%]'}>
                        <InputIcon
                            classNameLabel={'md:col-span-4'}
                            name={'nome'}
                            setValue={onChange}
                            value={dataSearch.nome}
                            placeholder={'Pesquisar'}
                        />
                        <div className={'md:col-span-4 mb-5'}>
                            <InputSelect
                                /*label={"Status"}*/
                                selected={options.filter((opt: any) => opt.value === dataSearch.tipo)}
                                options={options}
                                onChangeSelect={(e: any) => onSelect(e)}
                                placeholder={"Status"}
                            />
                        </div>
                        <div className={'md:col-span-4 mb-5'}>
                            <InputSelect
                                selected={searchPago}
                                options={optionsPagos}
                                onChangeSelect={(e: any) => onSelectPagos(e)}
                                placeholder={"Status pagamento"}
                            />
                        </div>
                    </div>
                    {
                        userData.usuario.tipo_id != 3
                            ?<div>
                                <Button className={'flex bg-[#082a60] text-white w-[146px] h-[48px]'}>
                                    <AddIcon className="fill-white mr-1 " /> Cadastrar
                                </Button>
                            </div>
                            :
                            ''
                    }

                </div>
                {/*Tabela*/}
                {
                    loading ? '' :
                        <div className={'w-full'}>
                            <Table
                                columns={columns}
                                tableData={data}
                                data={dataPaginate}
                                onPageChange={onPageChange}
                                setPerPage={onChangePerPage}
                                onClick={onClickTable}
                            />
                        </div>
                }


            </div>
        </PageContainer>
    )
}