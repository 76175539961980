import { QueryParams } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';
import { saveAs } from 'file-saver';
const endpoint = 'relatorio';

export default {
    getRelatorios: async (tipoRelatorio: string, consulta: string) => {
        try {
            const { data } = await api.get(endpoint+'/'+tipoRelatorio+'?'+consulta);
            return { data, isError: false };
        }catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },

    getRelatoriosExcel: async (tipoRelatorio: string, consulta: string, parceiro_id?: number) => {
        try {
            api.get(endpoint+'/'+tipoRelatorio+'?'+'date='+consulta+'&excel'+`${parceiro_id ? '&parceiro_id='+parceiro_id : ''}`, { responseType: 'blob' }).then(({ data }) => {
                const filename = `${tipoRelatorio}${consulta}.xls`;
                const blob = new Blob([data], { type: 'application/vnd.ms-excel' });
                saveAs(blob, filename);
            }).catch(error => {
                console.log(error)
            });


            /*const { data } = await api.get(endpoint+'/'+tipoRelatorio+'?'+consulta+'&excel');
            return { data, isError: false };*/
        }catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    }


};
