import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Button from "../../../../../components/button/Button";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import processosApi from "../../../../../services/processosApi";
import clientesApi from "../../../../../services/clientesApi";
import Alert from "../../../../../helpers/Alert";

export interface IDadosViagemAusVisitante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosViagemAusVisitante({processo, setProcesso, setTelaNumero, telanumero}: IDadosViagemAusVisitante ) {
    const [state, setState] = useState({
        pais_atual_visto: '',
        situacao_imigratoria: 'cidadao',
        motivo_situacao_imigratoria: '',
        motivo_viagem: '',
        quanto_tempo_pais: '3',
        data_prevista_viagem: '',
        data_prevista_retorno: '',
        endereco_pais: '',
        visitou_pais_anteriormente: 0,
        numero_visto_pais: '',
        visita_outros_paises: 0,
        passaporte_encaminhado_residencia: 0,
        estudar_no_pais: 0,
        informacoes_estudo_no_pais: '',
        familiar_no_pais: 0,
        nome_familiares_pais: '',
        dados_familiar: '',
        quem_vai_junto: 'sozinho',
        filhos: 0,
        informacoes_filhos: '',
        familiares_brasil: '',
        filho_menor_acompanha: 0,
    });
    const [situacaoImigratoriaOptions, setSituacaoImigratoriaOptions] = useState([
        { value: 'cidadao', label: 'Cidadão' },
        { value: 'visitante', label: 'Visitante' },
        { value: 'residente_permanente', label: 'Residente Permanente' },
        { value: 'estudante', label: 'Estudante' },
        { value: 'visto_trabalho', label: 'Visto de trabalho' },
        { value: 'outros', label: 'Outros' },
        { value: 'ilegal', label: 'Ilegal' },
    ]);
    const [tempoPermanenciaOptions, setTempoPermanenciaOptions] = useState([
        { value: '3', label: 'Até 3 meses' },
        { value: '6', label: 'Até 6 meses' },
        { value: '12', label: 'Até 12 meses' },
    ]);
    const [quemViajaOptions, setQuemViajaOptions] = useState([
        { value: 'sozinho', label: 'Sozinho(a)' },
        { value: 'familia_direta', label: 'Família Direta' },
        { value: 'parente', label: 'Parente' },
        { value: 'grupo_amigos', label: 'Grupo de Amigos' },
        { value: 'outros', label: 'Outros' },
    ]);
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const getData = async () => {
        const {data, isError} = await processosApi.one(processo.id);
        if (!isError){
            setState(
                {
                    pais_atual_visto:  data.data.pais_atual_visto,
                    situacao_imigratoria: data.data.situacao_imigratoria ? data.data.situacao_imigratoria : 'cidadao',
                    motivo_situacao_imigratoria:  data.data.motivo_situacao_imigratoria,
                    motivo_viagem:  data.data.motivo_viagem,
                    quanto_tempo_pais: data.data.quanto_tempo_pais ? data.data.quanto_tempo_pais : '3',
                    data_prevista_viagem:  data.data.data_prevista_viagem,
                    data_prevista_retorno:  data.data.data_prevista_retorno,
                    endereco_pais:  data.data.endereco_pais,
                    visitou_pais_anteriormente: data.data.visitou_pais_anteriormente !== null ? data.data.visitou_pais_anteriormente : 0,
                    numero_visto_pais:  data.data.numero_visto_pais,
                    visita_outros_paises:  data.data.visita_outros_paises !== null ? data.data.visita_outros_paises : 0,
                    passaporte_encaminhado_residencia: data.data.passaporte_encaminhado_residencia !== null ? data.data.passaporte_encaminhado_residencia : 0,
                    estudar_no_pais: data.data.estudar_no_pais !== null ? data.data.estudar_no_pais : 0,
                    informacoes_estudo_no_pais:  data.data.informacoes_estudo_no_pais,
                    familiar_no_pais: data.data.familiar_no_pais !== null ? data.data.familiar_no_pais : 0,
                    nome_familiares_pais:  data.data.nome_familiares_pais,
                    dados_familiar:  data.data.dados_familiar,
                    quem_vai_junto: data.data.quem_vai_junto ? data.data.quem_vai_junto : 'sozinho',
                    filhos: data.data.filhos !== null ? data.data.filhos : 0,
                    informacoes_filhos:  data.data.informacoes_filhos,
                    familiares_brasil:  data.data.familiares_brasil,
                    filho_menor_acompanha: data.data.filho_menor_acompanha,
                }
            )
        }
    }
    useEffect(() => {getData(); /*console.log(processo.percet_conclusao === 30)*/}, [])
    async function onSubmit() {
        Alert.await("Salvando dados");
        const data = await processosApi.update(processo.id, state);
        if (data.isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }

        if (processo.percet_conclusao === 30){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, percent })
        }

        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados da viagem'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form   onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-6 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-3'}
                            large={true}
                            required={true}
                            label={'Vocês está em qual país neste momento de solicitação do visto'}
                            name={'pais_atual_visto'}
                            setValue={e => handleChange(e,'pais_atual_visto')}
                            value={state.pais_atual_visto}
                        />
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Situação imigratoria"}
                                selected={situacaoImigratoriaOptions.filter((opt: any) => opt.value === state.situacao_imigratoria)}
                                options={situacaoImigratoriaOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'situacao_imigratoria')}
                            />
                        </div>
                        <Input
                            className={'md:col-span-2'}
                            large={true}
                            required={true}
                            label={'Motivo da viagem'}
                            name={'motivo_viagem'}
                            setValue={e => handleChange(e,'motivo_viagem')}
                            value={state.motivo_viagem}
                        />
                    </div>
                    <div className={'grid md:grid-cols-7 gap-4 mt-5'}>
                        <Input
                            className={'col-span-3'}
                            large={true}
                            label={'Caso não seja cidadão do país; informe os motivos da sua situação imigratória atual e data de término do seu visto vigente.'}
                            name={'motivo_situacao_imigratoria'}
                            setValue={e => handleChange(e,'motivo_situacao_imigratoria')}
                            value={state.motivo_situacao_imigratoria}
                        />
                        <div className={'col-span-2 sm:mt-[10px] md:mt-[25px] xl:mt-[25px] 2xl:mt-[0]'}>
                            <InputSelect
                                label={"Quanto tempo você pretende ficar na Austrália?"}
                                selected={tempoPermanenciaOptions.filter((opt: any) => opt.value === state.quanto_tempo_pais)}
                                options={tempoPermanenciaOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'quanto_tempo_pais')}
                            />
                        </div>
                        <div className={'col-span-2 sm:mt-[10px] md:mt-[50px] xl:mt-[50px] 2xl:mt-[1.5rem]'}>
                            <InputSelect
                                label={"Quem vai viajar com você"}
                                selected={quemViajaOptions.filter((opt: any) => opt.value === state.quem_vai_junto)}
                                options={quemViajaOptions}
                                onChangeSelect={(e: any) => handleChange(e.value, 'quem_vai_junto')}
                            />
                        </div>
                    </div>
                    <div className={'grid md:grid-cols-5 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            required={true}
                            type={'date'}
                            label={'Data da chegada'}
                            name={'data_prevista_viagem'}
                            setValue={e => handleChange(e,'data_prevista_viagem')}
                            value={state.data_prevista_viagem}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            required={true}
                            type={'date'}
                            label={'Data de partida'}
                            name={'data_prevista_retorno'}
                            setValue={e => handleChange(e,'data_prevista_retorno')}
                            value={state.data_prevista_retorno}
                        />
                        <Input
                            className={'md:col-span-3'}
                            large={true}
                            label={'Endereço completo na Austrália.'}
                            name={'endereco_pais'}
                            setValue={e => handleChange(e,'endereco_pais')}
                            value={state.endereco_pais}
                        />
                    </div>


                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'flex flex-col md:col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você já visitou ou esteve na Austrália anteriormente?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visitou_pais_anteriormente')}
                                        value={1}
                                        id="checked-visitou_pais_anteriormente-s"
                                        type="radio"
                                        checked={state.visitou_pais_anteriormente === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visitou_pais_anteriormente-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visitou_pais_anteriormente === 0 }
                                        onChange={e => handleChange(0,'visitou_pais_anteriormente')}
                                        id="checked-visitou_pais_anteriormente-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visitou_pais_anteriormente-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'md:col-span-2'}
                            large={true}
                            label={'Se você já possuiu um visto australiano, por favor insira o número do mesmo'}
                            name={'numero_visto_pais'}
                            setValue={e => handleChange(e,'numero_visto_pais')}
                            value={state.numero_visto_pais}
                        />
                    </div>


                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'flex flex-col md:col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você irá visitar outro país, saindo da Austrália e depois retornará para o país?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visita_outros_paises')}
                                        value={1}
                                        id="checked-visita_outros_paises-s"
                                        type="radio"
                                        checked={state.visita_outros_paises === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visita_outros_paises-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visita_outros_paises === 0 }
                                        onChange={e => handleChange(0,'visita_outros_paises')}
                                        id="checked-visita_outros_paises-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visita_outros_paises-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={'flex flex-col md:col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Pretende estudar na Austrália por até 12 semanas, durante sua estadia no país?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'estudar_no_pais')}
                                        value={1}
                                        id="checked-estudar_no_pais-s"
                                        type="radio"
                                        checked={state.estudar_no_pais === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-estudar_no_pais-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.estudar_no_pais === 0 }
                                        onChange={e => handleChange(0,'estudar_no_pais')}
                                        id="checked-estudar_no_pais-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-estudar_no_pais-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Em caso positivo, informar nome da escola e curso a ser estudado'}
                            name={'informacoes_estudo_no_pais'}
                            setValue={e => handleChange(e,'informacoes_estudo_no_pais')}
                            value={state.informacoes_estudo_no_pais}
                        />
                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'flex flex-col md:col-span-3'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você tem algum familiar, amigo, ou conhecido na Austrália, que vai visitar durante sua estadia?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'familiar_no_pais')}
                                        value={1}
                                        id="checked-familiar_no_pais-s"
                                        type="radio"
                                        checked={state.familiar_no_pais === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-familiar_no_pais-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.familiar_no_pais === 0 }
                                        onChange={e => handleChange(0,'familiar_no_pais')}
                                        id="checked-familiar_no_pais-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-familiar_no_pais-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-3'}
                            large={true}
                            label={'Parentes na Austrália'}
                            name={'nome_familiares_pais'}
                            setValue={e => handleChange(e,'nome_familiares_pais')}
                            value={state.nome_familiares_pais}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-3'}
                            large={true}
                            label={'Informe nome completo, data de nascimento de cada um deles e se viajarão com você'}
                            name={'dados_familiar'}
                            setValue={e => handleChange(e,'dados_familiar')}
                            value={state.dados_familiar}
                        />
                    </div>
                    <div className={'grid md:grid-cols-6 gap-4 mt-5'}>
                        <div className={'flex flex-col md:col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você tem filhos?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'filhos')}
                                        value={1}
                                        id="checked-filhos-s"
                                        type="radio"
                                        checked={state.filhos === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-filhos-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.filhos === 0 }
                                        onChange={e => handleChange(0,'filhos')}
                                        id="checked-filhos-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-filhos-n"
                                        className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'md:col-span-5'}
                            large={true}
                            required={!!state.filhos}
                            label={'Informe nome completo, data de nascimento e parentesco dos seus acompanhantes na viagem.'}
                            name={'informacoes_filhos'}
                            setValue={e => handleChange(e,'informacoes_filhos')}
                            value={state.informacoes_filhos}
                        />
                    </div>
                    {/*<div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-3'}
                            large={true}
                            label={'Parentes que ficam no Brasil.'}
                            name={'familiares_brasil'}
                            setValue={e => handleChange(e,'familiares_brasil')}
                            value={state.familiares_brasil}
                        />
                    </div>*/}
                    <div className={'mt-3 flex flex-col'}>
                        <label
                            className="text-sm"
                        >
                            Parentes que ficam no Brasil <span className={'text-[#c22929]'}>*</span>
                        </label>
                        <textarea
                            required={true}
                            className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                            rows={5}
                            cols={50}
                            name={'descricao'}
                            onChange={e => handleChange(e.target.value.toUpperCase(),'familiares_brasil')}
                            value={state.familiares_brasil ? state.familiares_brasil : ""}
                        />
                        <div className={'text-sm text-gray-400'}>
                            Caso tenha parentes colocar o nome, Data de nascimento e Parentesco
                        </div>
                    </div>

                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero -1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}