import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import caraterPessoalApi from "../../../../../services/caraterPessoalApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";

export interface IDeclaracaoCaraterPessoal{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DeclaracaoCaraterPessoal({processo, setProcesso, setTelaNumero, telanumero}: IDeclaracaoCaraterPessoal) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        condenado_por_crime: 0,
        dados_condenado_por_crime: '',
        acusado_por_crime: 0,
        dados_acusado_por_crime: '',
        acusado_por_delito: 0,
        dados_acusado_por_delito: '',
        deportado: 0,
        dados_deportado: '',
        deixou_pais: 0,
        dados_deixou_pais: '',
        expulso_pais: 0,
        dados_expulso_pais: '',
        crimes_guerra: 0,
        dados_crimes_guerra: '',
        serviu_forcas_armadas: 0,
        atividade_risco_pais: 0,
        dados_atividade_risco_pais: '',
        dividas_pais: 0,
        dados_dividas_pais: '',
        movimento_ilegal: 0,
        dados_movimento_ilegal: '',
        serviu_forcas_militares: 0,
        dados_forcas_militares: '',
    });
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const getDados = async () => {
        const {data, isError} = await caraterPessoalApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                console.log(data);
                setState(data.data);
            }
        }
    }
    useEffect(()=>{getDados()}, [])
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await caraterPessoalApi.update(state.id, state) : await caraterPessoalApi.create(state);
        if (isError){

            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }

        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    return(
        <>
            <HeaderForm
                titulo={'Declaração de caráter pessoal'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'p-5'}>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Foi condenado(a) por um crime ou delito em qualquer país (incluindo qualquer caso que agora tenha sido removido dos registros oficiais)
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'condenado_por_crime')}
                                            value={1}
                                            id="checked-condenado_por_crime-s"
                                            type="radio"
                                            checked={state.condenado_por_crime === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-condenado_por_crime-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.condenado_por_crime === 0 }
                                            onChange={e => handleChange(0,'condenado_por_crime')}
                                            id="checked-condenado_por_crime-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-condenado_por_crime-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'col-span-1 mt-3 flex flex-col'}>
                                <label
                                    className="font-medium"
                                >
                                    Caso tenha respondido "Sim", especifique {!!state.condenado_por_crime ? <span className={'text-[#c22929]'}>*</span> : ''}
                                </label>
                                <textarea
                                    required={!!state.condenado_por_crime}
                                    className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                    rows={2}
                                    cols={50}
                                    name={'dados_condenado_por_crime'}
                                    onChange={e => handleChange(e.target.value,'dados_condenado_por_crime')}
                                    value={state.dados_condenado_por_crime ? state.dados_condenado_por_crime : ""}
                                />
                            </div>
                        </div>

                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Foi acusado(a) de qualquer crime que está aguardando uma ação legal?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'acusado_por_crime')}
                                            value={1}
                                            id="checked-acusado_por_crime-s"
                                            type="radio"
                                            checked={state.acusado_por_crime === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-acusado_por_crime-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.acusado_por_crime === 0 }
                                            onChange={e => handleChange(0,'acusado_por_crime')}
                                            id="checked-acusado_por_crime-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-acusado_por_crime-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'col-span-1 mt-3 flex flex-col'}>
                                <label
                                    className="font-medium"
                                >
                                    Caso tenha respondido "Sim", especifique {!!state.acusado_por_crime ? <span className={'text-[#c22929]'}>*</span> : ''}
                                </label>
                                <textarea
                                    required={!!state.acusado_por_crime}
                                    className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                    rows={2}
                                    cols={50}
                                    name={'dados_acusado_por_crime'}
                                    onChange={e => handleChange(e.target.value,'dados_acusado_por_crime')}
                                    value={state.dados_acusado_por_crime ? state.dados_acusado_por_crime : ""}
                                />
                            </div>
                        </div>

                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Foi acusado(a) de qualquer delito criminal ou outro em razão de doença mental, loucura ou debilidade mental?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'acusado_por_delito')}
                                            value={1}
                                            id="checked-acusado_por_delito-s"
                                            type="radio"
                                            checked={state.acusado_por_delito === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-acusado_por_delito-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.acusado_por_delito === 0 }
                                            onChange={e => handleChange(0,'acusado_por_delito')}
                                            id="checked-acusado_por_delito-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-acusado_por_delito-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'col-span-1 mt-3 flex flex-col'}>
                                <label
                                    className="font-medium"
                                >
                                    Caso tenha respondido "Sim", especifique {!!state.acusado_por_delito ? <span className={'text-[#c22929]'}>*</span> : ''}
                                </label>
                                <textarea
                                    required={!!state.acusado_por_delito}
                                    className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                    rows={2}
                                    cols={50}
                                    name={'dados_acusado_por_delito'}
                                    onChange={e => handleChange(e.target.value,'dados_acusado_por_delito')}
                                    value={state.dados_acusado_por_delito ? state.dados_acusado_por_delito : ""}
                                />
                            </div>
                        </div>

                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Foi expulso(a) ou deportado(a) de qualquer país?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'deportado')}
                                            value={1}
                                            id="checked-deportado-s"
                                            type="radio"
                                            checked={state.deportado === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-deportado-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.deportado === 0 }
                                            onChange={e => handleChange(0,'deportado')}
                                            id="checked-deportado-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-deportado-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'col-span-1 mt-3 flex flex-col'}>
                                <label
                                    className="font-medium"
                                >
                                    Caso tenha respondido "Sim", especifique {!!state.deportado ? <span className={'text-[#c22929]'}>*</span> : ''}
                                </label>
                                <textarea
                                    required={!!state.deportado}
                                    className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                    rows={2}
                                    cols={50}
                                    name={'dados_deportado'}
                                    onChange={e => handleChange(e.target.value,'dados_deportado')}
                                    value={state.dados_deportado ? state.dados_deportado : ""}
                                />
                            </div>
                        </div>


                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Deixou algum país para evitar ser expulso(a) ou deportado(a)?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'deixou_pais')}
                                            value={1}
                                            id="checked-deixou_pais-s"
                                            type="radio"
                                            checked={state.deixou_pais === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-deixou_pais-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.deixou_pais === 0 }
                                            onChange={e => handleChange(0,'deixou_pais')}
                                            id="checked-deixou_pais-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-deixou_pais-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'col-span-1 mt-3 flex flex-col'}>
                                <label
                                    className="font-medium"
                                >
                                    Caso tenha respondido "Sim", especifique {!!state.deixou_pais ? <span className={'text-[#c22929]'}>*</span> : ''}
                                </label>
                                <textarea
                                    required={!!state.deixou_pais}
                                    className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                    rows={2}
                                    cols={50}
                                    name={'dados_deixou_pais'}
                                    onChange={e => handleChange(e.target.value,'dados_deixou_pais')}
                                    value={state.dados_deixou_pais ? state.dados_deixou_pais : ""}
                                />
                            </div>
                        </div>


                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Foi expulso(a) ou teve um pedido para sair de qualquer país?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'expulso_pais')}
                                            value={1}
                                            id="checked-expulso_pais-s"
                                            type="radio"
                                            checked={state.expulso_pais === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-expulso_pais-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.expulso_pais === 0 }
                                            onChange={e => handleChange(0,'expulso_pais')}
                                            id="checked-expulso_pais-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-expulso_pais-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'col-span-1 mt-3 flex flex-col'}>
                                <label
                                    className="font-medium"
                                >
                                    Caso tenha respondido "Sim", especifique {!!state.expulso_pais ? <span className={'text-[#c22929]'}>*</span> : ''}
                                </label>
                                <textarea
                                    required={!!state.expulso_pais}
                                    className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                    rows={2}
                                    cols={50}
                                    name={'dados_expulso_pais'}
                                    onChange={e => handleChange(e.target.value,'dados_expulso_pais')}
                                    value={state.dados_expulso_pais ? state.dados_expulso_pais : ""}
                                />
                            </div>
                        </div>

                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Cometeu, ou se envolveu na prática de crimes de guerra contra a humanidade ou direitos humanos?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'crimes_guerra')}
                                            value={1}
                                            id="checked-crimes_guerra-s"
                                            type="radio"
                                            checked={state.crimes_guerra === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-crimes_guerra-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.crimes_guerra === 0 }
                                            onChange={e => handleChange(0,'crimes_guerra')}
                                            id="checked-crimes_guerra-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-crimes_guerra-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'col-span-1 mt-3 flex flex-col'}>
                                <label
                                    className="font-medium"
                                >
                                    Caso tenha respondido "Sim", especifique {!!state.crimes_guerra ? <span className={'text-[#c22929]'}>*</span> : ''}
                                </label>
                                <textarea
                                    required={!!state.crimes_guerra}
                                    className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                    rows={2}
                                    cols={50}
                                    name={'dados_crimes_guerra'}
                                    onChange={e => handleChange(e.target.value,'dados_crimes_guerra')}
                                    value={state.dados_crimes_guerra ? state.dados_crimes_guerra : ""}
                                />
                            </div>
                        </div>


                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Serviu nas forças armadas, policiais militares ou paramilitares, voluntárias ou outro não, no território da ex-Yugoslavia* entre 1991-2000?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'serviu_forcas_armadas')}
                                            value={1}
                                            id="checked-serviu_forcas_armadas-s"
                                            type="radio"
                                            checked={state.serviu_forcas_armadas === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-serviu_forcas_armadas-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.serviu_forcas_armadas === 0 }
                                            onChange={e => handleChange(0,'serviu_forcas_armadas')}
                                            id="checked-serviu_forcas_armadas-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-serviu_forcas_armadas-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Esteve envolvidos em quaisquer atividades que possam representar um risco para a segurança nacional da Austrália?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'atividade_risco_pais')}
                                            value={1}
                                            id="checked-atividade_risco_pais-s"
                                            type="radio"
                                            checked={state.atividade_risco_pais === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-atividade_risco_pais-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.atividade_risco_pais === 0 }
                                            onChange={e => handleChange(0,'atividade_risco_pais')}
                                            id="checked-atividade_risco_pais-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-atividade_risco_pais-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'col-span-1 mt-3 flex flex-col'}>
                                <label
                                    className="font-medium"
                                >
                                    Caso tenha respondido "Sim", especifique {!!state.atividade_risco_pais ? <span className={'text-[#c22929]'}>*</span> : ''}
                                </label>
                                <textarea
                                    required={!!state.atividade_risco_pais}
                                    className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                    rows={2}
                                    cols={50}
                                    name={'dados_atividade_risco_pais'}
                                    onChange={e => handleChange(e.target.value,'dados_atividade_risco_pais')}
                                    value={state.dados_atividade_risco_pais ? state.dados_atividade_risco_pais : ""}
                                />
                            </div>
                        </div>



                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Tem quaisquer dívidas com o Governo australiano ou qualquer autoridade pública na Austrália?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'dividas_pais')}
                                            value={1}
                                            id="checked-dividas_pais-s"
                                            type="radio"
                                            checked={state.dividas_pais === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-dividas_pais-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.dividas_pais === 0 }
                                            onChange={e => handleChange(0,'dividas_pais')}
                                            id="checked-dividas_pais-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-dividas_pais-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'col-span-1 mt-3 flex flex-col'}>
                                <label
                                    className="font-medium"
                                >
                                    Caso tenha respondido "Sim", especifique {!!state.dividas_pais ? <span className={'text-[#c22929]'}>*</span> : ''}
                                </label>
                                <textarea
                                    required={!!state.dividas_pais}
                                    className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                    rows={2}
                                    cols={50}
                                    name={'dados_dividas_pais'}
                                    onChange={e => handleChange(e.target.value,'dados_dividas_pais')}
                                    value={state.dados_dividas_pais ? state.dados_dividas_pais : ""}
                                />
                            </div>
                        </div>

                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Se envolveu em qualquer atividade, ou foi acusado(a) ​​de qualquer delito, relacionado com o movimento ilegal de pessoas para qualquer país?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'movimento_ilegal')}
                                            value={1}
                                            id="checked-movimento_ilegal-s"
                                            type="radio"
                                            checked={state.movimento_ilegal === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-movimento_ilegal-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.movimento_ilegal === 0 }
                                            onChange={e => handleChange(0,'movimento_ilegal')}
                                            id="checked-movimento_ilegal-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-movimento_ilegal-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'col-span-1 mt-3 flex flex-col'}>
                                <label
                                    className="font-medium"
                                >
                                    Caso tenha respondido "Sim", especifique {!!state.movimento_ilegal ? <span className={'text-[#c22929]'}>*</span> : ''}
                                </label>
                                <textarea
                                    required={!!state.movimento_ilegal}
                                    className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                    rows={2}
                                    cols={50}
                                    name={'dados_movimento_ilegal'}
                                    onChange={e => handleChange(e.target.value,'dados_movimento_ilegal')}
                                    value={state.dados_movimento_ilegal ? state.dados_movimento_ilegal : ""}
                                />
                            </div>
                        </div>

                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'flex flex-col'}>
                                <label
                                    className="text-sm font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Serviu uma das forças militares, patrocinados pelo estado ou milícia privada, submetido(a) a qualquer treinamento militar ou paramilitar, ou treinamento no uso de armas ou explosivos, excepto no curso de serviço militar obrigatório?
                                </label>
                                <div className={'flex  gap-4 mt-[1rem]'}>
                                    <div className={'flex'}>
                                        <input
                                            onChange={e => handleChange(1,'serviu_forcas_militares')}
                                            value={1}
                                            id="checked-serviu_forcas_militares-s"
                                            type="radio"
                                            checked={state.serviu_forcas_militares === 1 }
                                            className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-serviu_forcas_militares-s"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Sim
                                        </label>
                                    </div>
                                    <div className={'flex'}>
                                        <input
                                            checked={state.serviu_forcas_militares === 0 }
                                            onChange={e => handleChange(0,'serviu_forcas_militares')}
                                            id="checked-serviu_forcas_militares-n"
                                            type="radio"
                                            value={0}
                                            className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                        />
                                        <label
                                            htmlFor="checked-serviu_forcas_militares-n"
                                            className="ms-2 text-[16px] font-medium text-gray-900 dark:text-gray-300"
                                        >
                                            Não
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'grid md:grid-cols-1 gap-4 mt-5'}>
                            <div className={'col-span-1 mt-3 flex flex-col'}>
                                <label
                                    className="font-medium text-gray-900 dark:text-gray-300"
                                >
                                    Caso tenha respondido "Sim", especifique {!!state.serviu_forcas_militares ? <span className={'text-[#c22929]'}>*</span> : ''}
                                </label>
                                <textarea
                                    required={!!state.serviu_forcas_militares}
                                    className={'bg-light border border-border rounded-md outline-none p-2  w-full'}
                                    rows={2}
                                    cols={50}
                                    name={'dados_forcas_militares'}
                                    onChange={e => handleChange(e.target.value,'dados_forcas_militares')}
                                    value={state.dados_forcas_militares ? state.dados_forcas_militares : ""}
                                />
                            </div>
                        </div>


                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}