import React from "react";
import EuaImg from '../../assets/img/eua.png';
import CaImg from '../../assets/img/cnd.png';
import AusImg from '../../assets/img/aus.png';
import {ReactComponent  as SetaSubindo} from '../../assets/icone/seta-subindo.svg';
import {ReactComponent  as SetaBaixo} from '../../assets/icone/seta-baixo.svg';
interface IPaisesDashboard {
    paises: any
}

const FieldText = (data: any) => {
    return (
        <div className={'flex flex-col ml-2 '}>
            <div className={'text-[20px] font-bold text-gray-700'}>{data.data.quantidade_paises}</div>
            {
                data.data.percent !== "0.00" ?
                Math.sign(data.data.percent) === -1 ?
                    <div className={'flex ml-1 text-[#c12929]'}>
                        <SetaBaixo/> {data.data.percent} %
                    </div>
                    :
                    <div className={'flex ml-1 text-[#1d9d68]'}>
                        <SetaSubindo/> {data.data.percent} %
                    </div>
                :
                    <div className={'flex ml-1'}>
                        {data.data.percent} %
                    </div>
            }
        </div>
    )
}


export default function PaisesDashboard({paises}: IPaisesDashboard){
    //console.log(paises['eua'].quantidade_paises);
    return(
        <div className={'flex flex-col border rounded-md w-[200px] h-[305px] border-2 p-5 md:ml-[32px]  '}>

            <div className={'font-semibold text-[20px] text-gray-700 '}>
                Países
            </div>
            <div className={'mt-5'}>
                <div className={'flex'}>
                    <img className={'w-[48px] h-[48px]'} src={EuaImg} />
                    <FieldText data={paises['eua']}/>
                </div>
                <div className={'flex mt-5'}>
                    <img className={'w-[48px] h-[48px]'} src={CaImg} />
                    <FieldText data={paises['ca']}/>
                </div>
                <div className={'flex mt-5'}>
                    <img className={'w-[48px] h-[48px]'} src={AusImg} />
                    <FieldText data={paises['aus']}/>
                </div>
            </div>
        </div>
    )
}