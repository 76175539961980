import {Line} from "rc-progress";
import React from "react";
export interface IHeaderForm {
    titulo: string;
    numeroTotal: number;
    numeroPaginalAtual: number;
    percentual?: number;
}
const HeaderForm =({titulo, numeroTotal, numeroPaginalAtual, percentual}:IHeaderForm) =>{
    return(
        <>
            <div className={'p-5 flex justify-between'}>
                <div className={'flex'}>
                    <div className={'flex items-center'}>
                        <span className={'text-[29px]'}>{titulo}</span>
                    </div>
                    <div className={'flex items-center bg-[#a2bde9] ml-3 p-2'}>
                        <span className={'text-[18px]  text-gray-600'}> {percentual ? percentual.toFixed(2) : 0}% concluído</span>
                    </div>
                </div>
                <div className={'flex items-center text-[16px]  text-gray-600'}>
                    <div>{numeroPaginalAtual}/{numeroTotal}</div>
                </div>
            </div>
            <div className={'mt-5'}>
                <Line percent={(numeroPaginalAtual * 100) / numeroTotal} strokeWidth={1} strokeColor="#061f46" />
            </div>
        </>
    )
}

export default HeaderForm;