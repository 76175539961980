import PageContainer from "../../components/container/PageContainer";
import React, {useEffect, useState} from "react";
import InputSelect from "../../components/input/InputSelect";
import {ReactComponent as ArrowLeftIcon} from "../../assets/icone/arrow-left.svg";
import {ReactComponent as ArrowRightIcon} from "../../assets/icone/arrow-right.svg";
import parceirosApi from "../../services/parceirosApi";
import { MdOutlineFileDownload } from "react-icons/md";
import relatoriosApi from "../../services/relatoriosApi";
import Table from "../../components/table/Table";
import {QueryParams} from "../../types";
export default function Relatorios() {
    const [dataString, setDataString] = useState(new Date().toISOString().slice(0, 7));
    const [dataConsulta, setDataConsulta] = useState(new Date());
    const [loading, setLoading] = useState(true);
    const [tipo, setTipo] = useState('entradas');
    const [data, setData]: any = useState({
        list: [],
        totalVendas: 0,
        quantidade: 0,
    })

    const [parceitosOptions, setParceirosOptions] = useState([])
    const [parceitosSelected, setParceirosSelected]: any = useState('')
    const [dataPaginate, setDataPaginate] = useState({
        current_page: 1,
        last_page: 2,
        total_data: 0,
        per_page: 0,
    });
    const [atualPageData, setAtualPageData] = useState(1);
    const [porPagina, setPorPagina] = useState(10);
    const [textLegenda, setTextLegenda] = useState({
        primeiroCampo: 'Entrada',
        segundoCampo: 'Receita',
    })


    const arrayMeses: any = {
        1: 'Janeiro',
        2: 'Fevereiro',
        3: 'Março',
        4: 'Abril',
        5: 'Maio',
        6: 'Junho',
        7: 'Julho',
        8: 'Agosto',
        9: 'Setembro',
        10: 'Outobro',
        11: 'Novembro',
        12: 'Dezembro',
    }

    const options = [
        {value: 'entradas',label: "Entradas"},
        {value: 'saidas',label: "Saídas"},
        {value: 'vendas-parceiro',label: "Parceiros"},
    ]
    const columnsEntradas = [
        { label: "CLIENTE", index: "cliente.usuario:name", type: 'string'},
        { label: "DATA PAGAMENTO", index: "data", type: 'date'},
        { label: "DESTINO", index: "processo.pais:nome", type: 'string' },
        { label: "TIPO VISTO", index: "processo.tipo_visto:nome", type: 'string' },
        { label: "DATA VIAGEM", index: "processo.data_prevista_viagem", type: 'date' },
        { label: "VALOR", index: "valor", type: 'currency' },
    ];

    const columnsSaidas = [
        { label: "RECEBEDOR", index: "recebedor", type: 'string'},
        { label: "DATA PAGAMENTO", index: "data", type: 'date'},
        { label: "DATA VENCIMENTO", index: "data_vencimento", type: 'date'},
        { label: "VALOR", index: "valor", type: 'currency' },
    ];

    const columnsParceiros = [
        { label: "CLIENTE", index: "cliente.usuario:name", type: 'string'},
        { label: "PARCEIRO", index: "parceiro.usuario:name", type: 'string'},
        { label: "DATA PAGAMENTO", index: "entrada.data", type: 'date'},
        { label: "DESTINO", index: "pais.nome", type: 'string' },
        { label: "TIPO VISTO", index: "tipo_visto.nome", type: 'string' },
        { label: "DATA VIAGEM", index: "data_prevista_viagem", type: 'date' },
        { label: "VALOR", index: "entrada.valor", type: 'currency' },
    ];

    const [columns, setColumns] = useState(columnsEntradas);

    async function prev(){
        dataConsulta.setDate(1);
        dataConsulta.setMonth(dataConsulta.getMonth() - 1);
        setDataConsulta(dataConsulta);
        getData(dataConsulta.toISOString().slice(0, 7))
    }

    async function next(){
        dataConsulta.setDate(1);
        dataConsulta.setMonth(dataConsulta.getMonth() + 1);
        setDataConsulta(dataConsulta);
        getData(dataConsulta.toISOString().slice(0, 7))
    }

    const getData = async (dateConsulta?: string, tipoConsulta?: string, atualPage?: number|null, parceiro_id?: number|null, porPaginaDt?: number) => {
        setLoading(true);

        const consulta = tipoConsulta ? tipoConsulta : tipo;
        const porPaginaFinal = porPaginaDt ? porPaginaDt : porPagina;

        const splitMes = dateConsulta ? dateConsulta.split('-') : dataString.split('-');
        setDataString(arrayMeses[parseInt(splitMes[1])]+'-'+splitMes[0]);
        const relatorio = await relatoriosApi.getRelatorios(consulta, `page=${atualPage ? atualPage : 1}&perPage=${porPaginaFinal}&date=${dateConsulta}${parceiro_id ? '&parceiro_id='+parceiro_id : ''}`);
        const dadosRelatorio = relatorio.data.data;
        const paginate = {
            'current_page': dadosRelatorio.list.current_page,
            'last_page': dadosRelatorio.list.last_page,
            'total_data': dadosRelatorio.list.total,
            'per_page': dadosRelatorio.list.per_page,
        };
        setDataPaginate(paginate);

        setData({
            list: dadosRelatorio.list.data,
            //totalVendas: new Intl.NumberFormat("pt-BR").format(dadosRelatorio.valorTotal),
            //totalVendas: dadosRelatorio.valorTotal.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}),
            totalVendas: Intl.NumberFormat('pt-br', {style: 'currency', currency: 'BRL'}).format(dadosRelatorio.valorTotal),
            quantidade: dadosRelatorio.quantidade,
        })

        setLoading(false);
    }

    const buscaTipo = (value: string) =>{
        setTipo(value);
        setParceirosSelected('');
        switch (value) {
            case 'entradas' :
                setColumns(columnsEntradas)
                setTextLegenda({
                    primeiroCampo: 'Entrada',
                    segundoCampo: 'Receita',
                });

                break;
            case 'saidas' :
                setColumns(columnsSaidas)
                setTextLegenda({
                    primeiroCampo: 'Saídas',
                    segundoCampo: 'Despesas',
                });
                break;
            case 'vendas-parceiro':
                setColumns(columnsParceiros)
                setTextLegenda({
                    primeiroCampo: 'Entrada',
                    segundoCampo: 'Receita',
                });
                break;
        }
        getData(dataConsulta.toISOString().slice(0, 7), value)


    }

    useEffect(() => {
        getData(dataConsulta.toISOString().slice(0, 7))
        getParceiros();
    }, [])

    const getParceiros = async () => {
        const dataParceiros = await parceirosApi.listAll();
        const optPar = dataParceiros.data.data.map((parceiros: any) => {return {value: parceiros.id, label: parceiros.usuario.name}})
        optPar.unshift({value: '', label: 'Todos'})
        setParceirosOptions(optPar);
    }

    const dataParceiro = async (e: any) =>{
        setParceirosSelected(e)
        getData(dataConsulta.toISOString().slice(0, 7), tipo, null, e)
    }

    const getExcelData = async () =>{
        await relatoriosApi.getRelatoriosExcel(tipo, dataConsulta.toISOString().slice(0, 7), parceitosSelected);
    }

    const onPageChange = (e: number) => {
        getData(dataConsulta.toISOString().slice(0, 7), tipo, e);
    }

    /*const onChangePerPage = (e: number) => {
        console.log('')
    }*/

    const onChangePerPage = (e: number) => {
        //    (dateConsulta?: string, tipoConsulta?: string, atualPage?: number|null, parceiro_id?: number) => {

        setPorPagina(e);
        getData(dataConsulta.toISOString().slice(0, 7),tipo, 1, null, e);
    }

    return(
        <PageContainer pageTitle={'Relatórios'}>
            <div className={'flex max-[600px]:flex-col w-full justify-between'}>
                {/*<div className={'grid md:grid-cols-2 gap-4'}>
                    <div className={'col-span-1'}>
                        <InputSelect
                            selected={options.filter((opt: any) => opt.value === tipo)}
                            options={options}
                            onChangeSelect={(e: any) => buscaTipo(e.value)}
                            placeholder={"Tipo"}
                            labelPlace={'Tipo'}
                        />
                    </div>
                </div>*/}
                <div className={`flex max-[600px]:flex-col md:w-[50%] md:justify-between `}>
                    <div className={'md:w-[50%]'}>
                        <InputSelect
                            selected={options.filter((opt: any) => opt.value === tipo)}
                            options={options}
                            onChangeSelect={(e: any) => buscaTipo(e.value)}
                            placeholder={"Tipo"}
                            labelPlace={'Tipo'}
                        />
                    </div>
                    {
                        tipo === 'vendas-parceiro' ?
                            <div className={'md:w-[45%]'}>
                                <InputSelect
                                    selected={parceitosOptions.filter((opt: any) => opt.value === parceitosSelected)}
                                    options={parceitosOptions}
                                    onChangeSelect={(e: any) => dataParceiro(e.value)}
                                    placeholder={"Parceiro"}
                                    labelPlace={'Parceiro'}
                                />
                            </div>
                            :
                            ''
                    }

                </div>
                <div className={'flex max-[600px]:flex-col md:w-[50%] md:justify-between'}>
                    {
                        loading ?
                            ''
                            :
                            <>
                                <div className={'flex flex-col md:ml-5'}>
                                    <div className={'text-[16px] text-gray-600'}>
                                        {textLegenda.primeiroCampo}
                                    </div>
                                    <div className={'text-[18px] text-gray-800 font-bold'}>
                                        {data.quantidade}
                                    </div>
                                </div>
                                <div className={'flex flex-col'}>
                                    <div className={'text-[16px] text-gray-600'}>
                                        {textLegenda.segundoCampo}
                                    </div>
                                    <div className={'text-[18px] text-gray-800 font-bold'}>
                                        R$: {data.totalVendas}
                                    </div>
                                </div>

                                <div>
                                    <div className={'flex justify-between items-center content-center border rounded-md border-gray-300 p-2 w-[329px] text-[#082a60]'}>
                                        <div className={'cursor-pointer'} onClick={e => prev()}>
                                            <ArrowLeftIcon/>
                                        </div>
                                        <div className={'text-[20px] font-[#082a60] font-bold'}>
                                            {dataString}
                                        </div>
                                        <div className={'cursor-pointer'} onClick={e => next()}>
                                            <ArrowRightIcon/>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <button onClick={e => getExcelData()} className={'w-12 h-12 bg-[#082a60] flex justify-center items-center rounded-md'}>
                                        <MdOutlineFileDownload size={35} color={'#FFF'} />
                                    </button>
                                </div>
                            </>
                    }

                </div>
            </div>
            {
                loading ? '' :
                    <div className={'w-full mt-5'}>
                        <Table
                            columns={columns}
                            tableData={data.list}
                            data={dataPaginate}
                            onPageChange={onPageChange}
                            setPerPage={onChangePerPage}
                            onClick={(e: any) => console.log('aki')}
                            perPage={porPagina}
                        />
                    </div>
            }
        </PageContainer>
    )
}