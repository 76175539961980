import {ITableHeader} from "../../../types";
import { RiSortAsc, RiSortDesc  } from "react-icons/ri";
export default function Table ({
   columns,
   setTableOrder,
   tableOrder
}: ITableHeader) {

    return(
        <thead className={'text-left h-[48px] bg-[#fcfcfd] border border-slate-300 '}>
            <tr>
                {
                    columns.map(({label, index, w, type, order}) => {

                        return (
                            <th
                                key={index}
                                className={`${w ? `w-[${w}]` : ''} ${order ? setTableOrder ? 'hover:cursor-pointer' : '' : ''}`}
                                onClick={
                                    order ?
                                        setTableOrder
                                            ? () => setTableOrder(index, tableOrder.order)
                                            : () => console.log('')
                                        : () => console.log('')}
                            >
                                <span className={'flex items-center gap-1'}>
                                    {type === 'button' ? '' : label.toUpperCase()}
                                    {
                                        type === 'button'
                                        ? ''
                                        :  order ? tableOrder.order === 'asc'
                                            ? <RiSortAsc />
                                            : <RiSortDesc />
                                            : ''
                                    }
                                </span>
                            </th>
                        )
                    })
                }
            </tr>
        </thead>
    )
}