import axios from 'axios';

import { PaginatedData, QueryParams } from '../types';

import Storage from '../helpers/Storage';

export interface PaginatedRequestResponse<T = any> {
    data: PaginatedData<T>
    isError: false;
}

export interface RequestResponse<T = any> {
    data: T;
    isError: false;
}

export interface RequestError<T = any> {
    data: T | { message: string; };
    isError: true;
}

export interface GetAllParams {
    endpoint: string;
    params?: QueryParams;
}

export interface GetOneParams {
    endpoint: string;
    id: number | string;
}

export interface DeleteParams {
    endpoint: string;
    id: number | string;
}
export interface CreateParams<T> {
    endpoint: string;
    formData: Partial<T>;
}

export interface UpdateParams<T> {
    endpoint: string;
    id: number | string;
    formData: Partial<T>;
}

const api = axios.create({
    baseURL: process.env.REACT_APP_API,
    headers: {
        "access-control-allow-origin" : "*",
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

api.defaults.headers.common['Authorization'] = 'Bearer ' + Storage.getApiToken();
export default api;

export async function getAll<T>({
    params,
    endpoint
}: GetAllParams): Promise<RequestError | PaginatedRequestResponse<T>> {
    const page    = params?.page    ? 'page='    + params?.page    : 'page=1';
    const perPage = params?.perPage ? '&perPage=' + params?.perPage : '';
    const search  = params?.search  ? '&'  + params?.search  : '';
    const relations  = params?.relations  ? '&relations='  + params?.relations  : '';
    const withes    = params?.with  ? '&with='  + params?.with  : '';
    const where  = params?.where  ? `&where=${params?.where}`  : '';
    const orderBy  = params?.orderBy  ? `&orderBy=${params?.orderBy}`  : '&orderBy=id,desc';

    let finalEndPoint = `${endpoint}?${page}${perPage}${search}${relations}${where}${withes}${orderBy}`;

    Object.entries(params || {}).forEach(([key, value]) => {
        if (key !== 'page' && key !== 'perPage' && key !== 'search' && key !== 'relations' && key !== 'where' && key !== 'with' && key !== 'orderBy'){
            finalEndPoint += `&${key}=${value}`;
        }
    })

    try {
        const { data } = await api.get(finalEndPoint);
        return { data, isError: false };
    } catch (err: any) {
        return {
            data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export async function listAll<T>({
    endpoint,
    allConsult
}: {endpoint: string, allConsult?: any}): Promise<RequestError | RequestResponse<T[]>> {
    try {
        let finalEndpoint = endpoint
        if (allConsult){
            finalEndpoint = allConsult
        }
        const { data } = await api.get(`${finalEndpoint}`);
        return { data, isError: false };
    } catch (err: any) {
        let dataErro = err?.response?.data;
        if (err?.response?.data){
            if (err?.response?.data.error){
                dataErro = err?.response?.data.error;
            }
        }
        return {
            data: dataErro ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export async function getOne<T>({
    endpoint,
    id
}: GetOneParams): Promise<RequestError | RequestResponse<T>> {
    try {
        const { data } = await api.get(`${endpoint}/${id}`);
        return { data: data as T, isError: false };
    } catch (err: any) {
        return {
            data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export async function create<T>({
    endpoint,
    formData
}: CreateParams<T>): Promise<RequestError | RequestResponse<T>> {
    try {
        const { data } = await api.post(endpoint, formData);
        return { data, isError: false };
    } catch (err: any) {
        return {
            data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export async function deleteOne<T = any>({
    endpoint,
    id
}: DeleteParams): Promise<RequestError | RequestResponse<T>> {
    try {
        const { data } = await api.delete(`${endpoint}/${id}`);
        return { data: data as T, isError: false };
    } catch (err: any) {
        return {
            data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export async function update<T>({
    endpoint,
    id,
    formData
}: UpdateParams<T>): Promise<RequestError | RequestResponse<T>> {
    try {
        const { data } = await api.put(`${endpoint}/${id}`, formData);
        return { data, isError: false };
    } catch (err: any) {
        return {
            data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
            isError: true
        };
    }
}

export function makeCrudObject<T>(endpoint: any) {
    return {
        all: async (params?: QueryParams) => getAll<T>({ endpoint, params }),
        one: async (id: string) => getOne<T>({ endpoint, id }),
        destroy: async (id: string | number) => deleteOne({ endpoint, id }),
        create: async (formData: Partial<T>) => create<T>({ endpoint, formData }),
        update: async (id: string | number, formData: Partial<T>) => update<T>({ endpoint, formData, id }),
    };
}