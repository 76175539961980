import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import React, {useEffect, useState} from "react";
import permissaoEstudosApi from "../../../../../services/permissaoEstudosApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import detalhesEmpregaticiosEstudoAposentadoriaApi
    from "../../../../../services/detalhesEmpregaticiosEstudoAposentadoriaApi";
import Input from "../../../../../components/input/Input";

export interface IDetalhesEmpregaticiosEstudoAposentadoria{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DetalhesEmpregaticiosEstudoAposentadoria({processo, setProcesso, setTelaNumero, telanumero}: IDetalhesEmpregaticiosEstudoAposentadoria) {
    const [state, setState] = useState({
        id:'',
        processo_id: processo.id,
        profissao1: '',
        nome_contratante1: '',
        cidade1: '',
        pais1: '',
        data_inicio1: '',
        data_fim1: '',
        profissao2: '',
        nome_contratante2: '',
        cidade2: '',
        pais2: '',
        data_inicio2: '',
        data_fim2: '',
        profissao3: '',
        nome_contratante3: '',
        cidade3: '',
        pais3: '',
        data_inicio3: '',
        data_fim3: '',
    });
    const getData = async () => {
        const {data, isError} = await detalhesEmpregaticiosEstudoAposentadoriaApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }
    }

    useEffect(() => {
        getData()
    }, []);
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await detalhesEmpregaticiosEstudoAposentadoriaApi.update(state.id, state) : await detalhesEmpregaticiosEstudoAposentadoriaApi.create(state);
        if (isError){

            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telanumero + 1);
    }
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    return(
        <>
            <HeaderForm
                titulo={'Detalhes de Estudo / Emprego anterior'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'1 - Ocupação ou profissão'}
                            required={true}
                            name={'profissao1'}
                            setValue={e => handleChange(e,'profissao1')}
                            value={state.profissao1}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome do empregador ou Instituição de Ensino'}
                            required={true}
                            name={'nome_contratante1'}
                            setValue={e => handleChange(e,'nome_contratante1')}
                            value={state.nome_contratante1}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Cidade'}
                            required={true}
                            name={'cidade1'}
                            setValue={e => handleChange(e,'cidade1')}
                            value={state.cidade1}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Pais'}
                            required={true}
                            name={'pais1'}
                            setValue={e => handleChange(e,'pais1')}
                            value={state.pais1}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Data de início'}
                            type={'date'}
                            required={true}
                            name={'data_inicio1'}
                            setValue={e => handleChange(e,'data_inicio1')}
                            value={state.data_inicio1}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Data do término'}
                            type={'date'}
                            required={true}
                            name={'data_fim1'}
                            setValue={e => handleChange(e,'data_fim1')}
                            value={state.data_fim1}
                        />

                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'2 - Ocupação ou profissão'}
                            name={'profissao2'}
                            setValue={e => handleChange(e,'profissao2')}
                            value={state.profissao2}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome do empregador ou Instituição de Ensino'}
                            name={'nome_contratante2'}
                            setValue={e => handleChange(e,'nome_contratante2')}
                            value={state.nome_contratante2}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Cidade'}
                            name={'cidade2'}
                            setValue={e => handleChange(e,'cidade2')}
                            value={state.cidade2}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Pais'}
                            name={'pais2'}
                            setValue={e => handleChange(e,'pais2')}
                            value={state.pais2}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Data de início'}
                            type={'date'}
                            name={'data_inicio2'}
                            setValue={e => handleChange(e,'data_inicio2')}
                            value={state.data_inicio2}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Data do término'}
                            type={'date'}
                            name={'data_fim2'}
                            setValue={e => handleChange(e,'data_fim2')}
                            value={state.data_fim2}
                        />

                    </div>

                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'3 - Ocupação ou profissão'}
                            name={'profissao3'}
                            setValue={e => handleChange(e,'profissao3')}
                            value={state.profissao3}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Nome do empregador ou Instituição de Ensino'}
                            name={'nome_contratante3'}
                            setValue={e => handleChange(e,'nome_contratante3')}
                            value={state.nome_contratante3}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Cidade'}
                            name={'cidade3'}
                            setValue={e => handleChange(e,'cidade3')}
                            value={state.cidade3}
                        />
                    </div>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Pais'}
                            name={'pais3'}
                            setValue={e => handleChange(e,'pais3')}
                            value={state.pais3}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Data de início'}
                            type={'date'}
                            name={'data_inicio3'}
                            setValue={e => handleChange(e,'data_inicio3')}
                            value={state.data_inicio3}
                        />
                        <Input
                            className={'md:col-span-1'}
                            large={true}
                            label={'Data do término'}
                            type={'date'}
                            name={'data_fim3'}
                            setValue={e => handleChange(e,'data_fim3')}
                            value={state.data_fim3}
                        />

                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero - 1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}