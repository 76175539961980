import { QueryParams } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = '/user';

export default {
    all: async (params?: QueryParams) => getAll<any>({ endpoint, params }),
    listAll: async () => listAll<any>({ endpoint }),
    one: async (id: string) => getOne<any>({ endpoint, id }),
    destroy: async (id: string) => deleteOne({ endpoint, id }),
    create: async (formData: Partial<any>) => create<any>({ endpoint, formData }),
    update: async (id: string, formData: Partial<any>) => update<any>({ endpoint, formData, id }),
    listUsersAdmins: async () => {
        try {
            const { data } = await api.get(endpoint+'?tipoUsuario=1,2');
            return { data, isError: false };
        }catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },
    aceitoTermos: async () => {
        try {
            const { data } = await api.post(endpoint+'/termos/aceito');
            return { data, isError: false };
        }catch (err: any) {
            console.log(err);
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    }
};
