import quantidade from "../../../../../helpers/QuantidadeFormularios";
import HeaderForm from "../../../../../components/headerForm";
import React, {useEffect, useState} from "react";
import Form from "../../../../../components/form/Form";
import Button from "../../../../../components/button/Button";
import perguntaSegurancaApi from "../../../../../services/perguntaSegurancaApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import InputSelect from "../../../../../components/input/InputSelect";

export interface IDadosBiometria{
    processo?: any;
    setTelaNumero?: any;
    telanumero?: any;
    setProcesso?: any;
}
export default function DadosBiometria({processo, setTelaNumero, telanumero, setProcesso}: IDadosBiometria) {
    const [state, setState] = useState({
        processo_id: processo.id,
        local_colheita_biometria: processo.local_colheita_biometria ? processo.local_colheita_biometria : 'casv_brasilia'
    });
    const [atualizacao, setAtualizacao] = useState(false);
    const locaisOptions = [
        { value: 'casv_brasilia', label: 'CASV Brasília' },
        { value: 'casv_rio_janeiro', label: 'CASV Rio de Janeiro' },
        { value: 'casv_recife', label: 'CASV Recife' },
        { value: 'casv_belo_horizonte', label: 'CASV Belo Horizonte' },
        { value: 'casv_sao_paulo', label: 'CASV São Paulo' },
        { value: 'casv_porto_alegre', label: 'CASV Porto Alegre' },
        { value: 'menor_quatorze', label: 'Estou isento(a) desta etapa por ter menos de 14 anos.' },
        { value: 'mais_oitenta', label: 'Estou isento(a) desta etapa por ter mais de 80 anos.' },
    ]
    async function onSubmit() {
        const {data, isError} = await processosApi.update(state.processo_id, state);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!atualizacao){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        const arr = state.local_colheita_biometria.split("_");
        let numero = telanumero;
        if (arr[0] === 'casv'){
            numero = telanumero + 1
        }else{
            numero = telanumero + 3
        }
        setTelaNumero(numero);
    }
    const getDataProcesso = async () => {
        const {data, isError} = await processosApi.one(processo.id);
        if (!isError) {
            if (Object.values(data.data).length) {
                setProcesso(data.data);
                setState({
                    processo_id: processo.id,
                    local_colheita_biometria: data.data.local_colheita_biometria ? data.data.local_colheita_biometria : 'casv_brasilia',
                })
                setAtualizacao(true);
            }
        }
    }
    useEffect(() => {
        getDataProcesso()
    }, [])
    return(
        <>
            <HeaderForm
                titulo={'Local de coleta dos dados biométricos'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>

                        <div className={'col-span-2'}>
                            <InputSelect
                                label={"CASV de sua preferência"}
                                selected={locaisOptions.filter((opt: any) => opt.value === state.local_colheita_biometria)}
                                options={locaisOptions}
                                onChangeSelect={(e: any) => setState({...state, ['local_colheita_biometria']: e.value})}
                            />
                            <div className={'text-gray-400 text-sm'}>
                                Escolha o local onde fará a colheita dos dados biométricos, ou informe caso seja isento(a).
                            </div>
                        </div>

                    </div>
                </div>

                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}