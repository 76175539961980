import HeaderForm from "../../../../../components/headerForm";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import Form from "../../../../../components/form/Form";
import React, {useEffect, useState} from "react";
import Input from "../../../../../components/input/Input";
import InputSelect from "../../../../../components/input/InputSelect";
import Button from "../../../../../components/button/Button";
import nivelInglesApi from "../../../../../services/nivelInglesApi";
import Alert from "../../../../../helpers/Alert";
import processosApi from "../../../../../services/processosApi";
import vistosApi from "../../../../../services/vistosApi";

export interface IDadosVistosAnterioresAusEstudante{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosVistosAnterioresAusEstudante({processo, setProcesso, setTelaNumero, telanumero}: IDadosVistosAnterioresAusEstudante) {
    const [state, setState] = useState({
        id: '',
        processo_id: processo.id,
        aplicou_tipo_visto: 0,
        situacao_final: 'Aprovado',
        tipo_visto_solicitado: 'Visitor',
        visto_valido: 0,
        aguardando_resposta_processo: 0,
        informacao_resposta_processo: '',
        estadia_maior: 0,
        especifique_estadia_maior: '',
        aplicado_como: '',
        visto_aprovado_outro: 0,
        informacao_visto_aprovado_outro: '',
        visto_negado_outro: 0,
        informacao_visto_negado_outro: '',
        visto_aprovado: 0,
        informacao_visto_aprovado: '',
    });
    const optionsSituacaoVisto = [
        { value: 'Aprovado', label: 'Aprovado' },
        { value: 'Negado', label: 'Negado' },
        { value: 'Invalidado', label: 'Invalidado' },
        { value: 'Retirado', label: 'Retirado' },
        { value: 'Outros', label: 'Outros' },
    ];
    const optionsTipoVisto = [
        { value: 'Visitante', label: 'Visitor' },
        { value: 'Estudante', label: 'Student' },
        { value: 'Trabalho', label: 'Work' },
        { value: 'Transito', label: 'Transit' },
        { value: 'Outros', label: 'Outros' },
    ];
    const optionsAplicado = [
        { value: 'requerente_principal', label: 'Requerente principal, solicitando um visto de estudo para a Austrália' },
        { value: 'acompanhante', label: 'Um acompanhante, que irá viajar com um estudante para a Austrália.' },
    ];
    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    const getDados = async () => {
        const {data, isError} = await vistosApi.one(processo.id);
        if (!isError){
            if (Object.values(data.data).length){
                setState(data.data);
            }
        }

    }
    useEffect(() => {
        getDados()
    },[])
    const onSubmit = async () => {
        Alert.await("Salvando dados");
        const {data, isError} = state.id ? await vistosApi.update(state.id, state) : await vistosApi.create(state);
        if (isError){
            if (typeof data === 'object') {
                Object.keys(data.errors).forEach(function(key, index) {
                    Alert.error(data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, ['percet_conclusao']: percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        let numero = telanumero + 1;
        setTelaNumero(numero);
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados viagens anteriores'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você já aplicou para algum tipo de visto australiano?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'aplicou_tipo_visto')}
                                        value={1}
                                        id="checked-aplicou_tipo_visto-s"
                                        type="radio"
                                        checked={state.aplicou_tipo_visto === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-aplicou_tipo_visto-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.aplicou_tipo_visto === 0 }
                                        onChange={e => handleChange(0,'aplicou_tipo_visto')}
                                        id="checked-aplicou_tipo_visto-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-aplicou_tipo_visto-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Caso tenha respondido Sim, qual?"}
                                selected={optionsSituacaoVisto.filter((opt: any) => opt.value === state.situacao_final)}
                                options={optionsSituacaoVisto}
                                onChangeSelect={(e: any) => handleChange(e.value, 'situacao_final')}
                            />
                        </div>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Qual o tipo de visto solicitado?"}
                                selected={optionsTipoVisto.filter((opt: any) => opt.value === state.tipo_visto_solicitado)}
                                options={optionsTipoVisto}
                                onChangeSelect={(e: any) => handleChange(e.value, 'tipo_visto_solicitado')}
                            />
                        </div>

                    </div>
                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você está aguardando a resposta de algum processo de visto australiano atualmente?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'aguardando_resposta_processo')}
                                        value={1}
                                        id="checked-aguardando_resposta_processo-s"
                                        type="radio"
                                        checked={state.aguardando_resposta_processo === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-aguardando_resposta_processo-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.aguardando_resposta_processo === 0 }
                                        onChange={e => handleChange(0,'aguardando_resposta_processo')}
                                        id="checked-aguardando_resposta_processo-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-aguardando_resposta_processo-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            required={!!state.aguardando_resposta_processo}
                            className={'col-span-1'}
                            label={'Caso tenha respondido "Sim", especifique os dados abaixo'}
                            placeholder={'Caso tenha respondido "Sim", especifique os dados abaixo'}
                            large={true}
                            name={'informacao_resposta_processo'}
                            setValue={e => handleChange(e,'informacao_resposta_processo')}
                            value={state.informacao_resposta_processo}
                            legenda={'(nome do aplicante, tipo de visto, país da emissão, data da emissão e o número do visto)'}
                        />

                    </div>

                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você, ou outra pessoa incluída neste processo, já esteve na Austrália por um período maior que o concedido no visto, ou então desrespeitou alguma regra consular durante sua estadia? - Teve a entrada negada, ou o visto negado para a Austrália?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'estadia_maior')}
                                        value={1}
                                        id="checked-estadia_maior-s"
                                        type="radio"
                                        checked={state.estadia_maior === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-estadia_maior-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.estadia_maior === 0 }
                                        onChange={e => handleChange(0,'estadia_maior')}
                                        id="checked-estadia_maior-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-estadia_maior-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            className={'col-span-1'}
                            label={'Caso tenha respondido "Sim", especifique:'}
                            placeholder={'Caso tenha respondido "Sim", especifique:'}
                            large={true}
                            required={!!state.estadia_maior}
                            name={'especifique_estadia_maior'}
                            setValue={e => handleChange(e,'especifique_estadia_maior')}
                            value={state.especifique_estadia_maior}
                        />

                    </div>

                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você possui um visto australiano válido?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_valido')}
                                        value={1}
                                        id="checked-visto_valido-s"
                                        type="radio"
                                        checked={state.visto_valido === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_valido-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_valido === 0 }
                                        onChange={e => handleChange(0,'visto_valido')}
                                        id="checked-visto_valido-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_valido-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Você está aplicando o visto como:"}
                                selected={optionsAplicado.filter((opt: any) => opt.value === state.aplicado_como)}
                                options={optionsAplicado}
                                onChangeSelect={(e: any) => handleChange(e.value, 'aplicado_como')}
                            />
                        </div>

                    </div>

                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você já teve algum visto aprovado para outro país?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_aprovado_outro')}
                                        value={1}
                                        id="checked-visto_aprovado_outro-s"
                                        type="radio"
                                        checked={state.visto_aprovado_outro === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_aprovado_outro-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_aprovado_outro === 0 }
                                        onChange={e => handleChange(0,'visto_aprovado_outro')}
                                        id="checked-visto_aprovado_outro-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_aprovado_outro-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            required={!!state.visto_aprovado_outro}
                            className={'col-span-1'}
                            label={'Por favor, informe o nome do país e o tipo de visto'}
                            placeholder={'Por favor, informe o nome do país e o tipo de visto'}
                            large={true}
                            name={'informacao_visto_aprovado_outro'}
                            setValue={e => handleChange(e,'informacao_visto_aprovado_outro')}
                            value={state.informacao_visto_aprovado_outro}
                        />

                    </div>

                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você já teve algum visto negado para qualquer país?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_negado_outro')}
                                        value={1}
                                        id="checked-visto_negado_outro-s"
                                        type="radio"
                                        checked={state.visto_negado_outro === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_negado_outro-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_negado_outro === 0 }
                                        onChange={e => handleChange(0,'visto_negado_outro')}
                                        id="checked-visto_negado_outro-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_negado_outro-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            required={!!state.visto_negado_outro}
                            className={'col-span-1'}
                            label={'Por favor, informe o nome do país e o tipo de visto'}
                            placeholder={'Por favor, informe o nome do país e o tipo de visto'}
                            large={true}
                            name={'informacao_visto_negado_outro'}
                            setValue={e => handleChange(e,'informacao_visto_negado_outro')}
                            value={state.informacao_visto_negado_outro}
                        />

                    </div>

                    <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                        <div className={'flex flex-col col-span-1'}>
                            <label
                                className="text-sm font-medium  "
                            >
                                Você possui algum visto aprovado neste momento?
                            </label>
                            <div className={'flex  gap-4 mt-[1rem]'}>
                                <div className={'flex'}>
                                    <input
                                        onChange={e => handleChange(1,'visto_aprovado')}
                                        value={1}
                                        id="checked-visto_aprovado-s"
                                        type="radio"
                                        checked={state.visto_aprovado === 1 }
                                        className="w-5 h-5 text-[#061f46] bg-gray-100 accent-[#061f46] border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-[#fff] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_aprovado-s"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Sim
                                    </label>
                                </div>
                                <div className={'flex'}>
                                    <input
                                        checked={state.visto_aprovado === 0 }
                                        onChange={e => handleChange(0,'visto_aprovado')}
                                        id="checked-visto_aprovado-n"
                                        type="radio"
                                        value={0}
                                        className="w-5 h-5 text-blue-600 accent-[#061f46] bg-gray-100 border-gray-300 rounded-full focus:ring-[#fff] dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                    />
                                    <label
                                        htmlFor="checked-visto_aprovado-n"
                                        className="ms-2 text-[16px] font-medium  "
                                    >
                                        Não
                                    </label>
                                </div>
                            </div>
                        </div>
                        <Input
                            required={!!state.visto_aprovado}
                            className={'col-span-1'}
                            label={'Por favor, informe o nome do país e o tipo de visto'}
                            placeholder={'Por favor, informe o nome do país e o tipo de visto'}
                            large={true}
                            name={'informacao_visto_aprovado'}
                            setValue={e => handleChange(e,'informacao_visto_aprovado')}
                            value={state.informacao_visto_aprovado}
                        />

                    </div>

                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}