import React, {useEffect, useState} from "react";
import quantidade from "../../../../../helpers/QuantidadeFormularios";
import HeaderForm from "../../../../../components/headerForm";
import Form from "../../../../../components/form/Form";
import InputSelect from "../../../../../components/input/InputSelect";
import Input from "../../../../../components/input/Input";
import Button from "../../../../../components/button/Button";
import Alert from "../../../../../helpers/Alert";
import clientesApi from "../../../../../services/clientesApi";
import processosApi from "../../../../../services/processosApi";

export interface IDadosViagemEua{
    processo?: any;
    setProcesso?: any;
    setTelaNumero?: any;
    telanumero?: any;
}

export default function DadosViagemEua({processo, setProcesso, setTelaNumero, telanumero}: IDadosViagemEua) {

    const [state, setState] = useState({
        id: processo.id,
        motivo_viagem: 'turismo',
        data_prevista_viagem: processo.data_prevista_viagem,
        data_prevista_retorno: processo.data_prevista_retorno,
        endereco_pais: '',
        cidade_pais: '',
        estado_pais: '',
        cep_pais: '',
    });
    const [tela, setTela] = useState(7)
    const [motivosViagem, setMotivoViagem] = useState([
        { value: 'turismo', label: 'Turismo' },
        { value: 'negocios', label: 'Negócios' },
        { value: 'estudo_informal', label: 'Estudo Informal (carga horária máxima de 18 horas semanais por até 06 meses)' },
        { value: 'visita_familiar', label: 'Visita Familiar' },
    ]);

    useEffect(()=> {
        getDataProcesso();

    }, [])

    const getDataProcesso = async () =>{
        const {data, isError} = await processosApi.one(processo.id);
        if (isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        const response = data.data;
        if (response.tipo_visto_id === 2){
            setMotivoViagem(
                [
                    { value: 'estudo_carga_menor', label: 'Estudos (com carga horária inferior a 18 horas semanais)' },
                    { value: 'estudo_carga_maior', label: 'Estudos (com carga horária superior a 18 horas semanais)' },
                ]
            );
            if (response.motivo_viagem === 'estudo_carga_maior'){
                setTela(5);
            }
        }
        if (Object.values(response).length) {
            setProcesso(response);
            setState({
                id: response.id ? response.id : '',
                motivo_viagem: response.motivo_viagem ? response.motivo_viagem  : (response.tipo_visto_id === 2 ? 'estudo_carga_menor'  : 'turismo'),
                data_prevista_viagem: response.data_prevista_viagem ? response.data_prevista_viagem : '',
                data_prevista_retorno: response.data_prevista_retorno ? response.data_prevista_retorno : '',
                endereco_pais: response.endereco_pais ? response.endereco_pais : '',
                cidade_pais: response.cidade_pais ? response.cidade_pais : '',
                estado_pais: response.estado_pais ? response.estado_pais : '',
                cep_pais: response.cep_pais ? response.cep_pais : '',
            })
        }
    }

    const handleChange = (value: any, input: string) => {
        setState({...state, [input]: value});
    };
    async function onSubmit() {
        Alert.await("Salvando dados");
        const data = await processosApi.update(state.id, state);
        if (data.isError){
            if (typeof data.data === 'object') {
                Object.keys(data.data.errors).forEach(function(key, index) {
                    Alert.error(data.data.errors[key][0]);
                });
                return;
            }
            return Alert.error(data.data.message? data.data.message : data.data);
        }
        let telaFinal = tela;
        if (state.motivo_viagem === 'estudo_carga_maior'){
            telaFinal = 5;
        }
        if (!state.id){
            const percent = (telanumero * 100) / quantidade[processo.tipo_visto_id];
            await processosApi.update(processo.id, {percet_conclusao: percent})
            setProcesso({...processo, 'percet_conclusao': percent})
        }
        Alert.close();
        window.scrollTo(0, 0);
        setTelaNumero(telaFinal);
    }
    return(
        <>
            <HeaderForm
                titulo={'Dados da viagem'}
                numeroPaginalAtual={telanumero}
                numeroTotal={quantidade[processo.tipo_visto_id]}
                percentual={processo.percet_conclusao}
            />
            <Form  onSubmit={() => onSubmit()}>
                <div className={'p-5'}>
                    <div className={'grid grid-cols-1 gap-4 mt-5'}>
                        <div className={'col-span-1'}>
                            <InputSelect
                                label={"Motivo da viagem"}
                                selected={motivosViagem.filter((opt: any) => opt.value === state.motivo_viagem)}
                                options={motivosViagem}
                                onChangeSelect={(e: any) => handleChange(e.value, 'motivo_viagem')}
                                /*legenda={'Informe a finalidade da sua viagem. Caso não seja nenhum destes motivos, entre em contato com nossa Central de Atendimento, pois provavelmente este visto não se aplica a você.'}*/
                            />
                        </div>
                        <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                            <Input
                                className={'col-span-1'}
                                label={'Data de chegada aos EUA '}
                                placeholder={'Data de chegada aos EUA'}
                                large={true}
                                type={'date'}
                                name={'data_prevista_viagem'}
                                setValue={e => handleChange(e,'data_prevista_viagem')}
                                value={state.data_prevista_viagem}
                                required
                            />
                            <Input
                                className={'col-span-1'}
                                label={'Data de saída dos EUA '}
                                placeholder={'Data de saída dos EUA'}
                                large={true}
                                type={'date'}
                                name={'data_prevista_retorno'}
                                setValue={e => handleChange(e,'data_prevista_retorno')}
                                value={state.data_prevista_retorno}
                                required
                            />
                            <Input
                                className={'col-span-1'}
                                label={'Endereço completo onde ficará nos EUA '}
                                placeholder={'Endereço completo onde ficará nos EUA'}
                                large={true}
                                name={'endereco_pais'}
                                setValue={e => handleChange(e,'endereco_pais')}
                                value={state.endereco_pais}
                            />
                        </div>
                        <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                            <Input
                                className={'col-span-1'}
                                label={'Cidade onde ficará nos EUA '}
                                placeholder={'Cidade onde ficará nos EUA'}
                                name={'estado_pais'}
                                large={true}
                                setValue={e => handleChange(e,'estado_pais')}
                                value={state.estado_pais}
                                required
                            />
                            <Input
                                className={'col-span-1'}
                                label={'Estado onde ficará nos EUA'}
                                placeholder={'Estado onde ficará nos EUA'}
                                large={true}
                                name={'cidade_pais'}
                                setValue={e => handleChange(e,'cidade_pais')}
                                value={state.cidade_pais}
                            />
                            <Input
                                className={'col-span-1'}
                                label={'CEP de onde ficará nos EUA'}
                                placeholder={'CEP de onde ficará nos EUA'}
                                large={true}
                                name={'cep_pais'}
                                setValue={e => handleChange(e,'cep_pais')}
                                value={state.cep_pais}
                            />
                        </div>
                        {/*<div className={'grid grid-cols-1 gap-4 mt-5'}>
                            <Input
                                className={'col-span-1'}
                                label={'Você possui conta em alguma rede social? Se sim, informar a plataforma e nome de usuário '}
                                placeholder={'Você possui conta em alguma rede social? Se sim, informar a plataforma e nome de usuário'}
                                name={'rede_social'}
                                large={true}
                                setValue={e => handleChange(e,'rede_social')}
                                value={state.rede_social}
                                required
                            />
                        </div>*/}
                    </div>
                </div>
                <div className={'border-2'}></div>
                <div className={'flex justify-between p-5'}>
                    <Button
                        onClick={() => setTelaNumero(telanumero-1)}
                        className={'w-[164px] h-[48px] bg-white text-gray-800 border border-gray-500 font-semibold'}
                    >
                        Voltar
                    </Button>
                    <Button
                        type={'submit'}
                        className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold'}
                    >
                        Continuar
                    </Button>
                </div>
            </Form>
        </>
    )
}