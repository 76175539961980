import Form from "../../../components/form/Form";
import React, {useEffect, useState} from "react";
import paisApi from "../../../services/paisApi";
import InputSelect from "../../../components/input/InputSelect";
import Input from "../../../components/input/Input";
import InputCurrency from "../../../components/inputCurrency";
import parceirosApi from "../../../services/parceirosApi";
import Button from "../../../components/button/Button";
import {useNavigate} from "react-router-dom";
import Alert from "../../../helpers/Alert";
import Storage from "../../../helpers/Storage";

export default function DadosViagem({membros, idCliente, onSubmit}: any){
    const userData: any = Storage.getUserData();
    const navigate = useNavigate();

    const [stateViagem, setStateViagem]: any = useState({
        pais_id: '',
        tipo_visto_id: '',
        data_prevista_viagem: '',
        data_prevista_retorno: '',
        cliente_id: idCliente,
        valor_total_servico: '400,00',
        parceiro_id: '',
        indicado: userData.usuario.tipo_id === 3 ? true : false,
        membros: [],

    });
    const [membrosSelected, setMembrosSelected] = useState([]);
    const [paisesOptions, setPaisesOptions]: any = useState([]);
    const [paises, setPaises]: any = useState([]);
    const [parceirosOptions, setParceirosOptions]: any = useState([]);
    const [parceiros, setParceiros]: any = useState([]);
    const [parceiroSelecionado, setParceiroSelecionado]: any = useState({});
    const [tipoVistoOptions, setTipoVistoOptions] = useState([]);
    const handleChange = (value: any, input: string) => {
        setStateViagem({...stateViagem, [input]: value});
    };

    const getData = async () => {
        const [dataPais, dataParceiros] = await Promise.all([paisApi.listaPaises(), parceirosApi.listAll()]);
        setPaises(dataPais.data.data)
        setPaisesOptions(
            dataPais.data.data.map((pais: any) => {
                return { value: pais.id, label: pais.nome }
            })
        )
        setParceirosOptions(
            dataParceiros.data.data.map((parceiro: any) => {
                return { value: parceiro.id, label: parceiro.empresa+" - "+parceiro.usuario.name }
            })
        )
        const dtParceiros = dataParceiros.data.data;
        setParceiros(dtParceiros);


        if (userData.usuario.tipo_id === 3){
            let parceiroSelected = dtParceiros.filter((par: any) => par.usuario_id === userData.usuario.id)[0];
            setParceiroSelecionado(parceiroSelected)
            setStateViagem({...stateViagem, ['parceiro_id']: parceiroSelected.id});
        }

        /*setParceirosOptions(
            dataParceiros.data.data.map((parceiro: any) => {
                return { value: parceiro.id, label: parceiro.empresa+" - "+parceiro.usuario.name }
            })
        )*/
    }

    useEffect(() => {
        getData()
    }, []);

    const onSelectParceiro = (e: any) => {
        const dataParc = parceiros.filter((parceiro: any) => parceiro.id === e)[0];
        setParceiroSelecionado(dataParc)
        setStateViagem({...stateViagem, 'parceiro_id': e});
    }
    const onSelectPais = (e: any) => {
        const dadosPais = paises.filter((pais: any) => pais.id === e)[0];

        setTipoVistoOptions(dadosPais.tipos_vistos.map((tipoVisto: any) => {
            return { value: tipoVisto.id, label: tipoVisto.nome }
        } ))
        setStateViagem({...stateViagem, 'pais_id': e});
    }
    const submit = () => {
        if (!stateViagem.pais_id){
            Alert.error('País deve ser informado!');
            return;
        }
        if (!stateViagem.tipo_visto_id){
            Alert.error('Tipo de visto deve ser informado!');
            return;
        }
        if (!stateViagem.data_prevista_retorno){
            Alert.error('Data prevista de retorno deve ser informado!');
            return;
        }
        if (!stateViagem.data_prevista_viagem){
            Alert.error('Data prevista da viagem deve ser informado!');
            return;
        }
        if (!stateViagem.valor_total_servico){
            Alert.error('Valor assessoria visary deve ser informado!');
            return;
        }

        if (new Date(stateViagem.data_prevista_viagem) > new Date(stateViagem.data_prevista_retorno)){
            Alert.error('Data prevista da viagem deve ser maior que data prevista de retorno!');
            return;
        }

        stateViagem.valor_total_servico = parseFloat(stateViagem.valor_total_servico.replace(",", '.'));
        stateViagem.membros = membrosSelected.map((membro:any) => membro.value);

        onSubmit(stateViagem);
    }

    const onSelectMembrosViagem = (value: any) => {
        setMembrosSelected(value)

    }
    return(
        <Form className={'p-5'} onSubmit={() => submit()}>
            <div className={'grid md:grid-cols-2 gap-4 mt-5'}>
                <div className={'col-span-1'}>
                    <InputSelect
                        required={true}
                        placeholder={'Selecione'}
                        label={"País de destino"}
                        selected={paisesOptions.filter((opt: any) => opt.value === stateViagem.pais_id)}
                        options={paisesOptions}
                        onChangeSelect={(e: any) => onSelectPais(e.value)}
                    />
                </div>
                <div className={'col-span-1'}>
                    <InputSelect
                        required={true}
                        placeholder={'Selecione'}
                        label={"Tipo de visto"}
                        selected={tipoVistoOptions.filter((opt: any) => opt.value === stateViagem.tipo_visto_id)}
                        options={tipoVistoOptions}
                        onChangeSelect={(e: any) => handleChange(e.value, 'tipo_visto_id')}
                    />
                </div>
            </div>
            <div className={'grid md:grid-cols-3 gap-4 mt-5'}>
                <Input
                    className={'col-span-1'}
                    label={'Data prevista da viagem'}
                    type={'date'}
                    required={true}
                    name={'data_prevista_viagem'}
                    setValue={e => handleChange(e,'data_prevista_viagem')}
                    value={stateViagem.data_prevista_viagem}
                />
                <Input
                    className={'col-span-1'}
                    label={'Data prevista de retorno'}
                    type={'date'}
                    required={true}
                    name={'data_prevista_retorno'}
                    setValue={e => handleChange(e,'data_prevista_retorno')}
                    value={stateViagem.data_prevista_retorno}
                />
                <InputCurrency
                    label={'Valor assessoria visary'}
                    className={'col-span-1'}
                    required={true}
                    value={stateViagem.valor_total_servico}
                    onChange={(e: any) => handleChange(e,'valor_total_servico')}
                    decimalSeparator=","
                    decimalScale={2}
                />
            </div>
            <div className={'grid md:grid-cols-1 mt-5'}>
                <InputSelect
                    placeholder={'Selecione'}
                    label={"Selecionar membros"}
                    isMulti={true}
                    selected={membrosSelected}
                    options={membros ? membros.map((membro: any) => {return { value: membro.id, label: membro.nome }}) : []}
                    onChangeSelect={(e: any) => onSelectMembrosViagem(e)}
                />
            </div>
            <div className={'flex mt-4'}>
                <input
                    disabled={userData.usuario.tipo_id === 3}
                    onChange={() => handleChange(!stateViagem.indicado, 'indicado')}
                    checked={stateViagem.indicado}
                    id="checked-checkbox-2"
                    type="checkbox"
                    value=""
                    className="w-5 h-5 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                />
                <label
                    htmlFor="checked-checkbox-2"
                    className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                >
                    <div className={'flex flex-col'}>
                        <span className={'text-[16px] font-semibold'}>Viagem foi indicada por alguém?</span>
                        O parceiro só irá acompanhar o processo caso seja indicado aqui
                    </div>

                </label>
            </div>
            {

                stateViagem.indicado ?
                    <div className={'mt-[2rem]'}>

                        <div className={'grid md:grid-cols-5 gap-4 mt-5'}>
                            <div className={'md:col-span-3'}>
                                <InputSelect
                                    isDisabled={userData.usuario.tipo_id === 3}
                                    label={"Parceiro"}
                                    selected={parceirosOptions.filter((opt: any) => opt.value === stateViagem.parceiro_id)}
                                    options={parceirosOptions}
                                    onChangeSelect={(e: any) => onSelectParceiro(e.value)}
                                />
                            </div>
                            <Input
                                disabled={true}
                                className={'col-span-1'}
                                label={'Segmento'}
                                name={'segmento'}
                                value={parceiroSelecionado.segmento}
                            />
                            <Input
                                disabled={true}
                                className={'col-span-1'}
                                label={'Estado'}
                                name={'estado'}
                                value={parceiroSelecionado.estado}
                            />
                        </div>
                    </div>
                    :
                    ''
            }
            <div className={'mt-[2rem] gap-2 flex justify-end'}>
                <Button
                    onClick={() => navigate('/clientes')}
                    className={'w-[164px] h-[48px] bg-white text-[#082a60] border border-[#082a60] font-semibold mt-[1rem]'}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={!idCliente}
                    type={'submit'}
                    className={'w-[164px] h-[48px] bg-[#082a60] text-white font-semibold mt-[1rem]'}
                >
                    Salvar
                </Button>
            </div>
        </Form>
    )
}