import { QueryParams } from '../types';
import api, {getAll, create, update, getOne, deleteOne, listAll} from './api';

const endpoint = '/dashboard';

export default {
    async getData(date?: string, vendedor?: string){
        try {
            const url = `${endpoint}?data=${date}${vendedor ? '&vendedor_id='+vendedor : ''}`;
            const { data } = await api.get(url)
            return { data, isError: false };
        } catch (err: any) {
            return {
                data: err?.response?.data ?? { message: 'Falha ao processar requisição' },
                isError: true
            };
        }
    },

};
