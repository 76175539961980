import Str from "../../../helpers/Str";
import {SelectProps} from "../../../types";
import RSelect from "react-select";

export default function Select({
    className,
    label,
    loading,
    options,
    placeholder,
    multi,
    disabled,
    legenda,
    required,
    value,
    setValue
}: SelectProps){
    const inputSelectStyle = () => ({
        padding: '6px'
    });

    return(
        <div className={Str.tw('w-full', className)}>
            <div className="font-medium">
                {label}
            </div>
            <RSelect
                required={required}
                menuPlacement={'auto'}
                isMulti={multi}
                value={multi ? options.filter(option => value?.includes(option.value)) : options.find(option => option.value === value)}
                options={options}
                onChange={(v) => {
                    if (multi) {
                        setValue(v.map((option: any) => option.value))
                    } else
                    setValue(v.value)
                }}
                className="w-full"
                placeholder={placeholder ? placeholder : 'Selecione'}
                styles={
                    {
                        input: (styles) => ({ ...styles, ...inputSelectStyle() }),
                        control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: state.isFocused ? 'border-gray-300' : 'border-gray-300',
                            outline: state.isFocused ? '1px solid #d0d5dd' : 'none',
                            border: '2px solid #d0d5dd',
                            borderRadius: '0px',
                            boxShadow: state.isFocused ? '1px #d0d5dd' : '1px #d0d5dd',
                            overflow: 'auto',
                        }),
                        menu: provided => ({ ...provided, zIndex: 9999 })
                    }
                }
            />
            {
                legenda ?
                    <div className={'text-sm text-[#6c757d]'}>
                        {legenda}
                    </div>
                    :
                    ""
            }

        </div>

    )
}