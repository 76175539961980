import { useState } from 'react'
import { Tab } from '@headlessui/react'
import Button from "../button/Button";

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export interface ITabs {
    tabData: any;
    indexTabs?: any;
    setIndex?: any;
}

export default function Tabs({tabData, indexTabs, setIndex}: ITabs) {
    const [selectedIndex, setSelectedIndex] = useState(indexTabs ? indexTabs : 0)

    const onChangeTabs = (e: any) =>{
        if (indexTabs){
            setIndex(e)
        }else{
            setSelectedIndex(e)
        }
    }

    return (
        <div >
            <Tab.Group selectedIndex={indexTabs ? indexTabs : selectedIndex} onChange={onChangeTabs}>
                <Tab.List className="flex">
                    {
                        tabData.map((tab: any, index: number) => {
                            return(
                                <Tab
                                    key={tab.title+index}
                                    className={({ selected }) =>
                                        classNames(
                                            'sm:w-[15%] max-[800px]:w-[33%] rounded-tr-[1rem]  py-2.5 text-sm font-bold leading-5',
                                            selected
                                                ? 'bg-[#061f46] text-[#f8fafe] shadow'
                                                : 'bg-[#e4edfc] text-[#061f46] hover:bg-[#061f46] hover:text-[#f8fafe]'
                                        )
                                    }
                                >
                                    {tab.title}
                                </Tab>
                            )
                        })
                    }
                </Tab.List>
                <Tab.Panels className="border">
                    {
                        tabData.map((tab: any, index: number) => {
                            return(
                                <Tab.Panel
                                    key={tab+index}
                                    className={classNames(
                                        'bg-white ',
                                    )}
                                >
                                    {tab.component}
                                </Tab.Panel>
                            )
                        })
                    }

                    {/*{Object.values(categories).map((posts, idx) => (
                        <Tab.Panel
                            key={idx}
                            className={classNames(
                                'rounded-xl bg-white p-3',
                                'ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2'
                            )}
                        >
                            <ul>
                                {posts.map((post) => (
                                    <li
                                        key={post.id}
                                        className="relative rounded-md p-3 hover:bg-gray-100"
                                    >
                                        <h3 className="text-sm font-medium leading-5">
                                            {post.title}
                                        </h3>

                                        <ul className="mt-1 flex space-x-1 text-xs font-normal leading-4 text-gray-500">
                                            <li>{post.date}</li>
                                            <li>&middot;</li>
                                            <li>{post.commentCount} comments</li>
                                            <li>&middot;</li>
                                            <li>{post.shareCount} shares</li>
                                        </ul>

                                        <a
                                            href="#"
                                            className={classNames(
                                                'absolute inset-0 rounded-md',
                                                'ring-blue-400 focus:z-10 focus:outline-none focus:ring-2'
                                            )}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </Tab.Panel>
                    ))}*/}
                </Tab.Panels>
            </Tab.Group>
        </div>
    )
}